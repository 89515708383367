import React, { Component } from "react";
import { i18n } from "../i18n";


class MessageInput extends Component {
 
  handleValueChange = event => {
    this.props.onChange(this.props.selected, event.target.value);
  };
  handleSubmit = event => {
    event.preventDefault();
    this.props.onSubmit(this.props.selected);
  };
  handleKeyDown = e => {
    if (e.ctrlKey && e.which === 13) {
      this.handleSubmit(e);
    }
  };
  
  render() {
    return (
      <>
        <div style={{ height: 120 }}>
          <form onSubmit={this.handleSubmit} className="h-100">
            <div className="input-group h-100">
              <textarea
                className="h-100 form-control p-0 border-left-0 rounded-0"
                aria-label="With textarea"
                value={this.props.value}
                onChange={this.handleValueChange}
                onKeyDown={this.handleKeyDown}
              ></textarea>
              <div className="input-group-append">
                <button type="submit" className="input-group-text rounded-0">
                  {i18n.t("chat.send")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default MessageInput;
