import React, { Component } from "react";

import { i18n } from "./i18n";

import ReactPaginate from "react-paginate";
import { ConfirmButtonCustomTip } from "./ConfirmButtonCustomTip";
import { toast } from "react-toastify";
import * as Api from "./Api";

export default class VacationPayDoneDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      fileName: "",
      data: [],
      offset: 1,
      total: -1,
      forcePage: null,
      pageCount: 0,
      pageSize: 15,
      periods: [],
      // periodsStart: [],
      selectedPeriod: "",
      // selectedPeriodStart: "",
      listLoading: false,
      radioSelectedOption: "new"
    };
    this.file = null;
  }
  onFileSelect = e => {
    switch (e.target.name) {
      case "selectedFile":
        if (e.target.files.length > 0) {
          // Accessed .name from file
          const reader = new FileReader();
          reader.onload = e => {
            this.setState({ success: false });
          };
          reader.readAsDataURL(e.target.files[0]);
          this.file = e.target.files[0];
          this.setState({ fileName: e.target.files[0].name });
        }
        break;
      default:
    }
  };
  handleSubmitClick = () => {
    if (this.file) {
      this.setState({ listLoading: true });
      Api.uploadVacationPayCSV(this.file, global._token).then(response => {
        if (response.ok) {
          this.setState({ success: true, listLoading: false });
          toast.success(i18n.t("pay.uploadSuccess"), {
            className: "alert alert-success"
          });
          this.loadPayrollPeriod();
        } else {
          toast.error(i18n.t("pay.uploadFailed"), {
            className: "alert alert-danger"
          });
          this.setState({ success: false, listLoading: false });
        }
      }).catch(e => {
        this.setState({ listLoading: false });
        toast.error(e.message, { className: "alert alert-danger" });
      });;
    }
  };
  componentDidMount() {
    this.loadPayrollPeriod();
  }


  loadPayrollPeriod = () => {
    Api.getVacationPayPeroid(global._token).then(e => {
      if (e && e.count !== 0) {
        this.setState(
          {
            periods: e.items, //array .peroid,
            // periodsStart: e.items.peroidStart,
            selectedPeriod:  e.items[e.items.length - 1].value, //e.items.peroid[e.items.peroid.length - 1],
            // selectedPeriodStartEnd: e.items.peroidStart[e.items.peroid.length - 1] + '-' + e.items.peroid[e.items.peroid.length - 1]
          },
          () => this.loadDataFromServer()
        );
      } else {
        this.setState(
          {
            periods: [],
            // periodsStart: [],
            selectedPeriod: "",
            // selectedPeriodStartEnd: ""
          },
          () => this.loadDataFromServer()
        );
      }
    });
  }
  handlePageClick = data => {
    this.setState({ offset: data.selected + 1 }, () => {
      this.loadDataFromServer();
    });
  };

  loadDataFromServer = (forcePage = false) => {
    // this.setState({ listLoading: true, selectedPeriod: '20200131' });
    Api.getVacationPay(
      this.state.selectedPeriod,
      // '20200131',
      this.state.offset,
      this.state.pageSize,
      global._token
    ).then(p => {
      if (p && p.count !== 0) {
        this.setState({
          data: p.items,
          pageCount: Math.ceil(p.count / this.state.pageSize),
          forcePage: forcePage ? 0 : undefined,
          listLoading: false
        });
      } else {
        this.setState({
          data: [],
          pageCount: 0,
          forcePage: null,
          listLoading: false,
          periods: [],
          // periodsStart: [],
          selectedPeriod: "",
          // selectedPeriodStartEnd: ""
        });
      }
    }).catch(e => {
      this.setState({
        data: [],
        pageCount: 0,
        forcePage: null,
        listLoading: false,
        periods: [],
        // periodsStart: [],
        selectedPeriod: "",
        // selectedPeriodStartEnd: ""
      });
      // this.setState({ listLoading: false });
      toast.error(e.message, { className: "alert alert-danger" });
    });
  };

  handleVacationPayPeriodChange = e => {
    // this.state.periodsStart
    this.setState(
      { offset: 1, forcePage: null, selectedPeriod: e.target.value },
      () => this.loadDataFromServer(true)
    );

    // let selectedPeriodStartEnd = "";
    // this.state.periods.map((p, index) => {
    //   if (p == e.target.value) {
    //     selectedPeriod = this.state.periodsStart[index] + '-' + e.target.value;
    //     // selectedPeriodStartEnd = this.state.periodsStart[index] + '-' + e.target.value;
    //     // console.log(p+":"+index+",selectedPeriodStartEnd="+selectedPeriodStartEnd);
    //   }
    // });
    // this.setState({ selectedPeriodStartEnd: selectedPeriodStartEnd });
  };

  handleDeleteClick = () => {
    // this.setState({ listLoading: true });
    Api.deleteVacationPay(this.state.selectedPeriod, global._token)
      .then(response => {
        if (response.ok) { //if (response.ok) {
          toast.success(i18n.t("pay.payrollDeleteSuccess"), {
            className: "alert alert-success"
          });
          // this.setState({success:true, listLoading: false });
          // let timer = setTimeout(() => {
          this.loadPayrollPeriod(); //it includes loadDataFromServer();
          // console.log('This will run after 4 second!')
          // }, 4000);
          // return () => clearTimeout(timer);
        } else {
          this.loadPayrollPeriod();
          // this.setState({ listLoading: false });
          toast.error(i18n.t("pay.payrollDeleteFailed"), {
            className: "alert alert-danger"
          });
          // throw new Error(i18n.t("pay.payrollDeleteFailed"));
        }
      })
      .catch(e => {
        // this.setState({ listLoading: false });
        this.loadPayrollPeriod();
        console.log(e.message);
      });
  };

  handleSubmitClick2 = () => {
    window.location.href = window.location.href.replace("vacationPayDone", "vacationPayPending");
   
  };
  render() {
    const options = [
      { value: '2016', label: '2016' },
      { value: '2017', label: '2017' },
      { value: '2018', label: '2018' },
      { value: '2019', label: '2019' },
      { value: '2020', label: '2020' },
      { value: '2021', label: '2021' },
      { value: '2022', label: '2022' },
      { value: '2023', label: '2023' },
      { value: '2024', label: '2024' },
      { value: '2025', label: '2025' }
    ];
    return (
      <>
        <div className="card mt-2 border-0 shadow-sm">
          <div className="card-body">
             <div className="mb-3 row">
              <div className="input-group  col-6">
                {/* <div className="radio">
                  <label>
                    <input type="radio" value="new"
                      checked={this.state.radioSelectedOption === 'new'}
                      onChange={this.handleOptionChange} />
                    {i18n.t("vacation-pay-dashboard.new")}
                </label>
                </div>
                &nbsp;
              <div className="radio">
                  <label>
                    <input type="radio" value="history"
                      checked={this.state.radioSelectedOption === 'history'}
                      onChange={this.handleOptionChange} />
                    {i18n.t("vacation-pay-dashboard.history")}
                    </label>
                </div> */}

                {/* <div className="custom-file">
                  <input
                    type="file"
                    name="selectedFile"
                    className="custom-file-input"
                    id="inputGroupFile02"
                    onChange={this.onFileSelect}
                    accept=".csv"
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="inputGroupFile02"
                    aria-describedby="inputGroupFileAddon02"
                  >
                    {this.state.fileName === ""
                      ? i18n.t("pay.chooseFile")
                      : this.state.fileName}
                  </label>
                </div> */}
                
                {/* {this.state.fileName ? ( */}
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-link text-muted"
                      // className={
                        // this.state.success
                        //   ? "btn btn-success"
                        //   : 
                      //     "btn btn-primary"
                      // }
                      onClick={
                        // this.state.success ? null :
                         this.handleSubmitClick2
                      }
                    >
                      <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-dollar-sign d-none d-lg-inline"
                >
                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                  <polyline points="17 8 12 3 7 8" />
                  <line x1="12" y1="3" x2="12" y2="15" />
                </svg>
                      {/* {i18n.t("profile.upload")} */}
                    </button>
                  </div>
                {/* // ) : null} */}
              {/* </div>
              <div className="col-4 form-group"> */}
                
                <select
                  className="form-control"
                  id="t4-control"
                  value={this.state.selectedPeriod}
                  onChange={this.handleVacationPayPeriodChange}
                >
                  {this.state.periods.map((p, index) => (
                    <option key={index} value={p.value} >
                      {p.label}
                    </option>
                  ))}

                </select>
              {/* </div>

              <div className="col-2 form-group"> */}
                <ConfirmButtonCustomTip
                  confirmHandler={() =>
                    this.handleDeleteClick()
                  }
                  className="btn btn-link text-muted"
                  customTip={"pay.payDeleteConfirm"}
                  customTipParams={{ "payPeriod": this.state.selectedPeriod}} //StartEnd }}
                >
                  <span className="sr-only">
                    {i18n.t("pay-dashboard.table-delete")}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-trash-2"
                  >
                    <polyline points="3 6 5 6 21 6" />
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                    <line x1="10" y1="11" x2="10" y2="17" />
                    <line x1="14" y1="11" x2="14" y2="17" />
                  </svg>
                </ConfirmButtonCustomTip>
              </div>
            </div> 

            <div className="table-responsive position-relative">
              <table className="table table-bordered table-striped table-hover align-middle text-nowrap text-center">
                <thead>
                  <tr>
                    <th scope="col">{i18n.t("vacation-pay-dashboard.cheque-num")}</th>
                    <th scope="col">{i18n.t("vacation-pay-dashboard.name")}</th>
                    <th scope="col">{i18n.t("vacation-pay-dashboard.amount")}</th>
                    <th scope="col">{i18n.t("vacation-pay-dashboard.date")}</th>
                    <th scope="col">{i18n.t("vacation-pay-dashboard.import_type")}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.cheque_num}</td>
                      <td>{item.name}</td>
                      <td>${item.amount}</td>
                      <td>{!item.end ? '' : Math.floor(item.end / 10000) + '-' + Math.floor((item.end % 10000) / 100) + '-' + Math.floor(item.end % 100)}</td>
                      <td>{item.import_type? item.import_type: ""}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={i18n.t("paginate.previous")}
                nextLabel={i18n.t("paginate.next")}
                breakLabel={"..."}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                forcePage={this.state.forcePage}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                breakClassName={"page-item"}
                disabledClassName={"disabled"}
                pageClassName={"page-item"}
                previousClassName={"page-item"}
                nextClassName={"page-item"}
                breakLinkClassName="page-link"
                pageLinkClassName={"page-link"}
                activeClassName={"active"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
              />
              {this.state.listLoading && (
                <div
                  className="position-absolute d-flex justify-content-center align-items-center"
                  style={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    zIndex: 1,
                    background: "rgba(0, 0, 0, 0.2)"
                  }}
                >
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
