import React, { useState, useRef, useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import AddressDialog from "../postv1/AddressDialog";
import { Dialog } from "@reach/dialog";
import { i18n } from "../i18n";
import { Field, ErrorMessage } from "formik";
import VisuallyHidden from "@reach/visually-hidden";
import CloseIcon from "../component/CloseIcon";
import * as Api from "../Api";
import { toast } from "react-toastify";
function JobLocation(props) {
  const [editLocationState, setEditLocationState] = useState(false);
  const [addressValue, setAddressValue] = useState("");
  const [showAddress, setShowAddress] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const resetValue = useRef(null);
  const addressValueRef = useRef(null);
  const selectedAddressRef = useRef(null);
  function handleLocationEdit(event) {
    event.preventDefault();

    setEditLocationState(true);
  }
  function handleLocationConfirm() {
    resetValue.current = null;

    setEditLocationState(false);
  }

  function handleLabelClick() {
    setEditLocationState(true);
  }
  function handleLocationCancel(event) {
    event.preventDefault();

    setEditLocationState(false);
  }

  function handleSave(event) {
    event.preventDefault();
    if (props.saveAddress) {
      const payload = {
        addressName: props.addressDescription,
        addressUnit: props.addressUnit,
        location: {
          type: "Point",
          coordinates: [props.addressLongitude, props.addressLatitude]
        },
        isBranch: props.isBranch,
        ...(props.isBranch && { addressTitle: props.branchValue })
      };
      Api.createAddress(payload, global._token)
        .then(response => {
          if (response._id) {
            toast.success("address saved!", {
              className: "alert alert-success"
            });
            setShowSave(false);

            props.handleResetValue("saveAddress", false);
          } else {
            throw new Error("save address failed");
          }
        })
        .catch(e => {
          toast.error(e.message, { className: "alert alert-danger" });
        });
    }
    props.handleContinue(handleLocationConfirm);
  }

  function handleLocationDismiss() {
    setEditLocationState(false);
  }

  useEffect(() => {
    if (editLocationState === true) {
      // set initial value
      resetValue.current = {
        addressDescription: props.addressDescription,
        addressLatitude: props.addressLatitude,
        addressLongitude: props.addressLongitude,
        addressUnit: props.addressUnit,
        branchValue: props.branchValue,
        isBranch: props.isBranch,
        saveAddress: props.saveAddress
      };
      if (props.addressDescription) {
        setAddressValue(props.addressDescription);
      }
    } else {
      if (resetValue.current) {
        // resetValue
        props.handleResetValue(resetValue.current);
        const resetAddressValue =
          resetValue.current && resetValue.current.addressDescription
            ? resetValue.current.addressDescription
            : "";
        setAddressValue(resetAddressValue);
        setShowAddress(false);
      }
    }
  }, [editLocationState]);
  return (
    <div className="row mb-3">
      <div className="col-sm-3 col-form-label" onClick={handleLabelClick}>
        {i18n.t("postJob.step2")}
      </div>
      <a
        className="col-sm-9 d-flex justify-content-between"
        style={{
          paddingTop: "calc(0.375rem + 1px)",
          paddingBottom: "calc(0.375rem + 1px)",
          borderBottom: "dashed 1px #0088cc",
          textDecoration: "none"
        }}
        href="#"
        onClick={handleLocationEdit}
      >
        {props.addressDescription ? props.addressDescription : "-"}
        {props.addressUnit ? ` #${props.addressUnit}` : null}
      </a>

      <Dialog isOpen={editLocationState}>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="close"
            onClick={handleLocationDismiss}
          >
            <VisuallyHidden>Close</VisuallyHidden>
            <CloseIcon />
          </button>
        </div>
        <div className="form-group">
          <PlacesAutocomplete
            highlightFirstSuggestion={true}
            value={addressValue}
            debounce={1000}
            onChange={value => {
              setAddressValue(value);

              props.handleResetValue({
                addressDescription: "",
                addressLatitude: 0,
                addressLongitude: 0,
                ...(selectedAddressRef.current && {
                  isBranch: false,
                  branchValue: ""
                })
              });

              if (selectedAddressRef.current) {
                selectedAddressRef.current = false;
              }
            }}
            searchOptions={{
              componentRestrictions: {
                country: "ca"
              },
            }}
            onSelect={(value, placeId) => {
              if (!placeId) {
              } else {
                geocodeByAddress(value)
                  .then(result => {
                    setAddressValue(result[0].formatted_address);
                    setShowSave(true);
                    addressValueRef.current = result[0].formatted_address;
                    return getLatLng(result[0]);
                  })
                  .then(({ lat, lng }) => {
                    props.handleResetValue({
                      addressLatitude: lat,
                      addressLongitude: lng,
                      ...(addressValueRef.current && {
                        addressDescription: addressValueRef.current
                      })
                    });
                  });
              }
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading
            }) => (
              <>
                <div className="position-relative">
                  <label htmlFor="address">
                    {i18n.t("simplePost.address-label")}
                  </label>
                  <input
                    {...getInputProps({
                      placeholder: i18n.t(
                        "addressDialog.address-name-placeholder"
                      ),
                      type: "search",
                      id: "address",
                      className: "form-control"

                      //  +
                      //   (errors.latitude && touched.latitude
                      //     ? " is-invalid"
                      //     : "")
                    })}
                  />
                  <small className="form-text text-muted">
                    {i18n.t("simplePost.address-hint")}
                  </small>

                  <div
                    className="card border-0"
                    style={{
                      position: "absolute",
                      top: "100%",
                      zIndex: 1,
                      marginBottom: 8,
                      marginTop: 8
                    }}
                  >
                    <div className="card rounded border-0">
                      <div className="list-group">
                        {loading && (
                          <div className="list-group-item">Loading...</div>
                        )}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? "list-group-item active"
                            : "list-group-item";
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </PlacesAutocomplete>
          <ErrorMessage
            className="invalid-feedback d-block"
            name="addressLatitude"
            component="div"
          />
          <a
            href="#"
            onClick={event => {
              event.preventDefault();
              setShowAddress(true);
            }}
          >
            {i18n.t("simplePost.select-address")}
          </a>
        </div>
        <div className="form-group">
          <label htmlFor="unit">{i18n.t("postJob.unit-label")}</label>
          <Field
            id="unit"
            name="addressUnit"
            onChange={event => {
              setShowSave(true);
              props.handleResetValue({
                addressUnit: event.target.value,
                ...(selectedAddressRef.current && {
                  isBranch: false,
                  branchValue: ""
                })
              });
              if (selectedAddressRef.current) {
                selectedAddressRef.current = false;
              }
            }}
            autoComplete="off"
            className="form-control"
          />
        </div>
        <div className="form-group">
          <div className="custom-control custom-switch">
            <Field
              id="customSwitch2"
              name="isBranch"
              className="custom-control-input"
              type="checkbox"
              checked={props.isBranch}
              onChange={event => {
                setShowSave(true);
                props.handleResetValue({
                  isBranch: event.target.checked,
                  ...(selectedAddressRef.current && {
                    branchValue: ""
                  }),
                  // ...(!selectedAddressRef.current &&
                  //   event.target.checked && {
                  saveAddress: true
                  // })
                });
                // if (values.branchValue === "") {
                //   form.setFieldTouched("branchValue", false);
                // }
                if (selectedAddressRef.current) {
                  // form.setFieldValue("branchValue", "");
                  // form.setFieldTouched("branchValue", false);
                  selectedAddressRef.current = false;
                }
              }}
            />
            <label className="custom-control-label" htmlFor="customSwitch2">
              {i18n.t("postJob.is-branch")}
            </label>
          </div>
        </div>

        {props.isBranch ? (
          <div className="form-group">
            <label htmlFor="branchValue" className=" col-form-label text-muted">
              {i18n.t("postJob.branch-label")}
            </label>
            <Field
              id="branchValue"
              name="branchValue"
              onChange={event => {
                setShowSave(true);
                props.handleResetValue({
                  branchValue: event.target.value
                });
                if (selectedAddressRef.current) {
                  selectedAddressRef.current = false;
                }
              }}
              autoComplete="off"
              className="form-control"
            />
            <ErrorMessage
              className="invalid-feedback d-block"
              name="branchValue"
              component="div"
            />
          </div>
        ) : null}

        {showSave && (
          <div className="form-group">
            <div className="custom-control custom-switch">
              <Field
                id="save-address-switch"
                name="saveAddress"
                className="custom-control-input"
                type="checkbox"
                checked={props.saveAddress}
                disabled={props.isBranch}
              />
              <label
                className="custom-control-label"
                htmlFor="save-address-switch"
              >
                {i18n.t("postJob.save-address")}
              </label>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-end">
          <a href="#" className="btn btn-primary" onClick={handleSave}>
            {i18n.t("simplePost.save")}
          </a>
          <a href="#" className="btn btn-link" onClick={handleLocationCancel}>
            {i18n.t("simplePost.cancel")}
          </a>
        </div>
      </Dialog>

      <AddressDialog
        show={showAddress}
        hideDialog={() => setShowAddress(false)}
        handleShowAddressButton={() => {}}
        handleSelect={value => {
          props.handleResetValue({
            addressDescription: value.addressName,
            addressLongitude: value.location.coordinates[1],
            addressLongitude: value.location.coordinates[0],
            addressUnit: value.addressUnit,
            ...(value.isBranch && {
              branch: value._id,
              isBranch: true,
              branchValue: value.addressTitle
            }),
            ...(!value.isBranch && {
              branch: "",
              isBranch: false,
              branchValue: ""
            }),
            saveAddress: false
          });
          setAddressValue(value.addressName);
          setShowAddress(false);
          setShowSave(false);
          selectedAddressRef.current = true;
        }}
      />
    </div>
  );
}

export default JobLocation;
