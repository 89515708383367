import React, { Component } from "react";
import { Dialog } from "@reach/dialog";
import VisuallyHidden from "@reach/visually-hidden";
import SendInterview from "./SendInterview";
import SendOffer from "./SendOffer";
import * as Api from "./Api";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { i18n } from "./i18n";
import { toast } from "react-toastify";
import CloseIcon from "./component/CloseIcon";
import { enUS, zhCN } from "date-fns/locale";

export default class Application extends Component {
  state = {
    loading: false,
    data: null,
    error: null,
    showDialog: false,
    showOfferDialog: false,
    showConfirmDialog: false,
    showEmergencyDialog: false,
    update: 0
  };
  componentDidMount() {
    this.getApplicationDetail(this.props.applicationId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.applicationId !== this.props.applicationId ||
      prevState.update !== this.state.update
    ) {
      this.getApplicationDetail(this.props.applicationId);
    }
  }

  getApplicationDetail = id => {
    this.setState({ loading: true }, () =>
      Api.getApplicationDetail(id, global._token)
        .then(json => {
          this.setState({ loading: false, data: json });
        })
        .catch(error => {
          this.setState({ loading: false, error: error });
        })
    );
  };
  handleCancelInterview = () => {
    Api.updateApplication(
      this.props.applicationId,
      {
        action: "cancelInterviewER"
      },
      global._token
    )
      .then(json => {
        if (json._id) {
          toast.success("Interview canceled!", {
            className: "alert alert-success"
          });
          this.setState(prevState => ({
            update: prevState.update + 1,
            showConfirmDialog: false
          }));
        } else {
          throw new Error("Interview cancel failed");
        }
      })
      .catch(e => {
        toast.error(e.message, { className: "alert alert-danger" });
      });
  };
  handleEmergencyCancel = () => {
    Api.updateApplication(
      this.props.applicationId,
      {
        action: "cancelOfferER"
      },
      global._token
    )
      .then(json => {
        if (json._id) {
          this.setState(prevState => ({
            update: prevState.update + 1,
            showEmergencyDialog: false
          }));
        } else {
          throw new Error("Cancel offer failed");
        }
      })
      .catch(e => {
        toast.error(e.message, { className: "alert alert-danger" });
      });
  };
  handleDateUpdate = () =>
    this.setState(prevState => ({ update: prevState.update + 1 }));
  handleInterviewDialogClose = () => {
    this.setState(prevState => ({
      update: prevState.update + 1,
      showDialog: false
    }));
  };
  handleOfferDialogClose = () => {
    this.setState(prevState => ({
      update: prevState.update + 1,
      showOfferDialog: false
    }));
  };
  render() {
    let locale;
    if (i18n.language === "zh") {
      locale = zhCN;
    } else {
      locale = enUS;
    }
    if (this.state.loading === false && this.state.data !== null) {
      return (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Application</h1>
          </div>
          <div className="col-12 col-lg-7">
            <div className="card mb-4">
              <div className="card-body">
                <h5>Audit history</h5>
                <ul class="list-group">
                  {this.state.data.history.map(h => (
                    <li class="list-group-item">
                      {h.action}
                      {h.time
                        ? " at " +
                          formatDistanceToNow(new Date(h.time), {
                            locale: locale,
                            addSuffix: true
                          })
                        : ""}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {this.state.data.status === "applying" ||
            this.state.data.status === "interview_canceled_employer" ||
            this.state.data.status === "interview_declined" ||
            this.state.data.status === "offer_canceled_employer" ||
            this.state.data.status === "offer_declined" ? (
              <div className="btn-group" role="group">
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  onClick={() => this.setState({ showDialog: true })}
                >
                  Send Interview Invite
                </button>

                <button
                  className="btn btn-outline-primary"
                  type="button"
                  onClick={() => this.setState({ showOfferDialog: true })}
                >
                  Send Offer
                </button>
              </div>
            ) : null}
            {this.state.data.status === "interviewing" ? (
              <div className="btn-group" role="group">
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  onClick={() => {
                    this.setState({ showConfirmDialog: true });
                  }}
                >
                  cancelInterview
                </button>
              </div>
            ) : null}
            {this.state.data.status === "interviewed" ? (
              <div className="btn-group" role="group">
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  onClick={() => {
                    this.setState({ showConfirmDialog: true });
                  }}
                >
                  cancelInterview
                </button>
              </div>
            ) : null}
            {this.state.data.status === "hiring" ? (
              <div className="btn-group" role="group">
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  onClick={() => {
                    this.setState({ showEmergencyDialog: true });
                  }}
                >
                  emergencyCancelOffer
                </button>
              </div>
            ) : null}
            {this.state.data.status === "hired" ? (
              <div className="btn-group" role="group">
                <button className="btn btn-outline-primary" type="button">
                  hired
                </button>
              </div>
            ) : null}
            <Dialog
              isOpen={this.state.showDialog}
              onDismiss={() => this.setState({ showDialog: false })}
            >
              <button
                type="button"
                className="close"
                onClick={() => this.setState({ showDialog: false })}
              >
                <VisuallyHidden>Close</VisuallyHidden>
                <CloseIcon />
              </button>
              <h2>Interview detail</h2>
              <SendInterview
                applicationId={this.props.applicationId}
                handleInterviewDialogClose={this.handleInterviewDialogClose}
              />
            </Dialog>

            <Dialog
              isOpen={this.state.showOfferDialog}
              onDismiss={() => this.setState({ showOfferDialog: false })}
            >
              <button
                type="button"
                className="close"
                onClick={() => this.setState({ showOfferDialog: false })}
              >
                <VisuallyHidden>Close</VisuallyHidden>
                <CloseIcon />
              </button>
              <h2>Offer detail</h2>
              <SendOffer
                applicationId={this.props.applicationId}
                handleOfferDialogClose={this.handleOfferDialogClose}
              />
            </Dialog>
            <Dialog
              isOpen={this.state.showConfirmDialog}
              onDismiss={() => this.setState({ showConfirmDialog: false })}
            >
              <button
                type="button"
                className="close"
                onClick={() => this.setState({ showConfirmDialog: false })}
              >
                <VisuallyHidden>Close</VisuallyHidden>
                <CloseIcon />
              </button>
              <h2>Are you sure?</h2>
              <div className="btn-group">
                <button
                  type="button"
                  onClick={() => this.setState({ showConfirmDialog: false })}
                >
                  Cancel
                </button>
                <button type="button" onClick={this.handleCancelInterview}>
                  Confirm
                </button>
              </div>
            </Dialog>

            <Dialog
              isOpen={this.state.showEmergencyDialog}
              onDismiss={() => this.setState({ showEmergencyDialog: false })}
            >
              <button
                type="button"
                className="close"
                onClick={() => this.setState({ showEmergencyDialog: false })}
              >
                <VisuallyHidden>Close</VisuallyHidden>
                <CloseIcon />
              </button>
              <h2>Are you sure?</h2>
              <div className="btn-group">
                <button
                  type="button"
                  onClick={() => this.setState({ showEmergencyDialog: false })}
                >
                  Cancel
                </button>
                <button type="button" onClick={this.handleEmergencyCancel}>
                  Confirm
                </button>
              </div>
            </Dialog>
          </div>
        </>
      );
    } else {
      return (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      );
    }
  }
}
