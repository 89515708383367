import React, { Component } from "react";
import NetUtil from "./NetUtil";
import config from "./config";
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";
import { EmployeeDetail } from "./PageComponents"
import { i18n } from "./i18n"
import VisuallyHidden from "@reach/visually-hidden";
import { Dialog } from "@reach/dialog";
import CloseIcon from './component/CloseIcon';

const fetchLength = 10

export default class Notification extends Component{
   constructor(props){
      super(props)
      this.state={
         data: [],
         pageCount: 0,
         showEmployeeDetail: false,
         employeeDetail: {},
         jobDetail: {},
      }
      this.notificationOnClick = debounce(this.notificationOnClick, 300)
   }
   
   componentDidMount(){
      this.fetchNotifications(1)
   }

   convertDataToObject(data){
      return data
   }

   async fetchNotifications(page) {
      let params = {
         limit: fetchLength,
         page: page
      }
      NetUtil.get(config.apiurl + "v1/message/all", params, global._token).then((result) => {
         // console.log(result)
         if (result.hasOwnProperty("err_msg")) {
            // handle error
         } else {
            this.setState({
               data: this.convertDataToObject(result.items),
               pageCount: Math.ceil(result.total / fetchLength),
            })
         }
      })
   }

   toggleModal(){
      this.setState({showEmployeeDetail: !this.state.showEmployeeDetail})
   }

   onPageChange(index){
      this.fetchNotifications(index.selected + 1)
   }

   /**
    * modifies the applicant object in place
    * adds [satisfiedSkills] to the obj
    * adds [notSatisfiedSkills] to the obj
    * adds [additionalSkills] to the obj
    * @param {reference to applicant} applicant 
    * @param {array of skills} req 
    * @param {array of skills} has 
    */
   matchApplicantSkills(applicant, req, has) {
      let satisfied = []
      let notSatisfied = []
      let additional = []

      // key: id, value: skill data
      // loop through req
      // add req to map
      let reqMap = new Map()
      req.forEach((skill) => {
         reqMap.set(skill._id, skill)
      })

      // key: id, value: skill data
      // loop through has
      // add has to map
      let hasMap = new Map()
      has.forEach((skill) => {
         hasMap.set(skill.skill._id, skill)
      })

      // loop through has
      // if req map contains has
      // add skill to satisfied
      // remove skill from both maps
      hasMap.forEach((value, key) => {
         if (reqMap.has(key)){
            satisfied.push(value)
            reqMap.delete(key)
            hasMap.delete(key)
         }
      })

      // if map is not empty
      // add remaining elements to not satisfied
      reqMap.forEach((value, key) => {
         notSatisfied.push(value)
      })

      // if map is not empty
      // add remaining elements to additional skills
      hasMap.forEach((value, id) => {
         additional.push(value)
      })

      // unify data structure
      for (let i = 0; i < satisfied.length; i++){
         satisfied[i] = satisfied[i].skill
      }
      // for (let i = 0; i < notSatisfied.length; i++){
      //    notSatisfied[i] = notSatisfied[i].skill
      // }
      for (let i = 0; i < additional.length; i++){
         additional[i] = additional[i].skill
      }

      applicant["satisfiedSkills"] = satisfied
      applicant["notSatisfiedSkills"] = notSatisfied
      applicant["additionalSkills"] = additional
   }

   notificationOnClick(item){
      if (Object.keys(this.state.employeeDetail).length > 0 && this.state.employeeDetail._id === item.userId){
         this.toggleModal()
         if (!item.hasRead){
            this.markItemsAsRead([item._id])
         }
      } else {
         // console.log(item)
         let p1 = NetUtil.get(config.apiurl + 'v1/admin/employee/' + item.userId, null, global._token).then((result) => result)
         let p2 = NetUtil.get(config.apiurl + "v1/common/job/" + item.jobId, "").then((result) => result)

         Promise.all([p1, p2]).then((result) => {
            // console.log(result[0]) // employee detail
            // console.log(result[1]) // job detail
            if (result[0].hasOwnProperty("err_msg") || result[1].hasOwnProperty("err_msg")){
               alert("unable to fetch data")
            } else {
               let req = []
               if (result[1].hasOwnProperty("mandatorySkillRequirements")) {
                  req = req.concat(result[1].mandatorySkillRequirements)
               }
               if (result[1].hasOwnProperty("skillRequirements")) {
                  req = req.concat(result[1].skillRequirements)
               }
               let has = result[0].skills
               this.matchApplicantSkills(result[0], req, has)
               this.setState({ 
                  employeeDetail: result[0],
                  jobDetail: result[1],
               })
               this.toggleModal()
               if (!item.hasRead){
                  this.markItemsAsRead([item._id])
               }
            }
         })
      }
   }

   markAllAsReadOnPress(){
      let targets = []
      this.state.data.forEach((item) => {
         if(item.hasRead){
            targets.push(item._id)
         }
      })
      this.markItemsAsRead(targets)
   }

   markItemsAsRead(items){
      if (items instanceof Array && items.length > 0){
         let param = JSON.stringify({
            idArray: items
         })
         let url = config.apiurl + "v1/message/mark"
         NetUtil.post(url, param, global._token).then((result) => {
            if (result.hasOwnProperty("err_msg")){
               alert(result.err_msg)
            } else {
               let tempData = this.state.data
               tempData.forEach((message) => {
                  items.forEach((id) => {
                     if (message._id === id){
                        message.hasRead = true
                     }
                  })
               })
               this.setState({data: tempData})
            }
         })
      }
   }

   renderNotificationList = () => {
      let re = []
      this.state.data.forEach((element, index) => {
         if (!element.hasRead){
            re.push(
               <NewNotification 
                  key={element._id}
                  notification={element}
                  onClick={this.notificationOnClick.bind(this, element)}
               />
            )
         } else {
            re.push(
               <OldNotification 
                  key={element._id}
                  notification={element}
                  onClick={this.notificationOnClick.bind(this, element)}
               />
            )
         }
      })
      return re
   }

   refreshNotifications(){
      this.fetchNotifications(1)
   }

   render(){
      return (
         <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap pt-2 pb-2 mb-3 align-items-center border-bottom">
               <h1 className="h2 mb-0">{i18n.t("tabTitles.notification")}</h1>
               <button type="button" className="btn btn-primary m-3" onClick={this.markAllAsReadOnPress.bind(this)}>
                  {i18n.t("notification.markAllAsRead")}
               </button>
            </div>
            <div className="bg-white p-3">
            <div className="table-responsive">
            <table className="table table-bordered table-striped mt-3">
               <thead>
                  <tr>
                     <th scope="col">{i18n.t("employee.name")}</th>
                     <th scope="col">{i18n.t("employee.title")}</th>
                     <th scope="col">{i18n.t("employee.date")}</th>
                  </tr>
               </thead>
               <tbody>
                  {this.renderNotificationList()}
               </tbody>
            </table>
            </div>
            <div className="pagination">
               <ReactPaginate
                  previousLabel={i18n.t("paginate.previous")}
                  nextLabel={i18n.t("paginate.next")}
                  breakLabel={"..."}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.onPageChange.bind(this)}
                  containerClassName={"pagination"}
                  breakClassName={"page-item"}
                  disabledClassName={"disabled"}
                  pageClassName={"page-item"}
                  breakLinkClassName='page-link'
                  previousClassName={"page-item"}
                  nextClassName={"page-item"}
                  pageLinkClassName={'page-link'}
                  activeClassName={"active"}
                  previousLinkClassName={'page-link'}
                  nextLinkClassName={'page-link'}
               />
            </div>
            </div>

            <Dialog
               isOpen={this.state.showEmployeeDetail}
               onDismiss={this.toggleModal.bind(this)}
               style={{width: '90vw'}}
            >
               <div className="d-flex justify-content-end">
                  <button
                     type="button"
                     className="close"
                     onClick={this.toggleModal.bind(this, null)}
                  >
                     <VisuallyHidden>Close</VisuallyHidden>
                     <CloseIcon />
                  </button>
               </div>
                  { Object.keys(this.state.employeeDetail).length > 0 ?
                     <EmployeeDetail
                        icon={this.state.employeeDetail.icon}
                        employeeName={this.state.employeeDetail.firstName + this.state.employeeDetail.lastName}
                        satisfiedSkills={this.state.employeeDetail.satisfiedSkills}
                        notSatisfiedSkills={this.state.employeeDetail.notSatisfiedSkills}
                        additionalSkills={this.state.employeeDetail.additionalSkills}
                        address={this.state.employeeDetail.address.name}
                        email={this.state.employeeDetail.email}
                        introduction={this.state.employeeDetail.introduction}
                        phone={this.state.employeeDetail.mobile}
                     />
                  :
                     null
                  }
            </Dialog>
         </div>
      )
   }
}

class NewNotification extends Component {
   render() {
      return (
         <tr onClick={this.props.onClick}>
            <td>{this.props.notification.employeeName}</td>
            <td>{this.props.notification.jobTitle}</td>
            <td>{new Date(this.props.notification.createdAt).toLocaleString(i18n.language, dateDisplayOption)}</td>
         </tr>
      )
   }
}

class OldNotification extends Component{
   render() {
      return (
         <tr onClick={this.props.onClick}>
            <td style={{color: "rgb(150,150,150)"}}>{this.props.notification.employeeName}</td>
            <td style={{color: "rgb(150,150,150)"}}>{this.props.notification.jobTitle}</td>
            <td style={{color: "rgb(150,150,150)"}}>{new Date(this.props.notification.createdAt).toLocaleString(i18n.language, dateDisplayOption)}</td>
         </tr>
      )
   }
}

const dateDisplayOption = {
   year: "numeric",
   month: "numeric",
   day: "numeric",
}