import React, { useState, useEffect, useRef } from "react";
import { Field, ErrorMessage } from "formik";
import { i18n } from "../i18n";
import { Dialog } from "@reach/dialog";
import VisuallyHidden from "@reach/visually-hidden";
import CloseIcon from "../component/CloseIcon";
export default function(props) {
  const [editTypeState, setEditTypeState] = useState(false);
  const resetValue = useRef(null);
  function handleTypeEdit(event) {
    event.preventDefault();

    setEditTypeState(true);
  }
  function handleLableClick() {
    setEditTypeState(true);
  }
  function handleTypeConfirm(event) {
    resetValue.current = null;

    setEditTypeState(false);
  }
  function handleTypeCancel(event) {
    event.preventDefault();

    setEditTypeState(false);
  }
  function handleTypeDismiss() {
    setEditTypeState(false);
  }
  function handleSave(event) {
    event.preventDefault();
    props.handleContinue(handleTypeConfirm);
  }
  useEffect(() => {
    if (editTypeState === true) {
      // set initial value
      resetValue.current = {
        type: props.type,
        position: props.position,
        unlimit: props.unlimit
      };
    } else {
      if (resetValue.current) {
        // resetValue
        props.handleResetValue(resetValue.current);
      }
    }
  }, [editTypeState]);
  return (
    <>
      <div className="row mb-3">
        <div className="col-sm-3 col-form-label" onClick={handleLableClick}>
          {i18n.t("simplePost.job-info")}
        </div>
        <a
          className="col-sm-9 d-flex justify-content-between"
          style={{
            paddingTop: "calc(0.375rem + 1px)",
            paddingBottom: "calc(0.375rem + 1px)",
            borderBottom: "dashed 1px #0088cc",
            textDecoration: "none"
          }}
          href="#"
          onClick={handleTypeEdit}
        >
          {i18n.t(`jobType.${props.type}`)};{" "}
          {props.unlimit
            ? i18n.t("simplePost.unlimit-hire")
            : i18n.t("simplePost.position-prefix") +
              props.position +
              i18n.t("simplePost.position-suffix")}
        </a>
        <Dialog isOpen={editTypeState}>
          <div className="d-flex justify-content-end">
            <button type="button" className="close" onClick={handleTypeDismiss}>
              <VisuallyHidden>Close</VisuallyHidden>
              <CloseIcon />
            </button>
          </div>
          <div className="form-group">
            <label htmlFor="job-type-id col-form-label">
              {i18n.t("postJob.employment-type")}
            </label>
            <Field
              component="select"
              name="type"
              placeholder="Job type"
              id="job-type-id"
              className="form-control"
            >
              <option value="Full time">{i18n.t(`jobType.Full time`)}</option>
              <option value="Part time">{i18n.t(`jobType.Part time`)}</option>
              <option value="Full Time / Part Time">
                {i18n.t(`jobType.Full Time / Part Time`)}
              </option>
              <option value="Permanent">{i18n.t(`jobType.Permanent`)}</option>
              <option value="Contract">{i18n.t(`jobType.Contract`)}</option>
              <option value="Temporary">{i18n.t(`jobType.Temporary`)}</option>
            </Field>
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <Field
                name="unlimit"
                className="custom-control-input"
                type="checkbox"
                id="unlimit-checkbox"
                checked={props.unlimit}
              />
              <label
                className="custom-control-label"
                htmlFor="unlimit-checkbox"
              >
                {i18n.t("postJob.no-limit")}
              </label>
            </div>
          </div>
          {!props.unlimit && (
            <div className="form-group">
              <label htmlFor="job-position-id" className="col-form-label">
                {i18n.t("postJob.position-number")}
              </label>
              <Field
                name="position"
                id="job-position-id"
                className="form-control"
                placeholder={i18n.t("simplePost.position-placeholder")}
              ></Field>
              <ErrorMessage
                className="invalid-feedback d-block"
                name="position"
                component="div"
              />
            </div>
          )}
          <div className="d-flex justify-content-end">
            <a href="#" className="btn btn-primary" onClick={handleSave}>
              {i18n.t("simplePost.save")}
            </a>
            <a href="#" className="btn btn-link" onClick={handleTypeCancel}>
              {i18n.t("simplePost.cancel")}
            </a>
          </div>
        </Dialog>
      </div>
    </>
  );
}
