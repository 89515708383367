import React, { Component } from "react";
import { i18n } from "./i18n";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Api from "./Api";
import config from "./config";
import VisuallyHidden from "@reach/visually-hidden";
import { Dialog } from "@reach/dialog";
import Downshift from "downshift";
import CloseIcon from './component/CloseIcon';

class Step4 extends Component {
  state = {
    tab: 0,
    showSkillDialog: false,
    showSkills: [],
    level: false
  };
  languagePath = i18n.language === "zh" ? "name_cn" : "name";
  toggleModal = (child = [], level = false) => {
    this.setState(prevState => {
      return {
        showSkillDialog: !prevState.showSkillDialog,
        showSkills: prevState.showSkillDialog === false ? child : [],
        level: level
      };
    });
  };
  handleTabClick = e => {
    e.preventDefault();
    this.setState({ tab: parseInt(e.target.dataset.idx, 10) });
  };
  removeSkills = a => {
    this.props.removeAndAddSkill([a], []);
  };
  render() {
    const searchArray = [];
    const selectedSkills = this.props.requiredSkill.concat(
      this.props.preferredSkill
    );
    //  Array.from(.keys()).forEach(a => console.log(a))
    Object.keys(this.props.allSkills).map(key => {
      if (!selectedSkills.includes(key)) {
        searchArray.push(this.props.allSkills[key]);
      }
    });
    return (
      <div>
        <div className="card border-0">
          <div className="card-body">
            <h3 className="card-title">
              {i18n.t("postJob.step5")}
              <span aria-hidden className="text-muted">
                *
              </span>
            </h3>
            <small className="text-muted">{i18n.t("postJob.step5-hint")}</small>
            <Downshift
              onChange={selection => {
                if (selection) {
                  this.props.removeAndAddSkill([], [selection._id]);
                }
              }}
              itemToString={item => ""}
            >
              {({
                getInputProps,
                getItemProps,
                getLabelProps,
                getMenuProps,
                isOpen,
                inputValue,
                highlightedIndex,
                selectedItem
              }) => (
                <div className="form-group" style={{ position: "relative" }}>
                  {/* <label {...getLabelProps()}>Enter a fruit</label> */}
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text"
                        style={{ backgroundColor: "#fff" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-search"
                        >
                          <circle cx="11" cy="11" r="8" />
                          <line x1="21" y1="21" x2="16.65" y2="16.65" />
                        </svg>
                      </span>
                    </div>
                    <input {...getInputProps()} className="form-control" />
                  </div>
                  <ul
                    {...getMenuProps()}
                    style={{
                      position: "absolute",
                      top: "100%",
                      zIndex: 2,
                      maxHeight: "30vh",
                      overflowY: "auto"
                    }}
                    className="list-group w-100 shadow-sm mt-1"
                  >
                    {isOpen
                      ? searchArray
                          .filter(
                            item =>
                              !inputValue ||
                              item.name
                                .toLowerCase()
                                .includes(inputValue.toLowerCase()) ||
                              item.name_cn.includes(inputValue)
                          )
                          .map((item, index) => (
                            <li
                              className="list-group-item list-group-item-action"
                              {...getItemProps({
                                key: item._id,
                                index,
                                item
                              })}
                            >
                              {item[this.languagePath]}
                            </li>
                          ))
                      : null}
                  </ul>
                </div>
              )}
            </Downshift>
            <div className="my-3">
              {this.props.requiredSkill.concat(this.props.preferredSkill) && (
                <label className="text-muted">
                  {i18n.t("postJob.selected-skills")}
                </label>
              )}
              <div className="h4">
                {this.props.requiredSkill
                  .concat(this.props.preferredSkill)
                  .map(skill => (
                    <>
                      {this.props.allSkills[skill] && 
                      <span
                        className="badge badge-tag mx-1 mt-2 d-inline-flex align-items-center"
                        key={skill}
                      >
                        {this.props.allSkills[skill][this.languagePath]}
                        <button
                          type="button"
                          className="close mx-2"
                          aria-label="Close"
                          onClick={() => this.removeSkills(skill)}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </span>}
                    </>
                  ))}
              </div>
            </div>
            <div className="d-flex" role="tablist">
              <a
                href="#"
                id="first-tab"
                data-idx="0"
                role="tab"
                className={
                  "col text-center border-bottom py-2" +
                  (this.state.tab === 0
                    ? " border-primary font-weight-bolder"
                    : " border-muted text-muted")
                }
                onClick={this.handleTabClick}
                aria-selected={this.state.tab === 0}
              >
                {i18n.t("postJob.common")}
              </a>
              <a
                href="#"
                id="second-tab"
                data-idx="1"
                role="tab"
                onClick={this.handleTabClick}
                className={
                  "col text-center py-2 border-bottom" +
                  (this.state.tab === 1
                    ? " border-primary font-weight-bolder"
                    : " border-muted text-muted")
                }
                aria-selected={this.state.tab === 1}
              >
                {
                  this.props.category.find(
                    c => c._id === this.props.selectedCategory
                  ).parent[0][this.languagePath]
                }
              </a>
            </div>
            <div>
              <div
                role="tabpanel"
                aria-labelledby="first-tab"
                className="h2 py-2"
                style={{ display: this.state.tab === 0 ? "block" : "none" }}
              >
                {this.props.category
                  .find(function(elem) {
                    return elem._id === config.commonId;
                  })
                  .child.map(sub => (
                    <button
                      type="button"
                      className="btn btn-outline-primary mx-1 mt-2"
                      key={sub._id}
                      onClick={() =>
                        this.toggleModal(sub.skills, sub.type === "level")
                      }
                    >
                      {sub[this.languagePath]}
                    </button>
                  ))}
              </div>
              <div
                role="tabpanel"
                aria-labelledby="second-tab"
                style={{ display: this.state.tab === 1 ? "block" : "none" }}
                className="h2 py-2"
              >
                {this.props.category
                  .find(elem => {
                    /** @todo */
                    return elem._id === this.props.selectedCategory;
                  })
                  .child.map(sub => (
                    <button
                      type="button"
                      className="btn btn-outline-primary mx-1 mt-2"
                      key={sub._id}
                      onClick={() =>
                        this.toggleModal(sub.skills, sub.type === "level")
                      }
                    >
                      {sub[this.languagePath]}
                    </button>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <SkillDialog
          isOpen={this.state.showSkillDialog}
          preferredSkill={this.props.preferredSkill}
          requiredSkill={this.props.requiredSkill}
          toggleModal={this.toggleModal}
          showSkills={this.state.showSkills}
          removeAndAddSkill={this.props.removeAndAddSkill}
          level={this.state.level}
        />
      </div>
    );
  }
}

export class SkillDialog extends Component {
  constructor(props) {
    super(props);
    const currentSkillSet = props.requiredSkill.concat(props.preferredSkill);
    const result = props.showSkills.map(
      skill => currentSkillSet.indexOf(skill._id) !== -1
    );
    this.state = {
      checkboxValue: result
    };
    this.languagePath = i18n.language === "zh" ? "name_cn" : "name";
  }
  componentDidUpdate(prevProps) {
    if (prevProps.isOpen === false && this.props.isOpen === true) {
      // open dialog !
      const currentSkillSet = this.props.requiredSkill.concat(
        this.props.preferredSkill
      );
      const result = this.props.showSkills.map(
        skill => currentSkillSet.indexOf(skill._id) !== -1
      );
      this.setState({
        checkboxValue: result
      });
    }
  }
  handleCheckbox = e => {
    const index = parseInt(e.target.dataset.index, 10);
    const newValue = this.state.checkboxValue.slice();
    newValue[index] = e.target.checked;
    this.setState({
      checkboxValue: newValue
    });
  };
  handleRadioChange = e => {
    const index = parseInt(e.target.dataset.index, 10);
    const newValue = new Array(this.state.checkboxValue.length).fill(false);
    newValue[index] = e.target.checked;
    this.setState({
      checkboxValue: newValue
    });
  };

  handleConfirm = () => {
    const removeSkill = [];
    const addSkill = [];
    this.props.showSkills.map((skill, index) => {
      if (this.state.checkboxValue[index]) {
        addSkill.push(skill._id);
      } else {
        removeSkill.push(skill._id);
      }
    });
    this.props.removeAndAddSkill(removeSkill, addSkill);
    this.props.toggleModal();

    // remove every unset skill
    // add new skill to perferred skill
  };

  render() {
    return (
      <Dialog
        isOpen={this.props.isOpen}
        onDismiss={this.props.toggleModal}
        // style={{ width: "90vw" }}
      >
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="close"
            onClick={this.props.toggleModal}
          >
            <VisuallyHidden>Close</VisuallyHidden>
            <CloseIcon />
          </button>
        </div>
        <div className="py-3">
          {this.props.showSkills.map((v, index) => (
            <div key={v._id}>
              {this.props.level ? (
                <div className="custom-control custom-radio my-2">
                  <input
                    type="radio"
                    className="custom-control-input"
                    id={`customCheck_${v._id}`}
                    data-index={index}
                    name={"level"}
                    value={v._id}
                    checked={this.state.checkboxValue[index]}
                    onChange={this.handleRadioChange}
                  />
                  <label
                    className="custom-control-label d-block"
                    htmlFor={`customCheck_${v._id}`}
                  >
                    {v[this.languagePath]}
                  </label>
                </div>
              ) : (
                <div className="custom-control custom-checkbox my-2">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`customCheck_${v._id}`}
                    data-index={index}
                    onChange={this.handleCheckbox}
                    checked={this.state.checkboxValue[index]}
                  />
                  <label
                    className="custom-control-label d-block"
                    htmlFor={`customCheck_${v._id}`}
                  >
                    {v[this.languagePath]}
                  </label>
                </div>
              )}
            </div>
          ))}
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={this.handleConfirm}
        >
          {i18n.t("postJob.confirm")}
        </button>
      </Dialog>
    );
  }
}

export default Step4;
