import React, { Component } from "react";
import NetUtil from "./NetUtil";
import config from "./config";
import { EmployeeDetail } from "./PageComponents";
import { Dialog } from "@reach/dialog";
import ReactPaginate from "react-paginate";
import formatRelative from "date-fns/formatRelative";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { Link, navigate } from "@reach/router";
import * as Api from "./Api";
import VisuallyHidden from "@reach/visually-hidden";
import Dropdown from "react-bootstrap/Dropdown";
import SendInterview from "./SendInterview";
import SendOffer from "./SendOffer";
import { toast } from "react-toastify";
import { i18n } from "./i18n";
import { enCA as en, zhCN as zh } from "date-fns/locale";
import male from "./img/male.png";
import female from "./img/female.png";
import CloseIcon from "./component/CloseIcon";
import { ConfirmButton } from "./ConfirmButton";

export default class EmployerJobDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobDetail: {},
      employeeDetail: {},
      allApplicants: [], // all applicants data
      pendingApplicants: [], // all pending applicants data
      acceptedApplicants: [], // all accepted applicants data
      skillList: new Map(), // key: skill id, value: skill data
      tab: 0,
      tab0page: 1,
      tab1page: 1,
      tab2page: 1,
      tab0Total: 0,
      tab1Total: 0,
      tab2Total: 0,
      tab0PageCount: 0,
      tab1PageCount: 0,
      tab2PageCount: 0,
      fetchLength: 1000,
      forceTab0Page: null,
      searchValue: "",
      sort: "offer",
      showEmployeeDetail: false,
      showDialog: false,
      showOfferDialog: false,
      target: null,
      update: 0
    };
    this.searchValue = "";
    this.languagePath = i18n.language === "zh" ? "name_cn" : "name";
  }

  componentDidMount() {
    this.fetchJobDetail();
    this.fetchSkillData();
  }

  fetchSkillData() {
    let param = {
      page: 1,
      limit: 1000
    };
    Api.getAllSkill(global._token, param).then(json => {
      if (json && json.items && json.items.length !== 0) {
        let map = new Map();
        json.items.forEach(skill => {
          if (skill.type !== "suggest") {
            map.set(skill._id, skill);
          }
        });
        this.setState({ skillList: map });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.update !== this.state.update) {
      switch (this.state.tab) {
        case 0:
          // {
          this.fetchAllApplicants(this.state.tab0page);
          // }
          break;
        case 1:
          // {
          this.fetchPendingApplicants(this.state.tab1page);
          // }
          break;
        case 2:
          // {
          this.fetchAcceptedApplicants(this.state.tab2page);
          // }
          break;
        default:
      }
    }
  }

  fetchJobDetail() {
    NetUtil.get(config.apiurl + "v1/common/job/" + this.props.jobId, null)
      .then(result => {
        if (result.hasOwnProperty("err_msg")) {
          // handle error
        } else {
          let tempCombined = [];

          if (result.hasOwnProperty("mandatorySkillRequirements")) {
            tempCombined = tempCombined.concat(
              result.mandatorySkillRequirements
            );
          }
          if (result.hasOwnProperty("skillRequirements")) {
            tempCombined = tempCombined.concat(result.skillRequirements);
          }
          result["combinedSkillrequirements"] = tempCombined;
          this.setState({ jobDetail: result });
        }
      })
      .then(() => {
        this.fetchAllApplicants(1);
        this.fetchPendingApplicants(1);
        this.fetchAcceptedApplicants(1);
      });
  }

  fetchAllApplicants(page, forceUpdatePageNumber = false) {
    let params = {
      jobId: this.props.jobId,
      limit: this.state.fetchLength,
      page: page,
      search: this.searchValue,
      ...(this.state.sort !== "" && {
        sort: this.state.sort !== "" && this.state.sort
      })
    };
    Api.getApplicationList(this.props.jobId, params, global._token).then(
      result => {
        const mandatorySkillTemp =
          this.state.jobDetail.mandatorySkillRequirements || [];
        const skillTemp = this.state.jobDetail.skillRequirements || [];
        let req = mandatorySkillTemp.concat(skillTemp);
        result.items.forEach(applicant => {
          let has = applicant.employeeId.skills;

          // unify data structure
          applicant["employeeDetail"] = applicant.employeeId;
          if (applicant.employeeId.acceptOfferCount == undefined) {
            applicant.employeeId.acceptOfferCount = 0
          }
          if (applicant.employeeId.sendOfferCount == undefined) {
            applicant.employeeId.sendOfferCount = 0
          }
          applicant["offerDisplay"] = applicant.employeeId.acceptOfferCount + "/" + applicant.employeeId.sendOfferCount

          applicant["time"] = new Date(applicant.createdAt).getTime()
          applicant["noshow"] = applicant.employeeId && applicant.employeeId.reviews && applicant.employeeId && applicant.employeeId.reviews.noshows ? applicant.employeeId.reviews.noshows : 0
          applicant["unfinished"] = applicant.employeeId && applicant.employeeId.reviews && applicant.employeeId && applicant.employeeId.reviews.unfinished ? applicant.employeeId.reviews.unfinished : 0

          this.matchApplicantSkills(applicant, req, has.map((item) => {
            return {
              skill: item.skill._id,
              value: item.value
            }
          }));
        });

        if (this.state.sort == "offer") {
          result.items.sort((a, b) => {
            return b.employeeId.acceptOfferCount - a.employeeId.acceptOfferCount;
          })
        }

        this.setState({
          tab0PageCount: Math.ceil(result.total / this.state.fetchLength),
          allApplicants: result.items,
          tab0Total: result.total,
          forceTab0Page: forceUpdatePageNumber ? 0 : undefined
        });
      }
    );
  }

  fetchPendingApplicants(page) {
    let params = {
      jobId: this.props.jobId,
      limit: this.state.fetchLength,
      page: page
    };
    NetUtil.get(
      config.apiurl +
      "v1/listApplicationByStatus?status=interviewing|hiring&no_skill_detail=true",
      params,
      global._token
    ).then(result => {
      if (result.hasOwnProperty("err_msg")) {
        // handle error
      } else {
        const mandatorySkillTemp =
          this.state.jobDetail.mandatorySkillRequirements || [];
        const skillTemp = this.state.jobDetail.skillRequirements || [];
        let req = mandatorySkillTemp.concat(skillTemp);
        result.data.forEach(applicant => {
          let has = applicant.employeeId.skills;
          // unify data structure
          applicant["employeeDetail"] = applicant.employeeId;
          this.matchApplicantSkills(applicant, req, has);
        });
        this.setState({
          pendingApplicants: result.data,
          tab1PageCount: Math.ceil(result.total / this.state.fetchLength),
          tab1Total: result.total
        });
      }
    });
  }

  handleCancelInterview = target => {
    Api.updateApplication(
      target,
      {
        action: "cancelInterviewER"
      },
      global._token
    )
      .then(json => {
        if (json._id) {
          toast.success("Interview canceled!", {
            className: "alert alert-success"
          });
          this.setState(prevState => ({
            update: prevState.update + 1
          }));
        } else {
          throw new Error("Interview cancel failed");
        }
      })
      .catch(e => {
        toast.error(e.message, { className: "alert alert-danger" });
      });
  };
  handleEmergencyCancel = target => {
    Api.updateApplication(
      target,
      {
        action: "cancelOfferER"
      },
      global._token
    )
      .then(json => {
        if (json._id) {
          this.setState(prevState => ({
            update: prevState.update + 1
          }));
          toast.success("Offer cancel successed!", {
            className: "alert alert-success"
          });
        } else {
          throw new Error("Cancel offer failed");
        }
      })
      .catch(e => {
        toast.error(e.message, { className: "alert alert-danger" });
      });
  };
  handleBlackList = target => {
    Api.blackListUser(target, global._token)
      .then(json => {
        if (json.ok) {
          this.setState(prevState => ({
            update: prevState.update + 1
          }));
          toast.success("blacklist user successed!", {
            className: "alert alert-success"
          });
        } else {
          throw new Error("Cancel offer failed");
        }
      })
      .catch(e => {
        toast.error(e.message, { className: "alert alert-danger" });
      });
  };

  handleUnBlacklist = target => {
    Api.unblackListUser(target, global._token)
      .then(json => {
        if (json.ok) {
          this.setState(prevState => ({
            update: prevState.update + 1
          }));
          toast.success("unblacklist user successed!", {
            className: "alert alert-success"
          });
        } else {
          throw new Error("Cancel offer failed");
        }
      })
      .catch(e => {
        toast.error(e.message, { className: "alert alert-danger" });
      });
  };

  fetchAcceptedApplicants(page) {
    let params = {
      jobId: this.props.jobId,
      limit: this.state.fetchLength,
      page: page
    };
    NetUtil.get(
      config.apiurl +
      "v1/listApplicationByStatus?status=hired|interviewed&no_skill_detail=true",
      params,
      global._token
    ).then(result => {
      if (result.hasOwnProperty("err_msg")) {
        // handle error
      } else {
        const mandatorySkillTemp =
          this.state.jobDetail.mandatorySkillRequirements || [];
        const skillTemp = this.state.jobDetail.skillRequirements || [];
        let req = mandatorySkillTemp.concat(skillTemp);
        result.data.forEach(applicant => {
          let has = applicant.employeeId.skills;
          // unify data structure
          applicant["employeeDetail"] = applicant.employeeId;
          this.matchApplicantSkills(applicant, req, has);
        });
        this.setState({
          acceptedApplicants: result.data,
          tab2PageCount: Math.ceil(result.total / this.state.fetchLength),
          tab2Total: result.total
        });
      }
    });
  }

  /**
   * modifies the applicant object in place
   * adds [satisfiedSkills] to the obj
   * adds [notSatisfiedSkills] to the obj
   * adds [additionalSkills] to the obj
   * @param {reference to applicant} applicant
   * @param {array of skills} req
   * @param {array of skills} has
   */
  matchApplicantSkills(applicant, req, has) {
    // console.log(applicant, req, has )
    let satisfied = [];
    let notSatisfied = [];
    let additional = [];

    // key: id, value: skill data
    // loop through req
    // add req to map
    let reqMap = new Map();
    req.forEach(skill => {
      reqMap.set(skill._id, skill);
    });

    // key: id, value: level
    // loop through has
    // add has to map
    let hasMap = new Map();
    has.forEach(skill => {
      hasMap.set(skill.skill, skill.value);
    });

    // loop through has
    // if map contains has
    // add skill to satisfied
    // remove skill from map
    has.forEach(skill => {
      if (reqMap.has(skill.skill)) {
        let temp = reqMap.get(skill.skill);
        temp["value"] = skill.value;
        satisfied.push(temp);
        // all skills remain in hasMap will be additional skill
        hasMap.delete(skill.skill);
        // all skills remain in reqMap will be unsatisfied skill
        reqMap.delete(skill.skill);
      }
    });

    // if map is not empty
    // add remaining elements to not satisfied
    reqMap.forEach(skill => {
      notSatisfied.push(skill);
    });

    // get data for additional skill
    // ee's skill only contains id
    // match id with skill list
    let t = 0
    hasMap.forEach((value, id) => {
      if (this.state.skillList.has(id)) {
        let temp = this.state.skillList.get(id);
        temp["value"] = value;
        additional.push(temp);
      }
      else {
        t++
      }

    });
    // console.log(t)
    applicant["satisfiedSkills"] = satisfied;
    applicant["notSatisfiedSkills"] = notSatisfied;
    applicant["additionalSkills"] = additional;
  }

  handleSendInterview = id => {
    this.setState({ showDialog: true, target: id });
  };
  handleSendOffer = id => {
    this.setState({ showOfferDialog: true, target: id });
  };
  renderAllApplicants = () => {
    let re = [];
    if (this.state.allApplicants.length === 0) {
      return (
        <tr>
          <td colSpan="7">{i18n.t("jobManage.empty")}</td>
        </tr>
      );
    }
    this.state.allApplicants.forEach(applicant => {
      re.push(
        <Applicant
          key={applicant._id}
          id={applicant._id}
          gender={applicant.employeeDetail && applicant.employeeDetail.gender}
          employeeName={applicant.employeeName}
          status={applicant.status}
          skills={`${applicant.satisfiedSkills.length} / ${applicant
            .satisfiedSkills.length + applicant.notSatisfiedSkills.length}`}
          satisfiedSkills={applicant.satisfiedSkills}
          notSatisfied={applicant.notSatisfiedSkills}
          distance={applicant.distance}
          created={applicant.createdAt}
          offerDisplay={applicant.offerDisplay}
          onClick={this.toggleModal.bind(this, applicant)}
          handleSendInterview={this.handleSendInterview}
          handleSendOffer={this.handleSendOffer}
          handleConfirm={this.handleConfirm}
          handleCancel={this.handleCancel}
          handleCancelOffer={this.handleEmergencyCancel}
          handleBlackList={this.handleBlackList}
          handleUnBlacklist={this.handleUnBlacklist}
          handleCancelInterview={this.handleCancelInterview}
          phone={
            applicant.employeeId && applicant.employeeId.mobile
              ? applicant.employeeId.mobile
              : ""
          }
          employeeId={applicant.employeeId && applicant.employeeId._id}
          blacklist={applicant.blacklist ? applicant.blacklist : false}
          new={applicant.new ? applicant.new : false}
        />
      );
    });
    return re;
  };

  renderPendingApplicants = () => {
    let re = [];
    if (this.state.pendingApplicants.length === 0) {
      return (
        <tr>
          <td colSpan="7">{i18n.t("jobManage.empty")}</td>
        </tr>
      );
    }
    this.state.pendingApplicants.forEach(applicant => {
      re.push(
        <Applicant
          key={applicant._id}
          id={applicant._id}
          gender={applicant.employeeDetail && applicant.employeeDetail.gender}
          employeeName={applicant.employeeName}
          status={applicant.status}
          skills={`${applicant.satisfiedSkills.length} / ${applicant
            .satisfiedSkills.length + applicant.notSatisfiedSkills.length}`}
          satisfiedSkills={applicant.satisfiedSkills}
          notSatisfied={applicant.notSatisfiedSkills}
          distance={applicant.distance}
          created={applicant.createdAt}
          onClick={this.toggleModal.bind(this, applicant)}
          handleUnBlacklist={this.handleUnBlacklist}
          handleSendInterview={this.handleSendInterview}
          handleSendOffer={this.handleSendOffer}
          handleConfirm={this.handleConfirm}
          handleCancel={this.handleCancel}
          handleCancelOffer={this.handleEmergencyCancel}
          handleCancelInterview={this.handleCancelInterview}
          handleBlackList={this.handleBlackList}
          phone={
            applicant.employeeId && applicant.employeeId.mobile
              ? applicant.employeeId.mobile
              : ""
          }
          employeeId={applicant.employeeId && applicant.employeeId._id}
          blacklist={applicant.blacklist ? applicant.blacklist : false}
          new={applicant.new ? applicant.new : false}
        />
      );
    });
    return re;
  };

  renderAcceptedApplicants = () => {
    let re = [];
    if (this.state.acceptedApplicants.length === 0) {
      return (
        <tr>
          <td colSpan="7">{i18n.t("jobManage.empty")}</td>
        </tr>
      );
    }
    this.state.acceptedApplicants.forEach(applicant => {
      re.push(
        <Applicant
          key={applicant._id}
          id={applicant._id}
          gender={applicant.employeeDetail && applicant.employeeDetail.gender}
          employeeName={applicant.employeeName}
          status={applicant.status}
          skills={`${applicant.satisfiedSkills.length} / ${applicant
            .satisfiedSkills.length + applicant.notSatisfiedSkills.length}`}
          satisfiedSkills={applicant.satisfiedSkills}
          notSatisfied={applicant.notSatisfiedSkills}
          handleUnBlacklist={this.handleUnBlacklist}
          distance={applicant.distance}
          created={applicant.createdAt}
          onClick={this.toggleModal.bind(this, applicant)}
          handleSendInterview={this.handleSendInterview}
          handleSendOffer={this.handleSendOffer}
          handleConfirm={this.handleConfirm}
          handleCancel={this.handleCancel}
          handleBlackList={this.handleBlackList}
          handleCancelInterview={this.handleCancelInterview}
          handleCancelOffer={this.handleEmergencyCancel}
          employeeId={applicant.employeeId && applicant.employeeId._id}
          phone={
            applicant.employeeId && applicant.employeeId.mobile
              ? applicant.employeeId.mobile
              : ""
          }
          blacklist={applicant.blacklist ? applicant.blacklist : false}
          new={applicant.new ? applicant.new : false}
        />
      );
    });
    return re;
  };

  handleTabClick = event => {
    event.preventDefault();
    this.setState({ tab: parseInt(event.target.dataset.target) });
  };

  handlePageClick = data => {
    this.setState({ tab0page: data.selected + 1 }, () => {
      this.fetchAllApplicants(this.state.tab0page);
    });
  };
  handleTab1PageClick = data => {
    this.setState({ tab1page: data.selected + 1 }, () => {
      this.fetchPendingApplicants(this.state.tab1page);
    });
  };

  handleTab2PageClick = data => {
    this.setState({ tab2page: data.selected + 1 }, () => {
      this.fetchAcceptedApplicants(this.state.tab2page);
    });
  };
  handleSearch = event => {
    event.preventDefault();
    if (this.state.searchValue !== this.searchValue) {
      this.searchValue = this.state.searchValue;
      this.setState({ tab0page: 1, forceTab0Page: null }, () => {
        this.fetchAllApplicants(1, true);
      });
    }
  };

  toggleModal(applicant) {
    if (applicant === null) {
      this.setState({
        showEmployeeDetail: !this.state.showEmployeeDetail
      });
    } else {
      this.setState({
        showEmployeeDetail: !this.state.showEmployeeDetail,
        employeeDetail: applicant,
        target: applicant._id
      });
    }
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-4 d-none d-md-block">
            <div className="card mt-3 border-0 shadow-sm">
              <div className="card-body">
                <h5 className="card-title">{this.state.jobDetail.title}</h5>
                <div className="row">
                  <dl className="col-12 d-flex flex-wrap">
                    <dt className="sr-only">Position</dt>
                    <dd
                      className="d-flex align-items-center"
                      style={{ minWidth: "50%" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-user-check text-primary"
                      >
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                        <circle cx="8.5" cy="7" r="4" />
                        <polyline points="17 11 19 13 23 9" />
                      </svg>
                      <span className="ml-1">
                        {this.state.jobDetail.positionAccepted}/
                        {this.state.jobDetail.positionTotal}
                      </span>
                    </dd>
                    <dt className="sr-only">Job Type</dt>
                    <dd
                      className="d-flex align-items-center"
                      style={{ minWidth: "50%" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-info text-primary"
                      >
                        <circle cx="12" cy="12" r="10" />
                        <line x1="12" y1="16" x2="12" y2="12" />
                        <line x1="12" y1="8" x2="12" y2="8" />
                      </svg>
                      <span className="ml-1">
                        {i18n.t(`jobType.${this.state.jobDetail.type}`)}
                      </span>
                    </dd>
                    <dt className="sr-only">Salary</dt>
                    <dd
                      className="d-flex align-items-center"
                      style={{ minWidth: "50%" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-dollar-sign text-primary"
                      >
                        <line x1="12" y1="1" x2="12" y2="23" />
                        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
                      </svg>
                      {this.state.jobDetail.salary &&
                        this.state.jobDetail.salary.negotiable ? (
                        <span>{i18n.t("postJob.negotiable")}</span>
                      ) : (
                        <>
                          <span>
                            {this.state.jobDetail.salary &&
                              i18n.t(
                                `postJob.${this.state.jobDetail.salary.currency}`
                              )}
                          </span>
                          <span className="ml-1">
                            {this.state.jobDetail.salary &&
                              this.state.jobDetail.salary.value}
                            {this.state.jobDetail.salary &&
                              this.state.jobDetail.salary.maxValue &&
                              this.state.jobDetail.salary.maxValue !== 0
                              ? " - " + this.state.jobDetail.salary.maxValue
                              : ""}
                          </span>

                          <span className="ml-1">
                            {this.state.jobDetail.salary &&
                              i18n.t(
                                `payPeriod.${this.state.jobDetail.salary.unit}`
                              )}
                          </span>
                          <span className="ml-1">
                            {i18n.t(
                              `payMethod.${this.state.jobDetail.payingMethod}`
                            )}
                          </span>
                        </>
                      )}
                    </dd>
                    {this.state.jobDetail &&
                      this.state.jobDetail.startingDate ? (
                      <>
                        <dt className="sr-only">Start Date - End Date</dt>
                        <dd
                          className="d-flex align-items-center"
                          style={{ minWidth: "50%" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-calendar text-primary"
                          >
                            <rect
                              x="3"
                              y="4"
                              width="18"
                              height="18"
                              rx="2"
                              ry="2"
                            />
                            <line x1="16" y1="2" x2="16" y2="6" />
                            <line x1="8" y1="2" x2="8" y2="6" />
                            <line x1="3" y1="10" x2="21" y2="10" />
                          </svg>
                          {this.state.jobDetail.asap && " ASAP"}
                          {!this.state.jobDetail.asap && (
                            <>
                              <span className="ml-1">
                                {format(
                                  parseISO(this.state.jobDetail.startingDate),
                                  "MMM dd, yyyy"
                                )}
                              </span>
                              {this.state.jobDetail &&
                                this.state.jobDetail.endingDate &&
                                this.state.jobDetail.endingDate !== "no limit" ? (
                                <span>
                                  {" - "}
                                  <span>
                                    {format(
                                      parseISO(this.state.jobDetail.endingDate),
                                      "MMM dd, yyyy"
                                    )}
                                  </span>
                                </span>
                              ) : null}
                            </>
                          )}
                        </dd>
                      </>
                    ) : null}
                    {/* <dt className="sr-only">Shift</dt>
                    <dd
                      className="d-flex align-items-center"
                      style={{ minWidth: "50%" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-code text-primary"
                      >
                        <polyline points="16 18 22 12 16 6" />
                        <polyline points="8 6 2 12 8 18" />
                      </svg>
                      <span className="ml-1">
                        {this.state.jobDetail.shifts &&
                          this.state.jobDetail.shifts.length !== 0 &&
                          i18n.t(`shiftType.${this.state.jobDetail.shifts[0]}`)}
                      </span>
                    </dd> */}
                    <dt className="sr-only">From - To:</dt>
                    <dd
                      className="d-flex align-items-center"
                      style={{ minWidth: "50%" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-clock text-primary"
                      >
                        <circle cx="12" cy="12" r="10" />
                        <polyline points="12 6 12 12 16 14" />
                      </svg>

                      <span className="ml-1">
                        {this.state.jobDetail.workingTime && this.state.jobDetail.workingTime.flexibleTime ? i18n.t("simplePost.flexibleTime")
                          : this.state.jobDetail.workingDays &&
                          this.state.jobDetail.workingDays.length !== 0 &&
                          this.state.jobDetail.workingDays
                            .map(v => i18n.t(`weekdays.${v}`))
                            .join(", ")}
                      </span>
                      {this.state.jobDetail.workingTime && this.state.jobDetail.workingTime.flexibleTime ? i18n.t("simplePost.flexibleTime")
                        : <span className="ml-1">
                          {this.state.jobDetail.workingTime &&
                            this.state.jobDetail.workingTime.from}{" "}
                          -{" "}
                          {this.state.jobDetail.workingTime &&
                            this.state.jobDetail.workingTime.to}
                        </span>}
                    </dd>

                    <dt className="sr-only">Address</dt>
                    <dd>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-map-pin text-primary"
                      >
                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                        <circle cx="12" cy="10" r="3" />
                      </svg>
                      {this.state.jobDetail.address &&
                        this.state.jobDetail.address.name &&
                        this.state.jobDetail.address.name.length !== 0 ? (
                        <span className="ml-1">
                          {this.state.jobDetail.address.name}
                        </span>
                      ) : null}
                    </dd>
                  </dl>
                </div>
                {(this.state.jobDetail.mandatorySkillRequirements &&
                  this.state.jobDetail.mandatorySkillRequirements.length !==
                  0) ||
                  (this.state.jobDetail.skillRequirements &&
                    this.state.jobDetail.skillRequirements.length !== 0) ? (
                  <>
                    <div className="card-title h5">
                      {i18n.t(`jobManage.skill-requirement`)}
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {this.state.jobDetail.mandatorySkillRequirements &&
                          this.state.jobDetail.mandatorySkillRequirements
                            .length !== 0 ? (
                          <>
                            <div>{i18n.t(`jobManage.mandatory-skill`)}</div>
                            {this.state.jobDetail.mandatorySkillRequirements.map(
                              skill => (
                                <span
                                  className="badge badge-tag p-2 m-1"
                                  key={skill._id}
                                >
                                  {skill[this.languagePath]}
                                </span>
                              )
                            )}
                          </>
                        ) : null}
                        {this.state.jobDetail.skillRequirements &&
                          this.state.jobDetail.skillRequirements.length !== 0 ? (
                          <div>
                            <div>{i18n.t(`jobManage.preferred-skill`)}</div>
                            {this.state.jobDetail.skillRequirements.map(
                              skill => (
                                <span
                                  className="badge badge-tag p-2 m-1"
                                  key={skill._id}
                                >
                                  {skill[this.languagePath]}
                                </span>
                              )
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}
                {this.state.jobDetail.description &&
                  this.state.jobDetail.description.length !== 0 ? (
                  <>
                    <div className="card-title h5 mt-4">
                      {i18n.t(`jobManage.description`)}
                    </div>
                    <p>{this.state.jobDetail.description}</p>
                  </>
                ) : null}
                {this.state.jobDetail.reminder &&
                  this.state.jobDetail.reminder.length !== 0 ? (
                  <>
                    <div className="card-title h5 mt-4">
                      {i18n.t(`jobManage.reminder`)}
                    </div>
                    <p>{this.state.jobDetail.reminder}</p>
                  </>
                ) : null}

                <div className="my-3">
                  {/* <a>{i18n.t(`jobManage.duplicate`)}</a> */}
                  <button className="btn btn-link" onClick={() => {
                    if (window.confirm(i18n.t(`jobManage.confirmDuplicateJobPost`))) {
                      let jobInfo = this.state.jobDetail
                      const payload = {
                        title: jobInfo.title,
                        branch: jobInfo.branch,
                        job_category: jobInfo.job_category,
                        type: jobInfo.type,
                        positionTotal: jobInfo.positionTotal,
                        address: jobInfo.address,
                        salary: jobInfo.salary,
                        payingMethod: jobInfo.paymentMethod,
                        endingDate: jobInfo.endingDate,
                        startingDate: jobInfo.startingDate,
                        workingDays: jobInfo.workingDays,
                        workingTime: jobInfo.workingTime,
                        skillRequirements: jobInfo.skillRequirements,
                        mandatorySkillRequirements: jobInfo.mandatorySkillRequirements,
                        description: jobInfo.description,
                        reminder: jobInfo.reminder,
                        fullAlert: jobInfo.fullAlert ? jobInfo.fullAlert : "",
                        sexRequired: jobInfo.sexRequired ? jobInfo.sexRequired : ""
                      };
  
                      console.log(payload)
                      console.log(global._token)
                      Api.createDraft(payload, global._token)
                        .then(json => {
                          if (json.id) {
                            navigate(`/employer/draft`);
                          } else {
                            throw new Error("create draft failed");
                          }
                        })
                        .catch(e => {
                          console.error(e.message);
                        });
                    }
                  }}>
                    {i18n.t(`jobManage.duplicate`)}
                  </button>
                </div>
                <div className="my-3">
                  <Link
                    to={"/employer/post-new/" + this.state.jobDetail._id}
                    className="btn btn-primary rounded-circle d-flex justify-content-center align-items-center"
                    style={{ height: "1.5rem", width: "1.5rem" }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-edit-3"
                      >
                        <path d="M12 20h9" />
                        <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" />
                      </svg>
                    </span>
                  </Link>
                </div>
                <div className="my-3">
                  <Link
                    to={"/employer/announcementv2/" + this.state.jobDetail._id}
                    className="btn btn-primary rounded-circle d-flex justify-content-center align-items-center"
                    style={{ height: "1.5rem", width: "1.5rem" }}
                  >
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-megaphone" viewBox="0 0 16 16">
                        <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199V2.5zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065zm-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z" />
                      </svg>
                    </span>
                  </Link>
                </div>
              </div>

            </div>
          </div>
          <div className="col-12 col-md-8">
            <div className="card mt-3 border-0 shadow-sm">
              <div className="card-body">
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                      className={
                        this.state.tab === 0
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      id="nav-home-tab"
                      data-target="0"
                      href="#nav-home"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected={this.state.tab === 0}
                      onClick={this.handleTabClick}
                    >
                      {i18n.t("jobManage.allApplication")}
                      {this.state.tab0Total === 0 ? null : (
                        <span
                          className="ml-2 badge badge-primary badge-pill"
                          onClick={this.handleTabClick}
                          data-target="0"
                        >
                          {this.state.tab0Total}
                        </span>
                      )}
                    </a>
                    <a
                      className={
                        this.state.tab === 1
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      id="nav-profile-tab"
                      data-target="1"
                      href="#nav-profile"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected={this.state.tab === 1}
                      onClick={this.handleTabClick}
                    >
                      {i18n.t("jobManage.pendingApplication")}
                      {this.state.tab1Total === 0 ? null : (
                        <span
                          className="ml-2 badge badge-primary badge-pill"
                          onClick={this.handleTabClick}
                          data-target="1"
                        >
                          {this.state.tab1Total}
                        </span>
                      )}
                    </a>
                    <a
                      className={
                        this.state.tab === 2
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      id="nav-contact-tab"
                      href="#nav-contact"
                      data-target="2"
                      role="tab"
                      aria-controls="nav-contact"
                      aria-selected={this.state.tab === 2}
                      onClick={this.handleTabClick}
                    >
                      {i18n.t("jobManage.acceptedApplication")}
                      {this.state.tab2Total === 0 ? null : (
                        <span
                          className="ml-2 badge badge-primary badge-pill"
                          onClick={this.handleTabClick}
                          data-target="2"
                        >
                          {this.state.tab2Total}
                        </span>
                      )}
                    </a>
                  </div>
                </nav>

                <div className="tab-content" id="nav-tabContent">
                  <div
                    className={
                      this.state.tab === 0
                        ? "tab-pane fade show active bg-white"
                        : "tab-pane fade"
                    }
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div className="form-row mt-3">
                      <form
                        className="input-group col-12 col-md-6"
                        onSubmit={this.handleSearch}
                      >
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            style={{ backgroundColor: "#fff" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-search"
                            >
                              <circle cx="11" cy="11" r="8" />
                              <line x1="21" y1="21" x2="16.65" y2="16.65" />
                            </svg>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.searchValue}
                          placeholder={i18n.t("jobManage.search-phone")}
                          onChange={v =>
                            this.setState({ searchValue: v.target.value })
                          }
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            id="button-addon2"
                          >
                            {i18n.t("jobManage.search")}
                          </button>
                        </div>
                      </form>
                      <div className="col-12 col-md-3 d-none d-md-block">
                        <select
                          className="form-control"
                          value={this.state.sort}
                          onChange={v =>
                            this.setState(
                              {
                                tab0page: 1,
                                forceTab0Page: null,
                                sort: v.target.value
                              },
                              () => {
                                const allApplicants = this.state.allApplicants
                                if (this.state.sort == "offer") {
                                  allApplicants.sort((a, b) => {
                                    return b.employeeId.acceptOfferCount - a.employeeId.acceptOfferCount;
                                  })
                                } else if (this.state.sort == "distance") {
                                  allApplicants.sort((a, b) => {
                                    return a.distance - b.distance;
                                  })
                                } else if (this.state.sort == "time") {
                                  allApplicants.sort((a, b) => {
                                    return b.time - a.time;
                                  })
                                } else if (this.state.sort == "noshow") {
                                  allApplicants.sort((a, b) => {
                                    return a.noshow - b.noshow;
                                  })
                                } else if (this.state.sort == "unfinished") {
                                  allApplicants.sort((a, b) => {
                                    return a.unfinished - b.unfinished;
                                  })
                                }
                                this.setState({ allApplicants: allApplicants })
                              }
                            )
                          }
                        >
                          {/* <option value={""}>
                            {i18n.t("jobManage.default-sort")}
                          </option>
                          <option value={"distance"}>
                            {i18n.t("jobManage.distance-sort")}
                          </option> */}
                          <option value={"offer"}>
                            {i18n.t("jobManage.sortingMethod.offer")}
                          </option>
                          <option value={"distance"}>
                            {i18n.t("jobManage.sortingMethod.distance")}
                          </option>
                          <option value={"time"}>
                            {i18n.t("jobManage.sortingMethod.time")}
                          </option>
                          <option value={"noshow"}>
                            {i18n.t("jobManage.sortingMethod.noshow")}
                          </option>
                          <option value={"unfinished"}>
                            {i18n.t("jobManage.sortingMethod.unfinished")}
                          </option>
                        </select>
                      </div>
                      <div className="col-12 col-md-3 d-none d-md-block">
                        <select
                          className="form-control"
                          value={this.state.fetchLength}
                          onChange={v =>
                            this.setState(
                              {
                                fetchLength: parseInt(v.target.value, 10),
                                tab0page: 1,
                                forceTab0Page: null
                              },
                              () => {
                                this.fetchAllApplicants(1, true);
                              }
                            )
                          }
                        >
                          <option value={15}>15 {i18n.t("results")}</option>
                          <option value={30}>30 {i18n.t("results")}</option>
                          <option value={50}>50 {i18n.t("results")}</option>
                        </select>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-bordered my-3 table-striped table-sm  table-hover text-nowrap">
                        <caption className="sr-only">
                          {i18n.t("jobManage.allApplication")}
                        </caption>
                        <thead>
                          <tr>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-name")}
                            </th>
                            <th scope="col">
                              {i18n.t("jobManage.accept-offer")}
                            </th>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-skill")}
                            </th>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-phone")}
                            </th>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-status")}
                            </th>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-distance")}
                            </th>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-action")}
                            </th>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-apply")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>{this.renderAllApplicants()}</tbody>
                      </table>
                      {this.state.tab0Total > this.state.fetchLength ? (
                        <ReactPaginate
                          previousLabel={i18n.t("paginate.previous")}
                          nextLabel={i18n.t("paginate.next")}
                          breakLabel={"..."}
                          forcePage={this.state.forceTab0Page}
                          pageCount={this.state.tab0PageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination"}
                          breakClassName={"page-item"}
                          breakLinkClassName="page-link"
                          disabledClassName={"disabled"}
                          pageClassName={"page-item"}
                          previousClassName={"page-item"}
                          nextClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          activeClassName={"active"}
                          previousLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div
                    className={
                      this.state.tab === 1
                        ? "tab-pane fade show active bg-white"
                        : "tab-pane fade"
                    }
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    <div className="table-responsive">
                      <table className="table table-bordered my-3 table-striped table-sm  table-hover">
                        <caption className="sr-only">
                          {i18n.t("jobManage.pendingApplication")}
                        </caption>
                        <thead>
                          <tr>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-name")}
                            </th>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-skill")}
                            </th>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-phone")}
                            </th>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-status")}
                            </th>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-distance")}
                            </th>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-action")}
                            </th>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-apply")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>{this.renderPendingApplicants()}</tbody>
                      </table>
                      {this.state.tab1Total > this.state.fetchLength ? (
                        <ReactPaginate
                          previousLabel={i18n.t("paginate.previous")}
                          nextLabel={i18n.t("paginate.next")}
                          breakLabel={"..."}
                          pageCount={this.state.tab1PageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handleTab1PageClick}
                          containerClassName={"pagination"}
                          breakClassName={"page-item"}
                          disabledClassName={"disabled"}
                          pageClassName={"page-item"}
                          previousClassName={"page-item"}
                          nextClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          activeClassName={"active"}
                          previousLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div
                    className={
                      this.state.tab === 2
                        ? "tab-pane fade show active bg-white"
                        : "tab-pane fade"
                    }
                    id="nav-contact"
                    role="tabpanel"
                    aria-labelledby="nav-contact-tab"
                  >
                    <div className="table-responsive">
                      <table className="table table-bordered my-3 table-striped table-sm  table-hover">
                        <caption className="sr-only">
                          {i18n.t("jobManage.acceptedApplication")}
                        </caption>
                        <thead>
                          <tr>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-name")}
                            </th>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-skill")}
                            </th>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-phone")}
                            </th>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-status")}
                            </th>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-distance")}
                            </th>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-action")}
                            </th>
                            <th scope="col">
                              {i18n.t("jobManage.applicant-apply")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>{this.renderAcceptedApplicants()}</tbody>
                      </table>
                      {this.state.tab2Total > this.state.fetchLength ? (
                        <ReactPaginate
                          previousLabel={i18n.t("paginate.previous")}
                          nextLabel={i18n.t("paginate.next")}
                          breakLabel={"..."}
                          pageCount={this.state.tab2PageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handleTab2PageClick}
                          containerClassName={"pagination"}
                          breakClassName={"page-item"}
                          disabledClassName={"disabled"}
                          pageClassName={"page-item"}
                          previousClassName={"page-item"}
                          nextClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          activeClassName={"active"}
                          previousLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          isOpen={this.state.showEmployeeDetail}
          onDismiss={this.toggleModal.bind(this, null)}
          style={{ width: "90vw" }}
        >
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="close"
              onClick={this.toggleModal.bind(this, null)}
            >
              <VisuallyHidden>Close</VisuallyHidden>
              <CloseIcon />
            </button>
          </div>
          {Object.keys(this.state.employeeDetail).length > 0 ? (
            <EmployeeDetail
              icon={this.state.employeeDetail.employeeIcon}
              employeeName={this.state.employeeDetail.employeeName}
              satisfiedSkills={this.state.employeeDetail.satisfiedSkills}
              notSatisfiedSkills={this.state.employeeDetail.notSatisfiedSkills}
              additionalSkills={this.state.employeeDetail.additionalSkills}
              address={this.state.employeeDetail.employeeDetail.address.name}
              email={this.state.employeeDetail.employeeDetail.email}
              phone={this.state.employeeDetail.employeeDetail.mobile}
              introduction={
                this.state.employeeDetail.employeeDetail.introduction
              }
              birthday={this.state.employeeDetail.employeeDetail.birthday}
              gender={this.state.employeeDetail.employeeDetail.gender}
              resume={this.state.employeeDetail.employeeDetail.resume}
            >
              {/* {console.log(this.state.employeeDetail.employeeDetail)} */}
              {this.state.employeeDetail.status === "applying" ||
                this.state.employeeDetail.status ===
                "interview_canceled_employer" ||
                this.state.employeeDetail.status === "interview_declined" ||
                this.state.employeeDetail.status === "offer_canceled_employer" ||
                this.state.employeeDetail.status === "offer_declined" ? (
                <div className="py-2 d-flex justify-content-around">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSendInterview(this.state.target)}
                  >
                    {i18n.t("jobManage.sendInterview")}
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSendOffer(this.state.target)}
                  >
                    {i18n.t("jobManage.sendOffer")}
                  </button>
                  <Link
                    className="btn btn-primary btn-sm"
                    to={
                      "/employer/chat/" +
                      this.state.employeeDetail.employeeId._id
                    }
                  >
                    {i18n.t("jobManage.startChat")}
                  </Link>
                </div>
              ) : null}
              {this.state.employeeDetail.status === "interviewing" ? (
                <div className="py-2 d-flex justify-content-around">
                  <ConfirmButton
                    className="btn btn-primary btn-sm"
                    confirmHandler={() =>
                      this.handleCancelInterview(this.state.target)
                    }
                  >
                    {i18n.t("jobManage.cancelInterview")}
                  </ConfirmButton>
                  <Link
                    className="btn btn-primary btn-sm"
                    to={
                      "/employer/chat/" +
                      this.state.employeeDetail.employeeId._id
                    }
                  >
                    {i18n.t("jobManage.startChat")}
                  </Link>
                </div>
              ) : null}

              {this.state.employeeDetail.status === "interviewed" ? (
                <div className="py-2 d-flex justify-content-around">
                  <ConfirmButton
                    className="btn btn-primary btn-sm"
                    confirmHandler={() =>
                      this.handleCancelInterview(this.state.target)
                    }
                  >
                    {i18n.t("jobManage.cancelInterview")}
                  </ConfirmButton>
                  <Link
                    className="btn btn-primary btn-sm"
                    to={
                      "/employer/chat/" +
                      this.state.employeeDetail.employeeId._id
                    }
                  >
                    {i18n.t("jobManage.startChat")}
                  </Link>
                </div>
              ) : null}
              {this.state.employeeDetail.status === "hiring" ||
                this.state.employeeDetail.status === "hired" ? (
                <div className="py-2 d-flex justify-content-around">
                  <ConfirmButton
                    confirmHandler={() =>
                      this.handleEmergencyCancel(this.state.target)
                    }
                    className="btn btn-primary btn-sm"
                  >
                    {i18n.t("jobManage.emergencyCancelOffer")}
                  </ConfirmButton>
                  <Link
                    className="btn btn-primary btn-sm"
                    to={
                      "/employer/chat/" +
                      this.state.employeeDetail.employeeId._id
                    }
                  >
                    {i18n.t("jobManage.startChat")}
                  </Link>
                </div>
              ) : null}
            </EmployeeDetail>
          ) : null}
        </Dialog>
        <Dialog
          isOpen={this.state.showDialog}
          onDismiss={() => this.setState({ showDialog: false })}
        >
          <button
            type="button"
            className="close"
            onClick={() => this.setState({ showDialog: false })}
          >
            <VisuallyHidden>Close</VisuallyHidden>
            <CloseIcon />
          </button>
          <SendInterview
            applicationId={this.state.target}
            handleInterviewDialogClose={() =>
              this.setState(prevState => ({
                showDialog: false,
                update: prevState.update + 1
              }))
            }
            company={this.props.company}
          />
        </Dialog>

        <Dialog
          isOpen={this.state.showOfferDialog}
          onDismiss={() => this.setState({ showOfferDialog: false })}
        >
          <button
            type="button"
            className="close"
            onClick={() => this.setState({ showOfferDialog: false })}
          >
            <VisuallyHidden>Close</VisuallyHidden>
            <CloseIcon />
          </button>
          <SendOffer
            applicationId={this.state.target}
            handleOfferDialogClose={() =>
              this.setState(prevState => ({
                showOfferDialog: false,
                update: prevState.update + 1
              }))
            }
            company={this.props.company}
            job={this.state.jobDetail}
          />
        </Dialog>
      </>
    );
  }
}

class Applicant extends Component {
  componentDidMount = () => {
    console.log(this.props.new)
  }
  handleSendInterview = () => {
    this.props.handleSendInterview(this.props.id);
  };
  handleSendOffer = () => {
    this.props.handleSendOffer(this.props.id);
  };
  handleConfirm = () => {
    this.props.handleConfirm(this.props.id);
  };
  handleCancel = () => {
    this.props.handleCancel(this.props.id);
  };
  handleBlacklist = () => {
    this.props.handleBlackList(this.props.employeeId);
  };
  handleUnBlacklist = () => {
    this.props.handleUnBlacklist(this.props.employeeId);
  };
  render() {
    return (
      <tr>
        <td onClick={this.props.onClick} className="align-middle text-nowrap">
          {this.props.employeeName}
          {this.props.gender ? (
            this.props.gender === "Female" ? (
              <img
                alt="female"
                className="ml-1"
                src={female}
                style={{ height: 14, width: 8 }}
              />
            ) : (
              <img
                alt="male"
                className="ml-1"
                src={male}
                style={{ height: 12, width: 12 }}
              />
            )
          ) : null}
          {this.props.new ? " New " : ""}
          {this.props.blacklist ? "!" : ""}
        </td>
        <td onClick={this.props.onClick} className="align-middle text-nowrap">
          {this.props.offerDisplay}
        </td>
        <td onClick={this.props.onClick} className="align-middle text-nowrap">
          {this.props.skills}
        </td>
        <td onClick={this.props.onClick}>{this.props.phone}</td>
        <td onClick={this.props.onClick} className="align-middle">
          {(this.props.status === "interview_canceled_employer" ||
            this.props.status === "interview_declined" ||
            this.props.status === "offer_canceled_employer" ||
            this.props.status === "offer_declined") && (
              <span className="badge badge-pill badge-danger">
                {i18n.t(`jobManage.${this.props.status}`)}
              </span>
            )}
          {(this.props.status === "applying" ||
            this.props.status === "interviewed") && (
              <span className="badge badge-pill badge-info">
                {i18n.t(`jobManage.${this.props.status}`)}
              </span>
            )}
          {(this.props.status === "interviewing" ||
            this.props.status === "hiring") && (
              <span className="badge badge-pill badge-primary">
                {i18n.t(`jobManage.${this.props.status}`)}
              </span>
            )}
          {this.props.status === "hired" && (
            <span className="badge badge-pill badge-success">
              {i18n.t(`jobManage.${this.props.status}`)}
            </span>
          )}
        </td>
        <td onClick={this.props.onClick} className="align-middle text-nowrap">
          {this.props.distance} {i18n.t("jobBoard.km")}
        </td>
        <td>
          <Dropdown>
            <Dropdown.Toggle
              tabIndex={0}
              as="svg"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-menu"
            >
              <line x1="3" y1="12" x2="21" y2="12" />
              <line x1="3" y1="6" x2="21" y2="6" />
              <line x1="3" y1="18" x2="21" y2="18" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="border-0 shadow">
              {/* <Dropdown.Item
                as={Link}
                to={"/employer/application/" + this.props.id}
                className="dropdown-item"
              >
                Detail
              </Dropdown.Item> */}
              {/* <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}

              {this.props.status === "applying" ||
                this.props.status === "interview_canceled_employer" ||
                this.props.status === "interview_declined" ||
                this.props.status === "offer_canceled_employer" ||
                this.props.status === "offer_declined" ? (
                <>
                  <Dropdown.Item onClick={this.handleSendInterview}>
                    {i18n.t("jobManage.sendInterview")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={this.handleSendOffer}>
                    {i18n.t("jobManage.sendOffer")}
                  </Dropdown.Item>
                </>
              ) : null}
              {this.props.status === "interviewing" ? (
                <Dropdown.Item
                  as={ConfirmButton}
                  confirmHandler={() => {
                    this.props.handleCancelInterview(this.props.id);
                  }}
                  className="btn btn-link text-nowrap"
                >
                  {i18n.t("jobManage.cancelInterview")}
                </Dropdown.Item>
              ) : null}

              {this.props.status === "interviewed" ? (
                <Dropdown.Item
                  as={ConfirmButton}
                  confirmHandler={() => {
                    this.props.handleCancelInterview(this.props.id);
                  }}
                  className="btn btn-link text-nowrap"
                >
                  {i18n.t("jobManage.cancelInterview")}
                </Dropdown.Item>
              ) : null}
              {this.props.status === "hiring" ||
                this.props.status === "hired" ? (
                <Dropdown.Item
                  as={ConfirmButton}
                  confirmHandler={() => {
                    this.props.handleCancelOffer(this.props.id);
                  }}
                  className="btn btn-link text-nowrap"
                >
                  {i18n.t("jobManage.emergencyCancelOffer")}
                </Dropdown.Item>
              ) : null}
              {this.props.blacklist === false && this.props.employeeId && (
                <Dropdown.Item
                  as={ConfirmButton}
                  confirmHandler={this.handleBlacklist}
                  className="btn btn-link text-nowrap"
                >
                  {i18n.t("jobManage.blacklist")}
                </Dropdown.Item>
              )}
              {this.props.blacklist === true && this.props.employeeId && (
                <Dropdown.Item
                  as={ConfirmButton}
                  confirmHandler={this.handleUnBlacklist}
                  className="btn btn-link text-nowrap"
                >
                  {i18n.t("jobManage.unblacklist")}
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </td>
        <td onClick={this.props.onClick} className="align-middle text-nowrap">
          {formatRelative(parseISO(this.props.created), new Date(), {
            locale: i18n.language === "zh" ? zh : en
          })}
        </td>
      </tr>
    );
  }
}
