import React, { Component } from "react";
import { i18n } from "../i18n";
import { Dialog } from "@reach/dialog";
import Downshift from "downshift";
import config from "../config";
import { SkillDialog } from "../Step4";
import VisuallyHidden from "@reach/visually-hidden";
import CloseIcon from "../component/CloseIcon";
class SkillInfo extends Component {
  state = {
    editSkill: false,
    tab: 0,
    showSkillDialog: false,
    showSkills: [],
    level: false
  };
  languagePath = i18n.language === "zh" ? "name_cn" : "name";
  initialSkill = [];
  toggleModal = (child = [], level = false) => {
    this.setState(prevState => {
      return {
        showSkillDialog: !prevState.showSkillDialog,
        showSkills: prevState.showSkillDialog === false ? child : [],
        level: level
      };
    });
  };
  handleEditSkill = event => {
    event.preventDefault();
    this.setState({ editSkill: true });
  };
  handleLabelClick = () => {
    this.setState({ editSkill: true });
  };
  handleSkillConfirm = () => {
    this.initialSkill = null;
    this.setState({ editSkill: false });
  };
  handleSkillCancel = event => {
    event.preventDefault();
    this.setState({ editSkill: false });
  };
  handleTabClick = e => {
    e.preventDefault();
    this.setState({ tab: parseInt(e.target.dataset.idx, 10) });
  };
  handleSkillDismiss = () => {
    this.setState({ editSkill: false });
  };
  removeSkills = a => {
    this.props.removeAndAddSkill([a], []);
  };
  handleSkillSave = event => {
    event.preventDefault();
    this.props.handleContinue(this.handleSkillConfirm);
  };

  componentDidUpdate(prevProps, prevState) {
    // if (this.props.requiredSkill.length !== prevProps.requiredSkill.length) {

    // }
    if (prevState.editSkill !== this.state.editSkill) {
      if (this.state.editSkill === true) {
        this.initialSkill = {
          requiredSkill: this.props.requiredSkill
        };
      } else {
        if (this.initialSkill) {
          this.props.handleResetValue(this.initialSkill);
          this.initialSkill = null;
        }
      }
    }
  }

  render() {
    const searchArray = [];
    Object.keys(this.props.allSkills).map(key => {
      if (!this.props.requiredSkill.includes(key)) {
        searchArray.push(this.props.allSkills[key]);
      }
    });
    return (
      <div className="row mb-3">
        <label
          htmlFor="job-skill-id"
          className="col-sm-3 col-form-label"
          onClick={this.handleLabelClick}
        >
          {i18n.t("postJob.step5")}
        </label>
        <a
          className="col-sm-9 h4"
          style={{
            paddingTop: "calc(0.375rem + 1px)",
            paddingBottom: "calc(0.375rem + 1px)",
            border: "dashed 1px #0088cc",
            textDecoration: "none"
          }}
          href="#"
          onClick={this.handleEditSkill}
        >
          {this.props.requiredSkill.map(skill => (
            <>
              {this.props.allSkills[skill] && (
                <div
                  className="badge badge-tag mx-1 my-2 px-2 py-1"
                  key={skill}
                >
                  {this.props.allSkills[skill][this.languagePath]}
                </div>
              )}
            </>
          ))}
        </a>
        <Dialog isOpen={this.state.editSkill}>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="close"
              onClick={this.handleSkillDismiss}
            >
              <VisuallyHidden>Close</VisuallyHidden>
              <CloseIcon />
            </button>
          </div>
          <div>
            {this.props.requiredSkill && (
              <label className="text-muted">
                {i18n.t("postJob.selected-skills")}
              </label>
            )}
            <div className="h4">
              {this.props.requiredSkill.map(skill => (
                <React.Fragment key={skill}>
                  {this.props.allSkills[skill] && (
                    <span className="badge badge-tag mx-1 mt-2 d-inline-flex align-items-center">
                      {this.props.allSkills[skill][this.languagePath]}
                      <button
                        type="button"
                        className="close mx-2"
                        aria-label="Close"
                        onClick={() => this.removeSkills(skill)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </span>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="dropdown-divider my-4"></div>
          <Downshift
            onChange={selection => {
              if (selection) {
                this.props.removeAndAddSkill([], [selection._id]);
              }
            }}
            itemToString={item => ""}
          >
            {({
              getInputProps,
              getItemProps,
              getLabelProps,
              getMenuProps,
              isOpen,
              inputValue,
              highlightedIndex,
              selectedItem
            }) => (
              <div className="form-group" style={{ position: "relative" }}>
                {/* <label {...getLabelProps()}>Enter a fruit</label> */}
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx="11" cy="11" r="8" />
                        <line x1="21" y1="21" x2="16.65" y2="16.65" />
                      </svg>
                    </span>
                  </div>
                  <input
                    {...getInputProps()}
                    className="form-control"
                    placeholder={i18n.t("simplePost.search")}
                  />
                </div>
                <ul
                  {...getMenuProps()}
                  style={{
                    position: "absolute",
                    top: "100%",
                    zIndex: 2,
                    maxHeight: "30vh",
                    overflowY: "auto"
                  }}
                  className="list-group w-100 shadow-sm mt-1"
                >
                  {isOpen
                    ? searchArray
                        .filter(
                          item =>
                            !inputValue ||
                            item.name
                              .toLowerCase()
                              .includes(inputValue.toLowerCase()) ||
                            item.name_cn.includes(inputValue)
                        )
                        .map((item, index) => (
                          <li
                            className="list-group-item list-group-item-action"
                            {...getItemProps({
                              key: item._id,
                              index,
                              item
                            })}
                          >
                            {item[this.languagePath]}
                          </li>
                        ))
                    : null}
                </ul>
              </div>
            )}
          </Downshift>

          {this.props.category && this.props.selectedCategory && (
            <>
              <div className="d-flex" role="tablist">
                <a
                  href="#"
                  id="first-tab"
                  data-idx="0"
                  role="tab"
                  className={
                    "col text-center border-bottom py-2" +
                    (this.state.tab === 0
                      ? " border-primary font-weight-bolder"
                      : " border-muted text-muted")
                  }
                  onClick={this.handleTabClick}
                  aria-selected={this.state.tab === 0}
                >
                  {i18n.t("postJob.common")}
                </a>
                <a
                  href="#"
                  id="second-tab"
                  data-idx="1"
                  role="tab"
                  onClick={this.handleTabClick}
                  className={
                    "col text-center py-2 border-bottom" +
                    (this.state.tab === 1
                      ? " border-primary font-weight-bolder"
                      : " border-muted text-muted")
                  }
                  aria-selected={this.state.tab === 1}
                >
                  {
                    this.props.category.find(
                      c => c._id === this.props.selectedCategory
                    ).parent[0][this.languagePath]
                  }
                </a>
              </div>
              <div>
                <div
                  role="tabpanel"
                  aria-labelledby="first-tab"
                  className="h2 py-2"
                  style={{ display: this.state.tab === 0 ? "block" : "none" }}
                >
                  {this.props.category
                    .find(function(elem) {
                      return elem._id === config.commonId;
                    })
                    .child.map(sub => (
                      <button
                        type="button"
                        className="btn btn-outline-primary mx-1 mt-2"
                        key={sub._id}
                        onClick={() =>
                          this.toggleModal(sub.skills, sub.type === "level")
                        }
                      >
                        {sub[this.languagePath]}
                      </button>
                    ))}
                </div>
                <div
                  role="tabpanel"
                  aria-labelledby="second-tab"
                  style={{ display: this.state.tab === 1 ? "block" : "none" }}
                  className="h2 py-2"
                >
                  {this.props.category
                    .find(elem => {
                      /** @todo */
                      return elem._id === this.props.selectedCategory;
                    })
                    .child.map(sub => (
                      <button
                        type="button"
                        className="btn btn-outline-primary mx-1 mt-2"
                        key={sub._id}
                        onClick={() =>
                          this.toggleModal(sub.skills, sub.type === "level")
                        }
                      >
                        {sub[this.languagePath]}
                      </button>
                    ))}
                </div>
              </div>
            </>
          )}
          <SkillDialog
            isOpen={this.state.showSkillDialog}
            preferredSkill={this.props.preferredSkill}
            requiredSkill={this.props.requiredSkill}
            toggleModal={this.toggleModal}
            showSkills={this.state.showSkills}
            removeAndAddSkill={this.props.removeAndAddSkill}
            level={this.state.level}
          />
          <div className="d-flex justify-content-end">
            <a
              href="#"
              className="btn btn-primary"
              onClick={this.handleSkillSave}
            >
              {i18n.t("simplePost.save")}
            </a>
            <a
              href="#"
              className="btn btn-link"
              onClick={this.handleSkillCancel}
            >
              {i18n.t("simplePost.cancel")}
            </a>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default SkillInfo;
