import React from "react";
import { i18n } from "../i18n";
class BasicInfo extends React.Component {
  constructor(props) {
    super(props);
  }
  languagePath = i18n.language === "zh" ? "name_cn" : "name";
 
  openDialog = event => {
    event.preventDefault();
    this.props.showBasicInfoCard()
  };
  openEditDialog = () => {
    this.props.showBasicInfoCard()
  };
  

  render() {
    return (
      <>
        <div className="row mb-3">
          <div
            className="col-sm-3 col-form-label"
            onClick={this.openEditDialog}
          >
            {i18n.t("postJob.job-category")}
          </div>
          <a
            className="col-sm-9 d-flex justify-content-between"
            style={{
              paddingTop: "calc(0.375rem + 1px)",
              paddingBottom: "calc(0.375rem + 1px)",
              borderBottom: "dashed 1px #0088cc",
              textDecoration: "none"
            }}
            href="#"
            onClick={this.openDialog}
          >
            {this.props.category
              ? this.props.categoryOptions.find(
                  e => e._id === this.props.category
                ).parent[0][this.languagePath]
              : "-"}
          </a>
        </div>
        <div className="row mb-3">
          <div
            className="col-sm-3 col-form-label"
            onClick={this.openEditDialog}
          >
            {i18n.t("postJob.job-title")}
          </div>
          <a
            className="col-sm-9 d-flex justify-content-between"
            style={{
              paddingTop: "calc(0.375rem + 1px)",
              paddingBottom: "calc(0.375rem + 1px)",
              borderBottom: "dashed 1px #0088cc",
              textDecoration: "none"
            }}
            href="#"
            onClick={this.openDialog}
          >
            {this.props.title ? this.props.title : "-"}
          </a>
        </div>
        <div className="row mb-3">
          <div
            className="col-sm-3 col-form-label"
            onClick={this.openEditDialog}
          >
            {i18n.t("postJob.description")}
          </div>
          <a
            className="col-sm-9 d-flex justify-content-between"
            style={{
              paddingTop: "calc(0.375rem + 1px)",
              paddingBottom: "calc(0.375rem + 1px)",
              borderBottom: "dashed 1px #0088cc",
              textDecoration: "none"
            }}
            href="#"
            onClick={this.openDialog}
          >
            {this.props.description ? this.props.description : "-"}
          </a>
        </div>
      </>
    );
  }
}

export default BasicInfo;
