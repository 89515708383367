import React, { useState, useEffect } from "react";
import { i18n } from "./i18n";
import * as Api from "./Api";
import format from "date-fns/format";
import { toast } from "react-toastify";
import parseISO from "date-fns/parseISO";
import ReactPaginate from "react-paginate";

function useAnnouncementData(page, token) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  useEffect(() => {
    setLoading(true);
    Api.getAnnouncement(page, token)
      .then(json => {
        if (json && json.total) {
          setData(json.items);
          setPageCount(Math.ceil(json.total / 10));
        }
        setLoading(false);
      })
      .catch(error => {
        toast.error(error.message, { className: "alert alert-danger" });
        setLoading(false);
      });
  }, [page]);
  return [data, loading, pageCount];
}

function Announcement(props) {
  const [value, setValue] = useState("");
  const [page, setPage] = useState(0);
  const [data, loading, pageCount] = useAnnouncementData(page, global._token);
  function sendAnnouncement(event) {
    event.preventDefault();
    if (value !== "" || value.length !== 0) {
      Api.sendAnnouncement(value, global._token)
        .then(json => {
          if (json.ok) {
            setValue("");
            toast.success("announcement sent!", {
              className: "alert alert-success"
            });
          } else {
            throw new Error("send announcement failed");
          }
        })
        .catch(e => {
          toast.error(e.message, { className: "alert alert-danger" });
        });
    }
  }

  function onPageChange(data) {
    setPage(data.selected + 1);
  }

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">{i18n.t("tabTitles.announcement")}</h1>
      </div>
      <div className="bg-white p-3 shadow-sm row">
        <div className="col-4">
          <form onSubmit={sendAnnouncement}>
            <div class="form-group">
              <label for="exampleFormControlTextarea1">
                {i18n.t("announcement.content")}
              </label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                value={value}
                onChange={e => setValue(e.target.value)}
              ></textarea>
              <small class="form-text text-muted">
                {i18n.t("announcement.hint")}
              </small>
            </div>
            <button type="submit" class="btn btn-primary">
              {i18n.t("announcement.submit")}
            </button>
          </form>
        </div>
        <div className="col-8">
          <table class="table">
            <thead class="thead-dark">
              <tr>
                {/* <th scope="col">#</th> */}
                <th scope="col">{i18n.t("announcement.content")}</th>
                <th scope="col">{i18n.t("announcement.time")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map(row => (
                <tr>
                  <td>{row.content}</td>
                  <td>{format(parseISO(row.createdAt), "M/d H:mm")}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <ReactPaginate
            previousLabel={i18n.t("paginate.previous")}
            nextLabel={i18n.t("paginate.next")}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={onPageChange}
            containerClassName={"pagination"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            disabledClassName={"disabled"}
            pageClassName={"page-item"}
            previousClassName={"page-item"}
            nextClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName={"active"}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
          />
        </div>
      </div>
    </div>
  );
}

export default Announcement;
