import React, { useState } from "react";
import { toast } from "react-toastify";
import * as Api from "../Api";
import FooterHirineLogo from "../img/HirineFooterLogo@3x.png";
function SendDownloadLink(props) {
  const [phone, setPhone] = useState("");
  function handleFormSubmit(event) {
    event.preventDefault();
    const formatPhone = phone.replace(/\D/g, "");
    if (formatPhone && formatPhone.length !== 0) {
      Api.sendDownloadLink(formatPhone)
        .then(response => {
          if (response.ok) {
            toast.success("text sent!", {
              className: "alert alert-success"
            });
          } else {
            throw new Error("sending text failed, please try again latter");
          }
        })
        .catch(e => {
          toast.error(e.message, { className: "alert alert-danger" });
        });
    }
  }
  function handleInputChange(event) {
    setPhone(event.target.value);
  }
  return (
    <div className="container card card-shadow mt-3 d-none d-lg-block">
      <div className="card-body">
        <div className="row">
          <div className="col-lg-5 col-12">
            <a href="http://onelink.to/gq6gbs" className="mr-2">
              <img
                style={{ width: 48 }}
                src={FooterHirineLogo}
                alt={"Hirine Logo"}
              />
            </a>
            <strong>Get the Hirine App - Text yourself a link</strong>
          </div>
          <div className="col-lg-7 col-12">
            <form className="form-inline" onSubmit={handleFormSubmit}>
              <div className="form-group">
                <input
                  inputMode="numeric"
                  pattern="[0-9]{10}"
                  type="text"
                  value={phone}
                  onChange={handleInputChange}
                  className="form-control mr-sm-2"
                  id="inlineFormInputGroupUsername2"
                  placeholder="eg. 8001234567"
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SendDownloadLink;
