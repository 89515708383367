export default class NetUtil {
  /*
    *  get请求
    *  url:请求地址
    *  data:参数
    *  callback:回调函数
    * */
  static async get(url, params, token = null) {
    if (params) {
      let paramsArray = [];
      //拼接参数
      Object.keys(params).forEach(key =>
        paramsArray.push(key + "=" + params[key])
      );
      if (url.search(/\?/) === -1) {
        url += "?" + paramsArray.join("&");
      } else {
        url += "&" + paramsArray.join("&");
      }
    }
    try {
      const headers = new Headers({ "Content-Type": "application/json", "Accept": "application/json" });
      if (token !== null) {
        headers.append("Authorization", "Bearer " + token);
      }

      let response = await fetch(url, {
        method: "GET",
        headers: headers
      })

      let result = await response.json();
      return result;
    } catch (error) {
      if (error.message.indexOf("Network request failed") !== -1 || error.message.indexOf("ajax error 0") !== -1) {
        error.message = "网络状态不佳，请检查网络设置";
        return error;
      } else {
        return error;
      }
    }
  }
  /*
     *  post请求
     *  url:请求地址
     *  data:参数
     *  callback:回调函数
     * */
  static async post(url, data, token = null) {
    try {
      const headers = new Headers({ "Content-Type": "application/json", "Accept": "application/json" });
      if (token !== null) {
        headers.append("Authorization", "Bearer " + token);
      }
      let response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: data
      });
      let result = await response.json();
      return result;
    } catch (error) {
      if (error.message.indexOf("Network request failed") !== -1 || error.message.indexOf("ajax error 0") !== -1) {
        error.message = 'pleaseCheckNetwork'
        return error;
      } else {
        return error;
      }
    }
  }

  /*
 *  put
 *  url:请求地址
 *  data:参数
 * */
  static async put(url, data, token = null) {
    try {
      const headers = new Headers({ "Content-Type": "application/json", "Accept": "application/json" });
      if (token !== null) {
        headers.append("Authorization", "Bearer " + token);
      }
      let response = await fetch(url, {
        method: "put",
        headers: headers,
        body: data
      });
      let result = await response.json();
      return result;
    } catch (error) {
      if (error.message.indexOf("Network request failed") !== -1 || error.message.indexOf("ajax error 0") !== -1) {
        error.message = "网络状态不佳，请检查网络设置";
        return error;
      } else {
        return error;
      }
    }
  }


  static async getPdf(url, params, token = null) {
    if (params) {
      let paramsArray = [];
      //拼接参数
      Object.keys(params).forEach(key =>
        paramsArray.push(key + "=" + params[key])
      );
      if (url.search(/\?/) === -1) {
        url += "?" + paramsArray.join("&");
      } else {
        url += "&" + paramsArray.join("&");
      }
    }

  //   axios(`${url}`, {
  //     method: 'GET',
  //     responseType: 'blob' //Force to receive data in a Blob Format
  // })
    // try {

    //   const headers = new Headers({ "Content-Type": "application/pdf", "Accept": "application/json" });
    //   if (token !== null) {
    //     headers.append("Authorization", "Bearer " + token);
    //   }

    //   let response = await fetch(url, {
    //     method: "GET",
    //     headers: headers
    //   })

    //   let result = await response.json();
    //   return result;
    // } catch (error) {
    //   if (error.message.indexOf("Network request failed") !== -1 || error.message.indexOf("ajax error 0") !== -1) {
    //     error.message = "网络状态不佳，请检查网络设置";
    //     return error;
    //   } else {
    //     return error;
    //   }
    // }
  }
}