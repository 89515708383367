import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import _o_ from "react-datepicker";
import * as Yup from "yup";
import format from "date-fns/format";

import * as Api from "../Api";
import AddressDialog from "./AddressDialog";
import { toast } from "react-toastify";
import { navigate } from "@reach/router";
import { i18n } from "../i18n";
import { Link } from "@reach/router";
import get from "lodash/get";
import Step4 from "../Step4";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import config from "../config";
import MandatorySkillDialog from "../MandatorySkillDialog";
import { parseISO } from "date-fns/esm";

const PostJobSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  category: Yup.string().required("Required"),
  subCategory: Yup.string(),
  template: Yup.string(),
  type: Yup.string().required("Required"),
  position: Yup.number().when("unlimit", {
    is: false,
    then: Yup.number()
      .integer()
      .min(1)
      .required("Required"),
    otherwise: Yup.number()
      .nullable()
      .notRequired()
  }),
  unlimit: Yup.boolean()
    .required()
    .default(false),
  unit: Yup.string()
    .nullable()
    .notRequired(),
  salary: Yup.number()
    .min(0)
    .typeError("invalid salary")
    .required(),
  maxSalary: Yup.number()
    .typeError("invalid salary")
    .nullable()
    .min(0),
  currency: Yup.string().when("negotiable", {
    is: false,
    then: Yup.string().required(),
    otherwise: Yup.string()
  }),
  paymentMethod: Yup.string().when("negotiable", {
    is: false,
    then: Yup.string().required(),
    otherwise: Yup.string()
  }),
  negotiable: Yup.boolean()
    .default(false)
    .required(),
  rate: Yup.string().when("negotiable", {
    is: false,
    then: Yup.string().required(),
    otherwise: Yup.string()
  }),
  startDate: Yup.date().required(),
  endDate: Yup.date().when("permanent", {
    is: true,
    then: Yup.date()
      .nullable()
      .notRequired(),
    otherwise: Yup.date().required()
  }),
  shift: Yup.string().required(),
  permanent: Yup.boolean()
    .required()
    .default(false),
  from: Yup.date()
    .typeError("invalid time")
    .required("invalid time"),
  to: Yup.date()
    .typeError("invalid time")
    .required("invalid time"),
  workingDay: Yup.array().min(1, "Minimum a working day"),
  address: Yup.object().shape({
    description: Yup.string()
      .required()
      .min(1),
    latitude: Yup.number()
      .required()
      .notOneOf([0], "Invalid address"),
    longitude: Yup.number()
      .required()
      .notOneOf([0], "Invalid address")
  }),
  branch: Yup.string().nullable(),
  saveAddress: Yup.boolean().default(false),
  isBranch: Yup.boolean().default(false),
  branchValue: Yup.string().when("isBranch", {
    is: true,
    then: Yup.string()
      .required()
      .min(1),
    otherwise: Yup.string()
  })
});

const workingDayArray = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const specialDayArray = ["M-F", "SS"];
const sortArray = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
  "M-F",
  "SS"
];

class PostJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      showAddress: false,
      step: 0,
      showAddressButton: false,
      addressValue: "",
      showSave: false,
      category: [],
      isMandatoryDialogOpen: false,
      /** @todo remove */
      showSecret: false
    };
    this.initialValues = {
      title: "",
      category: "",
      type: "Full time",
      position: 0,
      unlimit: true,
      unit: "",
      salary: 0,
      maxSalary: 0,
      shift: "",
      currency: "CAD",
      paymentMethod: "Cheque",
      rate: "hourly",
      permanent: true,
      startDate: null,
      endDate: null,
      from: null,
      to: null,
      requiredSkill: [],
      preferredSkill: [],
      workingDay: [],
      address: {
        description: "",
        latitude: 0,
        longitude: 0
      },
      negotiable: false,
      branch: "",
      saveAddress: false,
      isBranch: false,
      branchValue: "",
      /** @todo remove */
      directEmail: "",
      directPhone: ""
    };
    this.selectedAddress = false;
    this.languagePath = i18n.language === "zh" ? "name_cn" : "name";
    this.allSkills = {};
    this.allTemplates = {};
    this.createdDraftId = null;
  }
  hideAddressDialog = () => this.setState({ showAddress: false });
  showAddressDialog = e => {
    e.preventDefault();
    this.setState({ showAddress: true });
  };
  handleSaveDraft = values => {
    const payload = {
      address: {
        name: values.address.description,
        unitNumber: values.unit,
        location: {
          coordinates: [values.address.longitude, values.address.latitude],
          type: "Point"
        }
      },
      location: {
        coordinates: [values.address.longitude, values.address.latitude],
        type: "Point"
      },
      ...(values.category !== "" ? { category: values.category } : null),
      description: values.description,
      startingDate: values.startDate,
      endingDate: values.permanent ? "no limit" : values.endDate,
      title: values.title,
      workingTime: {
        from: values.from ? format(values.from, "HH:mm") : null,
        to: values.to ? format(values.to, "HH:mm") : null
      },
      workingDays: values.workingDay,
      ...(values.type !== "" ? { type: values.type } : null),
      positionTotal: values.unlimit ? 0 : values.position,
      salary: {
        value: values.salary,
        currency: values.currency,
        unit: values.rate,
        maxValue: values.maxSalary
      },
      payingMethod: values.paymentMethod,
      shifts: [values.shift],

      /** @todo */
      direct_email: values.directEmail,
      direct_phone: values.directPhone
    };
    if (this.props.draftId) {
      Api.updateDraft(this.props.draftId, payload, global._token)
        .then(json => {
          if (json.ok) {
            toast.success("draft updated!", {
              className: "alert alert-success"
            });
            navigate(`/employer/draft`);
          } else {
            throw new Error("update draft failed");
          }
        })
        .catch(e => {
          toast.error(e.message, { className: "alert alert-danger" });
        });
    } else {
      // create draft
      Api.createDraft(payload, global._token)
        .then(json => {
          if (json.id) {
            toast.success("draft created!", {
              className: "alert alert-success"
            });
            navigate(`/employer/draft`);
          } else {
            throw new Error("create draft failed");
          }
        })
        .catch(e => {
          toast.error(e.message, { className: "alert alert-danger" });
        });
    }
  };
  getJobDetail = () => {
    // edit
    Api.getJobDetail(this.props.jobId, global._token).then(json => {
      this.initialValues = {
        title: json.title,
        category:
          json.job_category && json.job_category._id
            ? json.job_category._id
            : "",
        type: json.type,
        position: json.positionTotal === 0 ? 0 : json.positionTotal,
        unlimit: json.positionTotal === 0 ? true : false,
        unit: json.address.unitNumber ? json.address.unitNumber : "",
        salary: json.salary && json.salary.value ? json.salary.value : 0,
        maxSalary:
          json.salary && json.salary.maxValue ? json.salary.maxValue : 0,
        shift:
          json.shifts.length && json.shifts[0] && json.shifts[0].length !== 0
            ? json.shifts[0]
            : "",
        currency: json.salary.currency ? json.salary.currency : "CAD",
        paymentMethod: json.payingMethod || "Cheque",
        negotiable: json.salary && json.salary.negotiable ? true : false,
        rate: json.salary.unit,
        permanent: json.endingDate === "no limit" ? true : false,
        startDate: json.startingDate ? parseISO(json.startingDate) : null,
        endDate:
          json.endingDate === "no limit"
            ? null
            : json.endingDate
            ? parseISO(json.endingDate)
            : null,
        from: this.convert(json.workingTime.from),
        to: this.convert(json.workingTime.to),
        requiredSkill:
          json.mandatorySkillRequirements &&
          json.mandatorySkillRequirements.length !== 0
            ? json.mandatorySkillRequirements.map(skill => skill._id)
            : [],
        preferredSkill:
          json.skillRequirements && json.skillRequirements.length !== 0
            ? json.skillRequirements.map(skill => skill._id)
            : [],
        workingDay:
          json.workingDays && json.workingDays.indexOf("everyDay") !== -1
            ? workingDayArray
            : json.workingDays,
        address: {
          description: json.address.name,
          latitude: json.address.location.coordinates[1],
          longitude: json.address.location.coordinates[0]
        },
        description: json.description,
        reminder: json.reminder,
        branch: json.branch && json.branch._id,
        saveAddress: false,
        isBranch: json.branch && json.branch._id ? true : false,
        branchValue:
          json.branch && json.branch._id ? json.branch.addressTitle : "",

        /** @todo remove */
        directEmail: json.direct_email || "",
        directPhone: json.direct_phone || ""
      };
      this.setState({
        ready: true,
        addressValue: json.address && json.address.name ? json.address.name : ""
      });
      if (json.branch && json.branch._id) {
        this.selectedAddress = true;
      }
    });
  };
  componentDidMount() {
    this.getSkillAndCategory();

    if (this.props.jobId) {
      this.getJobDetail();
    } else if (this.props.draftId) {
      this.getDraftDetail();
    } else {
      // post
      this.getTemplate();
      this.setState({ ready: true });
    }
  }

  getSkillAndCategory = () => {
    Api.getCategory(global._token).then(json => {
      if (json && json.items && json.items.length !== 0) {
        this.setState({ category: json.items });
        json.items.forEach(cat => {
          cat.child.forEach(c => {
            c.skills.forEach(skill => {
              this.allSkills[skill._id] = skill;
            });
          });
        });
      }
    });
  };

  getTemplate = () => {
    Api.getTemplate(global._token).then(json => {
      if (json && json.items && json.items.length !== 0) {
        json.items.forEach(cat => {
          const tempChild = [];
          if (cat.child && cat.child.length !== 0) {
            // console.log(cat)
            cat.child.forEach(c => {
              if (
                c.templates
                //  && c.templates.length !== 0
              ) {
                tempChild.push(c);
              }
            });
          }
          this.allTemplates[cat._id] = tempChild;
        });
        // console.log(this.allTemplates)
      }
    });
  };

  handleNext = (
    validateForm,
    setFieldTouched,
    fields,
    payload,
    first = false,
    callback = function() {}
  ) => {
    if (fields && fields.length !== 0) {
      fields.forEach(field => {
        setFieldTouched(field, true, false);
      });
      validateForm().then(e => {
        let error = false;
        fields.forEach(field => {
          if (get(e, field)) {
            error = true;
          }
        });
        if (!error) {
          this.setState(
            prevState => ({
              // step: 4
              step: prevState.step + 1
            }),
            () => {
              callback();
              if (payload) {
                this.saveToDraft(payload, first);
              }
            }
          );
          // this.saveToDraft()
        }
      });
    } else {
      this.setState(
        prevState => ({
          // step: 4
          step: prevState.step + 1
        }),
        () => {
          callback();
          if (payload) {
            this.saveToDraft(payload, first);
          }
        }
      );
    }
  };

  saveToDraft = (payload, first = false) => {
    if (this.props.draftId) {
      /** update this draft */
      Api.updateDraft(this.props.draftId, payload, global._token)
        .then(json => {
          if (json.ok) {
          } else {
            throw new Error("update draft failed");
          }
        })
        .catch(e => {
          console.error(e.message);
        });
    } else {
      if (this.props.jobId) {
        /** do nothing when edit */
      } else {
        if (first) {
          /** create */
          if (!this.createdDraftId) {
            Api.createDraft(payload, global._token)
              .then(json => {
                if (json.id) {
                  this.createdDraftId = json.id;
                } else {
                  throw new Error("create draft failed");
                }
              })
              .catch(e => {
                console.error(e.message);
              });
          } else {
            Api.updateDraft(this.createdDraftId, payload, global._token)
              .then(json => {
                if (json.ok) {
                } else {
                  throw new Error("update draft failed");
                }
              })
              .catch(e => {
                console.error(e.message);
              });
          }
        } else {
          if (this.createdDraftId) {
            /** update draft */
            Api.updateDraft(this.createdDraftId, payload, global._token)
              .then(json => {
                if (json.ok) {
                } else {
                  throw new Error("update draft failed");
                }
              })
              .catch(e => {
                console.error(e.message);
              });
          } else {
            /** create draft failed, do nothing */
          }
        }
      }
    }
  };

  handleAddressNext = (
    validateForm,
    setFieldTouched,
    fields,
    values,
    setFieldValue
  ) => {
    if (fields && fields.length !== 0) {
      fields.forEach(field => {
        setFieldTouched(field, true, false);
      });
      validateForm().then(e => {
        let error = false;
        fields.forEach(field => {
          if (get(e, field)) {
            error = true;
          }
        });
        if (!error) {
          this.setState(prevState => ({
            step: prevState.step + 1
          }));
          this.saveToDraft(
            {
              address: {
                name: values.address.description,
                unitNumber: values.unit,
                location: {
                  coordinates: [
                    values.address.longitude,
                    values.address.latitude
                  ],
                  type: "Point"
                }
              },
              location: {
                coordinates: [
                  values.address.longitude,
                  values.address.latitude
                ],
                type: "Point"
              },
              ...(values.branch && { branch: values.branch })
            },
            false
          );
          if (values.saveAddress) {
            const payload = {
              addressName: values.address.description,
              addressUnit: values.unit,
              location: {
                type: "Point",
                coordinates: [values.address.longitude, values.address.latitude]
              },
              isBranch: values.isBranch,
              ...(values.isBranch && { addressTitle: values.branchValue })
            };
            Api.createAddress(payload, global._token)
              .then(response => {
                if (response._id) {
                  toast.success("address saved!", {
                    className: "alert alert-success"
                  });
                  this.setState(prevState => ({
                    showSave: false
                  }));
                  setFieldValue("saveAddress", false);
                  this.selectedAddress = true;
                } else {
                  throw new Error("save address failed");
                }
              })
              .catch(e => {
                toast.error(e.message, { className: "alert alert-danger" });
              });
          }
        }
      });
    } else {
      this.setState(prevState => ({
        step: prevState.step + 1
      }));
    }
  };

  getDraftDetail = () => {
    Api.getDraftDetail(this.props.draftId, global._token).then(json => {
      this.initialValues = {
        title: json.title || "",
        category:
          json.job_category && json.job_category._id
            ? json.job_category._id
            : "",
        type: json.type || "",
        position: json.positionTotal || 0,
        unlimit: json.positionTotal === 0 ? true : false,
        unit:
          json.address && json.address.unitNumber
            ? json.address.unitNumber
            : "",
        negotiable: json.salary && json.salary.negotiable ? true : false,
        salary: json.salary && json.salary.value ? json.salary.value : 0,
        maxSalary:
          json.salary && json.salary.maxValue ? json.salary.maxValue : 0,
        shift:
          json.shifts &&
          json.shifts.length &&
          json.shifts[0] &&
          json.shifts[0].length !== 0
            ? json.shifts[0]
            : "",
        currency:
          json.salary && json.salary.currency ? json.salary.currency : "CAD",
        paymentMethod: json.payingMethod || "Cheque",
        rate: json.salary && json.salary.unit ? json.salary.unit : "",
        permanent: json.endingDate === "no limit" ? true : false,
        startDate: json.startingDate ? parseISO(json.startingDate) : null,
        endDate:
          json.endingDate === "no limit"
            ? null
            : json.endingDate
            ? parseISO(json.endingDate)
            : null,
        from:
          json.workingTime && json.workingTime.from
            ? this.convert(json.workingTime.from)
            : null,
        to:
          json.workingTime && json.workingTime.to
            ? this.convert(json.workingTime.to)
            : null,
        requiredSkill:
          json.mandatorySkillRequirements &&
          json.mandatorySkillRequirements.length !== 0
            ? json.mandatorySkillRequirements
            : [],
        preferredSkill:
          json.skillRequirements && json.skillRequirements.length !== 0
            ? json.skillRequirements
            : [],
        workingDay:
          json.workingDays && json.workingDays.indexOf("everyDay") !== -1
            ? workingDayArray
            : json.workingDays || [],
        address: {
          description:
            json.address && json.address.name ? json.address.name : "",
          latitude:
            json.address &&
            json.address.location &&
            json.address.location.coordinates[1]
              ? json.address.location.coordinates[1]
              : 0,
          longitude:
            json.address &&
            json.address.location &&
            json.address.location.coordinates[0]
              ? json.address.location.coordinates[0]
              : 0
        },
        description: json.description,
        reminder: json.reminder,
        branch: json.branch && json.branch._id,
        saveAddress: false,
        isBranch: json.branch && json.branch._id ? true : false,
        branchValue:
          json.branch && json.branch._id ? json.branch.addressTitle : "",

        /** @todo */
        directEmail: json.direct_email || "",
        directPhone: json.direct_phone || ""
      };
      this.setState({
        ready: true,
        addressValue: json.address && json.address.name ? json.address.name : ""
      });
      if (json.branch && json.branch._id) {
        this.selectedAddress = true;
      }
    });
  };
  convert(hourString) {
    const array = hourString.split(":");
    const hour = parseInt(array[0], 10);
    const minute = parseInt(array[1], 10);
    return new Date(new Date().setHours(hour, minute));
  }
  componentDidUpdate(prevProps) {
    if (prevProps.jobId !== this.props.jobId) {
      if (this.props.jobId) {
        this.setState({ ready: false, addressValue: "" }, () => {
          this.getJobDetail();
        });
      } else {
        //reset
        this.setState({ ready: false, addressValue: "" }, () => {
          this.initialValues = {
            title: "",
            category: "",
            type: "",
            position: 0,
            unlimit: false,
            negotiable: false,
            unit: "",
            salary: 0,
            shift: "",
            currency: "CAD",
            paymentMethod: "Cheque",
            rate: "",
            permanent: false,
            startDate: new Date(),
            endDate: new Date(),
            from: new Date(),
            to: new Date(),
            requiredSkill: [],
            preferredSkill: [],
            workingDay: [],
            address: {
              description: "",
              latitude: 0,
              longitude: 0
            },
            maxSalary: 0,
            branch: "",
            saveAddress: false,
            isBranch: false,

            /** @todo  */
            directEmail: "",
            directPhone: ""
          };
          this.setState({ ready: true });
        });
      }
    }

    //@todo draft
  }
  handleShowAddressButton = () => {
    this.setState({ showAddressButton: true });
  };

  handleSelectAddress = (item, form) => {
    this.setState({
      showAddress: false,
      addressValue: item.addressName,
      showSave: false
    });
    if (item.isBranch) {
      form.setFieldValue("branch", item._id);
      form.setFieldValue("isBranch", true);
      form.setFieldValue("branchValue", item.addressTitle);
    } else {
      form.setFieldValue("branch", "");
      form.setFieldValue("isBranch", false);
      form.setFieldValue("branchValue", "");
    }
    form.setFieldValue("address.description", item.addressName);
    form.setFieldValue("address.latitude", item.location.coordinates[1]);
    form.setFieldValue("address.longitude", item.location.coordinates[0]);
    form.setFieldValue("unit", item.addressUnit);
    form.setFieldValue("saveAddress", false);
    this.selectedAddress = true;
  };

  removeAndAddSkill = (form, removeSkills = [], addSkills = []) => {
    let tempPreferredSkill = form.values.preferredSkill.slice();
    let tempRequiredSkill = form.values.requiredSkill.slice();

    removeSkills.forEach(skill => {
      tempPreferredSkill = tempPreferredSkill.filter(e => e !== skill);
      tempRequiredSkill = tempRequiredSkill.filter(e => e !== skill);
    });
    if (tempPreferredSkill.length + tempRequiredSkill.length < 9) {
      addSkills.forEach(skill => {
        if (
          tempPreferredSkill.indexOf(skill) === -1 &&
          tempRequiredSkill.indexOf(skill) === -1
        ) {
          // no mo prefer skills
          tempRequiredSkill.push(skill);
          // tempPreferredSkill.push(skill);
        }
      });
    }

    form.setFieldValue("preferredSkill", tempPreferredSkill);
    form.setFieldValue("requiredSkill", tempRequiredSkill);
  };

  handleMandatoryChange = (form, requiredSkill = [], preferredSkill = []) => {
    this.setState({ isMandatoryDialogOpen: false });
    form.setFieldValue("preferredSkill", preferredSkill);
    form.setFieldValue("requiredSkill", requiredSkill);
    this.handleNext(form.validateForm, form.setFieldTouched, [], {
      mandatorySkillRequirements:
        requiredSkill.length !== 0 ? requiredSkill : [],
      skillRequirements: preferredSkill.length !== 0 ? preferredSkill : []
    });
  };

  customInput = ({ value, onClick, disabled }) => (
    <button
      className="picker-readOnly form-control"
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      {value}
    </button>
  );

  render() {
    if (!this.state.ready) return null;
    return (
      <>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">{i18n.t("jobBoard.postJob")}</h1>
          <Link to="/employer/draft" className="btn btn-link text-nowrap  my-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-archive"
            >
              <polyline points="21 8 21 21 3 21 3 8" />
              <rect x="1" y="3" width="22" height="5" />
              <line x1="10" y1="12" x2="14" y2="12" />
            </svg>
            <span className="ml-1">{i18n.t("draft.draft")}</span>
          </Link>
        </div>
        <div className="bg-white py-5  shadow-sm">
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <Step pass={this.state.step === 0}>1</Step>
              <span
                style={{
                  width: "6vw",
                  backgroundColor: "#e1e1e1",
                  height: 6.5,
                  boxShadow: "inset 0 0 1px rgba(0, 0, 0, 0.19)"
                }}
              />
              <Step pass={this.state.step === 1}>2</Step>
              <span
                style={{
                  width: "6vw",
                  backgroundColor: "#e1e1e1",
                  height: 6.5,
                  boxShadow: "inset 0 0 1px rgba(0, 0, 0, 0.19)"
                }}
              />
              <Step pass={this.state.step === 2}>3</Step>
              <span
                style={{
                  width: "6vw",
                  backgroundColor: "#e1e1e1",
                  height: 6.5,
                  boxShadow: "inset 0 0 1px rgba(0, 0, 0, 0.19)"
                }}
              />
              <Step pass={this.state.step === 3}>4</Step>
              <span
                style={{
                  width: "6vw",
                  backgroundColor: "#e1e1e1",
                  height: 6.5,
                  boxShadow: "inset 0 0 1px rgba(0, 0, 0, 0.19)"
                }}
              />
              <Step pass={this.state.step === 4}>5</Step>
              <span
                style={{
                  width: "6vw",
                  backgroundColor: "#e1e1e1",
                  height: 6.5,
                  boxShadow: "inset 0 0 1px rgba(0, 0, 0, 0.19)"
                }}
              />
              <Step pass={this.state.step === 5}>6</Step>
              <span
                /** @todo remove */
                onClick={() => this.setState({showSecret: true})}
                style={{
                  width: "6vw",
                  backgroundColor: "#e1e1e1",
                  height: 6.5,
                  boxShadow: "inset 0 0 1px rgba(0, 0, 0, 0.19)"
                }}
              />
              <Step pass={this.state.step === 6}>7</Step>
            </div>
          </div>

          <div className="row">
            <Formik
              novalidate
              initialValues={this.initialValues}
              onSubmit={(values, { setSubmitting }) => {
                let workingDayPayload = values.workingDay.sort(
                  (a, b) => sortArray.indexOf(a) - sortArray.indexOf(b)
                );
                if (
                  workingDayPayload.includes("Mon") &&
                  workingDayPayload.includes("Tue") &&
                  workingDayPayload.includes("Wed") &&
                  workingDayPayload.includes("Thu") &&
                  workingDayPayload.includes("Fri")
                ) {
                  if (
                    workingDayPayload.includes("Sat") &&
                    workingDayPayload.includes("Sun")
                  ) {
                    workingDayPayload = ["everyDay"];
                  } else {
                    workingDayPayload = ["M-F"];
                  }
                }
                const payload = {
                  address: {
                    name: values.address.description,
                    unitNumber: values.unit,
                    location: {
                      coordinates: [
                        values.address.longitude,
                        values.address.latitude
                      ],
                      type: "Point"
                    }
                  },
                  location: {
                    coordinates: [
                      values.address.longitude,
                      values.address.latitude
                    ],
                    type: "Point"
                  },
                  job_category: values.category,
                  description: values.description,
                  startingDate: values.startDate,
                  endingDate: values.permanent ? "no limit" : values.endDate,
                  workingTime: {
                    from: format(values.from, "HH:mm"),
                    to: format(values.to, "HH:mm")
                  },
                  workingDays: workingDayPayload,
                  shifts: [values.shift],
                  startingDate: values.startDate,
                  endingDate: values.permanent ? "no limit" : values.endDate,
                  workingTime: {
                    from: format(values.from, "HH:mm"),
                    to: format(values.to, "HH:mm")
                  },
                  workingDays: workingDayPayload,
                  shifts: [values.shift],
                  title: values.title,
                  startingDate: values.startDate,
                  endingDate: values.permanent ? "no limit" : values.endDate,
                  workingTime: {
                    from: format(values.from, "HH:mm"),
                    to: format(values.to, "HH:mm")
                  },
                  workingDays: workingDayPayload,
                  shifts: [values.shift],
                  type: values.type,
                  positionTotal: values.unlimit ? 0 : values.position,
                  salary: {
                    value: values.negotiable ? 0 : values.salary,
                    maxValue: values.negotiable ? 0 : values.maxSalary,
                    currency: values.negotiable ? "CAD" : values.currency,
                    unit: values.negotiable ? "hourly" : values.rate,
                    negotiable: values.negotiable
                  },
                  payingMethod: values.paymentMethod,
                  startingDate: values.startDate,
                  endingDate: values.permanent ? "no limit" : values.endDate,
                  workingTime: {
                    from: format(values.from, "HH:mm"),
                    to: format(values.to, "HH:mm")
                  },
                  workingDays: workingDayPayload,
                  shifts: [values.shift],
                  reminder: values.reminder,
                  mandatorySkillRequirements:
                    values.requiredSkill.length !== 0
                      ? values.requiredSkill
                      : [],
                  skillRequirements:
                    values.preferredSkill.length !== 0
                      ? values.preferredSkill
                      : [],
                  ...(values.branch && { branch: values.branch }),
                  direct_email: values.directEmail,
                  direct_phone: values.directPhone
                };
                if (this.props.jobId) {
                  Api.editJob(this.props.jobId, payload, global._token)
                    .then(json => {
                      setSubmitting(false);
                      if (json._id) {
                        toast.success("Job edited!", {
                          className: "alert alert-success"
                        });
                        navigate(`/employer/job/${this.props.jobId}`);
                      } else {
                        throw new Error("Edit skill failed");
                      }
                    })
                    .catch(e => {
                      setSubmitting(false);
                      toast.error(e.message, {
                        className: "alert alert-danger"
                      });
                    });
                } else {
                  Api.postJob(payload, global._token)
                    .then(json => {
                      setSubmitting(false);
                      if (json._id) {
                        toast.success("Job created!", {
                          className: "alert alert-success"
                        });
                        navigate(`/employer/job`);
                      } else {
                        throw new Error("Create skill failed");
                      }
                    })
                    .catch(e => {
                      setSubmitting(false);
                      toast.error(e.message, {
                        className: "alert alert-danger"
                      });
                    });
                  if (this.props.draftId) {
                    Api.deleteDraft(this.props.draftId, global._token)
                      .then(json => {
                        if (json.ok) {
                        } else {
                          throw new Error("delete draft failed");
                        }
                      })
                      .catch(e => console.error(e.message));
                  }
                  if (this.createdDraftId) {
                    Api.deleteDraft(this.createdDraftId, global._token)
                      .then(json => {
                        if (json.ok) {
                        } else {
                          throw new Error("delete draft failed");
                        }
                      })
                      .catch(e => console.error(e.message));
                  }
                }
              }}
              validationSchema={PostJobSchema}
            >
              {({
                values,
                isSubmitting,
                errors,
                touched,
                submitCount,
                setTouched,
                validateForm,
                validateField,
                setFieldTouched,
                setFieldValue
              }) => (
                <Form
                  autoComplete="off"
                  className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2"
                >
                  {this.state.step === 0 && (
                    <div className="card border-0">
                      <div className="card-body">
                        <h3 className="card-title mb-4">
                          {i18n.t("postJob.step1")}
                        </h3>
                        <div className="form-group row">
                          <label
                            htmlFor="job-category"
                            className="col-sm-3 col-form-label text-muted"
                          >
                            {i18n.t("postJob.job-category")}
                          </label>
                          <div className="col-sm-9">
                            <Field
                              id="job-category"
                              component="select"
                              name="category"
                              className={
                                "form-control" +
                                (errors.category && touched.category
                                  ? " is-invalid"
                                  : "")
                              }
                              onChange={event => {
                                setFieldValue("category", event.target.value);
                                if (event.target.value !== "") {
                                  const selectedCategory = this.allTemplates[
                                    event.target.value
                                  ];
                                  if (
                                    selectedCategory &&
                                    selectedCategory.length === 1
                                  ) {
                                    setFieldValue("subCategory", "0");
                                  } else {
                                    setFieldValue("subCategory", "");
                                  }
                                } else {
                                  setFieldValue("subCategory", "");
                                }
                                setFieldValue("template", "");
                              }}
                            >
                              <option
                                value=""
                                label={i18n.t(
                                  "postJob.job-category-placeholder"
                                )}
                              />
                              {/* {defaultCategory.map((category, index) => (
                              <option value={category} key={"category" + index}>
                                {i18n.t(`jobCategory.${category}`)}
                              </option>
                            ))} */}
                              {this.state.category
                                .filter(e => e._id !== config.commonId)
                                .map((c, index) => (
                                  <option
                                    value={c._id}
                                    key={"category" + index}
                                  >
                                    {c.parent[0][this.languagePath]}
                                  </option>
                                ))}
                            </Field>
                            <ErrorMessage
                              className="invalid-feedback"
                              name="category"
                              component="div"
                            />
                          </div>
                        </div>

                        {!this.props.draftId &&
                          !this.props.jobId &&
                          values.category &&
                          values.category !== "" &&
                          values.category.length !== 0 &&
                          this.allTemplates[values.category].length !== 0 &&
                          this.allTemplates[values.category].length !== 1 && (
                            <div className="form-group row">
                              <label
                                htmlFor="sub-category"
                                className="col-sm-3 col-form-label text-muted"
                              >
                                {i18n.t("postJob.sub-category")}
                                <span className="text-danger">
                                  {i18n.t("postJob.optional")}
                                </span>
                              </label>
                              <div className="col-sm-9">
                                <Field
                                  id="sub-category"
                                  component="select"
                                  name="subCategory"
                                  className={
                                    "form-control" +
                                    (errors.subCategory && touched.subCategory
                                      ? " is-invalid"
                                      : "")
                                  }
                                  onChange={event => {
                                    setFieldValue(
                                      "subCategory",
                                      event.target.value
                                    );
                                    setFieldValue("template", "");
                                    // setFieldValue("title", "");
                                    // if (this.selectedAddress) {
                                    //   form.setFieldValue("isBranch", false);
                                    //   form.setFieldValue("branchValue", "");
                                    //   this.selectedAddress = false;
                                    // }
                                  }}
                                >
                                  <option value=""></option>
                                  {this.allTemplates[values.category].map(
                                    (c, index) => (
                                      <option
                                        value={index}
                                        key={"sub_category" + index}
                                      >
                                        {c[this.languagePath]}
                                      </option>
                                    )
                                  )}
                                  <option value="-1">
                                    {i18n.t("postJob.cant-selete")}
                                  </option>
                                </Field>
                                <ErrorMessage
                                  className="invalid-feedback"
                                  name="subCategory"
                                  component="div"
                                />
                              </div>
                            </div>
                          )}

                        {!this.props.draftId &&
                          !this.props.jobId &&
                          values.subCategory != undefined &&
                          values.subCategory !== "" &&
                          values.subCategory >= 0 && (
                            <div className="form-group row">
                              <label
                                htmlFor="template"
                                className="col-sm-3 col-form-label text-muted"
                              >
                                {i18n.t("postJob.template")}
                                <span className="text-danger">
                                  {i18n.t("postJob.optional")}
                                </span>
                              </label>
                              <div className="col-sm-9">
                                <Field
                                  id="template"
                                  component="select"
                                  name="template"
                                  className={
                                    "form-control" +
                                    (errors.template && touched.template
                                      ? " is-invalid"
                                      : "")
                                  }
                                  onChange={event => {
                                    setFieldValue(
                                      "template",
                                      event.target.value
                                    );
                                    const subIdx = parseInt(
                                      values.subCategory,
                                      10
                                    );
                                    const idx = parseInt(
                                      event.target.value,
                                      10
                                    );
                                    if (subIdx >= 0 && idx >= 0) {
                                      // console.log( this.allTemplates[values.category][subIdx].templates[idx])
                                      setFieldValue(
                                        "title",
                                        this.allTemplates[values.category][
                                          subIdx
                                        ].templates[idx][this.languagePath]
                                      );
                                    } else {
                                      setFieldValue("title", "");
                                    }
                                    // if (this.selectedAddress) {
                                    //   form.setFieldValue("isBranch", false);
                                    //   form.setFieldValue("branchValue", "");
                                    //   this.selectedAddress = false;
                                    // }
                                  }}
                                >
                                  <option
                                    value=""
                                    label={i18n.t(
                                      "postJob.template-placeholder"
                                    )}
                                  />
                                  {this.allTemplates[values.category][
                                    values.subCategory
                                  ].templates.map((c, index) => (
                                    <option value={index} key={c._id}>
                                      {c[this.languagePath]}
                                    </option>
                                  ))}
                                  <option value="-1">
                                    {i18n.t("postJob.cant-selete")}
                                  </option>
                                </Field>
                                <ErrorMessage
                                  className="invalid-feedback"
                                  name="subCategory"
                                  component="div"
                                />
                              </div>
                            </div>
                          )}

                        <div className="form-group row">
                          <label
                            htmlFor="job-title"
                            className="col-sm-3 col-form-label text-muted"
                          >
                            {i18n.t("postJob.job-title")}
                          </label>
                          <div className="col-sm-9">
                            <Field
                              id="job-title"
                              name="title"
                              className={
                                "form-control" +
                                (errors.title && touched.title
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder={i18n.t(
                                "postJob.job-title-placeholder"
                              )}
                            />
                            <ErrorMessage
                              className="invalid-feedback"
                              name="title"
                              component="div"
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label
                            htmlFor="job-type"
                            className="col-sm-3 col-form-label text-muted"
                          >
                            {i18n.t("postJob.employment-type")}
                          </label>
                          <div className="col-sm-9">
                            <Field
                              id="job-type"
                              component="select"
                              name="type"
                              className={
                                "form-control" +
                                (errors.type && touched.type
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <option
                                value=""
                                label={i18n.t(
                                  "postJob.employment-type-placeholder"
                                )}
                              />
                              <option value="Full time">
                                {i18n.t(`jobType.Full time`)}
                              </option>
                              <option value="Part time">
                                {i18n.t(`jobType.Part time`)}
                              </option>
                              <option value="Full Time / Part Time">
                                {i18n.t(`jobType.Full Time / Part Time`)}
                              </option>
                              <option value="Permanent">
                                {i18n.t(`jobType.Permanent`)}
                              </option>
                              <option value="Contract">
                                {i18n.t(`jobType.Contract`)}
                              </option>
                              <option value="Temporary">
                                {i18n.t(`jobType.Temporary`)}
                              </option>
                            </Field>
                            <ErrorMessage
                              className="invalid-feedback"
                              name="type"
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="position"
                            className="col-sm-3 col-form-label text-muted"
                          >
                            {i18n.t("postJob.position-number")}
                          </label>
                          <div className="col-sm-6">
                            <Field
                              id="position"
                              name="position"
                              onFocus={e => e.target.select()}
                              className={
                                "form-control" +
                                (errors.position && touched.position
                                  ? " is-invalid"
                                  : "")
                              }
                              disabled={values.unlimit === true}
                            />
                            <ErrorMessage
                              className="invalid-feedback"
                              name="position"
                              component="div"
                            />
                          </div>
                          <div className="col-sm-3">
                            <div className="custom-control custom-checkbox">
                              <Field
                                name="unlimit"
                                className="custom-control-input"
                                type="checkbox"
                                id="unlimit-checkbox"
                                checked={values.unlimit}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="unlimit-checkbox"
                              >
                                {i18n.t("postJob.no-limit")}
                              </label>
                              <ErrorMessage
                                className="invalid-feedback"
                                name="unlimit"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                        {(this.state.showSecret ||
                          (values.directEmail &&
                            values.directEmail.length !== 0)) && (
                          <div className="form-group row">
                            <label
                              htmlFor="position"
                              className="col-sm-3 col-form-label text-muted"
                            >
                              Direct Email
                            </label>
                            <div className="col-sm-6">
                              <Field
                                id="direct-email"
                                name="directEmail"
                                className={"form-control"}
                              />
                            </div>
                          </div>
                        )}
                        {(this.state.showSecret ||
                          (values.directPhone &&
                            values.directPhone.length !== 0)) && (
                          <div className="form-group row">
                            <label
                              htmlFor="position"
                              className="col-sm-3 col-form-label text-muted"
                            >
                              Direct Phone
                            </label>
                            <div className="col-sm-6">
                              <Field
                                id="direct-phone"
                                name="directPhone"
                                className={"form-control"}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {this.state.step === 1 && (
                    <div>
                      <div className="card border-0">
                        <Field
                          render={({ field, form }) => (
                            <div className="card-body">
                              <div className="card-title d-flex justify-content-between align-items-center">
                                <h3>
                                  {i18n.t("postJob.step2")}
                                  <span aria-hidden className="text-muted">
                                    *
                                  </span>
                                </h3>
                              </div>
                              <p className="card-text">
                                <small className="text-muted">
                                  {i18n.t("postJob.step2-hint")}
                                </small>
                              </p>

                              <div className="form-group row">
                                <label
                                  htmlFor="address"
                                  className="col-sm-3 col-form-label text-muted"
                                >
                                  {i18n.t("postJob.address-label")}
                                </label>
                                <div className="col-sm-9 input-group">
                                  <PlacesAutocomplete
                                    highlightFirstSuggestion={true}
                                    value={this.state.addressValue}
                                    id="address"
                                    debounce={1000}
                                    onChange={value => {
                                      this.setState({
                                        addressValue: value,
                                        showSave: true
                                      });
                                      form.setFieldValue("description", "");
                                      form.setFieldValue("latitude", 0);
                                      form.setFieldValue("longitude", 0);
                                      if (this.selectedAddress) {
                                        form.setFieldValue("isBranch", false);
                                        form.setFieldValue("branchValue", "");
                                        this.selectedAddress = false;
                                      }
                                    }}
                                    searchOptions={{
                                      componentRestrictions: {
                                        country: "ca"
                                      },
                                    }}
                                    onSelect={(value, placeId) => {
                                      if (!placeId) {
                                      } else {
                                        geocodeByAddress(value)
                                          .then(result => {
                                            this.setState({
                                              addressValue:
                                                result[0].formatted_address
                                            });
                                            form.setFieldValue(
                                              "address.description",
                                              result[0].formatted_address
                                            );
                                            return getLatLng(result[0]);
                                          })
                                          .then(({ lat, lng }) => {
                                            form.setFieldValue(
                                              "address.latitude",
                                              lat
                                            );
                                            form.setFieldValue(
                                              "address.longitude",
                                              lng
                                            );
                                          });
                                      }
                                    }}
                                  >
                                    {({
                                      getInputProps,
                                      suggestions,
                                      getSuggestionItemProps,
                                      loading
                                    }) => (
                                      <>
                                        <input
                                          {...getInputProps({
                                            placeholder: i18n.t(
                                              "addressDialog.address-name-placeholder"
                                            ),
                                            className:
                                              "form-control" +
                                              (errors.latitude &&
                                              touched.latitude
                                                ? " is-invalid"
                                                : "")
                                          })}
                                        />
                                        <div
                                          className="card"
                                          style={{
                                            position: "absolute",
                                            top: "100%",
                                            zIndex: 1,
                                            marginTop: 8,
                                            marginBottom: 8
                                          }}
                                        >
                                          <div className="card rounded border-0">
                                            <div className="list-group">
                                              {loading && (
                                                <div className="list-group-item">
                                                  Loading...
                                                </div>
                                              )}
                                              {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                  ? "list-group-item active"
                                                  : "list-group-item";
                                                return (
                                                  <div
                                                    {...getSuggestionItemProps(
                                                      suggestion,
                                                      {
                                                        className
                                                      }
                                                    )}
                                                  >
                                                    <span>
                                                      {suggestion.description}
                                                    </span>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </PlacesAutocomplete>

                                  {this.state.showAddressButton && (
                                    <div className="input-group-append">
                                      <button
                                        className="btn btn-primary px-4"
                                        type="button"
                                        onClick={this.showAddressDialog}
                                      >
                                        {i18n.t("postJob.select-address")}
                                      </button>
                                    </div>
                                  )}
                                  <ErrorMessage
                                    className="invalid-feedback d-block"
                                    name="address.latitude"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="unit"
                                  className="col-sm-3 col-form-label text-muted"
                                >
                                  {i18n.t("postJob.unit-label")}
                                </label>
                                <div className="col-sm-3">
                                  <Field
                                    id="unit"
                                    name="unit"
                                    onChange={event => {
                                      this.setState({
                                        showSave: true
                                      });
                                      form.setFieldValue(
                                        "unit",
                                        event.target.value
                                      );
                                      if (this.selectedAddress) {
                                        form.setFieldValue("isBranch", false);
                                        form.setFieldValue("branchValue", "");
                                        this.selectedAddress = false;
                                      }
                                    }}
                                    autoComplete="off"
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    className="invalid-feedback"
                                    name="unit"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="custom-control custom-switch mb-4">
                                <Field
                                  id="customSwitch2"
                                  name="isBranch"
                                  className="custom-control-input"
                                  type="checkbox"
                                  checked={values.isBranch}
                                  onChange={event => {
                                    this.setState({
                                      showSave: true
                                    });
                                    form.setFieldValue(
                                      "isBranch",
                                      event.target.checked
                                    );
                                    if (values.branchValue === "") {
                                      form.setFieldTouched(
                                        "branchValue",
                                        false
                                      );
                                    }
                                    if (this.selectedAddress) {
                                      form.setFieldValue("branchValue", "");
                                      form.setFieldTouched(
                                        "branchValue",
                                        false
                                      );
                                      this.selectedAddress = false;
                                    } else {
                                      if (event.target.checked) {
                                        form.setFieldValue("saveAddress", true);
                                      }
                                    }
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customSwitch2"
                                >
                                  {i18n.t("postJob.is-branch")}
                                </label>
                              </div>
                              {values.isBranch ? (
                                <div className="form-group row">
                                  <label
                                    htmlFor="branchValue"
                                    className="col-sm-3 col-form-label text-muted"
                                  >
                                    {i18n.t("postJob.branch-label")}
                                  </label>
                                  <div className="col-sm-9">
                                    <Field
                                      id="branchValue"
                                      name="branchValue"
                                      onChange={event => {
                                        this.setState({
                                          showSave: true
                                        });
                                        form.setFieldValue(
                                          "branchValue",
                                          event.target.value
                                        );
                                        if (this.selectedAddress) {
                                          // form.setFieldValue('isBranch', false);
                                          // form.setFieldValue('branchName', '')
                                          this.selectedAddress = false;
                                        }
                                      }}
                                      autoComplete="off"
                                      className="form-control"
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback d-block"
                                      name="branchValue"
                                      component="div"
                                    />
                                  </div>
                                </div>
                              ) : null}
                              {this.state.showSave && (
                                <>
                                  <div className="custom-control custom-switch">
                                    <Field
                                      id="customSwitch1"
                                      name="saveAddress"
                                      className="custom-control-input"
                                      type="checkbox"
                                      checked={values.saveAddress}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customSwitch1"
                                    >
                                      {i18n.t("postJob.save-address")}
                                    </label>
                                  </div>
                                  <p className="card-text">
                                    <small className="text-muted">
                                      {i18n.t("postJob.step2-hint")}
                                    </small>
                                  </p>
                                </>
                              )}

                              <AddressDialog
                                show={this.state.showAddress}
                                hideDialog={this.hideAddressDialog}
                                handleShowAddressButton={
                                  this.handleShowAddressButton
                                }
                                handleSelect={value => {
                                  this.handleSelectAddress(value, form);
                                }}
                              />
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  )}
                  {this.state.step === 2 && (
                    <div>
                      <div className="card border-0">
                        <div className="card-body">
                          <h3 className="card-title mb-4">
                            {i18n.t("postJob.step3")}
                          </h3>
                          <div className="form-group row">
                            <div className="custom-control custom-checkbox">
                              <Field
                                name="negotiable"
                                className="custom-control-input"
                                type="checkbox"
                                id="negotiable-checkbox"
                                checked={values.negotiable}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="negotiable-checkbox"
                              >
                                {i18n.t(`postJob.negotiable`)}
                              </label>
                              <ErrorMessage
                                className="invalid-feedback"
                                name="negotiable"
                                component="div"
                              />
                            </div>
                          </div>

                          {!values.negotiable && (
                            <>
                              <div className="form-group row">
                                <label
                                  htmlFor="salary"
                                  className="col-sm-3 col-form-label text-muted"
                                >
                                  {i18n.t("postJob.salary")}
                                </label>
                                <div className="col-sm-6 d-flex">
                                  <span className="mr-1 pt-2">$</span>
                                  <div className="input-group col-sm pl-0">
                                    <Field
                                      name="salary"
                                      id="salary"
                                      onFocus={e => e.target.select()}
                                      className={
                                        "form-control" +
                                        (errors.salary && touched.salary
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback d-block"
                                      name="salary"
                                      component="div"
                                    />
                                  </div>

                                  <span className="pt-1 m-1">-</span>

                                  <div className="input-group col-sm">
                                    <Field
                                      name="maxSalary"
                                      id="max-salary"
                                      onFocus={e => e.target.select()}
                                      className={
                                        "form-control" +
                                        (errors.maxSalary && touched.maxSalary
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback d-block"
                                      name="maxSalary"
                                      component="div"
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-3 mt-2 mt-sm-0">
                                  <Field
                                    id="currency"
                                    component="select"
                                    name="currency"
                                    className={
                                      "form-control" +
                                      (errors.currency && touched.currency
                                        ? " is-invalid"
                                        : "")
                                    }
                                  >
                                    <option value="CAD">
                                      {i18n.t("postJob.CAD")}
                                    </option>
                                    <option value="USD">
                                      {i18n.t("postJob.USD")}
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                    className="invalid-feedback"
                                    name="currency"
                                    component="div"
                                  />
                                </div>
                              </div>
                              {/* <div className="form-group col-sm-3"> */}
                              {/* <label htmlFor="currency">
                              {i18n.t("postJob.currency")}
                            </label> */}

                              {/* </div> */}

                              <p className="card-text">
                                <small className="text-muted">
                                  {/* * 0 Val */}
                                </small>
                              </p>

                              <div className="form-group row">
                                <label
                                  htmlFor="rate"
                                  className="col-sm-3 col-form-label text-muted"
                                >
                                  {i18n.t("postJob.pay-frenquency")}
                                </label>
                                <div className="col-sm-9">
                                  <Field
                                    id="rate"
                                    component="select"
                                    name="rate"
                                    className={
                                      "form-control" +
                                      (errors.rate && touched.rate
                                        ? " is-invalid"
                                        : "")
                                    }
                                  >
                                    <option value="" />
                                    <option value="hourly">
                                      {i18n.t("payPeriod.hourly")}
                                    </option>
                                    <option value="daily">
                                      {i18n.t("payPeriod.daily")}
                                    </option>
                                    <option value="weekly">
                                      {i18n.t("payPeriod.weekly")}
                                    </option>
                                    <option value="monthly">
                                      {i18n.t("payPeriod.monthly")}
                                    </option>
                                    <option value="yearly">
                                      {i18n.t("payPeriod.yearly")}
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                    className="invalid-feedback"
                                    name="rate"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="payment-method"
                                  className="col-sm-3 col-form-label text-muted"
                                >
                                  {i18n.t("postJob.payment-method")}
                                </label>
                                <div className="col-sm-9">
                                  <Field
                                    component="select"
                                    id="payment-method"
                                    name="paymentMethod"
                                    className={
                                      "form-control" +
                                      (errors.paymentMethod &&
                                      touched.paymentMethod
                                        ? " is-invalid"
                                        : "")
                                    }
                                  >
                                    <option value="Cheque">
                                      {i18n.t("payMethod.cheque")}
                                    </option>
                                    <option value="Cash">
                                      {i18n.t("payMethod.cash")}
                                    </option>
                                    <option value="Cheque Cash">
                                      {i18n.t("payMethod.cheque/cash")}
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                    className="invalid-feedback"
                                    name="paymentMethod"
                                    component="div"
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.step === 3 && (
                    <div>
                      <div className="card border-0">
                        <div className="card-body">
                          <h3 className="card-title mb-4">
                            {i18n.t("postJob.step4")}
                          </h3>

                          <div className="form-group row">
                            <label
                              htmlFor="start-date"
                              className="col-sm-3 col-form-label text-muted"
                            >
                              {i18n.t("postJob.start-date")}
                            </label>
                            <div className="col-sm-9">
                              <div className="d-flex">
                                <Field
                                  render={({ field, form }) => (
                                    <_o_
                                      // id="start-date"
                                      minDate={new Date()}
                                      onChange={value => {
                                        field.onChange(value);
                                        form.setFieldValue(field.name, value);
                                      }}
                                      selected={field.value}
                                      // className={
                                      //   "picker-readOnly form-control" +
                                      //   (errors.startDate && touched.startDate
                                      //     ? " is-invalid"
                                      //     : "")
                                      // }
                                      customInput={<this.customInput />}
                                    />
                                  )}
                                  name="startDate"
                                />
                              </div>
                              <ErrorMessage
                                className="invalid-feedback"
                                name="startDate"
                                component="div"
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="end-date"
                              className="col-sm-3 col-form-label text-muted"
                            >
                              {i18n.t("postJob.end-date")}
                            </label>
                            <div className="col-sm-9">
                              <div className="d-flex">
                                <Field
                                  name="endDate"
                                  render={({ field, form }) => (
                                    <_o_
                                      minDate={new Date()}
                                      onChange={value => {
                                        field.onChange(value);
                                        form.setFieldValue(field.name, value);
                                      }}
                                      id="end-date"
                                      selected={field.value}
                                      // className={
                                      //   "picker-readOnly form-control" +
                                      //   (errors.endDate && touched.endDate
                                      //     ? " is-invalid"
                                      //     : "")
                                      // }
                                      customInput={<this.customInput />}
                                      disabled={values.permanent}
                                    />
                                  )}
                                />
                              </div>
                              <ErrorMessage
                                className="invalid-feedback"
                                name="endDate"
                                component="div"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="custom-control custom-checkbox col-sm-9 offset-sm-3">
                              <Field
                                name="permanent"
                                className="custom-control-input"
                                type="checkbox"
                                id="permanent-checkbox"
                                checked={values.permanent}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="permanent-checkbox"
                              >
                                {i18n.t("postJob.no-end-date")}
                              </label>
                              <ErrorMessage
                                className="invalid-feedback"
                                name="permanent"
                                component="div"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="shift"
                              className="col-sm-3 col-form-label text-muted"
                            >
                              {i18n.t("postJob.shift")}
                            </label>
                            <div className="col-sm-9">
                              <Field
                                name="shift"
                                id="shift"
                                className={
                                  "form-control" +
                                  (errors.shift && touched.shift
                                    ? " is-invalid"
                                    : "")
                                }
                                component="select"
                              >
                                <option
                                  value=""
                                  label={i18n.t("postJob.shift-placeholder")}
                                />
                                <option value="morning">
                                  {i18n.t("shiftType.morning")}
                                </option>
                                <option value="afternoon">
                                  {i18n.t("shiftType.afternoon")}
                                </option>
                                <option value="night">
                                  {i18n.t("shiftType.night")}
                                </option>
                                <option value="rotate">
                                  {i18n.t("shiftType.rotate")}
                                </option>
                              </Field>
                              <ErrorMessage
                                className="invalid-feedback"
                                name="shift"
                                component="div"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="from"
                              className="col-sm-3 col-form-label text-muted"
                            >
                              {i18n.t("postJob.work-time")}
                            </label>
                            <div className="col-sm-3">
                              <div className="d-flex">
                                <Field
                                  render={({ field, form }) => (
                                    <_o_
                                      showTimeSelect
                                      showTimeSelectOnly
                                      onChange={value => {
                                        field.onChange(value);
                                        form.setFieldValue(field.name, value);
                                      }}
                                      selected={field.value}
                                      customInput={<this.customInput />}
                                      timeIntervals={15}
                                      timeCaption="Time"
                                      dateFormat="h:mm aa"
                                    />
                                    // <TimePicker
                                    // onChange={value => {
                                    //   field.onChange(value);
                                    //   form.setFieldValue(field.name, value);
                                    // }}
                                    //   id="from"
                                    //   readOnly
                                    //   TextFieldComponent="input"
                                    //   minutesStep={5}
                                    // value={field.value}
                                    //   className={
                                    //     "picker-readOnly form-control" +
                                    //     (errors.from && touched.from
                                    //       ? " is-invalid"
                                    //       : "")
                                    //   }
                                    // />
                                  )}
                                  name="from"
                                />
                              </div>
                              <ErrorMessage
                                className="invalid-feedback d-block"
                                name="from"
                                component="div"
                              />
                            </div>
                            <hr className="col-sm-3 d-none d-sm-inline-block p-0 my-auto" />
                            <div className="col-sm-3 mt-sm-0 mt-2">
                              <div className="d-flex">
                                <Field
                                  name="to"
                                  render={({ field, form }) => (
                                    <_o_
                                      showTimeSelect
                                      showTimeSelectOnly
                                      onChange={value => {
                                        field.onChange(value);
                                        form.setFieldValue(field.name, value);
                                      }}
                                      selected={field.value}
                                      customInput={<this.customInput />}
                                      timeIntervals={15}
                                      timeCaption="Time"
                                      dateFormat="h:mm aa"
                                    />
                                  )}
                                />
                              </div>

                              <ErrorMessage
                                className="invalid-feedback d-block"
                                name="to"
                                component="div"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            {/* <label htmlFor="to">
                            {i18n.t("postJob.work-to")}
                          </label> */}
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <label
                                style={{ fontSize: "1rem" }}
                                className="col-sm-3 col-form-label text-muted"
                              >
                                {i18n.t("postJob.working-day")}
                              </label>
                              <div className="col-sm-9 ">
                                <FieldArray
                                  name="workingDay"
                                  render={({ push, remove, form }) => (
                                    <>
                                      <div className="row">
                                        {specialDayArray.map((day, index) => (
                                          <div
                                            key={"week-" + index}
                                            className="custom-control custom-radio col-sm-4"
                                          >
                                            <input
                                              name="workingDay"
                                              className="custom-control-input"
                                              type="radio"
                                              id={day + "-checkbox"}
                                              checked={values.workingDay.includes(
                                                day
                                              )}
                                              value={day}
                                              onChange={e => {
                                                form.setFieldValue(
                                                  "workingDay",
                                                  []
                                                );
                                                if (e.target.checked) {
                                                  push(day);
                                                }
                                                // else {
                                                //   const idx = values.workingDay.indexOf(
                                                //     day
                                                //   );
                                                //   remove(idx);
                                                // }
                                              }}
                                            />
                                            <label
                                              htmlFor={day + "-checkbox"}
                                              className="custom-control-label"
                                            >
                                              {i18n.t(`weekdays.${day}`)}
                                            </label>
                                          </div>
                                        ))}
                                      </div>
                                      <div className="row mt-2">
                                        {workingDayArray.map((day, index) => (
                                          <div
                                            key={"week-" + index}
                                            className="custom-control custom-checkbox col-sm-3"
                                          >
                                            <input
                                              name="workingDay"
                                              className="custom-control-input"
                                              type="checkbox"
                                              id={day + "-checkbox"}
                                              checked={values.workingDay.includes(
                                                day
                                              )}
                                              value={day}
                                              onChange={e => {
                                                const mfIdx = values.workingDay.indexOf(
                                                  "M-F"
                                                );
                                                const ssIdx = values.workingDay.indexOf(
                                                  "SS"
                                                );
                                                if (mfIdx !== -1) remove(mfIdx);
                                                if (ssIdx !== -1) remove(ssIdx);
                                                if (e.target.checked) push(day);
                                                else {
                                                  const idx = values.workingDay.indexOf(
                                                    day
                                                  );
                                                  remove(idx);
                                                }
                                              }}
                                            />
                                            <label
                                              htmlFor={day + "-checkbox"}
                                              className="custom-control-label"
                                            >
                                              {i18n.t(`weekdays.${day}`)}
                                            </label>
                                          </div>
                                        ))}
                                      </div>
                                    </>
                                  )}
                                />
                              </div>
                              <ErrorMessage
                                className="invalid-feedback d-block"
                                name="workingDay"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.step === 4 && (
                    <Step4
                      requiredSkill={values.requiredSkill}
                      preferredSkill={values.preferredSkill}
                      allSkills={this.allSkills}
                      category={this.state.category}
                      selectedCategory={values.category}
                      removeAndAddSkill={(removeSkill, addSkill) =>
                        this.removeAndAddSkill(
                          { values, setFieldValue },
                          removeSkill,
                          addSkill
                        )
                      }
                    />
                  )}
                  {this.state.step === 4 && (
                    <MandatorySkillDialog
                      isOpen={this.state.isMandatoryDialogOpen}
                      handleClose={() =>
                        this.setState({ isMandatoryDialogOpen: false })
                      }
                      allSkills={this.allSkills}
                      requiredSkill={values.requiredSkill}
                      preferredSkill={values.preferredSkill}
                      handleChange={(mandatorySkill, preferredSkill) =>
                        this.handleMandatoryChange(
                          {
                            values,
                            setFieldValue,
                            validateForm,
                            setFieldTouched
                          },
                          mandatorySkill,
                          preferredSkill
                        )
                      }
                    />
                  )}

                  {this.state.step === 5 && (
                    <div>
                      <div className="card border-0">
                        <div className="card-body">
                          <h3 className="card-title">
                            {i18n.t("postJob.step6")}
                          </h3>
                          <div className="form-group">
                            <label htmlFor="description" className="sr-only">
                              {i18n.t("postJob.description")}
                              <span className="text-danger">
                                {i18n.t("postJob.optional")}
                              </span>
                            </label>
                            <Field
                              id="description"
                              name="description"
                              className="form-control"
                              component="textarea"
                              rows="6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.step === 6 && (
                    <div>
                      <div className="card border-0">
                        <div className="card-body">
                          <h3 className="card-title">
                            {i18n.t("postJob.step7")}
                          </h3>
                          <small className="text-muted">
                            {i18n.t("postJob.step7-hint")}
                          </small>

                          <div className="form-group py-3">
                            <label htmlFor="reminder" className="sr-only">
                              {i18n.t("postJob.reminder")}
                              <span className="text-danger">
                                {i18n.t("postJob.optional")}
                              </span>
                            </label>
                            <Field
                              id="reminder"
                              name="reminder"
                              className="form-control"
                              component="textarea"
                              placeholder={i18n.t(
                                "postJob.job-reminder-placeholder"
                              )}
                              rows="6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.step !== 6 && (
                    <div>
                      <div className="mt-3 mb-5 d-flex justify-content-end">
                        {/* {this.state.step !== 0 ? (
                        <button
                          type="button"
                          className="btn btn-link btn-lg"
                          onClick={() => this.handleSaveDraft(values)}
                        >
                          {i18n.t("postJob.save-to-draft")}
                        </button>
                      ) : (
                        <div />
                      )} */}
                        <div>
                          {this.state.step !== 0 && (
                            <button
                              type="button"
                              className="btn btn-link"
                              onClick={() =>
                                this.setState(prevState => ({
                                  step: prevState.step - 1
                                }))
                              }
                            >
                              {i18n.t(`postJob.back`)}
                            </button>
                          )}
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              if (this.state.step === 0) {
                                let updateSkill = false;
                                let requiredSkill = [];
                                let preferSkill = [];
                                if (!this.props.jobId && !this.props.draftId) {
                                  if (
                                    values.subCategory >= 0 &&
                                    values.subCategory !== "" &&
                                    values.category !== "" &&
                                    values.template >= 0 &&
                                    values.template !== ""
                                  ) {
                                    const subIdx = parseInt(
                                      values.subCategory,
                                      10
                                    );
                                    const templateIdx = parseInt(
                                      values.template,
                                      10
                                    );
                                    const template = this.allTemplates[
                                      values.category
                                    ][subIdx].templates[templateIdx];
                                    updateSkill = true;

                                    requiredSkill =
                                      template.mandatorySkillRequirements &&
                                      template.mandatorySkillRequirements
                                        .length !== 0
                                        ? template.mandatorySkillRequirements
                                        : [];
                                    preferSkill =
                                      template.skillRequirements &&
                                      template.skillRequirements.length !== 0
                                        ? template.skillRequirements
                                        : [];
                                  }
                                }
                                this.handleNext(
                                  validateForm,
                                  setFieldTouched,
                                  [
                                    "title",
                                    "category",
                                    "type",
                                    "position",
                                    "unlimit"
                                  ],
                                  {
                                    job_category: values.category
                                      ? values.category
                                      : null,
                                    title: values.title,
                                    type: values.type ? values.type : null,
                                    positionTotal: values.unlimit
                                      ? 0
                                      : values.position,
                                    direct_email: values.directEmail,
                                    direct_phone: values.directPhone,
                                    ...(updateSkill && {
                                      mandatorySkillRequirements: requiredSkill,
                                      skillRequirements: preferSkill
                                    })
                                  },
                                  true,
                                  () => {
                                    if (requiredSkill.length !== 0) {
                                      setFieldValue(
                                        "requiredSkill",
                                        requiredSkill
                                      );
                                    }
                                    if (preferSkill.length !== 0) {
                                      setFieldValue(
                                        "preferredSkill",
                                        preferSkill
                                      );
                                    }
                                  }
                                );
                              } else if (this.state.step === 1) {
                                this.handleAddressNext(
                                  validateForm,
                                  setFieldTouched,
                                  ["address.latitude", "branchValue"],
                                  values,
                                  setFieldValue
                                );
                              } else if (this.state.step === 2) {
                                this.handleNext(
                                  validateForm,
                                  setFieldTouched,
                                  [
                                    "currency",
                                    "paymentMethod",
                                    "rate",
                                    "salary",
                                    "maxSalary"
                                  ],
                                  {
                                    salary: {
                                      value: values.negotiable
                                        ? 0
                                        : values.salary,
                                      maxValue: values.negotiable
                                        ? 0
                                        : values.maxSalary,
                                      currency: values.negotiable
                                        ? "CAD"
                                        : values.currency,
                                      unit: values.negotiable
                                        ? "hourly"
                                        : values.rate,
                                      negotiable: values.negotiable
                                    },
                                    payingMethod: values.paymentMethod
                                  }
                                );
                              } else if (this.state.step === 3) {
                                let workingDayPayload = values.workingDay.sort(
                                  (a, b) =>
                                    sortArray.indexOf(a) - sortArray.indexOf(b)
                                );
                                if (
                                  workingDayPayload.includes("Mon") &&
                                  workingDayPayload.includes("Tue") &&
                                  workingDayPayload.includes("Wed") &&
                                  workingDayPayload.includes("Thu") &&
                                  workingDayPayload.includes("Fri")
                                ) {
                                  if (
                                    workingDayPayload.includes("Sat") &&
                                    workingDayPayload.includes("Sun")
                                  ) {
                                    workingDayPayload = ["everyDay"];
                                  } else {
                                    workingDayPayload = ["M-F"];
                                  }
                                }
                                this.handleNext(
                                  validateForm,
                                  setFieldTouched,
                                  [
                                    "permanent",
                                    "startDate",
                                    "endDate",
                                    "from",
                                    "to",
                                    "shift",
                                    "workingDay.0"
                                  ],
                                  {
                                    startingDate: values.startDate,
                                    endingDate: values.permanent
                                      ? "no limit"
                                      : values.endDate,
                                    workingTime: {
                                      from: values.from
                                        ? format(values.from, "HH:mm")
                                        : null,
                                      to: values.to
                                        ? format(values.to, "HH:mm")
                                        : null
                                    },
                                    workingDays: workingDayPayload,
                                    shifts: [values.shift]
                                  }
                                );
                              } else if (this.state.step === 4) {
                                if (
                                  values.preferredSkill.length !== 0 &&
                                  values.requiredSkill.length !== 0
                                ) {
                                  this.setState({
                                    isMandatoryDialogOpen: true
                                  });
                                } else {
                                  this.handleNext(
                                    validateForm,
                                    setFieldTouched,
                                    [],
                                    {
                                      mandatorySkillRequirements:
                                        values.requiredSkill.length !== 0
                                          ? values.requiredSkill
                                          : [],
                                      skillRequirements:
                                        values.preferredSkill.length !== 0
                                          ? values.preferredSkill
                                          : []
                                    }
                                  );
                                }
                              } else if (this.state.step === 5) {
                                this.handleNext(
                                  validateForm,
                                  setFieldTouched,
                                  [],
                                  {
                                    description: values.description
                                  }
                                );
                              } else {
                                console.error("something wrong");
                              }
                            }}
                          >
                            {i18n.t(`postJob.next`)}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.step === 6 && (
                    <div>
                      <div className="mt-3 mb-5 d-flex justify-content-end">
                        <div>
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={() =>
                              this.setState(prevState => ({
                                step: prevState.step - 1
                              }))
                            }
                          >
                            {i18n.t(`postJob.back`)}
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting}
                          >
                            {this.props.jobId
                              ? i18n.t("postJob.edit-text")
                              : i18n.t("postJob.submit-text")}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </>
    );
  }
}

function Step({ children, onClick, pass = false }) {
  return (
    <span
      className="rounded-circle border d-flex justify-content-center align-items-center"
      style={{
        height: "2rem",
        width: "2rem",
        backgroundColor: "#e1e1e1",
        boxShadow: "inset 0 0 1px rgba(0, 0, 0, 0.19)"
      }}
    >
      <span
        className="rounded-circle border text-center d-inline-block"
        style={{
          height: "1.5rem",
          width: "1.5rem",
          backgroundImage: pass
            ? "linear-gradient(#2ca1f8, #188ee7)"
            : "linear-gradient(#b5b5b5, #909090)",
          color: "#fff"
        }}
      >
        {children}
      </span>
    </span>
  );
}

export default PostJob;
