// import { formatDistance } from "date-fns";
import { Link } from "@reach/router";
import React, { Component } from "react";
import { i18n } from "../i18n";
import isArray from "lodash/isArray";
const displayFullDate = {
  year: "numeric",
  month: "numeric",
  day: "numeric"
};
class JobCard extends Component {
  displayAddress = value => {
    const array = value.split(", ");

    if (isArray(array) && array[array.length - 3] && array[array.length - 2]) {
      return (
        array[array.length - 3] + ", " + array[array.length - 2].split(" ")[0]
      );
    }
  };

  render() {
    const workingString = this.props.workingDays
      .map(day => {
        return i18n.t("weekdays." + day);
      })
      .join(", ");

    return (
      <li className="position-relative">
        <div className="card border-0 mb-2 shadow-sm">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="">
                <h5 className="card-title">{this.props.jobTitle}</h5>
                <span className="home-tag mr-1">
                  {i18n.t("jobType." + this.props.jobType)}
                </span>
                <span className="home-tag mr-1">
                  {this.props.salary.negotiable
                    ? i18n.t("job-card.negotiable")
                    : "$" +
                      this.props.salary.value +
                      "/" +
                      i18n.t("currency." + this.props.salary.currency) +
                      " " +
                      i18n.t(
                        "payPeriod." + this.props.salary.unit.toLowerCase()
                      )}
                </span>
                {this.props.asap && (
                  <span className="home-tag mr-1">
                    {i18n.t("job-card.asap")}
                  </span>
                )}
                <ul className="list-unstyled">
                  <li className="mt-1">
                    <i className="far fa-clock"></i>
                    <span className="ml-1">{workingString}</span>
                    <span className="ml-1">
                      {this.props.workingTime.from +
                        " " +
                        i18n.t("to") +
                        " " +
                        this.props.workingTime.to}
                    </span>
                  </li>
                  {!this.props.asap && (
                    <li className="mt-1">
                      <i className="far fa-calendar-alt"></i>
                      <span className="ml-1">
                        {new Date(this.props.startingDate).toLocaleString(
                          "en",
                          displayFullDate
                        )}
                      </span>
                    </li>
                  )}
                  {this.props.companyAddress && (
                    <li className="mt-1">
                      <i className="fas fa-map-marker-alt"></i>
                      <span className="ml-2">
                        {this.displayAddress(this.props.companyAddress)}
                      </span>
                    </li>
                  )}
                </ul>
              </div>
              <div className="flex-column align-items-center d-none d-md-flex">
                <div
                  style={{
                    width: 150,
                    height: 80,
                    textAlign: "center"
                  }}
                  className="mb-1"
                >
                  <img
                    className="mw-100 mh-100 align-middle"
                    src={this.props.companyLogo}
                    alt="Company Logo"
                  />
                </div>

                <div style={{ width: 200, textAlign: "center" }}>
                  {this.props.companyName}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link className="position-absolute w-100 h-100"
          style={{ top: 0, left: 0 }}
          to={`job/${this.props.id}`}
        ></Link>
        
      </li>
    );
  }
}
export default JobCard;
