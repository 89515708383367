import React from 'react';
import { Link } from "@reach/router";
import HirineLogo from "./img/HirineLogo@3x.png";
import { i18n } from "./i18n";
import { Helmet } from "react-helmet";
// import Header from "./component/Header";

class Http404 extends React.Component {
  // function Http404() {
  render() {
    return (
      <div className="application">
        <Helmet>
          {/* <html lang={i18n.language === "en" ? "en-CA" : "zh"} /> */}
          {/* <link rel="canonical" href="https://hirine.com/http404" /> */}
          <meta charSet="utf-8" />
          <title>
            {i18n.t("http404.title")}
          </title>
          <meta
            name="description"
            content={
              i18n.t("http404.content")
            }
          />
        </Helmet>
        {/* <Header>
        <nav>
          <ul>
          </ul>
        </nav>  
      </Header> */}
        <div >
          <h2 style={{ textAlign: "center", paddingTop: "100px" }}>{i18n.t("http404.head")}</h2>
          <p style={{ textAlign: "center" }}>
            <img style={{ width: 100 }} src={HirineLogo} alt={"Hirine Logo"} />
            &nbsp;{i18n.t("http404.content")}
          </p>
          <p style={{ textAlign: "center" }}>
            <Link to="/">{i18n.t("http404.go-home")} </Link>
          </p>
        </div>
      </div>
    )
  }
}

export default Http404;