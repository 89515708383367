import React from "react";
import Header from "./component/Header";
import { i18n } from "./i18n";

function About(props) {
  return (
    <>
      <Header location={props.location}/>
      <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-white">
        <div className="col-md-5 p-lg-5 mx-auto my-5">
          <h1 className="display-4 font-weight-normal">Hirine</h1>
          <p className="lead font-weight-normal">
              The most advanced job searching platform you will ever need
          </p>
          <div>
            <a
              target="_blank"
              href={
                i18n.language === "zh"
                  ? "https://apps.apple.com/ca/app/hirine/id1454826078"
                  : "https://apps.apple.com/ca/app/hirine/id1454826078"
              }
            >
              <img
                style={{ width: 150 }}
                className="mt-1"
                src="https://storage.googleapis.com/hr_api_storage/images/gxTpos-YpC5.png"
                alt="download app on apple store"
              ></img>
            </a>
          </div>
          <div>
            <a
              target="_blank"
              href={
                i18n.language === "zh"
                  ? "https://play.google.com/store/apps/details?id=com.hrsee_app&hl=zh"
                  : "https://play.google.com/store/apps/details?id=com.hrsee_app&hl=en"
              }
            >
              <img
                className="mt-1"
                style={{ width: 150 }}
                src="https://storage.googleapis.com/hr_api_storage/images/V3WpLgfS45Z.png"
                alt="download app on play store"
              ></img>
            </a>
          </div>
        </div>
        <div className="pd-1 shadow-sm d-none d-lg-block"></div>
        <div className="pd-2 product-device-2 shadow-sm d-none d-lg-block"></div>
      </div>
      <div className="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
        <div className="bg-primary mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden">
          <div className="my-3 py-3">
            <h2 className="display-5">Search jobs with filter</h2>
            {/* <p className="lead">And an even wittier subheading.</p> */}
          </div>
          <div className="shadow-lg mx-auto pd-3"></div>
        </div>
        <div className="bg-white mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
          <div className="my-3 p-3">
            <h2 className="display-5">Search jobs on map</h2>
            {/* <p className="lead">And an even wittier subheading.</p> */}
          </div>
          <div className="shadow-lg mx-auto pd-4"></div>
        </div>
      </div>
      <div className="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
        <div className="bg-white mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
          <div className="my-3 p-3">
            <h2 className="display-5">
              Apply jobs by answering simple questions
            </h2>
            {/* <p className="lead">And an even wittier subheading.</p> */}
          </div>
          <div className="shadow-lg mx-auto pd-6"></div>
        </div>
        <div className="bg-primary mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden">
          <div className="my-3 py-3">
            <h2 className="display-5">
              Receive offers or interview invites with phone notification
            </h2>
            {/* <p className="lead">And an even wittier subheading.</p> */}
          </div>
          <div className="shadow-lg mx-auto pd-5"></div>
        </div>
      </div>
    </>
  );
}

export default About;
