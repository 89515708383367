import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import { Link, navigate } from "@reach/router";
import { i18n } from "./i18n";
import { ConfirmButton } from "./ConfirmButton";

import companyIcon from './img/group26@3x.png'
const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? "nav-link active" : "nav-link"
      };
    }}
  />
);
function EmployerApp(props) {
  const [showSidebar, setSidebarState] = useState(true);
  const [showLanguage, setLanguageState] = useState(false);
  const [showCollapse, setCollapseState] = useState(false);
  // useEffect(() => {
  //   let id = setInterval(() => {
  //     Api.getMessage(props.token)
  //   }, 4000);
  //   return () => clearInterval(id);
  // }, [props.token]);

  function handleLogout() {
    props.logout();
    navigate("/employer/login");
  }
  function handleToggleLanguageDropdown(event) {
    event.preventDefault();
    setLanguageState(!showLanguage);
  }
  function hideCollapse() {
    setCollapseState(false)
  }

  function selectLanguage(lang) {
    i18n.changeLanguage(lang);
    document.cookie = `lang=${lang};`;
    localStorage.setItem("lang", lang);
    
    document.getElementById('navbarDropdown').click();
    // window.location.reload();
  }
  if (props.requested === false) {
    return <div>loading</div>;
  } else if (!props.token && props.requested === true) {
    return props.children;
  } else {
    return (
      <div className="container-fluid">
        <nav className="navbar navbar-light navbar-expand-lg fixed-top bg-white p-0 shadow-sm" style={{zIndex: 1023}}>
          <Link to="/" className="navbar-brand text-center bg-primary col-md-2 col-6 text-white">
            <img className="mw-100" src={companyIcon} alt="hirine employer"/>
          </Link>
          <button
            className="navbar-toggler border-0"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            onClick={() => setCollapseState(!showCollapse)}
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon " />
          </button>
          <div
            className={
              "collapse navbar-collapse" + (showCollapse ? " show" : "")
            }
          >
            <ul className="navbar-nav px-3 ml-auto">
            <li className="nav-item d-block d-md-none">
            <NavLink to="/employer/job" onClick={hideCollapse}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-list d-none d-lg-inline"
              >
                <line x1="8" y1="6" x2="21" y2="6" />
                <line x1="8" y1="12" x2="21" y2="12" />
                <line x1="8" y1="18" x2="21" y2="18" />
                <line x1="3" y1="6" x2="3" y2="6" />
                <line x1="3" y1="12" x2="3" y2="12" />
                <line x1="3" y1="18" x2="3" y2="18" />
              </svg>
              {i18n.t("tabTitles.jobBoard")}
            </NavLink>
          </li>
          <li className="nav-item d-block d-md-none">
            <NavLink to="/employer/employee" onClick={hideCollapse}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-users d-none d-lg-inline"
              >
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                <circle cx="9" cy="7" r="4" />
                <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
              </svg>
              {i18n.t("tabTitles.employee")}
            </NavLink>
          </li>
          <li className="nav-item d-block d-md-none">
            <NavLink to="/employer/notification" onClick={hideCollapse}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-message-square d-none d-lg-inline"
              >
                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
              </svg>
              {i18n.t("tabTitles.notification")}
            </NavLink>
          </li>

          <li className="nav-item d-block d-md-none">
            <NavLink to="/employer/schedule" onClick={hideCollapse}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-calendar d-none d-lg-inline"
              >
                <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
                <line x1="16" y1="2" x2="16" y2="6" />
                <line x1="8" y1="2" x2="8" y2="6" />
                <line x1="3" y1="10" x2="21" y2="10" />
              </svg>
              {i18n.t("tabTitles.schedule")}
            </NavLink>
          </li>
          <li className="nav-item d-block d-md-none">
            <NavLink to="/employer/address" onClick={hideCollapse}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-map d-none d-lg-inline"
              >
                <polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6" />
                <line x1="8" y1="2" x2="8" y2="18" />
                <line x1="16" y1="6" x2="16" y2="22" />
              </svg>
              {i18n.t("tabTitles.addressBook")}
            </NavLink>
          </li>
          <li className="nav-item d-block d-md-none">
            <NavLink to="/employer/profile" onClick={hideCollapse}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-user d-none d-lg-inline"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                <circle cx="12" cy="7" r="4" />
              </svg>
              {i18n.t("tabTitles.profile")}
            </NavLink>
          </li>
              <li
                className={
                  "nav-item text-nowrap dropdown" +
                  (showLanguage ? " show" : "")
                }
              >
                <a
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  href="#"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={showLanguage}
                  onClick={handleToggleLanguageDropdown}
                >
                  {i18n.t("language")}
                </a>
                <div
                  className={"dropdown-menu dropdown-menu-right" + (showLanguage ? " show" : "")}
                  aria-labelledby="navbarDropdown"
                >
                  <button
                    className="dropdown-item d-flex align-items-center"
                    type="button"
                    onClick={() => selectLanguage("en")}
                  >
                    {i18n.t("english")}
                      {i18n.language === 'en' &&  <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-check text-primary"
                      >
                        <polyline points="20 6 9 17 4 12" />
                      </svg>
                    }
                  </button>
                  <button
                    className="dropdown-item"
                    type="button d-flex align-items-center"
                    onClick={() => selectLanguage("zh")}
                  >
                    {i18n.t("chinese")}
                    {i18n.language === 'zh' && <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-check text-primary"
                      >
                        <polyline points="20 6 9 17 4 12" />
                      </svg>}
                  </button>
                </div>
              </li>
              
            </ul>
          </div>
        </nav>
        {/* {showSidebar ? (
          <button
            type="button"
            className="position-fixed btn btn-primary btn-sm d-none d-md-block"
            style={{ top: "80vh", left: 0, zIndex: 1 }}
            onClick={() => setSidebarState(false)}
          >
            -
          </button>
        ) : (
          <button
            type="button"
            className="position-fixed btn btn-primary btn-sm d-none d-md-block"
            style={{ top: "80vh", left: 0, zIndex: 1 }}
            onClick={() => setSidebarState(true)}
          >
            +
          </button>
        )} */}
        <div className="row">
          {showSidebar ? <Sidebar company={props.company} handleLogout={handleLogout}/> : null}
          <main
            className={
              showSidebar
                ? "col-md-10 ml-sm-auto col-lg-10 px-lg-4"
                : "col-md-12 ml-sm-auto col-lg-12 px-lg-4"
            }
            role="main"
          >
            {props.children}
          </main>
        </div>
      </div>
    );
  }
}

export default EmployerApp;
