import React, { Component, useEffect, useState } from "react";
import { AuthContext } from "./Auth-context";
import { Router, Redirect } from "@reach/router";
import PostJob from "./postv1/PostJob";
import JobBoard from "./JobBoard";
import Employee from "./Employee";
import Notification from "./Notification";
import { EmployeeJobDetail } from "./JobDetail";
import EmployerJobDetail from "./EmployerJobDetail";
import Profile from "./Profile";
import MySchedule from "./MySchedule";
import Application from "./Application";
import Login from "./Login";
import Register from "./Register";
import EmployerApp from "./EmployerApp";
import Home from "./Home";
import AddressBook from "./AddressBook";
import Draft from "./Draft";

import PayDashboard from "./PayDashboard";
import T4Dashboard from "./T4Dashboard";
import WorkingHour from "./WorkingHour";

import VacationPayDoneDashboard from "./VacationPayDoneDashboard";
import VacationPayPendingDashboard from "./VacationPayPendingDashboard";
import Chat from "./chat/Chat";
import Post from "./postv2/Post";
import ScrollToTop from "./component/ScrollToTop";
import SEORoot from "./component/SEORoot";
import About from "./About";
import Http404 from "./Http404";
import Announcement from "./Announcement";
import AnnouncementV2 from "./AnnouncementV2";

import { useTranslation } from "react-i18next";
import * as Api from "./Api";

function NotFound() {
  return <p>Sorry, nothing here</p>;
}

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <AuthContext.Consumer>
    {context =>
      context.token ? (
        <Component {...rest} />
      ) : (
        <Redirect from="" to="/employer/login" noThrow />
      )
    }
  </AuthContext.Consumer>
);
const RedirectIfAuthRoute = ({ component: Component, ...rest }) => (
  <AuthContext.Consumer>
    {context =>
      !context.token ? (
        <Component {...rest} />
      ) : (
        <Redirect from="" to="/employer/job" noThrow />
      )
    }
  </AuthContext.Consumer>
);

function GoogleAds() {
  useEffect(() => {
    if (!window._loaded_ad) {
      const elem = document.createElement("script");
      elem.src = "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
      elem.async = true;
      elem.defer = true;
      elem.onload = function () {
        if (window.adsbygoogle && window.adsbygoogle.length !== 0) {
          window.adsbygoogle.push({
            google_ad_client: "ca-pub-1640852013980454",
            enable_page_level_ads: true
          });
        }
        // console.log('lets go!');
        window._loaded_ad = 1
      };
      document.body.insertBefore(elem, document.body.firstChild);
    }
  }, []);
  return null;
}

function App(props) {
  const [showAd, setShowAd] = useState(false);
  useEffect(() => {
    Api.getGlobalValue()
      .then(response => {
        if (response.ip !== "76.68.125.127") {
          setShowAd(true);
        }
      })
      .catch();
  }, []);
  return (
    <AuthContext.Consumer>
      {context => {
        return (
          <>
            {showAd && <GoogleAds />}
            <Router primary={false}>
              <SEORoot path="/">
                <ScrollToTop path="/">
                  <Home path="/" language="en" />
                  <ChineseSite path="/zh">
                    <Home path="/" language="zh" />
                    <EmployeeJobDetail path="/job/:jobId" language="zh" />
                    <About path="/about" />
                  </ChineseSite>
                  <EmployeeJobDetail path="/jobDetail/:jobId" language="en" />
                  <EmployeeJobDetail path="/job/:jobId" language="en" />
                  <About path="/about" />
                  <EmployerApp
                    path="/employer"
                    logout={context.logout}
                    token={context.token}
                    company={context.company}
                    requested={context.requested}
                  >
                    <ProtectedRoute component={PostJob} path="/post" />
                    <ProtectedRoute
                      component={Post}
                      path="/post-new"
                      company={context.company}
                    />
                    <ProtectedRoute
                      component={Post}
                      path="/post-new/:jobId"
                      company={context.company}
                    />
                    <ProtectedRoute
                      component={Post}
                      path="/post-draft/:draftId"
                      company={context.company}
                    />
                    {/** draft list */}
                    <ProtectedRoute component={Draft} path="/draft" />
                    {/** draft detail */}
                    <ProtectedRoute
                      component={PostJob}
                      path="/draft/:draftId"
                    />
                    {/** edit job detail */}
                    <ProtectedRoute component={PostJob} path="/post/:jobId" />
                    {/** job list */}
                    <ProtectedRoute component={JobBoard} path="/job" />
                    <ProtectedRoute
                      component={Application}
                      path="/application/:applicationId"
                    />
                    <ProtectedRoute component={Employee} path="/employee" />
                    <ProtectedRoute
                      component={Notification}
                      path="/notification"
                    />
                    <ProtectedRoute
                      component={EmployerJobDetail}
                      path="/job/:jobId"
                      company={context.company}
                    />
                    <ProtectedRoute component={Profile} path="/profile" />
                    <ProtectedRoute component={MySchedule} path="/schedule" />
                    <ProtectedRoute component={AddressBook} path="/address" />
                    <ProtectedRoute component={PayDashboard} path="/payroll" />
                    <ProtectedRoute
                      component={VacationPayDoneDashboard}
                      path="/vacationPayDone"
                    />
                    <ProtectedRoute
                      component={VacationPayPendingDashboard}
                      path="/vacationPayPending"
                    />
                    <ProtectedRoute component={WorkingHour} path="/workinghour" />
                    <ProtectedRoute component={T4Dashboard} path="/t4" />
                    <ProtectedRoute
                      component={Chat}
                      path="/chat"
                      userId={context.company && context.company._id}
                    />
                    <ProtectedRoute
                      component={Chat}
                      path="/chat/:target"
                      userId={context.company && context.company._id}
                    />
                    <ProtectedRoute
                      component={Announcement}
                      path="announcement"
                      userId={context.company && context.company._id}
                    />

                    <ProtectedRoute
                      component={AnnouncementV2}
                      path="/announcementv2/:jobId"
                      userId={context.company && context.company._id}
                    />

                    <RedirectIfAuthRoute
                      component={Login}
                      login={context.login}
                      path="/login"
                    />
                    <RedirectIfAuthRoute
                      component={Register}
                      login={context.login}
                      path="/register"
                    />

                    <RedirectIfAuthRoute
                      component={Login}
                      login={context.login}
                      path="/login"
                    />
                    <RedirectIfAuthRoute
                      component={Register}
                      login={context.login}
                      path="/register"
                    />
                  </EmployerApp>
                  <NotFound default />
                  <Http404 path="/http404" />
                </ScrollToTop>
              </SEORoot>
            </Router>
          </>
        );
      }}
    </AuthContext.Consumer>
  );
}

// function EnglishSite(props) {
//   useEffect(() => {
//     i18n.changeLanguage("en");
//   }, []);
//   return props.children;
// }
function ChineseSite(props) {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage("zh");
  }, []);
  return props.children;
}

export default App;
