import React, { Component } from "react";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { i18n } from "./i18n";
import ReactPaginate from "react-paginate";
// import { ConfirmButtonCustomTip } from "./ConfirmButtonCustomTip";
import { toast } from "react-toastify";
import * as Api from "./Api";
import { ConfirmButton } from "./ConfirmButton";
import moment from 'moment';
export default class VacationPayPendingDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      fileName: "",
      data: [],
      offset: 1,
      total: -1,
      forcePage: null,
      pageCount: 0,
      pageSize: 15,
      periods: [],
      periodsStart: [],
      selectedPeriod: "",
      selectedPeriodStart: "",
      listLoading: false,
      radioSelectedOption: "pending",
      radioSelectedImportType: "new", //is_history: default false => new
      test: false,
      processStartTimeStr: "",
      employeeBPId:"",
    };
    this.file = null;
  }
  onFileSelect = e => {
    switch (e.target.name) {
      case "selectedFile":
        if (e.target.files.length > 0) {
          const reader = new FileReader();
          reader.onload = e => {
            this.setState({ success: false });
          };
          reader.readAsDataURL(e.target.files[0]);
          this.file = e.target.files[0];
          this.setState({ fileName: e.target.files[0].name });
        }
        break;
      default:
    }
  };
  handleSubmitClick = () => {
    if (this.file) {
      this.setState({ listLoading: true }); //uploadCSV
      Api.uploadVacationPayCSV(this.state.radioSelectedImportType, this.file, global._token).then(response => {
        if (response.ok) {
          console.log("response.ok");
          this.setState({ success: true, listLoading: false });
          toast.success(i18n.t("pay.uploadSuccess"), {
            className: "alert alert-success"
          });
          // window.location.reload();
          // this.loadPayrollPeriod();
          this.loadDataFromServer();

        } else {
          // toast.error(i18n.t("pay.uploadFailed"), {
          //   className: "alert alert-danger"
          // });
          this.setState({ success: true, listLoading: false });
          toast.success(i18n.t("pay.uploadSuccess"), {
            className: "alert alert-success"
          });
          this.setState({ success: false, listLoading: false });
        }
      }).catch(e => {
        this.setState({ listLoading: false });
        toast.error(e.message, { className: "alert alert-danger" });
      });;
    }
  };

  handleSubmitClick2 = () => {
    window.location.href = window.location.href.replace("vacationPayPending", "vacationPayDone");
  };
  componentDidMount() {
    // this.loadPayrollPeriod();
    this.loadDataFromServer();
  }
  // loadPayrollPeriod = () => {
  //   Api.getPayPeroid(global._token).then(e => {
  //     if (e && e.count !== 0) {
  //       this.setState(
  //         {
  //           periods: e.items.peroid,
  //           periodsStart: e.items.peroidStart,
  //           selectedPeriod: e.items.peroid[e.items.peroid.length - 1],
  //           selectedPeriodStartEnd: e.items.peroidStart[e.items.peroid.length - 1] + '-' + e.items.peroid[e.items.peroid.length - 1]
  //         },
  //         () => this.loadDataFromServer()
  //       );
  //     } else {
  //       this.setState(
  //         {
  //           periods: [],
  //           periodsStart: [],
  //           selectedPeriod: "",
  //           selectedPeriodStartEnd: ""
  //         },
  //         () => this.loadDataFromServer()
  //       );
  //     }
  //   });
  // }
  handlePageClick = data => {
    this.setState({ offset: data.selected + 1 }, () => {
      this.loadDataFromServer();
    });
  };

  loadDataFromServer = (forcePage = false) => {

    let test1 = this.getUrlVars()["test"];
    console.log("test:" + test1)
    this.setState({ listLoading: true, test: test1 ? test1 : false });
    Api.getVacationPay4Employer(
      // this.state.selectedPeriod,
      this.state.radioSelectedOption,
      this.state.offset,
      this.state.pageSize,
      global._token
    ).then(p => {
      if (p && p.count !== 0) {
        this.setState({
          data: p.items,
          pageCount: Math.ceil(p.count / this.state.pageSize),
          forcePage: forcePage ? 0 : undefined,
          listLoading: false
        });
      } else {
        this.setState({
          data: [],
          pageCount: 0,
          forcePage: null,
          listLoading: false,
          periods: [],
          periodsStart: [],
          selectedPeriod: "",
          selectedPeriodStartEnd: ""
        });
      }
    }).catch(e => {
      this.setState({
        data: [],
        pageCount: 0,
        forcePage: null,
        listLoading: false,
        periods: [],
        periodsStart: [],
        selectedPeriod: "",
        selectedPeriodStartEnd: ""
      });
      // this.setState({ listLoading: false });
      toast.error(e.message, { className: "alert alert-danger" });
    });
  };

  handlePayPeriodChange = e => {
    // this.state.periodsStart
    this.setState(
      { offset: 1, forcePage: null, selectedPeriod: e.target.value },
      () => this.loadDataFromServer(true)
    );

    let selectedPeriodStartEnd = "";
    this.state.periods.map((p, index) => {
      if (p == e.target.value) {
        selectedPeriodStartEnd = this.state.periodsStart[index] + '-' + e.target.value;
        // console.log(p+":"+index+",selectedPeriodStartEnd="+selectedPeriodStartEnd);
      }
    });
    this.setState({ selectedPeriodStartEnd: selectedPeriodStartEnd });
  };

  getUrlVars = () => {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }

  handleDeleteClick = (processId) => {
    // this.setState({ listLoading: true });
    Api.deleteEmployeeBP(processId, global._token)
      .then(response => {
        if (response.ok) { //if (response.ok) {
          toast.success(i18n.t("pay.payrollDeleteSuccess"), {
            className: "alert alert-success"
          });
          // this.setState({success:true, listLoading: false });
          // let timer = setTimeout(() => {
            this.loadDataFromServer();
          // this.loadPayrollPeriod(); //it includes loadDataFromServer();
          // console.log('This will run after 4 second!')
          // }, 4000);
          // return () => clearTimeout(timer);
        } else {
          // this.loadPayrollPeriod();
          this.loadDataFromServer();
          // this.setState({ listLoading: false });
          toast.error(i18n.t("pay.payrollDeleteFailed"), {
            className: "alert alert-danger"
          });
          // throw new Error(i18n.t("pay.payrollDeleteFailed"));
        }
      })
      .catch(e => {
        // this.setState({ listLoading: false });
        this.loadDataFromServer();
        console.log(e.message);
      });
  };

  handleOptionChange = (changeEvent) => {
    // console.log("radioSelectedOption:" + changeEvent.target.value);
    this.setState({
      radioSelectedOption: changeEvent.target.value
    }
      , () => this.loadDataFromServer()
    );
  };
  handleUploadOptionChange = (changeEvent) => {
    this.setState({
      radioSelectedImportType: changeEvent.target.value
    }
      // , () => this.loadDataFromServer()
    );
  };
  handleChangeBPDate = () => {
    // this.setState({
    //   radioSelectedImportType: changeEvent.target.value
    // }
    let processStartTime1 = this.state.processStartTimeStr ? moment(this.state.processStartTimeStr, "YYYY-MM-DD HH:mm").toDate().getTime(): 0; 

    Api.changeVacationPayBPDate(
      this.state.employeeBPId,
      processStartTime1, //this.state.processStartTime,
      global._token
    ).then(p => {
      if (p && p.ok === 1) {
        this.setState({
          employeeBPId: "",
          processStartTimeStr:"",
        }, () => this.loadDataFromServer());
        toast.success("Update successfully!", {
          className: "alert alert-success"
        });
      } else {
        this.setState({
          employeeBPId: "",
          processStartTimeStr:"",
        });
        toast.error("Update failed! "+p.message, { className: "alert alert-danger" });
          
      }
    }).catch(e => {
      this.setState({
          employeeBPId: "",
          processStartTimeStr:"",
      });
      // this.setState({ listLoading: false });
      toast.error(e.message, { className: "alert alert-danger" });
    });
  };

  render() {
    let rExp = /\d+/g;
    return (
      <>
        <div className="card mt-2 border-0 shadow-sm">
          <div className="card-body">
            <div className="mb-3 row">
              <div className="input-group  col-2">
                {/* <div className="custom-file"> */}
                <div className="radio">
                  <label>
                    <input type="radio" value="new"
                      checked={this.state.radioSelectedImportType === "new"}
                      onChange={this.handleUploadOptionChange} />
                    {i18n.t("vacation-pay-dashboard.new")}
                  </label>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="radio">
                  <label>
                    <input type="radio" value="history"
                      checked={this.state.radioSelectedImportType === "history"}
                      onChange={this.handleUploadOptionChange} />
                    {i18n.t("vacation-pay-dashboard.history")}
                  </label>
                </div>
              </div>
              <div className="input-group  col-6">
                {/* </div>
                <div className="custom-file"> */}
                <input
                  type="file"
                  name="selectedFile"
                  className="custom-file-input"
                  id="inputGroupFile02"
                  onChange={this.onFileSelect}
                  accept=".csv"
                />
                <label
                  className="custom-file-label"
                  htmlFor="inputGroupFile02"
                  aria-describedby="inputGroupFileAddon02"
                >
                  {this.state.fileName === ""
                    ? i18n.t("pay.chooseFile")
                    : this.state.fileName}
                </label>
                {/* </div> */}
                {this.state.fileName ? (
                  <div className="input-group-append">
                    <button
                      type="button"
                      className={
                        this.state.success
                          ? "btn btn-success"
                          : "btn btn-primary"
                      }
                      onClick={
                        this.state.success ? null : this.handleSubmitClick
                      }
                    >
                      {this.state.success
                        ? i18n.t("profile.success")
                        : i18n.t("profile.upload")}
                    </button>
                  </div>
                ) : null}
              </div>
              {/* <div className="col-4 form-group">
 
                <select
                  className="form-control"
                  id="pay-control"
                  value={this.state.selectedPeriod}
                  onChange={this.handlePayPeriodChange}
                >
                  {this.state.periods.map((p, index) => (
                    <option key={index} value={p} data-period={p}>
                      {this.state.periodsStart &&
                        this.state.periodsStart.length > 0
                        ? this.state.periodsStart[index] + "-" + p
                        : p}
                    </option>
                  ))}
                </select>
              </div> */}
              <div className="input-group  col-4"></div>
            </div>

            <div className="mb-3 row">
              <div className="input-group  col-4">
                <div className="radio">
                  <label>
                    <input type="radio" value="pending"
                      checked={this.state.radioSelectedOption === 'pending'}
                      onChange={this.handleOptionChange} />
                    {i18n.t("vacation-pay-dashboard.pending")}
                  </label>
                </div>&nbsp;
                <div className="radio">
                  <label>
                    <input type="radio" value="done"
                      checked={this.state.radioSelectedOption === 'done'}
                      onChange={this.handleOptionChange} />
                    {i18n.t("vacation-pay-dashboard.done")}
                  </label>
                </div>
              </div>
              <div className="col-8 form-group">
                <div className="input-group-append">
                  <button
                    type="button"
                    className={
                      this.state.success
                        ? "btn btn-success"
                        : "btn btn-primary"
                    }
                    onClick={this.handleSubmitClick2
                    }
                  //this.state.success ? null :
                  >
                    {i18n.t("vacation-pay-dashboard.view-history")}
                  </button>
                </div>
                <div className="custom-file">
                </div>
              </div>
            </div>

            <div className="table-responsive position-relative">
              <table className="table table-bordered table-striped table-hover align-middle text-nowrap text-center">
                <thead>
                  <tr>
                    <th scope="col">{i18n.t("vacation-pay-dashboard.name")}</th>
                    <th scope="col">{i18n.t("vacation-pay-dashboard.mobile")}</th>
                    <th scope="col">{i18n.t("vacation-pay-dashboard.subType")}</th>
                    <th scope="col">{i18n.t("vacation-pay-dashboard.applicationTime")}</th>
                    <th scope="col">{i18n.t("vacation-pay-dashboard.import_type")}</th>
                    <th scope="col">{i18n.t("vacation-pay-dashboard.quit_time")}</th>
                    {/* <th scope="col">Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.mobile}</td>
                      <td>{item.subType}</td>
                      {/* parseISO( */}
                      <td>{format(item.processStartTime, "MMM, dd yyyy H:mm")}</td>
                      <td>{item.import_type? item.import_type: ""}</td>
                      <td>{item.remark && rExp.exec(item.remark) != null? format(parseInt(item.remark), "MMM, dd yyyy H:mm") :""}</td>
                      {/* <td>
                        <ConfirmButton
                          confirmHandler={() =>
                            this.handleDeleteClick(item._id)
                          }
                          className="btn btn-link text-muted"
                        >
                          <span className="sr-only">
                            {i18n.t("pay-dashboard.table-delete")}
                          </span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-trash-2"
                          >
                            <polyline points="3 6 5 6 21 6" />
                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                            <line x1="10" y1="11" x2="10" y2="17" />
                            <line x1="14" y1="11" x2="14" y2="17" />
                          </svg>
                        </ConfirmButton>
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          <input
                          type="text"
                          style={{"width":"280px"}}
                          placeholder={("format: 'yyyy-MM-dd HH:mm'")}
                          onChange={v => this.setState({ employeeBPId: item._id, processStartTimeStr: v.target.value })}
                        />

                        <button type="button" className="btn btn-primary" onClick={this.handleChangeBPDate}>Save</button>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={i18n.t("paginate.previous")}
                nextLabel={i18n.t("paginate.next")}
                breakLabel={"..."}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                forcePage={this.state.forcePage}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                breakClassName={"page-item"}
                disabledClassName={"disabled"}
                pageClassName={"page-item"}
                previousClassName={"page-item"}
                nextClassName={"page-item"}
                breakLinkClassName="page-link"
                pageLinkClassName={"page-link"}
                activeClassName={"active"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
              />
              {this.state.listLoading && (
                <div
                  className="position-absolute d-flex justify-content-center align-items-center"
                  style={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    zIndex: 1,
                    background: "rgba(0, 0, 0, 0.2)"
                  }}
                >
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
