import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { Link, navigate } from "@reach/router";
import formatDistance from "date-fns/formatDistance";
import * as Api from "./Api";
import { i18n } from "./i18n";
import { toast } from "react-toastify";
import Jumbotron from "./Jumbotron";
import Dropdown from "react-bootstrap/Dropdown";
import { ConfirmButton } from "./ConfirmButton";
import { enUS, zhCN } from "date-fns/locale";

class JobBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      offset: 1,
      total: -1,
      pageSize: 15,
      forcePage: null,
      searchValue: "",
      pageCount: 0,
      activeStatus: 0,
      initial: true,
      listLoading: false,
      dropdownOpen: {},
    };
    if (i18n.language === "zh") {
      this.locale = zhCN;
    } else {
      this.locale = enUS;
    }
    this.searchValue = "";
  }

  componentDidMount() {
    this.loadDataFromServer(false, true);
  }
  loadDataFromServer = (forcePage = false, initial = false) => {
    this.setState({ listLoading: true });
    Api.getJoblistCreatedByMe(
      this.state.offset,
      this.state.pageSize,
      this.searchValue,
      this.state.activeStatus,
      global._token
    )
      .then(json => {
        if (json.items) {
          this.setState({
            data: json.items,
            pageCount: Math.ceil(json.total / this.state.pageSize),
            total: json.total,
            forcePage: forcePage ? 0 : undefined,
            ...(!initial && { initial: false }),
            listLoading: false
          });
        } else {
          throw new Error("Getting job list failed");
        }
      })
      .catch(e => {
        toast.error(e.message, { className: "alert alert-danger" });
      });
  };
  handlePageClick = data => {
    this.setState({ offset: data.selected + 1 }, () => {
      this.loadDataFromServer();
    });
  };

  handleRowClick = event => {
    if (event.target.dataset.job) {
      navigate("/employer/job/" + event.target.dataset.job);
    }
  };
  handleSearchClick = event => {
    event.preventDefault();
    if (this.state.searchValue !== this.searchValue) {
      this.searchValue = this.state.searchValue;
      this.setState({ offset: 1, forcePage: null }, () => {
        this.loadDataFromServer(true);
      });
    }
  };
  handleDataToggle = (_id, key, value) => {
    Api.editJob(
      _id,
      {
        [key]: value
      },
      global._token
    )
      .then(json => {
        if (json._id) {
          this.setState(prevState => ({
            data: prevState.data.map(el =>
              el._id === _id ? { ...el, [key]: value } : el
            )
          }));
          toast.success("Job updated!", {
            className: "alert alert-success"
          });
        } else {
          throw new Error("Job update failed");
        }
      })
      .catch(e => {
        toast.error(e.message, { className: "alert alert-danger" });
      });
  };

  render() {
    if (this.state.total === 0 && this.state.initial === true)
      return <Jumbotron />;
    return (
      <>
        {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap pt-2 pb-2 mb-3 align-items-center border-bottom">
          <h1 className="h2 mb-0">{i18n.t("tabTitles.jobBoard")}</h1>
        </div> */}
        <div className="bg-white p-3 shadow-sm my-2 positive-relative">
          <div className="form-row my-3">
            <form
              onSubmit={this.handleSearchClick}
              className="input-group col-8 col-md-6"
            >
              <div className="input-group-prepend">
                <span
                  className="input-group-text"
                  style={{ backgroundColor: "#fff" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8" />
                    <line x1="21" y1="21" x2="16.65" y2="16.65" />
                  </svg>
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                value={this.state.searchValue}
                placeholder={i18n.t("jobBoard.searchPlaceHolder")}
                onChange={v => this.setState({ searchValue: v.target.value })}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="submit"
                  id="button-addon2"
                >
                  {i18n.t("search")}
                </button>
              </div>
            </form>
            <div className="col-4 col-md-2 offset-md-2">
              <select
                className="form-control"
                value={this.state.activeStatus}
                onChange={v =>
                  this.setState(
                    {
                      activeStatus: parseInt(v.target.value, 10),
                      offset: 1,
                      forcePage: null
                    },
                    () => {
                      this.loadDataFromServer(true);
                    }
                  )
                }
              >
                <option value={0}>{i18n.t("jobStatus.all")}</option>
                <option value={1}>{i18n.t("jobStatus.active")}</option>
                <option value={2}>{i18n.t("jobStatus.inactive")}</option>
              </select>
            </div>
            <div className="col-2 col-md-2 d-none d-md-block">
              <select
                className="form-control"
                value={this.state.pageSize}
                onChange={v =>
                  this.setState(
                    {
                      pageSize: parseInt(v.target.value, 10),
                      offset: 1,
                      forcePage: null
                    },
                    () => {
                      this.loadDataFromServer(true);
                    }
                  )
                }
              >
                <option value={15}>15 {i18n.t("results")}</option>
                <option value={30}>30 {i18n.t("results")}</option>
                <option value={50}>50 {i18n.t("results")}</option>
              </select>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-hover align-middle text-nowrap text-center border">
              <thead>
                <tr>
                  <th scope="col" className="border-right">
                    #
                  </th>
                  <th scope="col">{i18n.t("jobBoard.jobTitle")}</th>
                  <th scope="col" className="text-nowrap">
                    {i18n.t("jobBoard.applicants")}
                  </th>
                  <th scope="col">{i18n.t("jobBoard.status")}</th>

                  {/* <th scope="col" className="d-none d-md-table-cell">
                  {i18n.t("jobBoard.lastUpdatedAt")}
                </th> */}
                  <th scope="col">{i18n.t("jobBoard.actions")}</th>
                  <th scope="col" className="d-none d-md-table-cell">
                    {i18n.t("jobBoard.createdAt")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.data.map((item, index) => (
                  <tr key={item._id}>
                    <td
                      scope="row"
                      className="align-middle text-truncate border-right"
                      style={{ maxWidth: 70 }}
                    >
                      {/* {index + 1 + (this.state.offset - 1) * this.state.pageSize} */}
                      <span title={item._id}>{item._id}</span>
                    </td>
                    <td
                      onClick={this.handleRowClick}
                      data-job={item._id}
                      className="align-middle"
                    >
                      {item.title}
                    </td>
                    <td
                      onClick={this.handleRowClick}
                      data-job={item._id}
                      className="align-middle"
                    >
                      {item.applicants === 0 ? "-" : item.applicants}
                    </td>
                    <td
                      onClick={this.handleRowClick}
                      data-job={item._id}
                      className="align-middle"
                    >
                      {item.isActive ? (
                        <span className="badge badge-pill badge-success py-2 w-100">
                          {i18n.t("jobStatus.active")}
                        </span>
                      ) : (
                        <span className="badge badge-pill badge-danger py-2 w-100">
                          {i18n.t("jobStatus.inactive")}
                        </span>
                      )}
                    </td>

                    {/* <td
                    onClick={this.handleRowClick}
                    data-job={item._id}
                    className="d-none d-md-table-cell align-middle"
                  >
                    {formatDistanceToNow(new Date(item.updatedAt), {
                      locale: locale,
                      addSuffix: true
                    })}
                  </td> */}

                    <td>
                      <Dropdown show={this.state.dropdownOpen[item._id]}>
                        <Dropdown.Toggle as="button" className="btn btn-link" onClick={
                          () => {
                            this.setState((prevState) => ({
                              dropdownOpen: {
                                ...prevState.dropdownOpen,
                                [item._id]: !prevState.dropdownOpen[item._id],
                              },
                            }));
                          }
                        }>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-menu"
                          >
                            <line x1="3" y1="12" x2="21" y2="12" />
                            <line x1="3" y1="6" x2="21" y2="6" />
                            <line x1="3" y1="18" x2="21" y2="18" />
                          </svg>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="border-0 shadow">
                          <Dropdown.Item
                            as={Link}
                            to={"/employer/job/" + item._id}
                          >
                            {i18n.t("jobBoard.view")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Link}
                            to={`/employer/post-new/${item._id}`}
                          >
                            {i18n.t("jobBoard.edit")}
                          </Dropdown.Item>

                          {item.isAvailable === false ? (
                            <Dropdown.Item
                              as="button"
                              className="btn btn-link text-nowrap"
                              onClick={() =>
                                this.handleDataToggle(
                                  item._id,
                                  "isAvailable",
                                  true
                                )
                              }
                            >
                              {i18n.t("jobStatus.open")}
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              as={ConfirmButton}
                              confirmHandler={() =>
                                this.handleDataToggle(
                                  item._id,
                                  "isAvailable",
                                  false
                                )
                              }
                              className="btn btn-link text-nowrap"
                            >
                              {i18n.t("jobStatus.close")}
                            </Dropdown.Item>
                          )}

                          {item.isActive ? (
                            <Dropdown.Item
                              as={ConfirmButton}
                              confirmHandler={() =>
                                this.handleDataToggle(
                                  item._id,
                                  "isActive",
                                  false
                                )
                              }
                              className="btn btn-link text-nowrap"
                            >
                              {i18n.t("jobBoard.deactivate")}
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              as="button"
                              onClick={() =>
                                this.handleDataToggle(
                                  item._id,
                                  "isActive",
                                  true
                                )
                              }
                              className="btn btn-link text-nowrap"
                            >
                              {i18n.t("jobBoard.activate")}
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td
                      onClick={this.handleRowClick}
                      data-job={item._id}
                      className="d-none d-md-table-cell align-middle"
                    >
                      {formatDistance(new Date(item.createdAt), new Date(), {
                        locale: this.locale,
                        addSuffix: true
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <ReactPaginate
            previousLabel={i18n.t("paginate.previous")}
            nextLabel={i18n.t("paginate.next")}
            breakLabel={"..."}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={this.state.forcePage}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            breakClassName={"page-item"}
            disabledClassName={"disabled"}
            pageClassName={"page-item"}
            previousClassName={"page-item"}
            nextClassName={"page-item"}
            breakLinkClassName="page-link"
            pageLinkClassName={"page-link"}
            activeClassName={"active"}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
          />
          {this.state.listLoading && (
            <div
              className="position-absolute d-flex justify-content-center align-items-center"
              style={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1,
                background: "rgba(0, 0, 0, 0.1)"
              }}
            >
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default JobBoard;
