import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription
} from "@reach/alert-dialog";
import React, { useState, useRef } from "react";
import { i18n } from "./i18n";
{/* 使用方法介绍：您可以将自定义确认提示信息 通过参数传给customTip, 比如
  (Usage: pass your custom tip information to the 'customTip', for example: )
  <ConfirmButtonCustomTip
  confirmHandler={() =>
    this.handleDeleteClick()
  }
  className="btn btn-link text-muted"
  customTip="这里是您想要显示的自定义提示信息"
  customTipParams={{param1:value1,param2:value2,...}}
> */}

  export function ConfirmButtonCustomTip({
    confirmHandler,
    buttonType = "primary",
    link = false,
  ...props
}) {
  const [showDialog, setDialogState] = useState(false);
  const closeRef = useRef(null);
  function closeDialog() {
    setDialogState(false);
}
  function confirm() {
    confirmHandler();
  closeDialog();
}
  function openConfirm(event) {
    if (link) {
    event.preventDefault();
}
setDialogState(true);
}
return (
    <>
    {link ? (
      <a
        href="#"
        className={props.className}
        style={props.style}
        onClick={openConfirm}
      >
        {props.children}
      </a>
    ) : (
        <button
          className={props.className}
          style={props.style}
          onClick={openConfirm}
          type="button"
        >
          {props.children}
        </button>
      )}
    {showDialog && (
      <AlertDialog leastDestructiveRef={closeRef} onDismiss={closeDialog}>
        <div className="modal-header">
          <AlertDialogLabel>
            <h5>{i18n.t("confirmbox.confirm-title")}</h5>
          </AlertDialogLabel>
        </div>
        <div className="modal-body">
          <AlertDialogDescription>
            {props.customTip ? i18n.t(props.customTip, props.customTipParams) : i18n.t("confirmbox.confirm-text")}
          </AlertDialogDescription>
        </div>

        <div className="modal-footer">
          <button
            ref={closeRef}
            onClick={closeDialog}
            type="button"
            className="btn btn-link"
          >
            {i18n.t("confirmbox.cancel")}
          </button>
          <button
            onClick={confirm}
            type="button"
            className={`btn btn-${buttonType}`}
          >
            {i18n.t("confirmbox.confirm")}
          </button>
        </div>
      </AlertDialog>
    )}
  </>
  );
}
