import React, { Component } from "react";
import PersonIcon from "../img/person_small.png";
class ContactList extends Component {
  handleSelectContact = e => {
    const contact = e.target.dataset.contact;
    this.props.handleSelectContact(contact);
  };
  render() {
    const { contact, selected } = this.props;
    const { contactById } = contact;
    return (
      <>
        <div className="col-3 border-right p-0 d-flex flex-column">
          <div className="border-bottom" style={{ height: 50 }}></div>
{/* && contactById[contactId].user.roles[0] === "employer" */}
          {/* src={contactById[contactId].user.icon} */}
          {/* contactById[contactId].user.roles[0] === "employer" */}
          <div className="overflow-auto" style={{ flexBasis: 1, flexGrow: 1 }}>
            {contact.list.map(contactId => (
              <div onClick={this.handleSelectContact} key={contactId}>
                <div
                  className={
                    "border-bottom py-2 px-2 d-flex position-relative chat-contact-item" +
                    (selected === contactId ? " bg-light" : "")
                  }
                  data-contact={contactId}
                >
                  <img
                    alt='Contact person'
                    src={PersonIcon}
                    data-contact={contactId}
                    className="img-thumbnail rounded-circle"
                    style={{ height: 50, width: 50 }}
                  />
                  <div>
                    <div
                      className="pl-2 text-truncate"
                      data-contact={contactId}
                    >
                      
                      {!contactById[contactId].user ? '' 
                      :
                       ((contactById[contactId].user.roles || []).length > 0 && contactById[contactId].user.roles[0] === "employer"
                        ? contactById[contactId].user.companyName
                        : contactById[contactId].user.firstName +
                          " " +
                          contactById[contactId].user.lastName)
                          }
                    </div>
                    <div
                      className="pl-2 text-muted"
                      data-contact={contactId}
                      style={{
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        overflow: "hidden"
                      }}
                    >
                      {contactById[contactId].latestMessage.content}
                    </div>
                  </div>
                  {contactById[contactId].latestMessage.unread &&
                  contactById[contactId].latestMessage.from !==
                    this.props.userId ? (
                    <span
                      style={{
                        height: 15,
                        width: 15,
                        top: 5,
                        left: 5,
                        background: "red",
                        borderRadius: "50%"
                      }}
                      className="position-absolute"
                    ></span>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default ContactList;
