import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { AuthProvider } from "./Auth-context";
import "@reach/dialog/styles.css";
import "@reach/tooltip/styles.css";
import "./custom.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.minimal.css";
import "react-datepicker/dist/react-datepicker.css";


ReactDOM.render(
  <AuthProvider>
    <App />
    <ToastContainer
      className={"custom-toast-container"}
      closeButton={false}
      autoClose={2000}
    />
  </AuthProvider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
