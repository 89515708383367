import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { EmployeeDetail } from "./PageComponents";
import { i18n } from "./i18n";
import { Dialog } from "@reach/dialog";
import VisuallyHidden from "@reach/visually-hidden";
import * as Api from "./Api";
import CloseIcon from "./component/CloseIcon";
import { toast } from "react-toastify";
import { Link } from "@reach/router";
import { ConfirmButton } from "./ConfirmButton";

export default class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageCount: 0,
      jobDetail: {},
      fetchLength: 15,
      employeeDetail: {},
      searchValue: "",
      showEmployeeDetail: false
    };
    this.searchValue = "";
  }

  toggleModal() {
    this.setState({ showEmployeeDetail: !this.state.showEmployeeDetail });
  }

  componentDidMount() {
    this.fetchEmplopyeeList(1);
  }

  convertDataToObject(data) {
    if (data instanceof Array) {
      return data;
    } else {
      return [];
    }
  }

  async fetchEmplopyeeList(page = 1, forceUpdatePageNumber = false) {
    let params = {
      limit: this.state.fetchLength,
      page: page,
      search: this.searchValue
    };
    Api.getHiredApplication(global._token, params).then(json => {
      if (json && json.employees && json.employees.length !== 0) {
        let tmpData = [];
        json.employees.forEach(employee => {
          tmpData.push(employee.employeeDetail[0]);
        });
        this.setState({
          data: tmpData,
          employmentData: json.employees,
          pageCount: Math.ceil(json.count / this.state.fetchLength),
          forceTab0Page: forceUpdatePageNumber ? 0 : undefined
        });
      } else {
        this.setState({
          data: [],
          employmentData: [],
          pageCount: 0,
          forceTab0Page: forceUpdatePageNumber ? 0 : undefined
        });
      }
    });
  }

  onPageChange = data => {
    this.setState({ tab0page: data.selected + 1 }, () => {
      this.fetchEmplopyeeList(this.state.tab0page);
    });
  };

  /**
   * modifies the applicant object in place
   * adds [satisfiedSkills] to the obj
   * adds [notSatisfiedSkills] to the obj
   * adds [additionalSkills] to the obj
   * @param {reference to applicant} applicant
   * @param {array of skills} req
   * @param {array of skills} has
   */
  matchApplicantSkills(applicant, req, has) {
    let satisfied = [];
    let notSatisfied = [];
    let additional = [];

    // key: id, value: skill data
    // loop through req
    // add req to map
    let reqMap = new Map();
    req.forEach(skill => {
      reqMap.set(skill._id, skill);
    });

    // key: id, value: skill data
    // loop through has
    // add has to map
    let hasMap = new Map();
    has.forEach(skill => {
      hasMap.set(skill.skill._id, skill);
    });

    // loop through has
    // if req map contains has
    // add skill to satisfied
    // remove skill from both maps
    hasMap.forEach((value, key) => {
      if (reqMap.has(key)) {
        satisfied.push(value);
        reqMap.delete(key);
        hasMap.delete(key);
      }
    });

    // if map is not empty
    // add remaining elements to not satisfied
    reqMap.forEach((value, key) => {
      notSatisfied.push(value);
    });

    // if map is not empty
    // add remaining elements to additional skills
    hasMap.forEach((value, id) => {
      additional.push(value);
    });

    // unify data structure
    for (let i = 0; i < satisfied.length; i++) {
      satisfied[i] = satisfied[i].skill;
    }
    for (let i = 0; i < notSatisfied.length; i++) {
      notSatisfied[i] = notSatisfied[i].skill;
    }
    for (let i = 0; i < additional.length; i++) {
      additional[i] = additional[i].skill;
    }

    applicant["satisfiedSkills"] = satisfied;
    applicant["notSatisfiedSkills"] = notSatisfied;
    applicant["additionalSkills"] = additional;
  }

  employeeOnClick(employee) {
    let skillPool = {};
    employee.skills.forEach(skill => (skillPool[skill.skill] = skill));
    employee.skillsDetail.forEach(detail => {
      detail.value = skillPool[detail._id].value;
    });
    this.setState(
      {
        employeeDetail: employee
      },
      this.toggleModal
    );
  }
  handleSearch = event => {
    event.preventDefault();
    if (this.state.searchValue !== this.searchValue) {
      this.searchValue = this.state.searchValue;
      this.setState({ tab0page: 1, forceTab0Page: null }, () => {
        this.fetchEmplopyeeList(1, true);
      });
    }
  };

  deleteEmployment = employment_id => {
    // console.log(employment_id)
    if (employment_id) {
      Api.deleteEmployment(employment_id, global._token)
        .then(json => {
          if (json.ok) {
            toast.success("employment deleted!", {
              className: "alert alert-success"
            });
            // this.setState(prevState => ({
            //   data: prevState.data.filter(item => item._id !== id)
            // }));
          } else {
            throw new Error("delete employment failed");
          }
        })
        .catch(e => {
          toast.error(e.message, { className: "alert alert-danger" });
        });
    }
  };

  renderEmployeeList = () => {
    let re = [];
    this.state.data.forEach((element, index) => {
      re.push(
        <tr key={element._id}>
          <td onClick={this.employeeOnClick.bind(this, element)}>
            {element.firstName + " " + element.lastName}
          </td>
          <td onClick={this.employeeOnClick.bind(this, element)}>
            {"+" + element.mobile}
          </td>
          <td onClick={this.employeeOnClick.bind(this, element)}>
            {new Date(element.createdAt).toLocaleString(
              i18n.language,
              dateDisplayOption
            )}
          </td>
          <td>
            {this.state.employmentData[index].e_id && (
              <ConfirmButton
                confirmHandler={() => {
                  this.deleteEmployment(this.state.employmentData[index]._id);
                  // console.log(element)
                }}
                className="btn btn-link text-muted"
              >
                 {i18n.t("jobManage.disconnect")}
              </ConfirmButton>
            )}
          </td>
        </tr>
      );
    });
    return re;
  };

  render() {
    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">{i18n.t("tabTitles.employee")}</h1>
        </div>

        <div className="bg-white p-3 shadow-sm">
          <div className="form-row my-3">
            <form
              className="input-group col-12 col-md-6"
              onSubmit={this.handleSearch}
            >
              <div className="input-group-prepend">
                <span className="input-group-text bg-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8" />
                    <line x1="21" y1="21" x2="16.65" y2="16.65" />
                  </svg>
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                value={this.state.searchValue}
                placeholder={i18n.t("jobManage.search-name-phone")}
                onChange={v => this.setState({ searchValue: v.target.value })}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="submit"
                  id="button-addon2"
                >
                  {i18n.t("jobManage.search")}
                </button>
              </div>
            </form>

            <div className="col-12 col-md-3  offset-md-3 d-none d-md-block">
              <select
                className="form-control"
                value={this.state.fetchLength}
                onChange={v =>
                  this.setState(
                    {
                      fetchLength: parseInt(v.target.value, 10),
                      tab0page: 1,
                      forceTab0Page: null
                    },
                    () => {
                      this.fetchEmplopyeeList(1, true);
                    }
                  )
                }
              >
                <option value={15}>15 {i18n.t("results")}</option>
                <option value={30}>30 {i18n.t("results")}</option>
                <option value={50}>50 {i18n.t("results")}</option>
              </select>
            </div>
          </div>
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th scope="col">{i18n.t("employee.name")}</th>
                <th scope="col">{i18n.t("jobManage.applicant-phone")}</th>
                <th scope="col">{i18n.t("employee.date")}</th>
                <th scope="col">{i18n.t("action")}</th>
              </tr>
            </thead>
            <tbody>{this.renderEmployeeList()}</tbody>
          </table>
          <div className="pagination">
            <ReactPaginate
              previousLabel={i18n.t("paginate.previous")}
              nextLabel={i18n.t("paginate.next")}
              breakLabel={"..."}
              pageCount={this.state.pageCount}
              forcePage={this.state.forceTab0Page}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.onPageChange}
              containerClassName={"pagination"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              disabledClassName={"disabled"}
              pageClassName={"page-item"}
              previousClassName={"page-item"}
              nextClassName={"page-item"}
              pageLinkClassName={"page-link"}
              activeClassName={"active"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
            />
          </div>
        </div>
        <Dialog
          isOpen={this.state.showEmployeeDetail}
          onDismiss={this.toggleModal.bind(this, null)}
          style={{ width: "90vw" }}
        >
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="close"
              onClick={this.toggleModal.bind(this, null)}
            >
              <VisuallyHidden>Close</VisuallyHidden>
              <CloseIcon />
            </button>
          </div>
          {Object.keys(this.state.employeeDetail).length > 0 ? (
            <EmployeeDetail
              icon={this.state.employeeDetail.icon}
              employeeName={
                this.state.employeeDetail.firstName +
                " " +
                this.state.employeeDetail.lastName
              }
              additionalSkills={this.state.employeeDetail.skillsDetail}
              address={this.state.employeeDetail.address.name}
              email={this.state.employeeDetail.email}
              introduction={this.state.employeeDetail.introduction}
              phone={this.state.employeeDetail.mobile}
              resume={this.state.employeeDetail.resume}
            >
              <Link
                className="btn btn-primary btn-sm"
                to={"/employer/chat/" + this.state.employeeDetail._id}
              >
                {i18n.t("jobManage.startChat")}
              </Link>
            </EmployeeDetail>
          ) : null}
        </Dialog>
      </div>
    );
  }
}

const dateDisplayOption = {
  year: "numeric",
  month: "numeric",
  day: "numeric"
};
