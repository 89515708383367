import React, { Component } from "react";
import { Dialog } from "@reach/dialog";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { toast } from "react-toastify";
import * as Yup from "yup";
import VisuallyHidden from "@reach/visually-hidden";
import * as Api from "./Api";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ConfirmButton } from "./ConfirmButton";
import { i18n } from "./i18n";
import CloseIcon from './component/CloseIcon';
const addressSchema = Yup.object().shape({
  description: Yup.string()
    .required()
    .min(1),
  latitude: Yup.number()
    .required()
    .notOneOf([0], "Invalid address"),
  longitude: Yup.number()
    .required()
    .notOneOf([0], "Invalid address"),
  isBranch: Yup.boolean()
    .required()
    .default(false),
  branchName: Yup.string().when("isBranch", {
    is: false,
    then: Yup.string().notRequired(),
    otherwise: Yup.string()
      .required()
      .min(1)
  }),
  unit: Yup.string()
});
export default class AddressBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /** change when user edit or add new address */
      update: 0,
      showDialog: false,
      data: [],
      addressValue: ""
    };
    this.mode = "";
    this.initialValues = {
      description: "",
      latitude: 0,
      longitude: 0,
      unit: "",
      isBranch: false,
      branchName: ""
    };
  }
  componentDidMount() {
    requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
    this.getAddressList();
    // get address list
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.update !== this.state.update) {
      // get address list
      this.getAddressList();
    }
  }
  getAddressList = () => {
    Api.getAddressList(global._token, 1000).then(response => {
      if (response && response.total) {
        this.setState({ data: response.items });
      }
    });
  };
  handleAddButtonClick = () => {
    // open dialog
    this.setState({ showDialog: true });
    this.mode = "add";
  };

  handleEditButtonClick = address => {
    // open dialog and prepopulate data
    this.setState({
      showDialog: true,
      addressValue: address.addressName ? address.addressName : ""
    });
    this.initialValues = {
      description: address.addressName ? address.addressName : "",
      latitude:
        address.location &&
        address.location.coordinates &&
        address.location.coordinates[1]
          ? address.location.coordinates[1]
          : 0,
      longitude:
        address.location &&
        address.location.coordinates &&
        address.location.coordinates[0]
          ? address.location.coordinates[0]
          : 0,
      unit: address.addressUnit ? address.addressUnit : "",
      isBranch: address.isBranch ? address.isBranch : false,
      branchName: address.addressTitle ? address.addressTitle : ""
    };
    this.mode = "edit";
    this.editId = address._id;
  };

  handleCreateAddress = (values, setSubmitting) => {
    const payload = {
      addressName: values.description,
      addressUnit: values.unit,
      location: {
        type: "Point",
        coordinates: [values.longitude, values.latitude]
      },
      isBranch: values.isBranch,
      ...(values.isBranch && { addressTitle: values.branchName })
    };
    Api.createAddress(payload, global._token)
      .then(response => {
        if (response._id) {
          toast.success("address created!", {
            className: "alert alert-success"
          });
          this.setState(prevState => ({
            showDialog: false,
            update: prevState.update + 1
          }));
          setSubmitting(false);
        } else {
          throw new Error("create address failed");
        }
      })
      .catch(e => {
        toast.error(e.message, { className: "alert alert-danger" });
        setSubmitting(false);
      });
  };

  handleUpdateAddress = (values, setSubmitting) => {
    if (this.editId) {
      const payload = {
        addressName: values.description,
        addressUnit: values.unit,
        location: {
          type: "Point",
          coordinates: [values.longitude, values.latitude]
        },
        isBranch: values.isBranch,
        ...(values.isBranch && { addressTitle: values.branchName })
      };
      Api.updateAddress(this.editId, payload, global._token)
        .then(response => {
          if (response._id) {
            toast.success("address updated!", {
              className: "alert alert-success"
            });
            this.setState(prevState => ({
              showDialog: false,
              update: prevState.update + 1
            }));
            setSubmitting(false);
          } else {
            throw new Error("update address failed");
          }
        })
        .catch(e => {
          toast.error(e.message, { className: "alert alert-danger" });
          setSubmitting(false);
        });
    }
  };

  handleDeleteClick = id => {
    Api.deleteAddress(id, global._token)
      .then(response => {
        if (response.ok) {
          toast.success("address deleted!", {
            className: "alert alert-success"
          });
          this.setState(prevState => ({
            showDialog: false,
            update: prevState.update + 1
          }));
        } else {
          throw new Error("delete address failed");
        }
      })
      .catch(e => {
        toast.error(e.message, { className: "alert alert-danger" });
      });
  };

  render() {
    return (
      <>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-2 pb-2 mb-3 border-bottom">
          <h1 className="h2 mb-0">{i18n.t("addressBook.title")}</h1>
          <button
            className="btn btn-primary my-2"
            type="button"
            onClick={this.handleAddButtonClick}
          >
            <span>{i18n.t("addressBook.add")}</span>
          </button>
        </div>
        <div className="row bg-white p-3 shadow-sm">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered table-striped table-sm table-hover align-middle">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col" className="text-nowrap">
                      {i18n.t("addressBook.table-address")}
                    </th>
                    <th scope="col">{i18n.t("addressBook.table-unit")}</th>
                    <th scope="col" className="text-nowrap">
                      {i18n.t("addressBook.table-branch")}
                    </th>
                    <th scope="col">{i18n.t("addressBook.table-action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.map((item, index) => (
                    <tr key={item._id}>
                      <td className="align-middle">{index + 1}</td>
                      <td className="align-middle text-nowrap">
                        {item.addressName}
                      </td>
                      <td className="align-middle text-nowrap">
                        {item.addressUnit ? item.addressUnit : "-"}
                      </td>
                      <td className="align-middle">
                        {item.isBranch ? item.addressTitle : "-"}
                      </td>
                      <td className="align-middle">
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            onClick={() => this.handleEditButtonClick(item)}
                            className="btn btn-link text-muted"
                          >
                            <span className="sr-only">
                              {i18n.t("addressBook.table-edit")}
                            </span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-edit-3"
                            >
                              <path d="M12 20h9" />
                              <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" />
                            </svg>
                          </button>
                          <ConfirmButton
                            confirmHandler={() =>
                              this.handleDeleteClick(item._id)
                            }
                            className="btn btn-link text-muted"
                          >
                            <span className="sr-only">
                              {i18n.t("addressBook.table-delete")}
                            </span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash-2"
                            >
                              <polyline points="3 6 5 6 21 6" />
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                              <line x1="10" y1="11" x2="10" y2="17" />
                              <line x1="14" y1="11" x2="14" y2="17" />
                            </svg>
                          </ConfirmButton>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Dialog
          isOpen={this.state.showDialog}
          onDismiss={() => this.setState({ showDialog: false })}
        >
          <button
            type="button"
            className="close"
            onClick={() => this.setState({ showDialog: false })}
          >
            <VisuallyHidden>Close</VisuallyHidden>
            <CloseIcon />
          </button>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h2>{i18n.t("addressDialog.update-address")}</h2>
          </div>
          <Formik
            validationSchema={addressSchema}
            initialValues={this.initialValues}
            onSubmit={(values, { setSubmitting }) => {
              if (this.mode === "add") {
                this.handleCreateAddress(values, setSubmitting);
              } else if (this.mode === "edit") {
                this.handleUpdateAddress(values, setSubmitting);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <Form>
                <Field
                  render={({ field, form }) => (
                    <>
                      <div className="form-group">
                        <label htmlFor="address">
                          {i18n.t("addressDialog.address-name")}
                        </label>
                        <PlacesAutocomplete
                          highlightFirstSuggestion={true}
                          value={this.state.addressValue}
                          id="address"
                          debounce={1000}
                          onChange={value => {
                            this.setState({
                              addressValue: value
                            });
                            form.setFieldValue("description", "");
                            form.setFieldValue("latitude", 0);
                            form.setFieldValue("longitude", 0);
                          }}
                          searchOptions={{
                            componentRestrictions: {
                              country: "ca"
                            },
                          }}
                          onSelect={(value, placeId) => {
                            if (!placeId) {
                            } else {
                              geocodeByAddress(value)
                                .then(result => {
                                  this.setState({
                                    addressValue: result[0].formatted_address
                                  });
                                  form.setFieldValue(
                                    "description",
                                    result[0].formatted_address
                                  );
                                  return getLatLng(result[0]);
                                })
                                .then(({ lat, lng }) => {
                                  form.setFieldValue("latitude", lat);
                                  form.setFieldValue("longitude", lng);
                                });
                            }
                          }}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading
                          }) => (
                            <div style={{ position: "relative" }}>
                              <input
                                {...getInputProps({
                                  placeholder: i18n.t(
                                    "addressDialog.address-name-placeholder"
                                  ),
                                  className:
                                    "form-control" +
                                    (errors.latitude && touched.latitude
                                      ? " is-invalid"
                                      : "")
                                })}
                              />
                              <div
                                className="card"
                                style={{
                                  position: "absolute",
                                  top: "100%",
                                  zIndex: 1,
                                  marginTop: 8,
                                  marginBottom: 8
                                }}
                              >
                                <div className="card rounded border-0">
                                  <div className="list-group">
                                    {loading && (
                                      <div className="list-group-item">
                                        Loading...
                                      </div>
                                    )}
                                    {suggestions.map(suggestion => {
                                      const className = suggestion.active
                                        ? "list-group-item active"
                                        : "list-group-item";
                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className
                                            }
                                          )}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        <ErrorMessage
                          className="invalid-feedback d-block"
                          name="latitude"
                          component="div"
                        />
                      </div>
                    </>
                  )}
                />

                <div className="form-group">
                  <label htmlFor="unit">
                    {i18n.t("addressDialog.address-unit")}
                  </label>
                  <Field
                    id="unit"
                    name="unit"
                    autoComplete="off"
                    className="form-control"
                  />
                  <ErrorMessage
                    className="invalid-feedback"
                    name="unit"
                    component="div"
                  />
                </div>
                <div className="form-group">
                  <div className="form-check">
                    <Field
                      name="isBranch"
                      className="form-check-input"
                      type="checkbox"
                      id="is-branch-checkbox"
                      checked={values.isBranch}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="is-branch-checkbox"
                    >
                      {i18n.t("addressDialog.address-is-branch")}
                    </label>
                    <ErrorMessage
                      className="invalid-feedback"
                      name="isBranch"
                      component="div"
                    />
                  </div>
                </div>
                {values.isBranch ? (
                  <div className="form-group">
                    <label htmlFor="branch-name">
                      {i18n.t("addressDialog.branch-name")}
                    </label>
                    <Field
                      id="branch-name"
                      name="branchName"
                      autoComplete="off"
                      className={
                        "form-control" +
                        (errors.branchName && touched.branchName
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      className="invalid-feedback"
                      name="branchName"
                      component="div"
                    />
                  </div>
                ) : null}
                <div className="d-flex justify-content-end">
                  <button className="btn btn-link" type="button">
                    {i18n.t("addressDialog.cancel")}
                  </button>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {i18n.t("addressDialog.save")}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Dialog>
      </>
    );
  }
}
