import React from "react"
import config from "./config"
import NetUtil from "./NetUtil"
import { throttle } from "lodash"
import { ActivityIndicator } from "./PageComponents"
import { formatDistance } from "date-fns";
import { i18n } from "./i18n"
import ReactPaginate from "react-paginate";
import { enUS, zhCN } from "date-fns/locale";

const fetchLength = 10
const widthPivot = 680

export default class MySchedule extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         pastEvents: [],
         upcomingEvent: [],
         selectedTab: 1,
         isLoading: false,
         eventDetail: {},
         eventContentHeight: 0,
         windowWidth: 0,
         isShowingWideContent: true,
         totalPastEvents: 0,
         totalUpcomingEvents: 0,
      }
      this.setWindowWidthHeight = throttle(this.setWindowWidthHeight.bind(this), 350, {trailing: true, leading: true})
   }

   componentDidMount() {
      this.setState({ isLoading: true }, () => {
         let p1 = this.fetchPastEvent(1)
         let p2 = this.fetchUpcomingEvent(1)
         Promise.all([p1, p2]).then(() => {
            this.setState({ isLoading: false })
         })
      })

      // initial height
      this.setWindowWidthHeight()

      // on window size change
      window.addEventListener("resize", this.setWindowWidthHeight)
   }

   setWindowWidthHeight(){
      // window width
      this.setState({windowWidth: window.innerWidth, isShowingWideContent: window.innerWidth - widthPivot > 0 ? true : false})

      // tab height
      let tabHeader = document.getElementById("myScheduleTabHeader")
      if (tabHeader){
         let eventContentHeight = window.innerHeight - tabHeader.offsetTop - tabHeader.offsetHeight - 1
         this.setState({ eventContentHeight: eventContentHeight })
      }
   }

   fetchPastEvent(page) {
      let url = config.apiurl + "v1/event"
      let params = {
         page: page,
         limit: fetchLength,
         mode: "before",
      }
      return NetUtil.get(url, params, global._token).then((result) => {
         console.log(result)
         if (result.hasOwnProperty("err_msg")) {
            // handle error
         } else {
            this.setState({ 
               pastEvents: this.convertDataToObject(result.items),
               totalPastEvents: result.total,
               pastEventInitialPage: page - 1
            })
         }
      })
   }

   fetchUpcomingEvent(page) {
      let url = config.apiurl + "v1/event"
      let params = {
         page: page,
         limit: fetchLength,
      }
      return NetUtil.get(url, params, global._token).then((result) => {
         console.log(result)
         if (result.hasOwnProperty("err_msg")) {
            // handle error
         } else {
            this.setState({ 
               upcomingEvent: this.convertDataToObject(result.items),
               totalUpcomingEvents: result.total,
               upcomingEventInitialPage: page - 1
            })
         }
      })
   }

   showEventDetail = (item) => {
      if (Object.keys(this.state.eventDetail).length === 0 || this.state.eventDetail._id !== item._id)
      this.setState({ eventDetail: item })
   }

   convertDataToObject(data){
      data.forEach((event) => {
         // handle corrupted data
         if (!event.application.InterviewAddress.hasOwnProperty("name")){
            event.application.InterviewAddress["name"] = event.application.InterviewAddress.description
            event.application.InterviewAddress["location"] = {}
            event.application.InterviewAddress.location["coordinates"] = [event.application.InterviewAddress.longitude, event.application.InterviewAddress.latitude]
         }
      })
      return data
   }

   changeTab = (index) => {
      if (this.state.selectedTab !== index){
         this.setState({
            selectedTab: index,
            eventDetail: {},
         })
      }
   }

   render() {
      if (this.state.isShowingWideContent){
         return (
            <div>
               <h1 id="myScheduleTitle" className="h2 py-3 border-bottom">{i18n.t("tabTitles.schedule")}</h1>
               <ul className="nav nav-tabs" id="myScheduleTabHeader">
                  <li className="nav-item" onClick={()=>{this.changeTab(1)}}>
                     <a className={this.state.selectedTab === 1 ? "nav-link active" : "nav-link"} href="#">{i18n.t("schedule.upcomingEvent")}</a>
                  </li>
                  <li className="nav-item" onClick={()=>{this.changeTab(2)}}>
                     <a className={this.state.selectedTab === 2 ? "nav-link active" : "nav-link"} href="#">{i18n.t("schedule.pastEvent")}</a>
                  </li>
               </ul>

               {this.state.isLoading ?
                  <div className="d-flex justify-content-center mt-3"> <ActivityIndicator /></div>
               :
                  <>
                     <div className="d-flex">
                        <div style={{ height: this.state.eventContentHeight, overflowY: "auto" }} className="col-5 p-0 pr-3">
                           { this.state.selectedTab === 1 ?
                              <EventList 
                                 key={"upcoming"} 
                                 mode={"wide"} 
                                 onClick={this.showEventDetail} 
                                 title={"Upcoming Event"} 
                                 data={this.state.upcomingEvent}
                                 initialPage={this.state.upcomingEventInitialPage}
                              />
                           :
                              <EventList 
                                 key={"past"} 
                                 mode={"wide"} 
                                 onClick={this.showEventDetail} 
                                 title={"Past Event"} 
                                 data={this.state.pastEvents}
                                 initialPage={this.state.pastEventInitialPage}
                              />
                           }
                        </div>
         
                        <div style={{ height: this.state.eventContentHeight, overflowY: "auto" }} className="col-7 p-0">
                           { Object.keys(this.state.eventDetail).length !== 0 ?
                              <EventDetail data={this.state.eventDetail}/>
                           :
                              null
                           }
                        </div>
                     </div>
                  </>
               }
            </div>
         )
      } else {
         return (
            <div>
               <h1 id="myScheduleTitle" className="mb-0 mt-2 h2 pb-3 mb-3 border-bottom">{i18n.t("tabTitles.schedule")}</h1>
               <ul className="nav nav-tab m-0 justify-content-around bottom-shadow" id="myScheduleTabHeader">
                  <li style={{width: "50%"}} className="nav-item d-flex justify-content-center" onClick={()=>{this.changeTab(1)}}>
                     <a className={this.state.selectedTab === 1 ? "nav-link active border-bottom border-primary" : "nav-link "} href="#">
                        {i18n.t("schedule.upcomingEvent")}
                     </a>
                  </li>
                  <li style={{width: "50%"}} className="nav-item d-flex justify-content-center" onClick={()=>{this.changeTab(2)}}>
                     <a className={this.state.selectedTab === 2 ? "nav-link active border-bottom border-primary" : "nav-link "} href="#">
                        {i18n.t("schedule.pastEvent")}
                     </a>
                  </li>
               </ul>

               <div style={{height: this.state.eventContentHeight, overflowY: "auto"}}>
                  {this.state.isLoading ?
                     <div className="d-flex justify-content-center mt-3"> <ActivityIndicator /></div>
                  : this.state.selectedTab === 1 ?
                     <EventList 
                        key={"upcoming"} 
                        mode={"slim"} 
                        onClick={this.showEventDetail} 
                        title={"Upcoming Event"} 
                        data={this.state.upcomingEvent}
                        pageCount={Math.ceil(this.state.totalUpcomingEvents / fetchLength)}
                        onPageChange={(data) => { this.fetchUpcomingEvent(data.selected + 1)}}
                        initialPage={this.state.upcomingEventInitialPage}
                     />
                  :
                     <EventList 
                        key={"past"} 
                        mode={"slim"} 
                        onClick={this.showEventDetail} 
                        title={"Past Event"} 
                        data={this.state.pastEvents} 
                        pageCount={Math.ceil(this.state.totalPastEvents / fetchLength)}
                        onPageChange={(data) => { this.fetchPastEvent(data.selected + 1)}}
                        initialPage={this.state.pastEventInitialPage}
                     />
                  }
               </div>

            </div>
         )
      }
   }
}

class EventList extends React.Component {
   constructor(props){
      super(props)
      this.state={
         activeItem: -1,
      }
   }

   eventItemOnClick = (index) => {
      this.setState({activeItem: index})
      this.props.onClick(this.props.data[index])
   }

   renderEventItemList() {
      let re = []
      this.props.data.forEach((item, index) => {
         re.push(
            <EventItem
               key={item._id}
               type={item.eventType}
               companyName={item.job.companyName}
               jobTitle={item.application.jobTitle}
               date={item.application.interviewTime}
               companyIcon={item.job.companyIcon}
               contactName={item.application.employeeName}
               contactNumber={item.application.employeeId.mobile}
               address={item.application.InterviewAddress.name}
               active={index === this.state.activeItem}
               onClick={() => {this.eventItemOnClick(index)}}
               mode={this.props.mode}
               index={index}
            />
         )
      })
      return re
   }

   render() {
      return (
         <div className="mt-3 p-0">
            {this.props.data.length > 0 ?
               <>
                  <ul className="p-0 mt-0 mb-3 mt-1 list-unstyled">
                     {this.renderEventItemList()}
                  </ul>
                  { this.props.pageCount > 1 ? 
                     <div className="d-flex justify-content-center">
                        <ReactPaginate
                           initialPage={this.props.initialPage}
                           previousLabel={i18n.t("paginate.previous")}
                           nextLabel={i18n.t("paginate.next")}
                           breakLabel={"..."}
                           pageCount={this.props.pageCount}
                           marginPagesDisplayed={1}
                           pageRangeDisplayed={0}
                           onPageChange={this.props.onPageChange}
                           containerClassName={"pagination"}
                           breakClassName={"page-item"}
                           breakLinkClassName={"page-link"}
                           disabledClassName={"disabled"}
                           pageClassName={"page-item"}
                           previousClassName={"page-item"}
                           nextClassName={"page-item"}
                           pageLinkClassName={'page-link'}
                           activeClassName={"active"}
                           previousLinkClassName={'page-link'}
                           nextLinkClassName={'page-link'}
                        />
                     </div>
                  :
                     null
                  }
               </>
               :
               <div>
                  Empty
               </div>
            }
         </div>
      )
   }
}

class EventItem extends React.Component {
   render() {
      if (this.props.mode === "wide"){
         return (
            <li className={this.props.active ? "mt-2 p-2 border rounded border-primary bg-white" : "mt-2 p-2 rounded border bg-light"} onClick={() => {this.props.onClick(this.props.index)}}>
               <div className="container">
                  <div className={"row"}>
                     <div className={"m-0 p-0 col-3 d-flex flex-column justify-content-center align-items-center"}>
                        <div className="font-size-1-5">
                           {new Date(this.props.date).toLocaleString(i18n.language, {month: "short"})}
                        </div>
                        <div className="font-size-1-3">
                           {new Date(this.props.date).toLocaleString(i18n.language, {day: "2-digit"})}
                        </div>
                     </div>
                     <div className={"col-9"}>
                        <div className="mt-1 font-size-1-3">
                           {i18n.t("schedule.eventType."+this.props.type)}
                        </div>
                        <div className="mt-1">
                           {this.props.contactName}
                        </div>
                        <div className="mt-1">
                           {this.props.contactNumber}
                        </div>
                        <div className="mt-1">
                           {this.props.address}
                        </div>
                     </div>
                  </div>
               </div>
            </li>
         )
      } else {
         return (
            <li className={"rounded card-shadow p-3 m-1 mt-3"} onClick={() => {this.props.onClick(this.props.index)}}>

               <div className="d-flex justify-content-end">
                  <div>{new Date(this.props.date).toLocaleString(i18n.language, displayFullDate)}</div>
               </div>

               <div className="font-size-1-3">
                  {i18n.t("schedule.eventType."+this.props.type)}
               </div>

               <div className={"d-flex align-items-center mt-1"}>
                  <img className="mr-3" style={{maxWidth: 40}} src={this.props.companyIcon} alt="Company Logo"/>
                  <div>{this.props.companyName}</div>
               </div>
               
               <div className={"mt-3"}>
                  {this.props.jobTitle}
               </div>

               <div className={"mt-1"}>
                  {this.props.contactName}
               </div>
               
               <div className={"mt-1"}>
                  {this.props.contactNumber}
               </div>

               <div className={"mt-1"}>
                  {this.props.address}
               </div>
            </li>
         )
      }
   }
}

class EventDetail extends React.Component {
   render() {
      return (
         <div className="rounded border mt-3">
            <div className="d-flex justify-content-center border-bottom p-3">
               {i18n.t("schedule.eventType." + this.props.data.eventType)}
            </div>

            <div className="p-3 border-bottom">
               <div>
                  {i18n.t("schedule.interviewer")}
               </div>
               <div>
                  {i18n.t("schedule.name")}: {this.props.data.application.interviewContact}
               </div>
               <div>
                  {i18n.t("schedule.contact")}: {this.props.data.application.interviewPhoneNumber}
               </div>
            </div>

            <div className="p-3 border-bottom">
               <div>
                  {i18n.t("schedule.interviewee")}
               </div>
               <div>
                  {i18n.t("schedule.name")}: {this.props.data.application.employeeName}
               </div>
               <div>
                  {i18n.t("schedule.contact")}: {this.props.data.application.employeeId.mobile}
               </div>
            </div>

            <div className="p-3 border-bottom">
               <div>
                  {i18n.t("schedule.interviewTime")}
               </div>
               <div>
                  {i18n.t("schedule.duration")}: {this.props.data.application.interviewDuration + i18n.t("minute")} 
               </div>
               <div>
                  {i18n.t("schedule.startingTime")}: {new Date(this.props.data.application.interviewTime).toLocaleString(i18n.language, displayFullDate) + ", "} 
                  {formatDistance(
                     new Date(this.props.data.application.interviewTime), 
                     Date.now(), 
                     {locale: i18n.language === "zh" ? zhCN :enUS}
                  )}
                  {new Date().getTime() > new Date(this.props.data.application.interviewTime) ? i18n.t("schedule.ago") : i18n.t("schedule.fromNow")}
               </div>
            </div>

            <div className="p-3 border-bottom">
               {i18n.t("schedule.location")}: {this.props.data.application.InterviewAddress.name}
            </div>

            <div style={{width: "100%", height: 300}}>
               <img src={
                  "https://maps.googleapis.com/maps/api/staticmap?center=" + 
                  this.props.data.application.InterviewAddress.location.coordinates[1] + "," + 
                  this.props.data.application.InterviewAddress.location.coordinates[0] + 
                  "&zoom=15&size=500x280&maptype=roadmap&markers=color:green%7Clabel:T%7C" +
                  this.props.data.application.InterviewAddress.location.coordinates[1] + "," + 
                  this.props.data.application.InterviewAddress.location.coordinates[0] + 
                  "&key=" + config.googleAPIKey
               } 
               alt="interview location map"
               style={{width: "100%", height: "100%"}}
            />
            </div>
         </div>
      )
   }
}

const displayFullDate = {
   year: "numeric",
   month: "numeric",
   day: "numeric",
   hour: "numeric",
   minute: "numeric"
}