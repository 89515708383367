import React, { Component } from "react";
import { i18n } from "../i18n";
import * as Api from '../Api'
class MessageBanner extends Component {
  file = null;
  fileRef = React.createRef();
  handleFileUpload = e => {
    switch (e.target.name) {
      case "selectedFile":
        if (e.target.files.length > 0) {
          // Accessed .name from file
          const reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          this.file = e.target.files[0];
          if (this.file) {
            Api.uploadChat(this.file, this.props.selectedUser.user._id, global._token);
          }
        }
        break;
      default:
    }
  };
  handleSendFile = () => {
    if (this.fileRef.current) {
      this.fileRef.current.click();
    }
  };
  render() {
    return (
      <>
        <div
          style={{ height: 50 }}
          className="border-bottom d-flex align-items-center justify-content-between px-4"
        >
          <div>
            {/* <img
              alt='Message banner'
              src={this.props.selectedUser.user.icon}
              className="rounded-circle mr-1 mt-1"
              style={{ height: 35, width: 35 }}
            /> */}
            {/* <span className="ml-2">
              {this.props.selectedUser.user.roles[0] === "employer"
                ? this.props.selectedUser.user.companyName
                : this.props.selectedUser.user.firstName +
                  " " +
                  this.props.selectedUser.user.lastName}
            </span> */}

            <span className="ml-2">
              {!this.props.selectedUser.user ? ''
              :
              ((this.props.selectedUser.user.roles || []).length > 0 && this.props.selectedUser.user.roles[0] === "employer")
                ? this.props.selectedUser.user.companyName
                : this.props.selectedUser.user.firstName +
                  " " +
                  this.props.selectedUser.user.lastName}
            </span>
          </div>
          <input
            type="file"
            aria-describedby="inputGroupFileAddon01"
            onChange={this.handleFileUpload}
            accept=".jpg, .jpeg, .png"
            name="selectedFile"
            style={{ display: "none" }}
            ref={this.fileRef}
          />
          <button className="btn btn-link" onClick={this.handleSendFile}>
            {i18n.t(`chat.send-file`)}
          </button>
        </div>
      </>
    );
  }
}

export default MessageBanner;
