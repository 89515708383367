import React from "react";
import * as Api from "./Api";
import { i18n } from "./i18n";
import ReactPaginate from "react-paginate";
import { Link, navigate } from "@reach/router";
import { toast } from "react-toastify";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ConfirmButton } from "./ConfirmButton";
import { enUS, zhCN } from "date-fns/locale";
export default class Draft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      offset: 1,
      total: -1,
      pageSize: 15,
      pageCount: 0
      // forcePage: null,
      // searchValue: ""
    };
  }
  componentDidMount() {
    this.loadDataFromServer();
  }
  loadDataFromServer = () => {
    Api.getDraft(this.state.offset, this.state.pageSize, global._token).then(
      json => {
        if (json.items) {
          this.setState({
            data: json.items,
            pageCount: Math.ceil(json.total / this.state.pageSize),
            total: json.total
            // forcePage: forcePage ? 0 : undefined
          });
        }
      }
    );
  };
  handleRowClick = event => {
    if (event.target.dataset.draft) {
      navigate("/employer/post-draft/" + event.target.dataset.draft);
    }
  };

  handleDraftDelete = id => {
    if (id) {
      Api.deleteDraft(id, global._token)
        .then(json => {
          if (json.ok) {
            toast.success("draft deleted!", {
              className: "alert alert-success"
            });
            this.setState(prevState => ({
              data: prevState.data.filter(item => item._id !== id)
            }));
          } else {
            throw new Error("delete draft failed");
          }
        })
        .catch(e => {
          toast.error(e.message, { className: "alert alert-danger" });
        });
    }
  };
  render() {
    let locale;
    if (i18n.language === "zh") {
      locale = zhCN;
    } else {
      locale = enUS;
    }
    return (
      <>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">{i18n.t("draft.title")}</h1>
          <div>
            <Link to="/employer/post" className="btn btn-primary my-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-send"
              >
                <line x1="22" y1="2" x2="11" y2="13" />
                <polygon points="22 2 15 22 11 13 2 9 22 2" />
              </svg>
              <span className="ml-1">{i18n.t("draft.add")}</span>
            </Link>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered table-striped table-sm table-hover align-middle">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{i18n.t("draft.updatedAt")}</th>
                <th scope="col">{i18n.t("draft.action")}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map((item, index) => (
                <tr key={item._id}>
                  <th
                    className="align-middle"
                    onClick={this.handleRowClick}
                    data-draft={item._id}
                  >
                    {index + 1 + (this.state.offset - 1) * this.state.pageSize}
                  </th>
                  <td
                    className="align-middle"
                    onClick={this.handleRowClick}
                    data-draft={item._id}
                  >
                    {formatDistanceToNow(new Date(item.updatedAt), {
                      locale: locale,
                      addSuffix: true
                    })}
                  </td>
                  <td className="align-middle">
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={this.handleRowClick}
                      data-draft={item._id}
                    >
                      {i18n.t("draft.edit")}
                    </button>
                    <ConfirmButton
                      className="btn btn-link text-danger mx-1 btn-sm"
                      confirmHandler={() => this.handleDraftDelete(item._id)}
                    >
                      {i18n.t("draft.delete")}
                    </ConfirmButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <ReactPaginate
          previousLabel={i18n.t("paginate.previous")}
          nextLabel={i18n.t("paginate.next")}
          breakLabel={"..."}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          // forcePage={this.state.forcePage}
          onPageChange={this.handlePageClick}
          containerClassName={"pagination"}
          breakClassName={"page-item"}
          disabledClassName={"disabled"}
          pageClassName={"page-item"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          breakLinkClassName="page-link"
          pageLinkClassName={"page-link"}
          activeClassName={"active"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
        />
      </>
    );
  }
}
