import axios from "axios";
import config from "./config";
import { toast } from "react-toastify";

const prefix = config.apiurl;
const client = axios.create({
  baseURL: prefix
});
const handleError = error => {
  if (error.response) {
    console.log(error.response.data.err_msg);
    if (error.response.data && error.response.data.err_msg) {
      toast.error(error.response.data.err_msg, {
        className: "alert alert-danger"
      });
    } else {
      toast.error(
        "Something is wrong in our end, please try again later: Net",
        {
          className: "alert alert-danger"
        }
      );
    }
  } else if (error.request) {
    toast.error("Somthing is wrong in our end, please try again later: Leaf", {
      className: "alert alert-danger"
    });
  } else {
    toast.error("Somthing is wrong in our end, please try again later: SLeaf", {
      className: "alert alert-danger"
    });
  }
  throw error;
};

const handleResponse = response => response.data;

export const login = payload =>
  client
    .post(`v1/employer/login`, { ...payload })
    .catch(handleError)
    .then(handleResponse);

export const sendCodeForLogin = payload =>
  client
    .post(`v1/employer/sendCode/simplelogin`, { ...payload })
    .catch(handleError)
    .then(handleResponse);
export const sendCodeForEditProfile = (payload, token) =>
  client
    .post(
      `v1/employer/sendCode/editprofile`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .catch(handleError)
    .then(handleResponse);
export const sendCodeForRegister = payload =>
  client
    .post(`v1/employer/sendCode/register`, { ...payload })
    .catch(handleError)
    .then(handleResponse);

export const register = payload =>
  client
    .post(`v1/employer/register`, { ...payload })
    .catch(handleError)
    .then(handleResponse);

export const postJob = (payload, token) =>
  client
    .post(
      `v1/job`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .catch(handleError)
    .then(handleResponse);

export const editJob = (id, payload, token) =>
  client
    .put(
      `v1/job/${id}`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .catch(handleError)
    .then(handleResponse);

export const getJobDetail = (id, token) =>
  client
    .get(`v1/job/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const getCommonJobDetail = id =>
  client
    .get(`v1/common/job/${id}`)
    .catch(handleError)
    .then(handleResponse);

export const getDraftDetail = (id, token) =>
  client
    .get(`v1/common/draft/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const getJoblistCreatedByMe = (
  page,
  pageSize,
  search,
  isActive,
  token
) =>
  client
    .get(`v1/getJoblistCreatedByMe?`, {
      params: {
        page,
        limit: pageSize,
        search: search,
        ...(isActive !== 0 &&
          (isActive === 1 ? { isActive: 1 } : { isActive: 0 }))
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const getCompanyInfo = token =>
  client
    .get(`v1/employer/getMyCompanyInfo?locale=en`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const changeAccount = (payload, token) =>
  client
    .post(
      `v1/employer/changeAccount`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .catch(handleError)
    .then(handleResponse);

export const editMyCompanyInfo = (payload, token) =>
  client
    .put(
      `v1/employer/editMyCompanyInfo`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .catch(handleError)
    .then(handleResponse);

export const getApplicationDetail = (id, token) =>
  client
    .get(`v1/application/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const getDraft = (page, pageSize, token) =>
  client
    .get(`v1/common/draft?page=${page}&limit=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const updateApplication = (id, payload, token) =>
  client
    .put(
      `v1/application/${id}`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .catch(handleError)
    .then(handleResponse);

export const getApplicationList = (jobId, params, token) =>
  client
    .get(`v1/application_list_mobile/`, {
      params: {
        jobId,
        ...params
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const getAddressList = (token, limit = 10) =>
  client
    .get(`v1/address`, {
      params: {
        limit: limit,
        branch_first: true
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const createAddress = (payload, token) =>
  client
    .post(
      `v1/address`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .catch(handleError)
    .then(handleResponse);

export const updateAddress = (id, payload, token) =>
  client
    .put(
      `v1/address/${id}`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .catch(handleError)
    .then(handleResponse);

export const deleteAddress = (id, token) =>
  client
    .post(`v1/address/${id}/delete`, null, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const deletePay = (period, token) =>
  client
    .post(`v1/pay/${period}/delete`, null, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    // .catch(handleError)
    .then(handleResponse);

export const deleteEmployeeBP = (processId, token) =>
  client
    .post(`/v1/employeeBP/deleteBP2/${processId}`, null, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    // .catch(handleError)
    .then(handleResponse);
export const deleteVacationPay = (period, token) =>
  client
    .post(`v1/vacation_pay/${period}/delete`, null, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    // .catch(handleError)
    .then(handleResponse);

export const deleteT4 = (period, token) =>
  client
    .post(`v1/tax/${period}/delete`, null, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const deleteWorkingHour = (period, token) =>
  client
    .post(`v1/working_hour/${period}/delete`, null, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const updateDraft = (id, payload, token) =>
  client
    .put(`v1/common/draft/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const createDraft = (payload, token) =>
  client
    .post(`v1/common/draft`, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const deleteDraft = (id, token) =>
  client
    .post(`v1/common/draft-delete/${id}`, null, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const uploadIcon = (file, token) => {
  var formData = new FormData();
  formData.append("image", file);
  return client
    .post(`v1/common/icon`, formData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);
};

export const uploadCSV = (file, token) => {
  var formData = new FormData();
  formData.append("pay", file);
  return client
    .post(`v1/pay`, formData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);
};

export const uploadVacationPayCSV = (import_type, file, token) => {
  var formData = new FormData();
  formData.append("vacation_pay", file);
  return (
    client
      .post(`v1/vacation_pay/${import_type}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      // .catch(handleError)
      .then(handleResponse)
  );
};
export const changeVacationPayBPDate = (
  employeeBPId,
  processStartTime,
  token
) => {
  var formData = new FormData();
  // formData.append("vacation_pay", file);
  return (
    // client
    //   .get(`/v1/employeeBP/changeBPDate/${employeeBPId}/${processStartTime}`, formData, {
    //     headers: {
    //       Authorization: `Bearer ${token}`
    //     }
    //   })
    //   // .catch(handleError)
    //   .then(handleResponse)
    client
      .get(`/v1/employeeBP/changeBPDate/${employeeBPId}/${processStartTime}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(handleResponse)
    // client
    // .get(`/v1/employeeBP/changeBPDate/${employeeBPId}/${processStartTime}`, formData, {
    //   headers: {
    //     Authorization: `Bearer ${token}`
    //   }
    // })
    // .catch(handleError)
    // .then(handleResponse)
  );
};

export const uploadT4 = (t4_period, file, token) => {
  var formData = new FormData();

  formData.append("tax_return", file);
  return (
    client
      .post(`v1/tax_return/${t4_period}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      // .catch(handleError)
      .then(handleResponse)
  );
};

export const uploadWorkingHour = (period, file, token) => {
  var formData = new FormData();
  formData.append("working_hour", file);
  return (
    client
      .post(`v1/working_hour/${period}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      // .catch(handleError)
      .then(handleResponse)
  );
};

export const checkToken = token =>
  client
    .get(`v1/common/checkToken`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(handleResponse);

export const getMessage = token =>
  client.get(`v1/message/unread`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const getCategory = token =>
  client
    .get(`v1/allcategory`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const getAllSkill = (token, params) =>
  client
    .get(`v1/skill`, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const getHiredApplication = (token, params) =>
  client
    .get(`v1/employment/all`, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const getTemplate = token =>
  client
    .get(`v1/allcategory_template`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const getPayPeroid = token =>
  client
    .get(`v1/pay_period`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const getPay = (peroid, page, pageSize, token) =>
  client
    .get(`v1/pay/${peroid}`, {
      params: {
        page,
        limit: pageSize
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    // .catch(handleError)
    .then(handleResponse);

export const getVacationPayPeroid = token =>
  client
    .get(`v1/vacation_pay_period`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

//getPay
export const getVacationPay4Employer = (
  processStatus,
  page,
  pageSize,
  token
) => {
  //peroid,
  //v1/pay/${peroid}
  return (
    client
      .get(`v1/employeeBP/getVacationPay4Employer/${processStatus}`, {
        ///${peroid}
        params: {
          page,
          limit: pageSize
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      // .catch(handleError)
      .then(handleResponse)
  );
};

//query the done vacation pay
export const getVacationPay = (peroid, page, pageSize, token) =>
  client
    .get(`v1/vacation_pay/${peroid}`, {
      params: {
        page,
        limit: pageSize
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    // .catch(handleError)
    .then(handleResponse);

export const getUploadedT4Peroid2 = token =>
  client
    .get(`v1/uploaded_tax_year`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const getT4 = (period, page, pageSize, token) =>
  client
    .get(`v1/tax_return/${period}`, {
      params: {
        page,
        limit: pageSize
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const getWorkingHour = (period, page, pageSize, token) =>
  client
    .get(`v1/working_hour/${period}`, {
      params: {
        page,
        limit: pageSize
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

// downloadT4
export const downloadT4 = (_id, token) =>
  axios(`${config.apiurl}v1/get_t4/${_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    responseType: "blob"
  })
    .catch(error => {
      console.log(error);
    })
    .then(response => {
      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    });

// export const previewT4 = (_id, token) => {
//   axios(`${config.apiurl}v1/get_t4/${_id}`, {
//     method: 'GET',
//     headers: { "Content-Type": "application/json", 'Authorization': "Bearer " + token },
//     responseType: 'blob'
//   }).then(response => {
//     //Create a Blob from the PDF Stream
//     const file = new Blob(
//       [response.data],
//       { type: 'application/pdf' });
//     const fileURL = URL.createObjectURL(file);
//     window.open(fileURL);
//   })
//     .catch(error => {
//       console.log(error);
//     });
// }

export const getContact = token =>
  client
    .get(`v1/chat/contact`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const getChatHistory = (contact, token) =>
  client
    .get(`v1/chat/history/${contact}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const getChatUser = (id, token) =>
  client
    .get(`v1/chat/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);

export const setMessageRead = (id, token) =>
  client
    .post(
      `v1/chat/read/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    // .catch(handleError)
    .then(handleResponse);

export const blackListUser = (employee, token) =>
  client
    .post(
      `v1/blacklist/${employee}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .catch(handleError)
    .then(handleResponse);

export const unblackListUser = (employee, token) =>
  client
    .post(
      `v1/unblacklist/${employee}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .catch(handleError)
    .then(handleResponse);

export const uploadChat = (file, target, token) => {
  var formData = new FormData();
  formData.append("image", file);
  return client
    .post(`v1/chat/upload/${target}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);
};

export const uploadFakeIcon = (file, token) => {
  var formData = new FormData();
  formData.append("image", file);
  return client
    .post(`v1/job/upload_icon`, formData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);
};

export const getRecommandJobs = (lng, lat, page) => {
  return client
    .get(`v1/common/recommend`, {
      params: {
        lng,
        lat,
        page,
        limit: 10
      }
    })
    .catch(handleError)
    .then(handleResponse);
};

export const searchRecommandJobs = params => {
  return client
    .get(`v1/common/search/recommend`, {
      params
    })
    .catch(handleError)
    .then(handleResponse);
};

export const deleteEmployment = (employment_id, token) => {
  return client
    .post(
      `v1/employment/${employment_id}/delete`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .catch(handleError)
    .then(handleResponse);
};

export const sendAnnouncement = (content, token, jobId = null) => {
  return client
    .post(
      `v1/employment/announcement`,
      { content: content, jobId: jobId },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .catch(handleError)
    .then(handleResponse);
};

export const getAnnouncement = (page, token, jobId = null) => {
  return client
    .get(`v1/employment/announcement`, {
      params: {
        page,
        limit: 10,
        jobId: jobId
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch(handleError)
    .then(handleResponse);
};

export const sendDownloadLink = phone => {
  return client
    .post(`v1/send-link`, {
      mobile: phone
    })
    .catch(handleError)
    .then(handleResponse);
};

export const getGlobalValue = () => {
  return client
    .get(`v1/common/global`)
    .then(handleResponse)
    .catch();
}
