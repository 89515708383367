import React, { PureComponent } from "react";
import format from "date-fns/format";
import { i18n } from "../i18n";
class MessageBoard extends PureComponent {
  chatRef = React.createRef();
  componentDidMount() {
    this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
  }
  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) {
      this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
    }
    if (
      this.props.selectedMessage &&
      this.props.selected === prevProps.selected &&
      this.props.selectedMessage.length !== prevProps.selectedMessage.length
    ) {
      this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
    }
  }
  render() {
    return (
      <>
        <div
          style={{ flexBasis: 1, flexGrow: 1 }}
          className="overflow-auto d-flex flex-column p-3"
          ref={this.chatRef}
        >
          {this.props.selectedMessage &&
            this.props.selectedMessage.map(chat => (
              <div
                key={chat._id}
                style={{ maxWidth: "60%" }}
                className={
                  "d-flex" +
                  (chat.to === this.props.selected
                    ? " align-self-end"
                    : " align-self-start")
                }
              >
                {chat.to === this.props.selected && (
                  <div
                    className="text-muted pr-1 mt-1"
                    style={{ fontSize: ".875rem" }}
                  >
                    {format(chat.time, "M/d H:mm")}
                  </div>
                )}
                {chat.to !== this.props.selected && (
                  <img
                    alt='Message board'
                    src={this.props.icon}
                    className="rounded-circle mr-1 mt-1"
                    style={{ height: 30, width: 30 }}
                  />
                )}
                {chat.type === 'file' ? (
                  <div
                    style={{ flex: 1 }}
                    className={
                      chat.to === this.props.selected
                        ? "flex bg-primary text-white py-2 px-3 rounded mt-1"
                        : " bg-light text-dark py-2 px-3 rounded mt-1"
                    }
                    style={{
                      // height: 200,
                      width: 200
                    }}
                  >
                    <img src={chat.content} className="mw-100"/>
                  </div>
                ) : (
                  <div
                    style={{ flex: 1 }}
                    className={
                      chat.to === this.props.selected
                        ? "flex bg-primary text-white py-2 px-3 rounded mt-1"
                        : " bg-light text-dark py-2 px-3 rounded mt-1"
                    }
                  >
                    {chat.content}
                  </div>
                )}
                {chat.to !== this.props.selected && (
                  <div
                    className="text-muted pl-1 mt-1"
                    style={{ fontSize: ".875rem" }}
                  >
                    {format(chat.time, "M/d H:mm")}
                  </div>
                )}
              </div>
            ))}
        </div>
      </>
    );
  }
}

export default MessageBoard;
