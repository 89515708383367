import React from "react";
import ReactPaginate from "react-paginate";
import HirineFooterLogo from "./img/HirineFooterLogo@3x.png";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";

import { ActivityIndicator } from "./PageComponents";
import JobCard from "./component/JobCard";
import Header from "./component/Header";
import { throttle } from "lodash";
import qs from "qs";
import JobListHead from "./component/JobListHead";
import { navigate } from "@reach/router";
import { i18n } from "./i18n";
import isSafeInteger from "lodash/isSafeInteger";
import * as Api from "./Api";
import isArray from "lodash/isArray";
import isNumber from "lodash/isNumber";

import SendDownloadLink from './component/SendDownloadLink'
const fetchLength = 10;

export default class Home extends React.Component {
  constructor(p) {
    super(p);
    this.state = {
      data: [],
      totalFound: 0,
      pageCount: 0,
      // isShowingSearchResults: false,
      isLoadingData: false,
      // blockUserInput: false,
      geoLocationPermission: true,
      currLng: "-79.7624", // user current location job search
      currLat: "43.7315", // user current location job search
      // search
      keyword: "",
      location: "",
      jobType: null,
      shiftType: "",
      distance: "",
      // salaryRange: [10, 100],
      minValue: -Infinity,
      maxValue: Infinity,

      page: 1
    };
    this.fetchSearchResult = throttle(this.fetchSearchResult, 1000);
  }

  componentDidMount() {
    if (
      this.state.keyword.length === 0 &&
      this.state.jobType === null &&
      this.state.minValue === -Infinity &&
      this.state.maxValue === Infinity
    ) {
      this.fetchRecommandedJobList(this.state.page);
    } else {
      this.fetchSearchResult(this.state.page);
    }
  }
  // parse query string from router object
  static getDerivedStateFromProps(props, state) {
    if (props.location.search !== state.prevPropsSearch) {
      const query = qs.parse(props.location.search, {
        ignoreQueryPrefix: true
      });
      // page number query
      let page = 1;
      const number = parseInt(query.page, 10);
      if (isSafeInteger(number) && number >= 1) {
        page = number;
      }

      //keyword query
      let keyword = "";
      if (query.keyword && query.keyword.length > 0) {
        keyword = query.keyword;
      }

      //job type query
      let type = null;
      if (query.type && query.type.length > 0) {
        type = query.type;
      }

      let minValue = -Infinity;
      if (query.min && query.min.length > 0) {
        const min = parseFloat(query.min);
        if (isNumber(min) && !isNaN(min)) {
          if (min >= 0) {
            minValue = min;
          } else {
            minValue = 0;
          }
        }
      }

      let maxValue = Infinity;
      if (query.max && query.max.length > 0) {
        const max = parseFloat(query.max);
        if (isNumber(max) && !isNaN(max) && max > 0) {
          maxValue = max;
        }
      }

      let location = "";
      if (query.location && query.location.length > 0) {
        location = query.location;
      }

      return {
        prevPropsSearch: props.location.search,

        page: page,
        keyword: keyword,
        jobType: type,
        minValue: minValue,
        maxValue: maxValue,
        location: location
      };
    }
    return null;
  }
  normalizeValue = () => {
    const min = parseFloat(this.state.minValue);
    const max = parseFloat(this.state.maxValue);
    const result = {
      minValue: -Infinity,
      maxValue: Infinity
    };
    let range = 0;
    if (isNumber(min) && !isNaN(min)) {
      if (min >= 0) {
        result.minValue = min;
        range++;
      } else {
        result.minValue = -Infinity;
      }
    } else {
      result.minValue = -Infinity;
    }
    if (isNumber(max) && !isNaN(max) && max > 0) {
      result.maxValue = max;
      if (max !== Infinity) {
        range++;
      }
    } else {
      result.maxValue = Infinity;
    }
    if (result.maxValue < result.minValue) {
      const temp = result.maxValue;
      result.maxValue = result.minValue;
      result.minValue = temp;
    }

    if (range !== 0) {
      if (result.maxValue > 100) {
        result.maxValue = 100;
      }
      if (result.minValue < 10) {
        result.minValue = 10;
      }
    }
    this.setState({
      maxValue: result.maxValue,
      minValue: result.minValue
    });

    return {
      maxValue: result.maxValue,
      minValue: result.minValue
    };
  };
  changeMinValue = event => {
    this.setState({ minValue: event.target.value });
  };

  changeMaxValue = event => {
    this.setState({ maxValue: event.target.value });
  };

  displayMoneyValue = value => {
    if (value === -Infinity || value === Infinity) {
      return "";
    } else {
      return value;
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search !== prevProps.location.search) {
      if (
        this.state.keyword.length === 0 &&
        this.state.jobType === null &&
        this.state.minValue === -Infinity &&
        this.state.maxValue === Infinity &&
        this.state.location.length === 0
      ) {
        this.fetchRecommandedJobList(this.state.page);
      } else {
        this.fetchSearchResult(this.state.page);
      }
    }
  }

  handlePageChange = page => {
    const params = {
      keyword: this.state.keyword.length === 0 ? undefined : this.state.keyword,
      type: this.state.jobType === null ? undefined : this.state.jobType,
      page: page.selected === 0 ? undefined : page.selected + 1,
      min: this.state.minValue === -Infinity ? undefined : this.state.minValue,
      max: this.state.maxValue === Infinity ? undefined : this.state.maxValue,
      location:
        this.state.location.length === 0 ? undefined : this.state.location
    };
    const query = qs.stringify(params, { addQueryPrefix: true });
    if (query === "") {
      if (i18n.language === "en") {
        navigate(`/`);
      } else {
        navigate("/zh/");
      }
    }
    navigate(`${query}`);
  };

  fetchRecommandedJobList = page => {
    this.setState({ isLoadingData: true }, () => {
      Api.getRecommandJobs(this.state.currLng, this.state.currLat, page).then(
        result => {
          if (result && isArray(result.items)) {
            this.setState({
              totalFound: result.total,
              pageCount: Math.ceil(result.total / fetchLength),
              isLoadingData: false,
              data: this.convertDataToObject(result.items)
            });
          }
        }
      );
    });
  };
  handleFilterSubmit = event => {
    event.preventDefault();
    const { minValue, maxValue } = this.normalizeValue();
    // if change reset
    const params = {
      type: this.state.jobType === null ? undefined : this.state.jobType,
      keyword: this.state.keyword.length === 0 ? undefined : this.state.keyword,
      min: minValue === -Infinity ? undefined : minValue,
      max: maxValue === Infinity ? undefined : maxValue,
      location:
        this.state.location.length === 0 ? undefined : this.state.location
    };
    const query = qs.stringify(params, { addQueryPrefix: true });
    if (query === "") {
      if (i18n.language === "en") {
        navigate(`/`);
      } else {
        navigate("/zh/");
      }
    }
    navigate(`${query}`);
  };
  convertDataToObject(items) {
    items.forEach(item => {
      item["position"] =
        item.positionTotal === 0
          ? i18n.t("noLimit")
          : item.positionAccepted + " / " + item.positionTotal;
    });
    return items;
  }

  renderJobItems = () => {
    let re = this.state.data.map(item => {
      return (
        <JobCard
          key={item._id}
          id={item._id}
          jobTitle={item.title}
          jobType={item.type}
          salary={item.salary}
          startingDate={item.startingDate}
          workingTime={item.workingTime}
          workingDays={item.workingDays}
          postDate={item.createdAt}
          position={item.position}
          jobDescription={item.description ? item.description : ""}
          companyName={
            item.external_company_name
              ? item.external_company_name
              : item.companyName
          }
          companyLogo={
            item.external_company_name
              ? "https://storage.googleapis.com/hr_api_storage/default/img_default_employer.png"
              : item.companyIcon
          }
          companyAddress={
            item.address &&
            item.address.name &&
            item.address.name.length !== 0 &&
            item.address.name.split(", ").length > 2
              ? item.address.name
              : null
          }
          asap={item.asap}
        />
      );
    });
    return re;
  };

  changeDistance(evt) {
    this.setState({ distance: evt.target.value });
  }
  changeKeyword = evt => {
    this.setState({ keyword: evt.target.value });
  };
  changeLocation(evt) {
    this.setState({ location: evt.target.value });
  }

  searchJobOnPress = event => {
    event.preventDefault();
    this.fetchSearchResult(1);
  };

  fetchSearchResult = async page => {
    let param = {
      page: page,
      kw:
        (this.state.keyword.length !== 0 ? this.state.keyword.trim() : ""),
        // (this.state.jobType ? " " + this.state.jobType : ""),
      ...(this.state.minValue !== -Infinity && {
        salary_gte: this.state.minValue
      }),
      ...(this.state.maxValue !== Infinity && {
        salary_lte: this.state.maxValue
      }),
      ...(this.state.jobType && {
        job_type: this.state.jobType
      })
    };
    if (this.state.location.length < 1) {
      param["lat"] = this.state.currLat;
      param["lng"] = this.state.currLng;
    } else {
      try {
        const result = await geocodeByAddress(this.state.location);

        const latlngResult = await getLatLng(result[0]);
        param["lat"] = latlngResult.lat;
        param["lng"] = latlngResult.lng;
      } catch (e) {
        param["lat"] = this.state.currLat;
        param["lng"] = this.state.currLng;
      }
    }

    this.setState(
      {
        isLoadingData: true
      },
      () => {
        if (param.hasOwnProperty("lng")) {
          Api.searchRecommandJobs(param).then(result => {
            if (result && isArray(result.items)) {
              this.setState({
                data: this.convertDataToObject(result.items),
                totalFound: result.total,
                pageCount: Math.ceil(result.total / fetchLength),
                isLoadingData: false
              });
            }
          });
        }
      }
    );
  };
  handleCheckboxChange = event => {
    const value = event.target.dataset.value;
    this.setState(prevState => {
      if (prevState.jobType === value) {
        return {
          jobType: null
        };
      } else {
        return {
          jobType: value
        };
      }
    });
  };

  render() {
    return (
      <div>
        <JobListHead
          page={this.state.page}
          lastPage={this.state.pageCount}
          location={this.props.location}
        />
        <Header location={this.props.location} language={i18n.language} />
        <div className="container">
          <div className="row">
            <div className="d-none d-lg-block col-lg-3">
              <form
                onSubmit={this.handleFilterSubmit}
                className="mt-3 sticky-top border-right pr-3"
                style={{ top: "10vh", zIndex: 100 }}
              >
                <div className="form-group">
                  <label>{i18n.t("keyword")}</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.changeKeyword}
                    value={this.state.keyword}
                  ></input>
                </div>
                <div className="dropdown-divider my-2"></div>
                <div className="form-group">
                  <label>{i18n.t("jobLocation")}</label>
                  <PlacesAutocomplete
                    highlightFirstSuggestion={true}
                    value={this.state.location}
                    id="address"
                    debounce={1000}
                    onChange={value => {
                      this.setState({
                        location: value
                      });
                    }}
                    searchOptions={{
                      componentRestrictions: {
                        country: "ca"
                      },
                    }}
                    onSelect={(value, placeId) => {
                      if (!placeId) {
                      } else {
                        geocodeByAddress(value).then(result => {
                          this.setState({
                            location: result[0].formatted_address
                          });
                        });
                      }
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading
                    }) => (
                      <div style={{ position: "relative" }}>
                        <input
                          {...getInputProps({
                            className: "form-control"
                          })}
                        />
                        <div
                          className="card"
                          style={{
                            position: "absolute",
                            top: "100%",
                            zIndex: 1,
                            marginTop: 8,
                            marginBottom: 8
                          }}
                        >
                          <div className="card rounded border-0">
                            <div className="list-group">
                              {loading && (
                                <div className="list-group-item">
                                  Loading...
                                </div>
                              )}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? "list-group-item active"
                                  : "list-group-item";
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
                <div className="dropdown-divider my-2"></div>
                <div className="mb-2">{i18n.t("jobType.jobType")}</div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck2"
                    checked={this.state.jobType === "Full time"}
                    data-value="Full time"
                    onChange={this.handleCheckboxChange}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck2"
                  >
                    {i18n.t("jobType.fullTime")}
                  </label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck1"
                    checked={this.state.jobType === "Part time"}
                    data-value="Part time"
                    onChange={this.handleCheckboxChange}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck1"
                  >
                    {i18n.t("jobType.partTime")}
                  </label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck3"
                    checked={this.state.jobType === "Permanent"}
                    data-value="Permanent"
                    onChange={this.handleCheckboxChange}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck3"
                  >
                    {i18n.t("jobType.permanent")}
                  </label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck4"
                    data-value="Contract"
                    checked={this.state.jobType === "Contract"}
                    onChange={this.handleCheckboxChange}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck4"
                  >
                    {i18n.t("jobType.contract")}
                  </label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck5"
                    checked={this.state.jobType === "Temporary"}
                    data-value="Temporary"
                    onChange={this.handleCheckboxChange}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck5"
                  >
                    {i18n.t("jobType.temporary")}
                  </label>
                </div>
                <div className="dropdown-divider my-2"></div>
                <div className="mb-2">{i18n.t("hourlyRate")}</div>
                <div className="row">
                  <div className="col">
                    <input
                      type="text"
                      placeholder={i18n.t("minValue")}
                      className="form-control"
                      onChange={this.changeMinValue}
                      value={this.displayMoneyValue(this.state.minValue)}
                    ></input>
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      placeholder={i18n.t("maxValue")}
                      className="form-control"
                      onChange={this.changeMaxValue}
                      value={this.displayMoneyValue(this.state.maxValue)}
                    ></input>
                  </div>
                </div>
                <button
                  className="btn btn-primary btn-block mt-2"
                  type="submit"
                >
                  {i18n.t("searchJobs")} <i className="fas fa-search"></i>
                </button>
              </form>
            </div>
            <div className="col-12 col-lg-9">
              {this.state.isLoadingData ? (
                <div className="d-flex justify-content-center my-2">
                  <ActivityIndicator />
                </div>
              ) : (
                <>
                  <ul className="p-0 mt-3 list-unstyled">
                    {this.renderJobItems()}
                  </ul>
                  <nav>
                    <ReactPaginate
                      previousLabel={i18n.t("paginate.previous")}
                      nextLabel={i18n.t("paginate.next")}
                      pageCount={this.state.pageCount}
                      onPageChange={this.handlePageChange}
                      initialPage={this.state.page - 1}
                      disableInitialCallback={true}
                      containerClassName="pagination justify-content-end"
                      disabledClassName="disabled"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      breakLabel={"..."}
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      activeClassName="active"
                      pageRangeDisplayed={2}
                      marginPagesDisplayed={1}
                      hrefBuilder={page => {
                        const q = qs.parse(this.props.location.search, {
                          ignoreQueryPrefix: true
                        });
                        if (page === 1) {
                          q.page = null;
                        } else {
                          q.page = page;
                        }
                        const query = qs.stringify(q, { addQueryPrefix: true });
                        return this.props.location.pathname + query;
                      }}
                    />
                  </nav>
                </>
              )}
            </div>
          </div>
          <SendDownloadLink />
          <footer className="pt-4 my-md-5 pt-md-5 border-top">
            <div className="row">
              <div className="col-12 col-md">
                <img
                  className="mb-2"
                  src={HirineFooterLogo}
                  alt='Home'
                  width="24"
                  height="24"
                />
                <small className="d-block mb-3 text-muted">© 2019</small>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}
