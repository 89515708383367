import React from "react";
import BasicInfo from "./BasicInfo";
import { Formik } from "formik";
import JobLocation from "./JobLocation";
import JobDetailInfo from "./JobDetailInfo";
import Salary from "./Salary";
import WorkTimeInfo from "./WorkTimeInfo";
import SkillInfo from "./SkillInfo";
import * as Api from "../Api";
import * as Yup from "yup";
import { i18n } from "../i18n";
import get from "lodash/get";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { toast } from "react-toastify";
import { navigate } from "@reach/router";
import Reminder from "./Reminder";
import { Field } from "formik";
import BasicInfoCard from "./BasicInfoCard";

class Post extends React.Component {
  allSkills = {};
  allTemplates = {};
  postSchema = Yup.object().shape({
    title: Yup.string().required(i18n.t("simplePost.required-field")),
    category: Yup.string().required(i18n.t("simplePost.required-field")),
    template: Yup.string(),
    description: Yup.string(),
    type: Yup.string().required(i18n.t("simplePost.required-field")),
    position: Yup.number().when("unlimit", {
      is: false,
      then: Yup.number()
        .integer()
        .typeError(i18n.t("simplePost.invalid-value"))
        .min(1, i18n.t("simplePost.invalid-value"))
        .required(i18n.t("simplePost.required-field")),
      otherwise: Yup.number()
        .nullable()
        .notRequired()
    }),
    asap: Yup.boolean().default(true),
    unlimit: Yup.boolean()
      .required(i18n.t("simplePost.required-field"))
      .default(false),
    // unit: Yup.string()
    //   .nullable()
    //   .notRequired(),
    from: Yup.number(i18n.t("simplePost.invalid-value"))
      .min(0)
      .typeError(i18n.t("simplePost.invalid-value"))
      .when("negotiable", {
        is: false,
        then: Yup.number(i18n.t("simplePost.invalid-value")).required(
          i18n.t("simplePost.required-field")
        ),
        otherwise: Yup.number(i18n.t("simplePost.invalid-value"))
      }),
    to: Yup.number(i18n.t("simplePost.invalid-value"))
      .typeError(i18n.t("simplePost.invalid-value"))
      .nullable()
      .test("tooverfrom", i18n.t("simplePost.invalid-value"), function (value) {
        return !value || (value && this.parent.from < value);
      }),
    currency: Yup.string().when("negotiable", {
      is: false,
      then: Yup.string().required(i18n.t("simplePost.required-field")),
      otherwise: Yup.string()
    }),
    paymentMethod: Yup.string().when("negotiable", {
      is: false,
      then: Yup.string().required(i18n.t("simplePost.required-field")),
      otherwise: Yup.string()
    }),
    negotiable: Yup.boolean()
      .default(false)
      .required(i18n.t("simplePost.required-field")),
    rate: Yup.string().when("negotiable", {
      is: false,
      then: Yup.string().required(i18n.t("simplePost.required-field")),
      otherwise: Yup.string()
    }),
    startDate: Yup.date().required(i18n.t("simplePost.required-field")),
    endDate: Yup.date().when("permanent", {
      is: true,
      then: Yup.date()
        .nullable()
        .notRequired(),
      otherwise: Yup.date()
        .required(i18n.t("simplePost.required-field"))
        .typeError(i18n.t("simplePost.invalid-value"))
    }),
    // shift: Yup.string().required(),
    permanent: Yup.boolean()
      .required()
      .default(true),
    startTime: Yup.date()
      .typeError(i18n.t("simplePost.invalid-value"))
      .required(i18n.t("simplePost.required-field")),

    endTime: Yup.date()
      .typeError(i18n.t("simplePost.invalid-value"))
      .required(i18n.t("simplePost.required-field")),

    flexibleTime: Yup.boolean()
      .typeError(i18n.t("simplePost.invalid-value"))
      .required(i18n.t("simplePost.required-field")),

    workingDay: Yup.array().min(1, i18n.t("simplePost.invalid-value")),

    addressDescription: Yup.string()
      .required(i18n.t("simplePost.required-field"))
      .min(1),
    addressLatitude: Yup.number()
      .required(i18n.t("simplePost.required-field"))
      .notOneOf([0], i18n.t("simplePost.invalid-value")),
    addressLongitude: Yup.number()
      .required(i18n.t("simplePost.required-field"))
      .notOneOf([0], i18n.t("simplePost.invalid-value")),
    branch: Yup.string().nullable(),
    saveAddress: Yup.boolean().default(false),
    isBranch: Yup.boolean().default(false),
    branchValue: Yup.string().when("isBranch", {
      is: true,
      then: Yup.string()
        .required(i18n.t("simplePost.required-field"))
        .min(1),
      otherwise: Yup.string()
    }),
    reminder: Yup.string(),
    resumeRequired: Yup.boolean(),

    // @todo
    contact: Yup.string(),
    direct_email: Yup.string()
  });
  // upload fake icon start
  file = null;
  fileRef = React.createRef();
  handleFileUpload = (e, setFieldValue) => {
    switch (e.target.name) {
      case "selectedFile":
        if (e.target.files.length > 0) {
          // Accessed .name from file
          const reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          this.file = e.target.files[0];
          if (this.file) {
            Api.uploadFakeIcon(this.file, global._token).then(response => {
              if (response.result) {
                setFieldValue("externalCompanyIcon", response.result);
              }
            });
          }
        }
        break;
      default:
    }
  };
  handleSendFile = () => {
    if (this.fileRef.current) {
      this.fileRef.current.click();
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      showContact: false,
      showBasicInfo: props.jobId ? false : true,
      negotiable: false
    };
    this.initialValues = {
      category: "",
      template: "",
      title: "",
      description: "",
      type: "Full time",
      unlimit: true,
      position: "",
      from: "",
      to: "",
      currency: "CAD",
      paymentMethod: "Cheque",
      rate: "hourly",
      negotiable: false,
      startDate: new Date(),
      endDate: "",
      asap: true,
      permanent: true,
      startTime: new Date(new Date().setHours(9, 0)),
      endTime: new Date(new Date().setHours(17, 0)),
      flexibleTime: false,
      workingDay: ["Mon", "Tue", "Wed", "Thu", "Fri"],
      addressDescription:
        props.company && props.company.address
          ? props.company.address.name
          : "",
      reminder: "",
      addressLatitude:
        props.company && props.company.address
          ? props.company.address.location.coordinates[1]
          : 0,
      addressLongitude:
        props.company && props.company.address
          ? props.company.address.location.coordinates[0]
          : 0,
      addressUnit: "",
      branchValue: "",
      isBranch: false,
      saveAddress: false,
      requiredSkill: [],
      resumeRequired: false,

      // @todo
      contact: "",
      externalCompanyName: "",
      externalCompanyIcon: ""
    };
  }
  componentDidMount() {
    // if post
    if (this.props.draftId) {
      Promise.all([
        this.getSkillAndCategory(),
        this.getTemplate(),
        this.getDraftDetail()
      ]).then(([categories]) => {
        this.setState({ category: categories, ready: true });
      });
    } else if (this.props.jobId) {
      Promise.all([
        this.getSkillAndCategory(),
        this.getTemplate(),
        this.getJobDetail()
      ]).then(([categories]) => {
        this.setState({ category: categories, ready: true });
      });
    } else {
      Promise.all([this.getSkillAndCategory(), this.getTemplate()]).then(
        ([categories]) => {
          this.setState({ category: categories, ready: true });
        }
      );
    }
  }

  getDraftDetail = () => {
    return new Promise((resolve, reject) => {
      Api.getDraftDetail(this.props.draftId, global._token).then(json => {
        if (json && json._id) {
          let weekdayValue = [];
          if (json.workingDays) {
            if (json.workingDays.indexOf("everyDay") !== -1) {
              weekdayValue = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
            } else if (json.workingDays.indexOf("SS") !== -1) {
              weekdayValue = ["Sat", "Sun"];
            } else if (json.workingDays.indexOf("M-F") !== -1) {
              weekdayValue = ["Mon", "Tue", "Wed", "Thu", "Fri"];
            } else {
              weekdayValue = json.workingDays;
            }
          } else {
            weekdayValue = ["Mon", "Tue", "Wed", "Thu", "Fri"];
          }
          this.initialValues = {
            title: json.title || "",
            category:
              json.job_category && json.job_category._id
                ? json.job_category._id
                : "",
            type: json.type || "Full time",
            position: json.positionTotal || "",
            unlimit: json.positionTotal === 0 ? true : false,
            addressUnit:
              json.address && json.address.unitNumber
                ? json.address.unitNumber
                : "",
            negotiable:
              json.salary && json.salary.negotiable
                ? json.salary.negotiable
                : false,
            from: json.salary && json.salary.value ? json.salary.value : "",
            to: json.salary && json.salary.maxValue ? json.salary.maxValue : "",
            currency:
              json.salary && json.salary.currency
                ? json.salary.currency
                : "CAD",
            paymentMethod: json.payingMethod || "Cheque",
            rate: json.salary && json.salary.unit ? json.salary.unit : "hourly",
            permanent: json.endingDate === "no limit" ? true : false,
            startDate: json.asap
              ? new Date()
              : json.startingDate
                ? parseISO(json.startingDate)
                : new Date(),
            asap: json.asap || json.asap === false ? json.asap : true,
            endDate:
              json.endingDate === "no limit"
                ? ""
                : json.endingDate
                  ? parseISO(json.endingDate)
                  : "",
            startTime:
              json.workingTime && json.workingTime.from
                ? this.convert(json.workingTime.from)
                : new Date(new Date().setHours(9, 0)),
            endTime:
              json.workingTime && json.workingTime.to
                ? this.convert(json.workingTime.to)
                : new Date(new Date().setHours(17, 0)),
            flexibleTime: json.workingTime.flexibleTime ? json.workingTime.flexibleTime : false,
            requiredSkill:
              json.mandatorySkillRequirements &&
                json.mandatorySkillRequirements.length !== 0
                ? json.mandatorySkillRequirements
                : [],
            workingDay: weekdayValue,
            addressDescription:
              json.address && json.address.name
                ? json.address.name
                : this.props.company.address.name,
            addressLatitude:
              json.address && json.address.location
                ? json.address.location.coordinates[1]
                : this.props.company.address.location.coordinates[1],
            addressLongitude:
              json.address && json.address.location
                ? json.address.location.coordinates[0]
                : this.props.company.address.location.coordinates[0],
            description: json.description,
            reminder: json.reminder,
            sexRequired: json.sexRequired,
            carRequired: json.carRequired,
            language_type: json.language_type,
            branch: json.branch && json.branch._id,
            saveAddress: false,
            isBranch: json.branch && json.branch._id ? true : false,
            branchValue:
              json.branch && json.branch._id ? json.branch.addressTitle : "",
            resumeRequired: json.resumeRequired ? json.resumeRequired : false,

            //@todo
            contact: json.direct_phone ? json.direct_phone : "",
            direct_email: json.direct_email ? json.direct_email : "",
            externalCompanyName: json.external_company_name
              ? json.external_company_name
              : "",
            externalCompanyIcon: json.external_company_icon
              ? json.external_company_icon
              : ""
          };
          resolve();
        } else {
          reject();
        }
      });
    });
  };

  getWorkingDayValue = workingDayPayload => {
    const weekday =
      workingDayPayload.includes("Mon") &&
      workingDayPayload.includes("Tue") &&
      workingDayPayload.includes("Wed") &&
      workingDayPayload.includes("Thu") &&
      workingDayPayload.includes("Fri");
    const weekend =
      workingDayPayload.includes("Sat") && workingDayPayload.includes("Sun");
    const anyWeekend =
      workingDayPayload.includes("Sat") || workingDayPayload.includes("Sun");
    const anyWeekday =
      workingDayPayload.includes("Mon") ||
      workingDayPayload.includes("Tue") ||
      workingDayPayload.includes("Wed") ||
      workingDayPayload.includes("Thu") ||
      workingDayPayload.includes("Fri");
    let result = [];
    if (weekday && weekend) {
      result = ["everyDay"];
    } else {
      if (weekday && !anyWeekend) {
        result = ["M-F"];
      } else if (!anyWeekday && weekend) {
        result = ["SS"];
      } else {
        result = workingDayPayload;
      }
    }
    return result;
  };

  getJobDetail = () => {
    // edit
    return new Promise((resolve, reject) => {
      Api.getJobDetail(this.props.jobId, global._token).then(json => {
        if (json && json._id) {
          let weekdayValue = [];
          if (json.workingDays) {
            if (json.workingDays.indexOf("everyDay") !== -1) {
              weekdayValue = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
            } else if (json.workingDays.indexOf("SS") !== -1) {
              weekdayValue = ["Sat", "Sun"];
            } else if (json.workingDays.indexOf("M-F") !== -1) {
              weekdayValue = ["Mon", "Tue", "Wed", "Thu", "Fri"];
            } else {
              weekdayValue = json.workingDays;
            }
          } else {
            weekdayValue = ["Mon", "Tue", "Wed", "Thu", "Fri"];
          }
          this.initialValues = {
            title: json.title,
            category:
              json.job_category && json.job_category._id
                ? json.job_category._id
                : "",
            type: json.type,
            asap: json.asap || json.asap === false ? json.asap : true,
            position: json.positionTotal === 0 ? 0 : json.positionTotal,
            unlimit: json.positionTotal === 0 ? true : false,
            addressUnit: json.address.unitNumber ? json.address.unitNumber : "",
            from: json.salary && json.salary.value ? json.salary.value : "",
            to: json.salary && json.salary.maxValue ? json.salary.maxValue : "",
            negotiable: json.salary && json.salary.negotiable ? json.salary.negotiable : false,
            rate: json.salary && json.salary.unit ? json.salary.unit : "hourly",
            permanent: json.endingDate === "no limit" ? true : false,
            startDate: json.asap
              ? new Date()
              : json.startingDate
                ? parseISO(json.startingDate)
                : new Date(),
            endDate:
              json.endingDate === "no limit"
                ? ""
                : json.endingDate
                  ? parseISO(json.endingDate)
                  : "",
            startTime:
              json.workingTime && json.workingTime.from
                ? this.convert(json.workingTime.from)
                : new Date(new Date().setHours(9, 0)),
            endTime:
              json.workingTime && json.workingTime.to
                ? this.convert(json.workingTime.to)
                : new Date(new Date().setHours(17, 0)),
            flexibleTime: json.workingTime.flexibleTime ? json.workingTime.flexibleTime : false,
            requiredSkill:
              json.mandatorySkillRequirements &&
                json.mandatorySkillRequirements.length !== 0
                ? json.mandatorySkillRequirements.map(skill => skill._id)
                : [],
            currency:
              json.salary && json.salary.currency
                ? json.salary.currency
                : "CAD",
            paymentMethod: json.payingMethod || "Cheque",
            workingDay: weekdayValue,
            addressDescription:
              json.address && json.address.name ? json.address.name : "",
            addressLatitude:
              json.address && json.address.location
                ? json.address.location.coordinates[1]
                : 0,
            addressLongitude:
              json.address && json.address.location
                ? json.address.location.coordinates[0]
                : 0,
            description: json.description ? json.description : "",
            reminder: json.reminder,
            sexRequired: json.sexRequired ? json.sexRequired : "",
            carRequired: json.carRequired ? json.carRequired : "",
            language_type: json.language_type ? json.language_type : "",
            branch: json.branch && json.branch._id,
            saveAddress: false,
            isBranch: json.branch && json.branch._id ? true : false,
            branchValue:
              json.branch && json.branch._id ? json.branch.addressTitle : "",
            reminder: json.reminder,
            resumeRequired: json.resumeRequired ? json.resumeRequired : false,
            // @todo
            contact: json.direct_phone ? json.direct_phone : "",
            direct_email: json.direct_email ? json.direct_email : "",
            externalCompanyName: json.external_company_name
              ? json.external_company_name
              : "",
            externalCompanyIcon: json.external_company_icon
              ? json.external_company_icon
              : ""
          };
          resolve();
        } else {
          reject();
        }
      });
    });
  };

  convert = hourString => {
    const array = hourString.split(":");
    const hour = parseInt(array[0], 10);
    const minute = parseInt(array[1], 10);
    return new Date(new Date().setHours(hour, minute));
  };

  removeAndAddSkill = (form, removeSkills = [], addSkills = []) => {
    // let tempPreferredSkill = form.values.preferredSkill.slice();
    let tempRequiredSkill = form.values.requiredSkill.slice();

    removeSkills.forEach(skill => {
      // tempPreferredSkill = tempPreferredSkill.filter(e => e !== skill);
      tempRequiredSkill = tempRequiredSkill.filter(e => e !== skill);
    });
    if (tempRequiredSkill.length < 9) {
      addSkills.forEach(skill => {
        if (tempRequiredSkill.indexOf(skill) === -1) {
          // no mo prefer skills
          tempRequiredSkill.push(skill);
          // tempPreferredSkill.push(skill);
        }
      });
    }
    form.setFieldValue("requiredSkill", tempRequiredSkill);
  };

  getSkillAndCategory = () => {
    return new Promise((resolve, reject) => {
      Api.getCategory(global._token)
        .then(json => {
          if (json && json.items && json.items.length !== 0) {
            // this.setState({ category: json.items });
            json.items.forEach(cat => {
              cat.child.forEach(c => {
                c.skills.forEach(skill => {
                  this.allSkills[skill._id] = skill;
                });
              });
            });
            resolve(json.items);
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  };
  toggleContact = () => {
    this.setState(prevState => ({
      showContact: !prevState.showContact
    }));
  };

  getTemplate = () => {
    return new Promise((resolve, reject) => {
      Api.getTemplate(global._token)
        .then(json => {
          if (json && json.items && json.items.length !== 0) {
            json.items.forEach(cat => {
              const tempChild = [];
              if (cat.child && cat.child.length !== 0) {
                cat.child.forEach(c => {
                  if (c.templates) {
                    tempChild.push(c);
                  }
                });
              }
              this.allTemplates[cat._id] = tempChild;
              resolve(json.items);
            });
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  };

  handleContinue = (
    validateForm,
    setFieldTouched,
    fields,
    payload,
    first = false,
    callback = function () { }
  ) => {
    if (fields && fields.length !== 0) {
      fields.forEach(field => {
        setFieldTouched(field, true, false);
      });
      validateForm().then(e => {
        let error = false;
        fields.forEach(field => {
          if (get(e, field)) {
            error = true;
          }
        });
        if (!error) {
          callback();
          if (payload) {
            this.saveToDraft(payload, first);
          }
        }
      });
    } else {
      callback();
      if (payload) {
        this.saveToDraft(payload, first);
      }
    }
  };

  saveToDraft = (payload, first = false) => {
    if (this.props.draftId) {
      /** update this draft */
      Api.updateDraft(this.props.draftId, payload, global._token)
        .then(json => {
          if (json.ok) {
          } else {
            throw new Error("update draft failed");
          }
        })
        .catch(e => {
          console.error(e.message);
        });
    } else {
      if (this.props.jobId) {
        /** do nothing when edit */
      } else {
        if (first) {
          /** create */
          if (!this.createdDraftId) {
            Api.createDraft(payload, global._token)
              .then(json => {
                if (json.id) {
                  this.createdDraftId = json.id;
                } else {
                  throw new Error("create draft failed");
                }
              })
              .catch(e => {
                console.error(e.message);
              });
          } else {
            Api.updateDraft(this.createdDraftId, payload, global._token)
              .then(json => {
                if (json.ok) {
                } else {
                  throw new Error("update draft failed");
                }
              })
              .catch(e => {
                console.error(e.message);
              });
          }
        } else {
          if (this.createdDraftId) {
            /** update draft */
            Api.updateDraft(this.createdDraftId, payload, global._token)
              .then(json => {
                if (json.ok) {
                } else {
                  throw new Error("update draft failed");
                }
              })
              .catch(e => {
                console.error(e.message);
              });
          } else {
            /** create draft failed, do nothing */
          }
        }
      }
    }
  };

  handleSalaryChange = (
    negotiable
  ) => {
    this.state = {
      negotiable: false
    }
    this.initialValues.negotiable = false;
    console.log(this.initialValues.negotiable);
  };

  render() {
    return (
      <div>
        {this.state.ready ? (
          <Formik
            onSubmit={(values, { setSubmitting }) => {
              let dayPayloadValue = this.getWorkingDayValue(values.workingDay);
              const payload = {
                address: {
                  name: values.addressDescription,
                  unitNumber: values.addressUnit,
                  location: {
                    coordinates: [
                      values.addressLongitude,
                      values.addressLatitude
                    ],
                    type: "Point"
                  }
                },
                asap: values.asap,
                location: {
                  coordinates: [
                    values.addressLongitude,
                    values.addressLatitude
                  ],
                  type: "Point"
                },
                job_category: values.category,
                description: values.description,
                startingDate: values.asap ? new Date() : values.startDate,
                title: values.title,
                workingTime: {
                  from: format(values.startTime, "HH:mm"),
                  to: format(values.endTime, "HH:mm"),
                  flexibleTime: values.flexibleTime
                },
                type: values.type,
                positionTotal: values.unlimit ? 0 : values.position,
                salary: {
                  value: values.negotiable ? 0 : values.from,
                  maxValue: values.negotiable ? 0 : values.to,
                  currency: values.negotiable ? "CAD" : values.currency,
                  unit: values.negotiable ? "hourly" : values.rate,
                  negotiable: values.negotiable
                },
                payingMethod: values.paymentMethod,
                endingDate:
                  values.permanent || values.asap ? "no limit" : values.endDate,
                workingDays: dayPayloadValue,
                reminder: values.reminder,
                sexRequired: values.sexRequired,
                carRequired: values.carRequired,
                language_type: values.language_type,
                mandatorySkillRequirements:
                  values.requiredSkill.length !== 0 ? values.requiredSkill : [],
                resumeRequired: values.resumeRequired
                  ? values.resumeRequired
                  : false,
                ...(values.branch && { branch: values.branch }),
                direct_phone: values.contact ? values.contact : "",
                direct_email: values.direct_email ? values.direct_email : "",
                external_company_name: values.externalCompanyName
                  ? values.externalCompanyName
                  : "",
                external_company_icon: values.externalCompanyIcon
                  ? values.externalCompanyIcon
                  : ""
              };
              if (this.props.jobId) {
                Api.editJob(this.props.jobId, payload, global._token)
                  .then(json => {
                    setSubmitting(false);
                    if (json._id) {
                      toast.success("Job edited!", {
                        className: "alert alert-success"
                      });
                      navigate(`/employer/job/${this.props.jobId}`);
                    } else {
                      throw new Error("Edit skill failed");
                    }
                  })
                  .catch(e => {
                    setSubmitting(false);
                    toast.error(e.message, {
                      className: "alert alert-danger"
                    });
                  });
              } else {
                Api.postJob(payload, global._token)
                  .then(json => {
                    setSubmitting(false);
                    if (json._id) {
                      toast.success("Job created!", {
                        className: "alert alert-success"
                      });
                      navigate(`/employer/job`);
                    } else {
                      throw new Error("Create skill failed");
                    }
                  })
                  .catch(e => {
                    setSubmitting(false);
                    toast.error(e.message, {
                      className: "alert alert-danger"
                    });
                  });
              }
            }}
            initialValues={this.initialValues}
            validationSchema={this.postSchema}
          >
            {({
              handleSubmit,
              values,
              setValues,
              setFieldValue,
              setFieldTouched,
              validateForm
            }) => (
              <>
                <form
                  onSubmit={handleSubmit}
                  className="card border-0 shadow-sm my-4 col-sm-10 offset-sm-1 offset-lg-2 col-lg-8 positive-relative new-post-form py-3"
                >
                  {this.state.showBasicInfo ? (
                    <div className="card-body">
                      <BasicInfoCard
                        skillOptions={this.allSkills}
                        templateOptions={this.allTemplates}
                        categoryOptions={this.state.category}
                        handleResetValue={updatedValue =>
                          setValues({ ...values, ...updatedValue })
                        }
                        jobId={this.props.jobId || this.props.draftId}
                        category={values.category}
                        title={values.title}
                        description={values.description}
                        handleContinue={callback =>
                          this.handleContinue(
                            validateForm,
                            setFieldTouched,
                            ["title", "category"],
                            {
                              address: {
                                name: values.addressDescription,
                                unitNumber: values.addressUnit,
                                location: {
                                  coordinates: [
                                    values.addressLongitude,
                                    values.addressLatitude
                                  ],
                                  type: "Point"
                                }
                              },
                              location: {
                                coordinates: [
                                  values.addressLongitude,
                                  values.addressLatitude
                                ],
                                type: "Point"
                              },
                              job_category: values.category,
                              description: values.description,
                              startingDate: values.asap
                                ? new Date()
                                : values.startDate,
                              title: values.title,
                              workingTime: {
                                from: format(values.startTime, "HH:mm"),
                                to: format(values.endTime, "HH:mm"),
                                flexibleTime: values.flexibleTime
                              },
                              asap: values.asap,
                              type: values.type,
                              positionTotal: values.unlimit
                                ? 0
                                : values.position,
                              salary: {
                                value: values.negotiable ? 0 : values.from,
                                maxValue: values.negotiable ? 0 : values.to,
                                currency: values.negotiable
                                  ? "CAD"
                                  : values.currency,
                                unit: values.negotiable
                                  ? "hourly"
                                  : values.rate,
                                negotiable: values.negotiable
                              },
                              payingMethod: values.paymentMethod,
                              endingDate:
                                values.permanent || values.asap
                                  ? "no limit"
                                  : values.endDate,
                              workingDays: this.getWorkingDayValue(
                                values.workingDay
                              ),
                              reminder: values.reminder,
                              sexRequired: values.sexRequired ? values.sexRequired : "",
                              carRequired: values.carRequired ? values.carRequired : "",
                              language_type: values.language_type ? values.language_type : "",
                              mandatorySkillRequirements:
                                values.requiredSkill.length !== 0
                                  ? values.requiredSkill
                                  : [],
                              ...(values.branch && { branch: values.branch })
                            },
                            true,
                            () => this.setState({ showBasicInfo: false })
                          )
                        }
                      />
                    </div>
                  ) : (
                    <>
                      <div className="card-body">
                        <h5 className="card-title">
                          {i18n.t("postJob.step1")}
                        </h5>
                        <BasicInfo
                          showBasicInfoCard={() =>
                            this.setState({ showBasicInfo: true })
                          }
                          jobId={this.props.jobId || this.props.draftId}
                          category={values.category}
                          title={values.title}
                          description={values.description}
                          categoryOptions={this.state.category}
                        />
                        <div className="dropdown-divider my-4"></div>
                        <h5
                          className="card-title"
                          onContextMenu={this.toggleContact}
                        >
                          {i18n.t("simplePost.detail-info")}
                        </h5>
                        <JobDetailInfo
                          type={values.type}
                          unlimit={values.unlimit}
                          position={values.position}
                          handleResetValue={updatedValue =>
                            setValues({ ...values, ...updatedValue })
                          }
                          handleContinue={callback =>
                            this.handleContinue(
                              validateForm,
                              setFieldTouched,
                              ["type", "unlimit", "position"],
                              {
                                type: values.type ? values.type : null,
                                positionTotal: values.unlimit
                                  ? 0
                                  : values.position
                              },
                              false,
                              callback
                            )
                          }
                        />
                        <JobLocation
                          addressDescription={values.addressDescription}
                          addressLatitude={values.addressLatitude}
                          addressLongitude={values.addressLongitude}
                          addressUnit={values.addressUnit}
                          branchValue={values.branchValue}
                          isBranch={values.isBranch}
                          saveAddress={values.saveAddress}
                          handleResetValue={updatedValue =>
                            setValues({ ...values, ...updatedValue })
                          }
                          handleContinue={callback =>
                            this.handleContinue(
                              validateForm,
                              setFieldTouched,
                              [
                                "addressDescription",
                                "addressLatitude",
                                "addressLongitude",
                                "branchValue"
                              ],
                              {
                                address: {
                                  name: values.addressDescription,
                                  unitNumber: values.addressUnit,
                                  location: {
                                    coordinates: [
                                      values.addressLongitude,
                                      values.addressLatitude
                                    ],
                                    type: "Point"
                                  }
                                },
                                location: {
                                  coordinates: [
                                    values.addressLongitude,
                                    values.addressLatitude
                                  ],
                                  type: "Point"
                                },
                                ...(values.branch && { branch: values.branch })
                              },
                              false,
                              callback
                            )
                          }
                        />
                        <Salary
                          handleResetValue={updatedValue =>
                            setValues({ ...values, ...updatedValue })
                          }
                          negotiable={values.negotiable}
                          from={values.from}
                          to={values.to}
                          paymentMethod={values.paymentMethod}
                          currency={values.currency}
                          rate={values.rate}
                          salaryChange={this.handleSalaryChange}
                          handleContinue={callback =>
                            this.handleContinue(
                              validateForm,
                              setFieldTouched,
                              [
                                "negotiable",
                                "from",
                                "to",
                                "rate",
                                "currency",
                                "paymentMethod"
                              ],
                              {
                                salary: {
                                  negotiable: values.negotiable ? values.negotiable : false,
                                  value: values.from ? values.from : 0,
                                  maxValue: values.to ? values.to : 0,
                                  currency: values.currency
                                    ? values.currency
                                    : "CAD",
                                  unit: values.rate
                                    ? values.rate
                                    : "hourly"
                                },
                                payingMethod: values.paymentMethod
                              },
                              false,
                              callback
                            )
                          }
                        />
                        {/*
                        salary: {
                                  negotiable: false,
                                  value: values.negotiable ? 0 : values.from,
                                  maxValue: values.negotiable ? 0 : values.to,
                                  currency: values.negotiable
                                    ? "CAD"
                                    : values.currency,
                                  unit: values.negotiable
                                    ? "hourly"
                                    : values.rate
                                },
                        negotiable={values.negotiable} */}

                        <WorkTimeInfo
                          startDate={values.startDate}
                          endDate={values.endDate}
                          permanent={values.permanent}
                          startTime={values.startTime}
                          endTime={values.endTime}
                          flexibleTime={values.flexibleTime}
                          workingDay={values.workingDay}
                          asap={values.asap}
                          handleResetValue={updatedValue =>
                            setValues({ ...values, ...updatedValue })
                          }
                          handleContinue={callback =>
                            this.handleContinue(
                              validateForm,
                              setFieldTouched,
                              [
                                "startDate",
                                "endDate",
                                "permanent",
                                "startTime",
                                "endTime",
                                "flexible",
                                "workingDay",
                                "asap"
                              ],
                              {
                                asap: values.asap,
                                startingDate: values.asap
                                  ? new Date()
                                  : values.startDate,
                                endingDate: values.permanent
                                  ? "no limit"
                                  : values.endDate,
                                workingTime: {
                                  from: values.startTime
                                    ? format(values.startTime, "HH:mm")
                                    : null,
                                  to: values.endTime
                                    ? format(values.endTime, "HH:mm")
                                    : null,
                                  flexibleTime: values.flexibleTime
                                },
                                workingDays: this.getWorkingDayValue(
                                  values.workingDay
                                )
                              },
                              false,
                              callback
                            )
                          }
                        />


                        <SkillInfo
                          requiredSkill={values.requiredSkill}
                          allSkills={this.allSkills}
                          category={this.state.category}
                          selectedCategory={values.category}
                          handleResetValue={updatedValue =>
                            setValues({ ...values, ...updatedValue })
                          }
                          removeAndAddSkill={(removeSkill, addSkill) =>
                            this.removeAndAddSkill(
                              { values, setFieldValue },
                              removeSkill,
                              addSkill
                            )
                          }
                          handleContinue={callback =>
                            this.handleContinue(
                              validateForm,
                              setFieldTouched,
                              [],
                              {
                                mandatorySkillRequirements: values.requiredSkill
                              },
                              false,
                              callback
                            )
                          }
                        />
                        <Reminder
                          reminder={values.reminder}
                          handleResetValue={updatedValue =>
                            setValues({ ...values, ...updatedValue })
                          }
                          handleContinue={callback =>
                            this.handleContinue(
                              validateForm,
                              setFieldTouched,
                              [],
                              { reminder: values.reminder },
                              false,
                              callback
                            )
                          }
                        />
                        <div className="form-group col">
                          <div className="custom-control custom-checkbox">
                            <Field
                              name="resumeRequired"
                              className="custom-control-input"
                              type="checkbox"
                              id="resume-checkbox"
                              checked={values.resumeRequired}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="resume-checkbox"
                            >
                              {i18n.t("postJob.resume-required")}
                            </label>
                          </div>
                        </div>

                        <div className="form-group col">
                          <div className="custom-control custom-checkbox">
                            <label>{i18n.t("postJob.sexRequired")}:&nbsp;</label>
                            <input type="radio" name="sex" value="" checked={!values.sexRequired} onChange={(e) => {
                              setValues({ ...values, sexRequired: e.target.value })
                            }} />
                            <label>&nbsp;{i18n.t("postJob.both")}&nbsp;</label>
                            <input type="radio" id="age2" name="sex" value="Male" checked={values.sexRequired == "Male"} onChange={(e) => {
                              setValues({ ...values, sexRequired: e.target.value })
                            }} />
                            <label>&nbsp;{i18n.t("postJob.male")}&nbsp;</label>
                            <input type="radio" id="age3" name="sex" value="Female" checked={values.sexRequired == "Female"} onChange={(e) => { setValues({ ...values, sexRequired: e.target.value }) }} />
                            <label>&nbsp;{i18n.t("postJob.female")}&nbsp;</label>
                          </div>
                        </div>

                        <div className="form-group col">
                          <div className="custom-control custom-checkbox">
                            <label>{i18n.t("postJob.carRequired")}:&nbsp;</label>
                            <input type="radio" name="car" value="" checked={!values.carRequired} onChange={(e) => {
                              setValues({ ...values, carRequired: e.target.value })
                            }} />
                            <label>&nbsp;{i18n.t("postJob.both")}&nbsp;</label>
                            <input type="radio" id="age2" name="car" value="Yes" checked={values.carRequired == "Yes"} onChange={(e) => {
                              setValues({ ...values, carRequired: e.target.value })
                            }} />
                            <label>&nbsp;{i18n.t("postJob.yes")}&nbsp;</label>
                            <input type="radio" id="age3" name="car" value="No" checked={values.carRequired == "No"} onChange={(e) => { setValues({ ...values, carRequired: e.target.value }) }} />
                            <label>&nbsp;{i18n.t("postJob.no")}&nbsp;</label>
                          </div>
                        </div>

                        <div className="form-group col">
                          <div className="custom-control custom-checkbox">
                            <label>{i18n.t("postJob.languageRequired")}:&nbsp;</label>
                            <input type="radio" name="language" value="" checked={!values.language_type} onChange={(e) => {
                              setValues({ ...values, language_type: e.target.value })
                            }} />
                            <label>&nbsp;{i18n.t("postJob.both")}&nbsp;</label>
                            <input type="radio" id="age2" name="language" value="English" checked={values.language_type == "English"} onChange={(e) => {
                              setValues({ ...values, language_type: e.target.value })
                            }} />
                            <label>&nbsp;{i18n.t("postJob.english")}&nbsp;</label>
                            <input type="radio" id="age3" name="language" value="Chinese" checked={values.language_type == "Chinese"} onChange={(e) => { setValues({ ...values, language_type: e.target.value }) }} />
                            <label>&nbsp;{i18n.t("postJob.chinese")}&nbsp;</label>
                          </div>
                        </div>

                        {this.state.showContact && (
                          <>
                            <div className="form-group">
                              <label>Phone number</label>
                              <Field
                                type="text"
                                name="contact"
                                className="form-control"
                              />
                            </div>
                            <div className="form-group">
                              <label>Company Email</label>
                              <Field
                                type="text"
                                name="direct_email"
                                className="form-control"
                              ></Field>
                            </div>
                            <div className="form-group">
                              <label>Company name</label>
                              <Field
                                type="text"
                                name="externalCompanyName"
                                className="form-control"
                              ></Field>
                            </div>
                            <div className="form-group">
                              <label>Company icon</label>
                              <Field
                                type="text"
                                name="externalCompanyIcon"
                                className="form-control"
                              ></Field>
                            </div>

                            <input
                              type="file"
                              aria-describedby="inputGroupFileAddon01"
                              onChange={e =>
                                this.handleFileUpload(e, setFieldValue)
                              }
                              accept=".jpg, .jpeg, .png"
                              name="selectedFile"
                              style={{ display: "none" }}
                              ref={this.fileRef}
                            />
                            <button
                              className="btn btn-link"
                              onClick={this.handleSendFile}
                              type="button"
                            >
                              upload icon
                            </button>
                          </>
                        )}
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg"
                        >
                          {i18n.t("simplePost.post-job")}
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </>
            )}
          </Formik>
        ) : null}
      </div>
    );
  }
}

export default Post;
