import React from "react";
import HirineLogo from "../img/HirineLogo@3x.png";
import { Link } from "@reach/router";
// import { i18n } from "../i18n";
import { useTranslation } from "react-i18next";
function Header(props) {
  const { i18n } = useTranslation();
  /** prefix can be /zh or / */
  function getLanguageRouter(prefix, current) {
    const temp = props.location.pathname.replace(current, "");
    if (prefix === "/" && temp === "/") {
      return "/";
    }
    return prefix + temp + props.location.search;
  }

  return (
    <nav className="sticky-top py-1 bg-white shadow-sm">
      <div className="container-fluid d-flex flex-column flex-md-row align-items-center">
        {i18n.language === "zh" && (
          <Link to="/zh/" className="py-2 mr-3">
            <img style={{ width: 100 }} src={HirineLogo} alt={"Hirine Logo"} />
          </Link>
        )}
        {i18n.language === "en" && (
          <Link to="/" className="py-2 mr-3">
            <img style={{ width: 100 }} src={HirineLogo} alt={"Hirine Logo"} />
          </Link>
        )}
        <div className="mr-auto">
          <Link className="p-2 d-none d-md-inline-block text-black" to="/about">
            {i18n.t("about.title")}
          </Link>
          {/* <a className="p-2 d-none d-md-inline-block text-black" href="#">
            Product
          </a> */}
        </div>
        <div className="d-flex">
          <a
            className="btn btn-link d-block d-md-none text-muted"
            href="http://onelink.to/gq6gbs"
          >
            {i18n.t("download-app")}
          </a>
          <div className="header-dropdown d-none d-md-block">
            <button className="btn btn-link text-muted">
              {i18n.t("download-app")}
              <i className="fas fa-arrow-down ml-1"></i>
            </button>
            <div className="header-dropdown-content ">
              <img
                style={{ height: 150, width: 150 }}
                src="https://storage.googleapis.com/hr_api_storage/images/downloadAppCode%403x.png"
                alt="employee app download"
              />
              <a
                target="_blank"
                href={
                  i18n.language === "zh"
                    ? "https://apps.apple.com/ca/app/hirine/id1454826078"
                    : "https://apps.apple.com/ca/app/hirine/id1454826078"
                }
              >
                <img
                  style={{ width: 150 }}
                  className="mt-1"
                  src="https://storage.googleapis.com/hr_api_storage/images/gxTpos-YpC5.png"
                  alt="download app on apple store"
                ></img>
              </a>
              <a
                target="_blank"
                href={
                  i18n.language === "zh"
                    ? "https://play.google.com/store/apps/details?id=com.hrsee_app&hl=zh"
                    : "https://play.google.com/store/apps/details?id=com.hrsee_app&hl=en"
                }
              >
                <img
                  className="mt-1"
                  style={{ width: 150 }}
                  src="https://storage.googleapis.com/hr_api_storage/images/V3WpLgfS45Z.png"
                  alt="download app on play store"
                ></img>
              </a>
            </div>
          </div>

          <div className="header-dropdown border-right">
            <button className="btn btn-link text-muted">
              {i18n.language === "zh" && (
                <>
                  简体中文 <i className="fas fa-chevron-down"></i>
                </>
              )}
              {i18n.language === "en" && (
                <>
                  English <i className="fas fa-chevron-down"></i>
                </>
              )}
            </button>
            <div className="header-dropdown-content ">
              {i18n.language === "zh" && (
                <Link
                  className="btn btn-link"
                  to={getLanguageRouter("", "/zh")}
                  onClick={() => {
                    i18n.changeLanguage("en");
                  }}
                >
                  English
                </Link>
              )}
              {i18n.language === "en" && (
                <Link
                  className="btn btn-link"
                  to={getLanguageRouter("/zh/", "/")}
                >
                  简体中文
                </Link>
              )}
            </div>
          </div>
          <Link to="/employer/login" className="btn btn-link text-muted">
            <i className="far fa-user mr-1"></i>
            {i18n.t("employer-login")}
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Header;
