import React, { Component } from "react";

import { i18n } from "./i18n";

import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import * as Api from "./Api";
import { ConfirmButtonCustomTip } from "./ConfirmButtonCustomTip";
import moment from 'moment';

export default class WorkingHour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      fileName: "",
      data: [],
      offset: 1,
      total: -1,
      forcePage: null,
      pageCount: 0,
      pageSize: 15,
      // periods: [],
      // periodsStart: [],
      selectedPeriod: "",
      selectedPeriod2: "",
      uploadedYears: [],
      listLoading: false,
      options: []
    };
    this.file = null;




  }
  componentDidMount = () => {
    let timeOptions = []
    for (let i = 0; i < 20; i++) {
      let tmpDate = moment().add(-7 * i, 'd')
      timeOptions.push({
        value: tmpDate.startOf('week').format('YYYY-MM-DD') + " ~ " + tmpDate.endOf('week').format('YYYY-MM-DD'),
        label: tmpDate.startOf('week').format('YYYY-MM-DD') + " ~ " + tmpDate.endOf('week').format('YYYY-MM-DD')
      })
    }

    // { offset: 1, forcePage: null, selectedPeriod2: e.target.value, fileName: "" }
    this.setState({
      options: timeOptions,
      selectedPeriod: timeOptions[0].value,
      selectedPeriod2: timeOptions[0].value,
      offset: 1, forcePage: null,
    }
      , () => this.loadDataFromServer(true)
    )
  }
  onFileSelect = e => {
    switch (e.target.name) {
      case "selectedFile":
        if (e.target.files.length > 0) {
          // Accessed .name from file
          const reader = new FileReader();
          reader.onload = e => {
            this.setState({ success: false });
          };
          reader.readAsDataURL(e.target.files[0]);
          this.file = e.target.files[0];
          this.setState({ fileName: e.target.files[0].name });
        }
        break;
      default:
    }
  };
  handleSubmitClick = () => {
    if (!this.state.selectedPeriod) {
      alert("Please select which week")
      return
    }
    if (this.file) {
      this.setState({ listLoading: true });
      Api.uploadWorkingHour(this.state.selectedPeriod, this.file, global._token).then(response => {
        if (response.ok) {
          this.setState({ success: true, listLoading: false });
          toast.success(i18n.t("t4.uploadSuccess"), {
            className: "alert alert-success"
          });
          let timer = setTimeout(() => {
            this.loadUploadedYear();
            console.log('This will run after 1 second!')
          }, 2000);
          return () => clearTimeout(timer);
        }
        else {
          toast.error(i18n.t("t4.uploadFailed"), {
            className: "alert alert-danger"
          });
          this.setState({ success: false, listLoading: false });
        }
      }).catch(e => {
        this.setState({ success: false, listLoading: false });
        toast.error(e.message, { className: "alert alert-danger" });
      });
    }
  };

  componentDidMount() {
    this.loadUploadedYear();
  }
  loadUploadedYear = () => {
    // Api.getUploadedWorkingHourPeroid(global._token).then(e => {
    //   if (e && e.year.length !== 0) { //&& e.length !== 0
    //     console.log(JSON.stringify(e));
    //     this.setState(
    //       {
    //         uploadedYears: e.year,
    //         selectedPeriod2: e.year[e.year.length - 1]
    //       },
    //       () => this.loadT4DataFromServer()
    //     );
    //   }
    // });
  }

  // periods: e.items.peroid,
  // periodsStart: e.items.peroidStart,
  // selectedPeriod2: e.year[e.year.length - 1],
  // selectedPeriodStartEnd: e.items.peroidStart[e.items.peroid.length - 1]+'-'+e.items.peroid[e.items.peroid.length - 1]
  // }

  handlePageClick = data => {
    this.setState({ offset: data.selected + 1 }, () => {
      this.loadDataFromServer();
    });
  };

  loadT4DataFromServer = (forcePage = false) => {
    this.setState({ listLoading: true });
    Api.getT4(
      this.state.selectedPeriod2,
      this.state.offset,
      this.state.pageSize,
      global._token
    ).then(p => {
      if (p && p.count !== 0) {
        this.setState({
          data: p.items,
          pageCount: Math.ceil(p.count / this.state.pageSize),
          forcePage: forcePage ? 0 : undefined,
          listLoading: false
        });
      } else {
        this.setState({
          data: [],
          pageCount: 0,
          forcePage: null,
          listLoading: false
        });
      }
    });
  };

  loadDataFromServer = (forcePage = false) => {
    this.setState({ listLoading: true });
    Api.getWorkingHour(
      this.state.selectedPeriod2,
      this.state.offset,
      this.state.pageSize,
      global._token
    ).then(p => {
      if (p && p.count !== 0) {
        this.setState({
          data: p.items,
          pageCount: Math.ceil(p.count / this.state.pageSize),
          forcePage: forcePage ? 0 : undefined,
          listLoading: false
        });
      } else {
        this.setState({
          data: [],
          pageCount: 0,
          forcePage: null,
          listLoading: false
        });
      }
    });
  };

  handleT4PeriodChange = e => {
    this.setState(
      { offset: 1, forcePage: null, selectedPeriod: e.target.value, fileName: "" }
      // , () => this.loadT4DataFromServer(true)
    );
  };
  handleWorkingHourWeekChange = e => {
    this.setState(
      { offset: 1, forcePage: null, selectedPeriod: e.target.value, fileName: "" }
      // , () => this.loadT4DataFromServer(true)
    );
  };
  handleWorkingHourWeekChange2 = e => {
    this.setState(
      { offset: 1, forcePage: null, selectedPeriod2: e.target.value, fileName: "" }
      , () => this.loadDataFromServer(true)
    );
  };

  handleT4PeriodChange2 = e => {
    // this.t4_period = e.target.value;
    this.setState(
      {
        offset: 1, forcePage: null, selectedPeriod2: e.target.value
      }
      , () => this.loadT4DataFromServer()
      //true)
    );
  };

  handleDownloadButtonClick = _id => {
    this.setState({ listLoading: true });
    Api.downloadT4(
      _id,
      global._token
    ).then(response => {
      this.loadT4DataFromServer(true);
    });
  };
  handleDeleteClick = () => {
    Api.deleteWorkingHour(this.state.selectedPeriod2.split(' ~ ')[0].replace(/-/g, ""), global._token)
      .then(response => {
        if (response.ok) {
          toast.success(i18n.t("workingHour.deleteSuccess"), {
            className: "alert alert-success"
          });
          this.loadDataFromServer(true);
        } else {
          throw new Error(i18n.t("workingHour.deleteFailed"));
        }
      })
      .catch(e => {
        toast.error(e.message, { className: "alert alert-danger" });
      });
  };

  render() {
    return (
      <>
        <div className="card mt-2 border-0 shadow-sm">
          <div className="card-body">
            <div className="mb-3 row border-bottom">
              <div className="col-2 form-group">
                {i18n.t("workingHour.selectWeekToUpload")}
              </div>
              <div className="col-3 form-group">
                {/* <label htmlFor="t4-control" className="sr-only">
                  T4 period select
                </label> */}
                <select
                  className="form-control"
                  id="t4-control"
                  value={this.state.selectedPeriod}
                  onChange={this.handleWorkingHourWeekChange}
                >
                  {this.state.options.map((item, index) => (
                    <option key={index} value={item.value} >
                      {item.label}
                    </option>
                  ))}

                </select>
              </div>

              <div className="input-group  col-6">
                <div className="custom-file">
                  <input
                    type="file"
                    name="selectedFile"
                    className="custom-file-input"
                    id="inputGroupFile02"
                    onChange={this.onFileSelect}
                    accept=".csv"
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="inputGroupFile02"
                    aria-describedby="inputGroupFileAddon02"
                  >
                    {this.state.fileName === ""
                      ? i18n.t("workingHour.chooseFile")
                      : this.state.fileName}
                  </label>
                </div>
                {this.state.fileName ? (
                  <div className="input-group-append">
                    <button
                      type="button"
                      className={
                        this.state.success
                          ? "btn btn-success"
                          : "btn btn-primary"
                      }
                      onClick={
                        this.state.success ? null : this.handleSubmitClick
                      }
                    >
                      {this.state.success
                        ? i18n.t("profile.success")
                        : i18n.t("profile.upload")}
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="mb-3 row">
              <div className="col-2 form-group">
                {i18n.t("workingHour.UploadedWorkingHour")}
              </div>
              <div className="col-3 form-group">
                <select
                  className="form-control"
                  id="t4-control"
                  value={this.state.selectedPeriod2}
                  onChange={this.handleWorkingHourWeekChange2}
                >
                  {this.state.options.map((item, index) => (
                    <option key={index} value={item.value} >
                      {item.label}
                    </option>
                  ))}

                </select>
              </div>

              <div className="col-1 form-group">
                <ConfirmButtonCustomTip
                  confirmHandler={() =>
                    this.handleDeleteClick()
                  }
                  className="btn btn-link text-muted"
                  customTip="workingHour.deleteConfirm"
                  customTipParams={{ year: this.state.selectedPeriod2 }}
                >
                  <span className="sr-only">
                    {i18n.t("t4-dashboard.table-delete")}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-trash-2"
                  >
                    <polyline points="3 6 5 6 21 6" />
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                    <line x1="10" y1="11" x2="10" y2="17" />
                    <line x1="14" y1="11" x2="14" y2="17" />
                  </svg>
                </ConfirmButtonCustomTip>
              </div>

            </div>

            <div className="table-responsive position-relative">
              <table className="table table-bordered table-striped table-hover align-middle text-nowrap text-center">
                <thead>
                  <tr>
                    <th scope="col">{i18n.t("t4-dashboard.name")}</th>
                    <th scope="col">{i18n.t("workingHour.detail")}</th>
                    <th scope="col">{i18n.t("workingHour.note")}</th>
                    <th scope="col">{i18n.t("workingHour.period")}</th>
                    <th scope="col">{i18n.t("workingHour.total")}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{Object.keys(item.detail).map((key) => { return <>{key + " "+ item.detail[key]}<br/></>})}</td>
                      <td>{item.note}</td>
                      <td>{item.period_label}</td>
                      <td>{item.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={i18n.t("paginate.previous")}
                nextLabel={i18n.t("paginate.next")}
                breakLabel={"..."}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                forcePage={this.state.forcePage}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                breakClassName={"page-item"}
                disabledClassName={"disabled"}
                pageClassName={"page-item"}
                previousClassName={"page-item"}
                nextClassName={"page-item"}
                breakLinkClassName="page-link"
                pageLinkClassName={"page-link"}
                activeClassName={"active"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
              />
              {this.state.listLoading && (
                <div
                  className="position-absolute d-flex justify-content-center align-items-center"
                  style={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    zIndex: 1,
                    background: "rgba(0, 0, 0, 0.2)"
                  }}
                >
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
