import React from "react";
import { Field, ErrorMessage } from "formik";
import config from "../config";
import { i18n } from "../i18n";
class BasicInfoCard extends React.Component {
  constructor(props) {
    super(props);
  }
  languagePath = i18n.language === "zh" ? "name_cn" : "name";
  handleSave = () => {
    this.props.handleContinue(() => {});
  };

  render() {
    return (
      <>
        <div className="form-group row">
          <label htmlFor="job-category-id" className="col-sm-3 col-form-label">
            {i18n.t("postJob.job-category")}
          </label>
          <div className="col-sm-9">
            <Field
              component="select"
              name="category"
              id="job-category-id"
              className="form-control"
              onChange={event => {
                this.props.handleResetValue({
                  category: event.target.value,
                  template: "",
                  requiredSkill: []
                });
              }}
            >
              <option value="">
                {i18n.t("simplePost.category-placeholder")}
              </option>
              {this.props.categoryOptions
                .filter(e => e._id !== config.commonId)
                .map((c, index) => (
                  <option value={c._id} key={"category" + index}>
                    {c.parent[0][this.languagePath]}
                  </option>
                ))}
            </Field>
            <ErrorMessage
              className="invalid-feedback d-block"
              name="category"
              component="div"
            />
          </div>
        </div>
        {this.props.category && (
          <div className="form-group row">
            <label
              htmlFor="job-template-id"
              className="col-sm-3 col-form-label"
            >
              {i18n.t("postJob.template")}
            </label>
            <div className="col-sm-9">
              <Field
                component="select"
                name="template"
                id="job-template-id"
                className="form-control"
                onChange={event => {
                  const idx = parseInt(event.target.value, 10);
                  this.props.handleResetValue({
                    template: event.target.value,
                    ...(idx >= 0 && {
                      title: this.props.templateOptions[this.props.category][0]
                        .templates[idx][this.languagePath],
                      requiredSkill: this.props.templateOptions[
                        this.props.category
                      ][0].templates[idx].mandatorySkillRequirements
                    })
                  });
                }}
              >
                <option
                  value=""
                  label={i18n.t("postJob.template-placeholder")}
                />
                {this.props.templateOptions[
                  this.props.category
                ][0].templates.map((c, index) => (
                  <option value={index} key={c._id}>
                    {c[this.languagePath]}
                  </option>
                ))}
              </Field>
            </div>
          </div>
        )}
        <div className="form-group row">
          <label htmlFor="job-title-id" className="col-sm-3 col-form-label">
            {i18n.t("postJob.job-title")}
          </label>
          <div className="col-sm-9">
            <Field
              type="text"
              name="title"
              id="job-title-id"
              className="form-control"
            />
            <ErrorMessage
              className="invalid-feedback d-block"
              name="title"
              component="div"
            />
          </div>
        </div>
        <div className="form-group row">
          <label
            htmlFor="job-description-id"
            className="col-sm-3 col-form-label"
          >
            {i18n.t("postJob.description")}
          </label>
          <div className="col-sm-9">
            <Field
              type="text"
              component="textarea"
              name="description"
              placeholder={i18n.t("postJob.description-hint")}
              id="job-description-id"
              className="form-control"
              rows="4"
            />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button onClick={this.handleSave} className="btn btn-primary">
            {i18n.t("simplePost.continue")}
          </button>
        </div>
      </>
    );
  }
}

export default BasicInfoCard;
