import React, { useState, useEffect, useRef } from "react";
import { Field, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import { i18n } from "../i18n";
import Checkbox from "./Checkbox";
import { Dialog } from "@reach/dialog";
import VisuallyHidden from "@reach/visually-hidden";
import format from "date-fns/format";
import CloseIcon from "../component/CloseIcon";
import { zhCN as zh, enUS as en } from "date-fns/locale";
const sortArray = [
	"Mon",
	"Tue",
	"Wed",
	"Thu",
	"Fri",
	"Sat",
	"Sun",
	"M-F",
	"SS"
];

function WorkTimeInfo(props) {
	const [editTimeState, setEditTimeState] = useState(false);
	const resetValue = useRef(null);
	const locale = i18n.language === "zh" ? zh : en;
	const dateFormat = i18n.language === "zh" ? "M月dd日" : "MMMM do";
	const timeFormat = i18n.language === "zh" ? "aaaa hh:mm" : "hh:mm aaaa";
	const workingDayPayload = props.workingDay.sort(
		(a, b) => sortArray.indexOf(a) - sortArray.indexOf(b)
	);
	function handleTimeEdit(event) {
		event.preventDefault();

		setEditTimeState(true);
	}
	function handleTimeConfirm() {
		resetValue.current = null;

		setEditTimeState(false);
	}
	function handleTimeCancel(event) {
		event.preventDefault();

		setEditTimeState(false);
	}
	function handleLabelClick() {
		setEditTimeState(true);
	}
	function handleTimeDismiss() {
		setEditTimeState(false);
	}
	function handleSave(event) {
		event.preventDefault();
		props.handleContinue(handleTimeConfirm);
	}
	useEffect(() => {
		if (editTimeState === true) {
			// set initial value
			resetValue.current = {
				startDate: props.startDate,
				endDate: props.endDate,
				permanent: props.permanent,
				startTime: props.startTime,
				endTime: props.endTime,
				workingDay: props.workingDay,
				asap: props.asap,
				flexibleTime: props.flexibleTime
			};
		} else {
			if (resetValue.current) {
				// resetValue
				props.handleResetValue(resetValue.current);
			}
		}
	}, [editTimeState]);

	const weekday =
		workingDayPayload.includes("Mon") &&
		workingDayPayload.includes("Tue") &&
		workingDayPayload.includes("Wed") &&
		workingDayPayload.includes("Thu") &&
		workingDayPayload.includes("Fri");
	const weekend =
		workingDayPayload.includes("Sat") && workingDayPayload.includes("Sun");
	const anyWeekend =
		workingDayPayload.includes("Sat") || workingDayPayload.includes("Sun");
	const anyWeekday =
		workingDayPayload.includes("Mon") ||
		workingDayPayload.includes("Tue") ||
		workingDayPayload.includes("Wed") ||
		workingDayPayload.includes("Thu") ||
		workingDayPayload.includes("Fri");
	let dayDisplayValue = "";
	if (weekday && weekend) {
		dayDisplayValue = i18n.t("weekdays.everyDay");
	} else {
		if (weekday && !anyWeekend) {
			dayDisplayValue = i18n.t("weekdays.M-F");
		} else if (!anyWeekday && weekend) {
			dayDisplayValue = i18n.t("weekdays.SS");
		} else {
			dayDisplayValue = workingDayPayload
				.map(d => i18n.t("weekdays." + d))
				.join(" ");
		}
	}

	return (
		<div className="row mb-3">
			<div className="col-sm-3 col-form-label" onClick={handleLabelClick}>
				{i18n.t("postJob.step4")}
			</div>
			<a
				className="col-sm-9 d-flex justify-content-between"
				style={{
					paddingTop: "calc(0.375rem + 1px)",
					paddingBottom: "calc(0.375rem + 1px)",
					borderBottom: "dashed 1px #0088cc",
					textDecoration: "none"
				}}
				href="#"
				onClick={handleTimeEdit}
			>
				{props.asap
					? i18n.t("simplePost.asap") + "; "
					: i18n.t("simplePost.start") + " " + format(props.startDate, dateFormat, { locale: locale })
				}

				{props.endDate &&
					!props.permanent &&
					` ${i18n.t("simplePost.end")} ${format(props.endDate, dateFormat, {
						locale: locale
					})}; `}

				{props.flexibleTime
					? i18n.t("simplePost.flexibleTime") + "; "
					: ` ${format(props.startTime, timeFormat, {
						locale: locale
					})} - ${format(props.endTime, timeFormat, {
						locale: locale
					})}; `
				}

				{dayDisplayValue}
			</a>
			<Dialog isOpen={editTimeState}>
				<div className="d-flex justify-content-end">
					<button type="button" className="close" onClick={handleTimeDismiss}>
						<VisuallyHidden>Close</VisuallyHidden>
						<CloseIcon />
					</button>
				</div>
				<div className="row">
					<div className="form-group col">
						<div className="custom-control custom-checkbox">
							<Field
								name="asap"
								className="custom-control-input"
								type="checkbox"
								id="asap-checkbox"
								checked={props.asap}
							/>
							<label className="custom-control-label" htmlFor="asap-checkbox">
								{i18n.t("simplePost.asap")}
							</label>
						</div>
					</div>
				</div>
				{!props.asap && (
					<>
						<div className="row">
							<div className="form-group col new-post-form">
								<label htmlFor="job-start-date-id" className="col-form-label">
									{i18n.t("postJob.start-date")}
								</label>
								<Field
									name="startDate"
									render={({ field, form }) => (
										<DatePicker
											todayButton="Today"
											minDate={new Date()}
											selected={props.startDate}
											onChange={value => {
												field.onChange(value);
												form.setFieldValue(field.name, value);
											}}
											id="job-start-date-id"
											className="form-control"
										/>
									)}
								></Field>
								<ErrorMessage
									className="invalid-feedback d-block"
									name="startDate"
									component="div"
								/>
							</div>
							{!props.permanent && (
								<div className="form-group col new-post-form">
									<label htmlFor="job-end-date-id" className="col-form-label">
										{i18n.t("postJob.end-date")}
									</label>
									<Field
										name="endDate"
										render={({ field, form }) => (
											<DatePicker
												minDate={props.startDate}
												selected={props.endDate}
												onChange={value => {
													field.onChange(value);
													form.setFieldValue(field.name, value);
												}}
												id="job-end-date-id"
												className="form-control"
											/>
										)}
									></Field>
									<ErrorMessage
										className="invalid-feedback d-block"
										name="endDate"
										component="div"
									/>
								</div>
							)}
						</div>

						<div className="form-group">
							<div className="custom-control custom-checkbox">
								<Field
									name="permanent"
									className="custom-control-input"
									type="checkbox"
									id="permanent-checkbox"
									checked={props.permanent}
								/>
								<label
									className="custom-control-label"
									htmlFor="permanent-checkbox"
								>
									{i18n.t("postJob.no-end-date")}
								</label>
							</div>
						</div>
					</>
				)}
				<div className="dropdown-divider my-4"></div>

				<div className="form-group">
					<div className="custom-control custom-checkbox">
						<Field
							name="flexibleTime"
							className="custom-control-input"
							type="checkbox"
							id="flexible-checkbox"
							checked={props.flexibleTime}
						/>
						<label className="custom-control-label" htmlFor="flexible-checkbox">
							{i18n.t("simplePost.flexibleTime")}
						</label>
					</div>
				</div>
				{!props.flexibleTime ?
					<div className="row">
						<div className="form-group col new-post-form">
							<label htmlFor="job-start-time-id" className="col-form-label">
								{i18n.t("postJob.work-from")}
							</label>
							<Field
								name="startTime"
								render={({ field, form }) => (
									<DatePicker
										showTimeSelect
										showTimeSelectOnly
										selected={props.startTime}
										onChange={value => {
											field.onChange(value);
											form.setFieldValue(field.name, value);
										}}
										id="job-start-time-id"
										className="form-control"
										timeIntervals={15}
										timeCaption="Time"
										dateFormat="h:mm aa"
									/>
								)}
							></Field>
							<ErrorMessage
								className="invalid-feedback d-block"
								name="startTime"
								component="div"
							/>
						</div>
						<div className="form-group col new-post-form">
							<label htmlFor="job-end-time-id" className="col-form-label">
								{i18n.t("postJob.work-to")}
							</label>
							<Field
								name="endTime"
								render={({ field, form }) => (
									<DatePicker
										showTimeSelect
										showTimeSelectOnly
										selected={props.endTime}
										onChange={value => {
											field.onChange(value);
											form.setFieldValue(field.name, value);
										}}
										id="job-start-time-id"
										className="form-control"
										timeIntervals={15}
										timeCaption="Time"
										dateFormat="h:mm aa"
									/>
								)}
							></Field>
							<ErrorMessage
								className="invalid-feedback d-block"
								name="endTime"
								component="div"
							/>
						</div>
					</div>
					:
					null
				}

				<div className="dropdown-divider my-4"></div>
				<fieldset className="mb-3">
					<Checkbox
						name="workingDay"
						value="Mon"
						displayValue={i18n.t("weekdays.Mon")}
					/>
					<Checkbox
						name="workingDay"
						value="Tue"
						displayValue={i18n.t("weekdays.Tue")}
					/>
					<Checkbox
						name="workingDay"
						value="Wed"
						displayValue={i18n.t("weekdays.Wed")}
					/>
					<Checkbox
						name="workingDay"
						value="Thu"
						displayValue={i18n.t("weekdays.Thu")}
					/>
					<Checkbox
						name="workingDay"
						value="Fri"
						displayValue={i18n.t("weekdays.Fri")}
					/>
					<Checkbox
						name="workingDay"
						value="Sat"
						displayValue={i18n.t("weekdays.Sat")}
					/>
					<Checkbox
						name="workingDay"
						value="Sun"
						displayValue={i18n.t("weekdays.Sun")}
					/>
				</fieldset>
				<ErrorMessage
					className="invalid-feedback d-block"
					name="workingDay"
					component="div"
				/>
				<div className="d-flex justify-content-end">
					<a href="#" className="btn btn-primary" onClick={handleSave}>
						{i18n.t("simplePost.save")}
					</a>
					<a href="#" className="btn btn-link" onClick={handleTimeCancel}>
						{i18n.t("simplePost.cancel")}
					</a>
				</div>
			</Dialog>
		</div>
	);
}

export default WorkTimeInfo;
