import React, { useEffect, useState } from "react";
import * as Api from "./Api";
import { Link } from "@reach/router";
import { i18n } from "./i18n";
export default function Jumbotron() {
  const [postState, setPostState] = useState(0);
  useEffect(() => {
    Api.getCompanyInfo(global._token).then(result => {
      if (result.companyName && result.address.name) {
        setPostState(2);
      } else {
        setPostState(1);
      }
    });
  }, []);
  return (
    <div className="jumbotron mt-3">
      <div className="display-4">No results found</div>
      <p className="lead mt-1">you don't have any job postings to review</p>
      <hr className="my-4" />
      <p className="lead">
        {postState === 2 ? (
          <>
            <Link to="/employer/post" className="btn btn-primary btn-lg my-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-send"
              >
                <line x1="22" y1="2" x2="11" y2="13" />
                <polygon points="22 2 15 22 11 13 2 9 22 2" />
              </svg>
              <span className="ml-1">{i18n.t("jobBoard.postJob")}</span>
            </Link>
            <Link to="/employer/draft" className="btn btn-link btn-lg my-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-archive"
              >
                <polyline points="21 8 21 21 3 21 3 8" />
                <rect x="1" y="3" width="22" height="5" />
                <line x1="10" y1="12" x2="14" y2="12" />
              </svg>
              <span className="ml-1">{i18n.t("draft.draft")}</span>
            </Link>
          </>
        ) : postState === 1 ? (
          <Link to="/employer/profile" className="btn btn-primary btn-lg my-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-archive"
            >
              <polyline points="21 8 21 21 3 21 3 8" />
              <rect x="1" y="3" width="22" height="5" />
              <line x1="10" y1="12" x2="14" y2="12" />
            </svg>
            <span className="ml-1">Complete your profile</span>
          </Link>
        ) : null}
      </p>
    </div>
  );
}
