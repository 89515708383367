import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import qs from "qs";
import isSafeInteger from "lodash/isSafeInteger";
import { i18n } from "../i18n";

function JobListHead(props) {
  const [canonicalLink, setCanonicalLink] = useState("/");
  const [prevLink, setPrevLink] = useState("/");
  const [nextLink, setNextLink] = useState("/");
  const [title, setTitle] = useState("Hirine");
  useEffect(() => {
    const rootPath = props.location.pathname;
    const q = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const page = parseInt(q.page, 10);
    if (isSafeInteger(page)) {
      setCanonicalLink(rootPath + props.location.search);
      if (page === 2) {
        q.page = null;
      } else {
        q.page = page - 1;
      }
      const query = qs.stringify(q, { skipNulls: true, addQueryPrefix: true });
      setPrevLink(rootPath + query);
      q.page = page + 1;
      const nextQuery = qs.stringify(q, {
        skipNulls: true,
        addQueryPrefix: true
      });
      setNextLink(rootPath + nextQuery);
    } else {
      setCanonicalLink(rootPath + props.location.search);
      q.page = 2;
      const query = qs.stringify(q, { addQueryPrefix: true });
      setNextLink(rootPath + query);
    }
  }, [
    props.page,
    props.lastPage,
    props.location.pathname,
    props.location.search
  ]);

  function searchString(search, language) {
    let result = "";
    const q = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    if (q.page) {
      result += i18n.t("seo.page") + q.page + i18n.t("seo.page-suffix") + " | ";
    }
    if (q.keyword && q.keyword.length !== 0) {
      result += i18n.t("seo.keyword") + ": " + q.keyword + " | ";
    }
    if (q.type && q.type.length !== 0) {
      result += i18n.t("jobType." + q.type) + ' | ';
    }
    if (result.length === 0) {
      if (language === "zh") {
        result = "Hirine: 快速找工作，免费发工作";
      } else {
        result = "Hirine: Quick Job Search, Free Job Post";
      }
    } else {
      result += "Hirine";
    }

    return result;
  }

  useEffect(() => {
    setTitle(searchString(props.location.search, props.language));
  }, [props.language, props.location.search]);

  return (
    <Helmet>
      <html lang="en" lang={props.language === "en" ? "en-CA" : "zh"} />
      <title>{title}</title>
      {/* <link rel="canonical" href={canonicalLink} /> */}
      {props.page > 1 && <link rel="prev" href={prevLink} />}
      {props.page < props.lastPage && <link rel="next" href={nextLink} />}
    </Helmet>
  );
}

export default JobListHead;
