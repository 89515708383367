import React from "react";
import * as Api from "./Api";
export const AuthContext = React.createContext();

export class AuthProvider extends React.Component {
  state = {
    token: null,
    requested: false,
    loading: false,
    company: null
  };

  componentDidMount() {
    const _token = window.localStorage.getItem("login_token");
    if (_token) {
      this.setState({ loading: true }, () => {
        Api.checkToken(_token)
          .then(json => {
            if (json) {
              global._token = _token;

              Api.getCompanyInfo(global._token)
                .then(result => {
                  /** @todo (ren) fix this */
                  if (result.createdAt) {
                    this.setState({
                      token: _token,
                      requested: true,
                      loading: false,
                      company: result
                    });
                  } else {
                    this.setState({ requested: true, loading: false });
                  }
                })
                .catch(error => {
                  this.setState({ requested: true, loading: false });
                });
            } else {
              this.setState({ requested: true, loading: false });
            }
          })
          .catch(error => {
            console.log(error);
            this.setState({ requested: true, loading: false });
          });
      });
    } else {
      this.setState({ requested: true });
    }
  }

  login = token => {
    global._token = token;
    window.localStorage.setItem("login_token", token);
    this.setState({ token });
    Api.getCompanyInfo(token).then(result => {
      /** @todo (ren) fix this */
      if (result.createdAt) {
        this.setState({
          company: result
        });
      }
    });
  };

  logout = () => {
    global._token = null;
    window.localStorage.removeItem("login_token");
    this.setState({ token: null });
  };

  render() {
    return (
      <AuthContext.Provider
        value={{ ...this.state, login: this.login, logout: this.logout }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
