import React from "react";
import { Helmet } from "react-helmet";
import { i18n } from "../i18n";
export default function(props) {
  return (
    <>
      <Helmet>
        <html lang={i18n.language === "en" ? "en-CA" : "zh"} />
        <title>
          {i18n.language === "en"
            ? "Hirine: Quick Job Search, Free Job Post"
            : "Hirine: 快速找工作，免费发工作"}
        </title>
        <meta
          name="description"
          content={
            i18n.language === "en"
              ? "Search or post available jobs in Canada with Hirine. Find job nearby or around Canada without resume, or search right candidates quickly."
              : "用Hirine找工作招员工. 多伦多, 温哥华, 蒙特利尔等加拿大各地工作快速申请发布. 加拿大招聘求职神器. "
          }
        />
        <meta
          name="keywords"
          content={
            i18n.language === "en"
              ? "Jobs in Canada, Jobs in Toronto, Jobs in Brampton, Jobs in Mississauga, Jobs in Markham, Jobs in GTA, job listing, job search, general labour"
              : "找工, 求职, 找工作, 工作介绍, 加拿大工作, 多伦多工作, 工厂工作, 餐馆工作"
          }
        />
        <meta
          name="language"
          content={i18n.language === "en" ? "en-CA" : "zh"}
        />
        <meta name="locale" content={i18n.language === "en" ? "en-CA" : "zh"} />
      </Helmet>
      {props.children}
    </>
  );
}
