import React, { Component } from "react";
import config from "../config";
import io from "socket.io-client";
import * as Api from "../Api";
import parseISO from "date-fns/parseISO";
import ContactList from "./ContactList";
import MessageBanner from "./MessageBanner";
import MessageBoard from "./MessageBoard";
import MessageInput from "./MessageInput";
import debounce from "lodash/debounce";
import { navigate } from "@reach/router";
import PersonIcon from "../img/person_small.png";
class Chat extends Component {
  /** state = {
    selected: 123132,
    contact: {
      list: [123123],
      contactById: {
        123123: {
          latestMessage: null,
          user: null
        }
      }
    },
    message: {
      123123: {
        messsages: [],
        requested: false,
      },m
      456456: {
        messages: [],
        requested: false
      }
    }
    
  } **/

  state = {
    selected: null,
    contact: {
      requested: false,
      list: [],
      contactById: {}
    },
    message: {},
    input: {}
  };

  componentDidMount() {
    this.socket = io(config.apiurl, {
      transports: ["websocket"],
      query: {
        token: global._token
      }
    });
    this.socket.on("connect", () => {
      this.socket.on("res", this.handleMessage);
    });
    this.socket.on("disconnect", () => {
      this.socket.removeListener("res", this.handleMessage);
    });

    Api.getContact(global._token).then(res => {
      if (res && res.items) {
        let updateState = {
          contact: {
            list: res.items.map(c => c._id),
            contactById: {},
            requested: true
          },
          message: {},
          input: {}
        };
        res.items.forEach(item => {
          updateState.contact.contactById[item._id] = {
            user: item.user,
            latestMessage: item.latestMessage
          };
          updateState.message[item._id] = {
            messages: [],
            requested: false
          };
          updateState.input[item._id] = {
            text: ""
          };
        });
        // console.log(updateState)
        this.setState(updateState);
      }
    });
  }
  handleNewChat = (
    user,
    latestMessage = {},
    selectNewUser = undefined,
    removeUrlId = false
  ) => {
    Api.getChatUser(user, global._token).then(info => {
      this.setState(prevState => {
        if (info._id) {
          return {
            contact: {
              list: prevState.contact.list.concat(info._id),
              contactById: {
                ...prevState.contact.contactById,
                [info._id]: {
                  user: info,
                  latestMessage: latestMessage
                }
              }
            },
            message: {
              ...prevState.message,
              [info._id]: {
                messsages: [],
                requested: false
              }
            },
            input: {
              ...prevState.input,
              [info._id]: {
                text: ""
              }
            },
            ...(selectNewUser && { selected: selectNewUser })
          };
        } else {
          throw new Error("We cant find this user");
        }
      });
      if (removeUrlId) {
        navigate("/employer/chat", { replace: true });
      }
    });
  };

  handleMessage = res => {
    if (res.type === "status") {
      // get user id here
      this.userId = res.content.msg;
    } else if (res.type === "message") {
      if (
        // if someone who is not an existed contact sent you a message
        res.content.from !== this.userId &&
        !this.state.contact.contactById[res.content.from]
      ) {
        this.handleNewChat(res.content.from, {
          ...res.content,
          content: res.content.msg
        });
      }

      // set message unread to false when we receive the message while talk to them
      if (
        res.content.from !== this.userId &&
        this.state.contact.contactById[res.content.from] &&
        this.state.message[res.content.from].requested &&
        this.state.selected === res.content.from
      ) {
        this.setMessageRead(res.content._id);
      }

      // if you send message to someone on your other device
      if (
        res.content.from === this.userId &&
        !this.state.message[res.content.to]
      ) {
        this.handleNewChat(res.content.to, {
          ...res.content,
          content: res.content.msg
        });
      }

      // updte state
      this.setState(prevState => {
        if (res.content.from === this.userId) {
          if (
            prevState.message[res.content.to] &&
            prevState.message[res.content.to].requested === true
          ) {
            return {
              message: {
                ...prevState.message,
                [res.content.to]: {
                  ...prevState.message[res.content.to],
                  messages: prevState.message[res.content.to].messages.concat({
                    ...res.content,
                    time: new Date(),
                    content: res.content.msg
                  })
                }
              }
            };
          }
        } else {
          if (prevState.contact.contactById[res.content.from]) {
            // exist contact
            // check chat history requested or not
            if (prevState.message[res.content.from].requested) {
              // push and update latest message
              return {
                message: {
                  ...prevState.message,
                  [res.content.from]: {
                    ...prevState.message[res.content.from],
                    messages: prevState.message[
                      res.content.from
                    ].messages.concat({
                      ...res.content,
                      time: new Date(),
                      content: res.content.msg
                    })
                  }
                },
                contact: {
                  ...prevState.contact,
                  contactById: {
                    ...prevState.contact.contactById,
                    [res.content.from]: {
                      ...prevState.contact.contactById[res.content.from],
                      latestMessage: {
                        ...res.content,
                        content: res.content.msg,
                        ...(prevState.selected === res.content.from && {
                          unread: false
                        })
                      }
                    }
                  }
                }
              };
            } else {
              // only update latest message
              return {
                contact: {
                  ...prevState.contact,
                  contactById: {
                    ...prevState.contact.contactById,
                    [res.content.from]: {
                      ...prevState.contact.contactById[res.content.from],
                      latestMessage: {
                        ...res.content,
                        content: res.content.msg
                      }
                    }
                  }
                }
              };
            }
          }
        }
      });
    }
  };

  setMessageRead = debounce(id => {
    Api.setMessageRead(id, global._token);
  }, 3000);

  handleMessageChange = (id, text) => {
    this.setState(prevState => ({
      input: {
        ...prevState.input,
        [id]: {
          text: text
        }
      }
    }));
  };

  handleSelectContact = contact => this.setState({ selected: contact });
  handleMessageSubmit = selected => {
    const payload = {
      type: "message",
      to: selected,
      from: this.userId,
      msg: this.state.input[selected].text
    };
    this.socket.emit("chat", payload);
    this.setState(prevState => ({
      input: {
        ...prevState.input,
        [selected]: {
          text: ""
        }
      },
      message: {
        ...prevState.message,
        [selected]: {
          ...prevState.message[selected],
          messages: prevState.message[selected].messages.concat({
            ...payload,
            content: payload.msg,
            time: new Date(),
            _id: "chat_" + Date.now()
          })
        }
      },
      contact: {
        ...prevState.contact,
        contactById: {
          ...prevState.contact.contactById,
          [payload.to]: {
            ...prevState.contact.contactById[payload.to],
            latestMessage: {
              ...payload.content,
              content: payload.msg,
              unread: false
            }
          }
        }
      }
    }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.selected &&
      this.state.selected !== prevState.selected &&
      !this.state.message[this.state.selected].requested
    ) {
      // request chat history
      Api.getChatHistory(this.state.selected, global._token).then(res => {
        if (res && res.items) {
          const history = res.items.map(item => ({
            time: parseISO(item.createdAt),
            ...item
          }));
          this.setState({
            message: {
              ...this.state.message,
              [this.state.selected]: {
                requested: true,
                messages: history
              }
            }
          });
        }
      });
    }

    // remove red dot
    if (
      this.state.selected &&
      this.state.selected !== prevState.selected &&
      this.state.contact.contactById[this.state.selected].latestMessage.unread
    ) {
      this.setState(currentState => {
        return {
          contact: {
            ...currentState.contact,
            contactById: {
              ...currentState.contact.contactById,
              [currentState.selected]: {
                ...currentState.contact.contactById[currentState.selected],
                latestMessage: {
                  ...currentState.contact.contactById[currentState.selected]
                    .latestMessage,
                  unread: false
                }
              }
            }
          }
        };
      });
    }

    // after getting contact list,

    if (
      this.state.contact.requested === true &&
      this.state.contact.requested !== prevState.contact.requested &&
      this.props.target
    ) {
      // check if the target id is in the contact list or not, if true, we will set selected to that target id automatically, if not true, we will query the user data, push data to the contact, and selected that contact
      if (this.state.contact.contactById[this.props.target]) {
        this.setState({ selected: this.props.target });
      } else {
        this.handleNewChat(this.props.target, {}, this.props.target, true);
      }
    }
  }
  componentWillUnmount() {
    this.socket.removeListener("res", this.handleMessage);
  }

  render() {
    return (
      <>
        <div
          className="rounded bg-white row mt-2"
          style={{ height: "calc(100vh - 60px)" }}
        >
          <ContactList
            userId={this.userId}
            contact={this.state.contact}
            selected={this.state.selected}
            handleSelectContact={this.handleSelectContact}
          ></ContactList>

           {/* icon={
                  this.state.contact.contactById[this.state.selected].user.icon
                } */}
          <div className="col-9 p-0 d-flex flex-column">
            {this.state.selected ? (
              <MessageBanner
                selectedUser={
                  this.state.contact.contactById[this.state.selected]
                }
              ></MessageBanner>
            ) : null}
           
            {this.state.selected &&
            this.state.message[this.state.selected].requested ? (
              <MessageBoard
                selectedMessage={
                  this.state.message[this.state.selected].messages
                }
                selected={this.state.selected}
                icon={
                  PersonIcon
                }
              ></MessageBoard>
            ) : (
              this.state.selected && (
                <div
                  style={{ flexBasis: 1, flexGrow: 1 }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )
            )}
            {this.state.selected && (
              <MessageInput
                selected={this.state.selected}
                value={this.state.input[this.state.selected].text}
                onChange={this.handleMessageChange}
                onSubmit={this.handleMessageSubmit}
              ></MessageInput>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Chat;
