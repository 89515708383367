import React, { Component } from "react";
import { Dialog } from "@reach/dialog";
import VisuallyHidden from "@reach/visually-hidden";
import { i18n } from "./i18n";
import CloseIcon from './component/CloseIcon';
export default class MandatorySkillDialog extends Component {
  state = {
    checkboxValue: []
  };
  languagePath = i18n.language === "zh" ? "name_cn" : "name";
  componentDidUpdate(prevProps) {
    if (prevProps.isOpen === false && this.props.isOpen === true) {
      const tempValue = [];
      // open dialog !
      this.props.requiredSkill.forEach(element => {
        tempValue.push(true);
      });
      this.props.preferredSkill.forEach(element => {
        tempValue.push(false);
      });

      this.setState({
        checkboxValue: tempValue
      });
    }
  }
  handleCheckbox = e => {
    const index = parseInt(e.target.dataset.index, 10);
    const newValue = this.state.checkboxValue.slice();
    newValue[index] = e.target.checked;
    this.setState({
      checkboxValue: newValue
    });
  };
  handleConfirm = e => {
    const mandatorySkill = [];
    const preferredSkill = [];
    const selectSkill = this.props.requiredSkill.concat(
      this.props.preferredSkill
    );
    this.state.checkboxValue.forEach((e, index) => {
      if (e) {
        mandatorySkill.push(selectSkill[index]);
      } else {
        preferredSkill.push(selectSkill[index]);
      }
    });
    this.props.handleChange(mandatorySkill, preferredSkill);
  };
  render() {
    return (
      <Dialog
        isOpen={this.props.isOpen}
        onDismiss={this.props.handleClose}
        // style={{ width: "90vw" }}
      >
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h2>{i18n.t("postJob.select-mandatory")}</h2>
          <button
            type="button"
            className="close"
            onClick={this.props.handleClose}
          >
            <VisuallyHidden>Close</VisuallyHidden>
            <CloseIcon />
          </button>
        </div>

        <div className="py-3">
          {this.props.requiredSkill
            .concat(this.props.preferredSkill)
            .map((v, index) => (
              this.props.allSkills[v] && <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`customCheck_${v}`}
                  data-index={index}
                  onChange={this.handleCheckbox}
                  checked={this.state.checkboxValue[index]}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`customCheck_${v}`}
                >
                  {this.props.allSkills[v][this.languagePath]}
                </label>
              </div>
            ))}
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={this.handleConfirm}
        >
          {i18n.t("postJob.confirm")}
        </button>
      </Dialog>
    );
  }
}
