import React, { Component } from "react";

import { i18n } from "./i18n";

import ReactPaginate from "react-paginate";
import { ConfirmButtonCustomTip } from "./ConfirmButtonCustomTip";
import { toast } from "react-toastify";
import * as Api from "./Api";

export default class PayDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      fileName: "",
      data: [],
      offset: 1,
      total: -1,
      forcePage: null,
      pageCount: 0,
      pageSize: 15,
      periods: [],
      periodsStart: [],
      selectedPeriod: "",
      selectedPeriodStart: "",
      listLoading: false
    };
    this.file = null;
  }
  onFileSelect = e => {
    switch (e.target.name) {
      case "selectedFile":
        if (e.target.files.length > 0) {
          // Accessed .name from file
          const reader = new FileReader();
          reader.onload = e => {
            this.setState({ success: false });
          };
          reader.readAsDataURL(e.target.files[0]);
          this.file = e.target.files[0];
          this.setState({ fileName: e.target.files[0].name });
        }
        break;
      default:
    }
  };
  handleSubmitClick = () => {
    if (this.file) {
      this.setState({ listLoading: true });
      Api.uploadCSV(this.file, global._token).then(response => {
        if (response.ok) {
          this.setState({ success: true, listLoading: false });
          toast.success(i18n.t("pay.uploadSuccess"), {
            className: "alert alert-success"
          });
          // window.location.reload();
          this.loadPayrollPeriod();
        } else {
          toast.error(i18n.t("t4.uploadFailed"), {
            className: "alert alert-danger"
          });
          this.setState({ success: false, listLoading: false });
        }
      }).catch(e => {
        this.setState({ listLoading: false });
        toast.error(e.message, { className: "alert alert-danger" });
      });;
    }
  };
  componentDidMount() {
    this.loadPayrollPeriod();
  }
  loadPayrollPeriod = () => {
    Api.getPayPeroid(global._token).then(e => {
      if (e && e.count !== 0) {
        this.setState(
          {
            periods: e.items.peroid,
            periodsStart: e.items.peroidStart,
            selectedPeriod: e.items.peroid[e.items.peroid.length - 1],
            selectedPeriodStartEnd: e.items.peroidStart[e.items.peroid.length - 1] + '-' + e.items.peroid[e.items.peroid.length - 1]
          },
          () => this.loadDataFromServer()
        );
      }else{
        this.setState(
          {
            periods: [],
            periodsStart: [],
            selectedPeriod: "",
            selectedPeriodStartEnd: ""
          },
          () => this.loadDataFromServer()
        );
      }
    });
  }
  handlePageClick = data => {
    this.setState({ offset: data.selected + 1 }, () => {
      this.loadDataFromServer();
    });
  };

  loadDataFromServer = (forcePage = false) => {
    this.setState({ listLoading: true });
    Api.getPay(
      this.state.selectedPeriod,
      this.state.offset,
      this.state.pageSize,
      global._token
    ).then(p => {
      if (p && p.count !== 0) {
        this.setState({
          data: p.items,
          pageCount: Math.ceil(p.count / this.state.pageSize),
          forcePage: forcePage ? 0 : undefined,
          listLoading: false
        });
      } else {
        this.setState({
          data: [],
          pageCount: 0,
          forcePage: null,
          listLoading: false,
          periods: [],
          periodsStart: [],
          selectedPeriod: "",
          selectedPeriodStartEnd: ""
        });
      }
    }).catch(e => {
      this.setState({
        data: [],
        pageCount: 0,
        forcePage: null,
        listLoading: false,
        periods: [],
        periodsStart: [],
        selectedPeriod: "",
        selectedPeriodStartEnd: ""
      });
      // this.setState({ listLoading: false });
      toast.error(e.message, { className: "alert alert-danger" });
    });
  };

  handlePayPeriodChange = e => {
    // this.state.periodsStart
    this.setState(
      { offset: 1, forcePage: null, selectedPeriod: e.target.value },
      () => this.loadDataFromServer(true)
    );

    let selectedPeriodStartEnd = "";
    this.state.periods.map((p, index) => {
      if (p == e.target.value) {
        selectedPeriodStartEnd = this.state.periodsStart[index] + '-' + e.target.value;
        // console.log(p+":"+index+",selectedPeriodStartEnd="+selectedPeriodStartEnd);
      }
    });
    this.setState({ selectedPeriodStartEnd: selectedPeriodStartEnd });
  };
  handleDeleteClick = () => {
    // this.setState({ listLoading: true });
    Api.deletePay(this.state.selectedPeriod, global._token)
      .then(response => {
        if (response.ok) { //if (response.ok) {
          toast.success(i18n.t("pay.payrollDeleteSuccess"), {
            className: "alert alert-success"
          });
          // this.setState({success:true, listLoading: false });
          // let timer = setTimeout(() => {
            this.loadPayrollPeriod(); //it includes loadDataFromServer();
            // console.log('This will run after 4 second!')
          // }, 4000);
          // return () => clearTimeout(timer);
        } else {
          this.loadPayrollPeriod(); 
          // this.setState({ listLoading: false });
          toast.error(i18n.t("pay.payrollDeleteFailed"), {
            className: "alert alert-danger"
          });
          // throw new Error(i18n.t("pay.payrollDeleteFailed"));
        }
      })
      .catch(e => {
        // this.setState({ listLoading: false });
        this.loadPayrollPeriod(); 
        console.log(e.message);
      });
  };
  render() {
    return (
      <>
        <div className="card mt-2 border-0 shadow-sm">
          <div className="card-body">
            <div className="mb-3 row">
              <div className="input-group  col-6">
                <div className="custom-file">
                  <input
                    type="file"
                    name="selectedFile"
                    className="custom-file-input"
                    id="inputGroupFile02"
                    onChange={this.onFileSelect}
                    accept=".csv"
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="inputGroupFile02"
                    aria-describedby="inputGroupFileAddon02"
                  >
                    {this.state.fileName === ""
                      ? i18n.t("pay.chooseFile")
                      : this.state.fileName}
                  </label>
                </div>
                {this.state.fileName ? (
                  <div className="input-group-append">
                    <button
                      type="button"
                      className={
                        this.state.success
                          ? "btn btn-success"
                          : "btn btn-primary"
                      }
                      onClick={
                        this.state.success ? null : this.handleSubmitClick
                      }
                    >
                      {this.state.success
                        ? i18n.t("profile.success")
                        : i18n.t("profile.upload")}
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="col-4 form-group">
                {/* <label htmlFor="pay-control" className="sr-only">
                  Pay period select
                </label> */}
                <select
                  className="form-control"
                  id="pay-control"
                  value={this.state.selectedPeriod}
                  onChange={this.handlePayPeriodChange}
                >
                  {this.state.periods.map((p, index) => (
                    <option key={index} value={p} data-period={p}>
                      {this.state.periodsStart &&
                        this.state.periodsStart.length > 0
                        ? this.state.periodsStart[index] + "-" + p
                        : p}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-2 form-group">
                <ConfirmButtonCustomTip
                  confirmHandler={() =>
                    this.handleDeleteClick()
                  }
                  className="btn btn-link text-muted"
                  customTip={"pay.payDeleteConfirm"}
                  customTipParams={{ "payPeriod": this.state.selectedPeriodStartEnd }}
                >
                  <span className="sr-only">
                    {i18n.t("t4-dashboard.table-delete")}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-trash-2"
                  >
                    <polyline points="3 6 5 6 21 6" />
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                    <line x1="10" y1="11" x2="10" y2="17" />
                    <line x1="14" y1="11" x2="14" y2="17" />
                  </svg>
                </ConfirmButtonCustomTip>
              </div>
            </div>

            <div className="table-responsive position-relative">
              <table className="table table-bordered table-striped table-hover align-middle text-nowrap text-center">
                <thead>
                  <tr>
                    <th scope="col">{i18n.t("pay-dashboard.cheque-num")}</th>
                    <th scope="col">{i18n.t("pay-dashboard.name")}</th>
                    <th scope="col">{i18n.t("pay-dashboard.amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.cheque_num}</td>
                      <td>{item.name}</td>
                      <td>${item.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={i18n.t("paginate.previous")}
                nextLabel={i18n.t("paginate.next")}
                breakLabel={"..."}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                forcePage={this.state.forcePage}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                breakClassName={"page-item"}
                disabledClassName={"disabled"}
                pageClassName={"page-item"}
                previousClassName={"page-item"}
                nextClassName={"page-item"}
                breakLinkClassName="page-link"
                pageLinkClassName={"page-link"}
                activeClassName={"active"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
              />
              {this.state.listLoading && (
                <div
                  className="position-absolute d-flex justify-content-center align-items-center"
                  style={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    zIndex: 1,
                    background: "rgba(0, 0, 0, 0.2)"
                  }}
                >
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
