import React, { useState, useEffect, useRef } from "react";
import { Field } from "formik";
import { i18n } from "../i18n";
import { Dialog } from "@reach/dialog";
import VisuallyHidden from "@reach/visually-hidden";
import CloseIcon from "../component/CloseIcon";

export default function Reminder(props) {
  const [editReminderState, setEditReminderState] = useState(false);
  const resetValue = useRef(null);
  function handleReminderEdit(event) {
    event.preventDefault();

    setEditReminderState(true);
  }
  function handleReminderConfirm() {
    resetValue.current = null;

    setEditReminderState(false);
  }
  function handleReminderCancel(event) {
    event.preventDefault();

    setEditReminderState(false);
  }
  function handleReminderDismiss() {
    setEditReminderState(false);
  }

  function handleSave(event) {
    event.preventDefault();
    props.handleContinue(handleReminderConfirm);
  }

  useEffect(() => {
    if (editReminderState === true) {
      // set initial value
      resetValue.current = {
        reminder: props.reminder
      };
    } else {
      if (resetValue.current) {
        // resetValue
        props.handleResetValue(resetValue.current);
      }
    }
  }, [editReminderState]);
  return (
    <>
      <div className="row mb-3">
        <div className="col-sm-3 col-form-label">{i18n.t("postJob.step7")}</div>
        <a
          className="col-sm-9 d-flex justify-content-between"
          style={{
            paddingTop: "calc(0.375rem + 1px)",
            paddingBottom: "calc(0.375rem + 1px)",
            borderBottom: "dashed 1px #0088cc",
            textDecoration: "none"
          }}
          href="#"
          onClick={handleReminderEdit}
        >
          {props.reminder ? props.reminder : "-"}
        </a>
      </div>
      <Dialog isOpen={editReminderState}>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="close"
            onClick={handleReminderDismiss}
          >
            <VisuallyHidden>Close</VisuallyHidden>
            <CloseIcon />
          </button>
        </div>
        <div className="form-group row">
          <label htmlFor="job-reminder-id" className="col-form-label">
            {i18n.t("postJob.reminder")}
          </label>
          <Field
            type="text"
            component="textarea"
            placeholder={i18n.t("postJob.job-reminder-placeholder")}
            name="reminder"
            id="job-reminder-id"
            className="form-control"
            rows="4"
          />
          <small className="form-text text-muted">
            *{i18n.t("postJob.step7-hint")}
          </small>
        </div>
        <div className="d-flex justify-content-end">
          <a
            href="#"
            className="btn btn-primary"
            onClick={handleSave}
          >
            {i18n.t("simplePost.save")}
          </a>
          <a href="#" className="btn btn-link" onClick={handleReminderCancel}>
            {i18n.t("simplePost.cancel")}
          </a>
        </div>
      </Dialog>
    </>
  );
}
