import React from "react";
import { Field } from "formik";
export default function Checkbox(props) {
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <div className="form-check form-check-inline">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="checkbox"
              name={props.name}
              value={props.value}
              checked={field.value.includes(props.value)}
              onChange={() => {
                if (field.value.includes(props.value)) {
                  const nextValue = field.value.filter(
                    value => value !== props.value
                  );
                  form.setFieldValue(props.name, nextValue);
                } else {
                  const nextValue = field.value.concat(props.value);
                  form.setFieldValue(props.name, nextValue);
                }
              }}
            />
            {props.displayValue}
          </label>
        </div>
      )}
    </Field>
  );
}
