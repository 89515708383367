import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import React, { Component } from "react";
import { toast } from "react-toastify";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import addHours from "date-fns/addHours";
import { i18n } from "./i18n";
import DatePicker from "react-datepicker";
import * as Api from "./Api";
import parseISO from "date-fns/parseISO";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
const OfferSchema = Yup.object().shape({
  jobStartTime: Yup.date().required(),
  jobEndTime: Yup.date().required(),
  offerExpireTime: Yup.date().required(),
  offerAddress: Yup.object().shape({
    description: Yup.string()
      .required()
      .min(1),
    latitude: Yup.number()
      .required()
      .notOneOf([0], "Invalid address"),
    longitude: Yup.number()
      .required()
      .notOneOf([0], "Invalid address")
  }),
  offerContact: Yup.string().required(),
  offerPhoneNumber: Yup.string().required()
});

class SendOffer extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   addressValue:
    //     props.company && props.company.address && props.company.address.name
    //       ? props.company.address.name
    //       : ""
    // };
    this.state = {
      addressValue: (props.job.address &&
        props.job.address.name &&
        props.job.address.name.length !== 0) ? (
        props.job.address.name
      ) : null
    };
    let startTime;
    if (
      props.job &&
      props.job.startingDate &&
      props.job.workingTime &&
      props.job.workingTime.from
    ) {
      const array = props.job.workingTime.from.split(":");
      const hour = parseInt(array[0], 10);
      const minute = parseInt(array[1], 10);
      startTime = setMinutes(
        setHours(parseISO(props.job.startingDate), hour),
        minute
      );
    } else {
      startTime = null;
    }

    let endTime;
    if (
      props.job &&
      props.job.endingDate && props.job.endingDate == "no limit"
    ) {
      endTime = null;

    } else {
      const array = props.job.workingTime.to.split(":");
      const hour = parseInt(array[0], 10);
      const minute = parseInt(array[1], 10);
      endTime = setMinutes(
        setHours(parseISO(props.job.endingDate), hour),
        minute
      );
    }
    this.initialValues = {
      jobStartTime: startTime,
      jobEndTime: endTime,
      permanent: props.job.endingDate === "no limit" ? true : false,
      // offerAddress: {
      //   description:
      //     props.company && props.company.address && props.company.address.name
      //       ? props.company.address.name
      //       : "",
      //   latitude:
      //     props.company &&
      //     props.company.address &&
      //     props.company.address.location &&
      //     props.company.address.location.coordinates
      //       ? props.company.address.location.coordinates[1]
      //       : 0,
      //   longitude:
      //     props.company &&
      //     props.company.address &&
      //     props.company.address.location &&
      //     props.company.address.location.coordinates
      //       ? props.company.address.location.coordinates[0]
      //       : 0
      // },
      offerAddress: {
        description:
          props.job && props.job.address && props.job.address.name
            ? props.job.address.name
            : "",
        latitude:
          props.job &&
            props.job.address &&
            props.job.address.location &&
            props.job.address.location.coordinates
            ? props.job.address.location.coordinates[1]
            : 0,
        longitude:
          props.job &&
            props.job.address &&
            props.job.address.location &&
            props.job.address.location.coordinates
            ? props.job.address.location.coordinates[0]
            : 0
      },
      offerContact:
        props.company && props.company.companyName
          ? props.company.companyName
          : "",
      offerPhoneNumber: props.company
        ? props.company.contact || props.company.mobile || ""
        : "",
      offerExpireTime: addHours(new Date(), 4)
    };
  }
  customInput = ({ value, onClick }) => (
    <button
      className="picker-readOnly form-control"
      onClick={onClick}
      type="button"
    >
      {value}
    </button>
  );
  render() {
    return (
      <Formik
        initialValues={this.initialValues}
        validationSchema={OfferSchema}
        onSubmit={(values, { setSubmitting }) => {
          Api.updateApplication(
            this.props.applicationId,
            {
              action: "sendOffer",
              offerContact: values.offerContact,
              offerPhoneNumber: values.offerPhoneNumber,
              offerAddress: {
                name: values.offerAddress.description,
                location: {
                  coordinates: [
                    values.offerAddress.longitude,
                    values.offerAddress.latitude
                  ],
                  type: "Point"
                }
              },
              jobStartTime: values.jobStartTime,
              jobEndTime: values.permanent ? "no limit" : values.jobEndTime,
              offerExpireTime: values.offerExpireTime
            },
            global._token
          )
            .then(json => {
              if (json._id) {
                setSubmitting(false);
                this.props.handleOfferDialogClose();
                toast.success("Send offer successed!", {
                  className: "alert alert-success"
                });
              } else {
                throw new Error("Send Offer");
              }
            })
            .catch(e => {
              toast.error(e.message, { className: "alert alert-danger" });
              setSubmitting(false);
            });
        }}
      >
        {({ values, isSubmitting, errors, touched }) => {
          return (
            <Form autoComplete="off">
              <div className="form-group">
                <label>{i18n.t("sendOffer.start-time")}</label>
                <div className="d-flex">
                  <Field
                    name="jobStartTime"
                    // component={}
                    render={({ field, form }) => (
                      <DatePicker
                        selected={field.value}
                        onChange={value => {
                          field.onChange(value);
                          form.setFieldValue(field.name, value);
                        }}
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm aa"
                        minDate={new Date()}
                        customInput={<this.customInput />}
                      />
                    )}
                  />

                  <ErrorMessage
                    className="invalid-feedback d-block"
                    name="jobStartTime"
                    component="div"
                  />
                </div>
              </div>

              <div className="form-group">
                <Field
                  name="permanent"
                  className="custom-control-input"
                  type="checkbox"
                  id="permanent-checkbox"
                  checked={values.permanent}
                />
                <label
                  className="custom-control-label"
                  htmlFor="permanent-checkbox"
                >
                  {i18n.t("postJob.no-end-date")}
                </label>
                <ErrorMessage
                  className="invalid-feedback"
                  name="permanent"
                  component="div"
                />
              </div>
              {!values.permanent ? <div className="form-group">
                <label>{i18n.t("sendOffer.end-time")}</label>
                <div className="d-flex">
                  <Field
                    name="jobEndTime"
                    // component={}
                    render={({ field, form }) => (
                      <DatePicker
                        selected={field.value}
                        onChange={value => {
                          field.onChange(value);
                          form.setFieldValue(field.name, value);
                        }}
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm aa"
                        minDate={new Date()}
                        customInput={<this.customInput />}
                      />
                    )}
                  />
                  <ErrorMessage
                    className="invalid-feedback d-block"
                    name="jobEndTime"
                    component="div"
                  />
                </div>
              </div> : null}


              <div className="form-group" style={{ position: "relative" }}>
                <label>{i18n.t("sendOffer.job-address")}</label>
                <Field
                  name="offerAddress"
                  render={({ field, form }) => (
                    <PlacesAutocomplete
                      highlightFirstSuggestion={true}
                      // {...field}
                      value={this.state.addressValue}
                      debounce={1000}
                      onChange={value => {
                        this.setState({
                          addressValue: value
                        });
                        form.setFieldValue("offerAddress.description", "");
                        form.setFieldValue("offerAddress.latitude", 0);
                        form.setFieldValue("offerAddress.longitude", 0);
                      }}
                      searchOptions={{
                        componentRestrictions: {
                          country: "ca"
                        },
                      }}
                      onSelect={(value, placeId) => {
                        if (!placeId) {
                        } else {
                          geocodeByAddress(value)
                            .then(result => {
                              this.setState({
                                addressValue: result[0].formatted_address
                              });
                              form.setFieldValue(
                                "offerAddress.description",
                                result[0].formatted_address
                              );
                              return getLatLng(result[0]);
                            })
                            .then(({ lat, lng }) => {
                              form.setFieldValue("offerAddress.latitude", lat);
                              form.setFieldValue("offerAddress.longitude", lng);
                            });
                        }
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading
                      }) => (
                        <div style={{ postion: "relative" }}>
                          <input
                            {...getInputProps({
                              placeholder: i18n.t("sendOffer.job-address-hint"),
                              className:
                                "form-control" +
                                (errors.offerAddress && touched.offerAddress
                                  ? " is-invalid"
                                  : "")
                            })}
                          />
                          <div
                            className="card"
                            style={{
                              position: "absolute",
                              top: "100%",
                              zIndex: 1,
                              marginTop: 8,
                              marginBottom: 8
                            }}
                          >
                            <div className="card rounded border-0">
                              <div className="list-group">
                                {loading && (
                                  <div className="list-group-item">
                                    Loading...
                                  </div>
                                )}
                                {suggestions.map(suggestion => {
                                  const className = suggestion.active
                                    ? "list-group-item active"
                                    : "list-group-item";
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  )}
                />
                <ErrorMessage
                  className="invalid-feedback d-block"
                  name="offerAddress.latitude"
                  component="div"
                />
              </div>

              <div className="form-group">
                <label>{i18n.t("sendOffer.contact-name")}</label>
                <Field
                  name="offerContact"
                  className={
                    "form-control" +
                    (errors.offerContact && touched.offerContact
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  className="invalid-feedback"
                  name="offerContact"
                  component="div"
                />
              </div>



              <div className="form-group">
                <label>{i18n.t("sendOffer.contact")}</label>
                <Field
                  name="offerPhoneNumber"
                  className={
                    "form-control" +
                    (errors.offerPhoneNumber && touched.offerPhoneNumber
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  className="invalid-feedback"
                  name="offerPhoneNumber"
                  component="div"
                />
              </div>

              <div className="form-group">
                <label>{i18n.t("sendOffer.expire-time")}</label>
                <div className="d-flex">
                  <Field
                    name="offerExpireTime"
                    render={({ field, form }) => (
                      <DatePicker
                        selected={field.value}
                        onChange={value => {
                          field.onChange(value);
                          form.setFieldValue(field.name, value);
                        }}
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm aa"
                        minDate={new Date()}
                        customInput={<this.customInput />}
                      />
                      // <DateTimePicker
                      //   TextFieldComponent="input"
                      //   minutesStep={5}
                      //   onChange={value => {
                      //     field.onChange(value);
                      //     form.setFieldValue(field.name, value);
                      //   }}
                      //   value={field.value}
                      //   className={
                      //     "picker-readOnly form-control" +
                      //     (errors.offerExpireTime && touched.offerExpireTime
                      //       ? " is-invalid"
                      //       : "")
                      //   }
                      // />
                    )}
                  />
                  <ErrorMessage
                    className="invalid-feedback d-block"
                    name="offerExpireTime"
                    component="div"
                  />
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-primary my-2"
                disabled={isSubmitting}
              >
                {i18n.t("sendOffer.send-offer")}
              </button>
            </Form>
          )
        }}
      </Formik>
    );
  }
}

export default SendOffer;
