import React, { Component, useState, useEffect } from "react";
import NetUtil from "./NetUtil";
import config from "./config";
import { Footer, SoftSkillNoStyle } from "./PageComponents";
import PositionIcon from "./img/jobdetail/positionIcon@3x.png";
import JobTypeIcon from "./img/jobdetail/jobTypeIcon@3x.png";
import SalaryIcon from "./img/jobdetail/salaryIcon@3x.png";
import WorkingDayIcon from "./img/jobdetail/workingDayIcon@3x.png";
import Header from "./component/Header";
import { Helmet } from "react-helmet";
import { formatDistance } from "date-fns";
import { i18n } from "./i18n";
import SatisfiedIcon from "./img/jobdetail/satisfied@3x.png";
import { enUS, zhCN } from "date-fns/locale";
import SendDownloadLink from "./component/SendDownloadLink";
import * as Api from "./Api";
import { toast } from "react-toastify";

function EmployeeJobDetail(props) {
  const [data, setData] = useState(null);
  useEffect(() => {
    Api.getCommonJobDetail(props.jobId)
      .then(json => {
        if (json && json._id) {
          setData(json);
        }
      })
      .catch(error => {
        toast.error(error.message, { className: "alert alert-danger" });
      });
  }, [props.id]);
  return (
    <>
      <Header location={props.location} />
      <div className="container-fluid mt-2">
        <div className="row">
          <div className="col-lg-10 offset-lg-1 col-12">
            <div className="card bg-white border-0 card-shadow">
              <div className="card-body">
                {data && (
                  <JobInfo
                    companyIcon={
                      data.external_company_name
                        ? "https://storage.googleapis.com/hr_api_storage/default/img_default_employer.png"
                        : data.companyIcon
                    }
                    companyName={
                      data.external_company_name
                        ? data.external_company_name
                        : data.companyName
                    }
                    jobTitle={data.title}
                    positionTotal={data.positionTotal}
                    positionAccepted={data.positionAccepted}
                    type={data.type}
                    salary={data.salary}
                    shifts={data.shifts}
                    workingTime={data.workingTime}
                    workingDays={data.workingDays}
                    startingDate={data.startingDate}
                    createdAt={data.createdAt}
                    mandatorySkills={data.mandatorySkillRequirements}
                    preferedSkills={data.skillRequirements}
                    jobDescription={data.description}
                    addressName={data.address}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <Header /> */}

        {/* addressName={this.state.data.address? this.state.data.address.name :""} */}

        {/* <div className="d-flex justify-content-center mt-3">
          Download and Apply on Our App
        </div> */}
        <SendDownloadLink />
        <Footer />
      </div>
    </>
  );
}

class JobInfo extends Component {
  renderSkills(skills) {
    // console.log(skills);
    let reHard = [];
    let reSoft = [];
    skills.forEach(skill => {
      if (skill.type === "soft") {
        reSoft.push(
          <div style={{ minWidth: "50%" }} key={skill._id}>
            <SoftSkillNoStyle
              name={i18n.language === "zh" ? skill.name_cn : skill.name}
              value={0}
              key={skill._id}
            />
          </div>
        );
      } else {
        reHard.push(
          <div style={{ minWidth: "50%" }} key={skill._id}>
            <span className="mr-1">
              <img
                style={{ maxHeight: 18 }}
                src={SatisfiedIcon}
                alt="Satisfied icon"
              />
            </span>
            <span className="m-0">
              {i18n.language === "zh" ? skill.name_cn : skill.name}
            </span>
          </div>
        );
      }
    });
    return (
      <>
        {reHard && reHard.length !== 0 && (
          <div className="d-flex flex-wrap my-3 w-100">{reHard}</div>
        )}
        {reSoft && reSoft.length !== 0 && (
          <div className="d-flex flex-wrap my-3 w-100">{reSoft}</div>
        )}
      </>
    );
  }

  render() {
    return (
      <div className="mx-auto" style={{ maxWidth: 550 }}>
        <Helmet>
          <html lang={i18n.language === "en" ? "en-CA" : "zh"} />
          <title>
            {this.props.companyName} {i18n.t("seo.hiring")}{" "}
            {this.props.jobTitle} | Hirine
          </title>
          <meta
            name="description"
            content={
              this.props.companyName +
              " " +
              i18n.t("seo.hiring") +
              " " +
              this.props.jobTitle +
              " " +
              this.props.jobDescription
            }
          />
          <meta name="keywords" content={this.props.jobDescription} />
        </Helmet>
        <div className="mx-2 text-center">
          <div>
            <img
              className="mb-3"
              style={{ maxHeight: 100 }}
              src={this.props.companyIcon}
              alt={"company logo"}
            />
          </div>
          <div>
            <h1 className="mb-2 font-size-1-5">{this.props.jobTitle}</h1>
          </div>
          <div>
            <span className="text-muted h5">{this.props.companyName}</span>
          </div>
          <div>
            <span className="text-muted d-none">
              {this.props.addressName
                ? "Job location: " + this.props.addressName.name
                : "Job location: Great Toronto Area"}
            </span>
          </div>
        </div>

        <div className="d-flex align-items-center py-1">
          <div className="mx-2">
            <img
              style={{ maxWidth: 18 }}
              src={PositionIcon}
              alt="Company position icon"
            />
          </div>
          <div>
            {this.props.positionTotal === 0
              ? i18n.t("jobDetail.no-limit")
              : this.props.positionAccepted + " / " + this.props.positionTotal}
          </div>
        </div>
        <div className="d-flex align-items-center py-1">
          <div className="mx-2">
            <img
              style={{ maxWidth: 18 }}
              src={JobTypeIcon}
              alt="Job type icon"
            />
          </div>
          <div>{i18n.t(`jobType.${this.props.type}`)}</div>
        </div>
        <div className="d-flex align-items-center py-1">
          <div className="mx-2">
            <img style={{ maxWidth: 18 }} src={SalaryIcon} alt="Salary icon" />
          </div>
          <div>
            {this.props.salary && this.props.salary.negotiable ? (
              <span>{i18n.t("postJob.negotiable")}</span>
            ) : (
              <>
                <span>
                  {this.props.salary &&
                    i18n.t(`postJob.${this.props.salary.currency}`)}
                </span>
                <span className="ml-1">
                  {this.props.salary && this.props.salary.value}
                  {this.props.salary &&
                  this.props.salary.maxValue &&
                  this.props.salary.maxValue !== 0
                    ? " - " + this.props.salary.maxValue
                    : ""}
                </span>
                <span className="ml-1">
                  {this.props.salary &&
                    i18n.t(`payPeriod.${this.props.salary.unit}`)}
                </span>
              </>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center py-1">
          <div className="mx-2">
            <img
              style={{ maxWidth: 18 }}
              src={WorkingDayIcon}
              alt="Working day icon"
            />
          </div>
          <div>
            {this.props.workingTime.from +
              " - " +
              this.props.workingTime.to +
              ", " +
              this.props.workingDays &&
              this.props.workingDays.length !== 0 &&
              this.props.workingDays
                .map(v => i18n.t(`weekdays.${v}`))
                .join(", ") +
                "  " +
                new Date(this.props.startingDate).toLocaleString(
                  "en",
                  displayFullDate
                )}
          </div>
        </div>
        <div className="mx-2 row mt-3 d-flex justify-content-between">
          
        </div>

        {this.props.mandatorySkills && this.props.mandatorySkills.length > 0 ? (
          <>
            <div className="mx-2 row mt-4">
              <h4 className="font-size-1-3 font-weight-normal">
                {i18n.t("jobManage.mandatory-skill")}
              </h4>
            </div>
            <div className="mx-2 row">
              {this.renderSkills(this.props.mandatorySkills)}
            </div>
          </>
        ) : null}

        {this.props.preferedSkills && this.props.preferedSkills.length > 0 ? (
          <>
            <div className="mx-2 row mt-4">
              <h4 className="font-size-1-3 font-weight-normal">
                {i18n.t("jobManage.preferred-skill")}
              </h4>
            </div>
            <div className="mx-2 row">
              <div className="d-flex flex-wrap my-3">
                {this.renderSkills(this.props.preferedSkills)}
              </div>
            </div>
          </>
        ) : null}

        {this.props.jobDescription && this.props.jobDescription.length > 0 ? (
          <>
            <div className="mx-2 row mt-3">
              <h3 className="font-size-1-3 font-weight-normal">
                {i18n.t("jobManage.description")}
              </h3>
            </div>
            <div className="mx-2 row" style={{ whiteSpace: "pre-line" }}>
              {this.props.jobDescription}
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

const displayFullDate = {
  year: "numeric",
  month: "numeric",
  day: "numeric"
};

export { EmployeeJobDetail };
