import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription
} from "@reach/alert-dialog";
import React, { useState, useRef } from "react";
import { i18n } from "./i18n";

export function ConfirmButton({
  confirmHandler,
  buttonType = "primary",
  link = false,
  ...props
}) {
  const [showDialog, setDialogState] = useState(false);
  const closeRef = useRef(null);
  function closeDialog() {
    setDialogState(false);
  }
  function confirm() {
    confirmHandler();
    closeDialog();
  }
  function openConfirm(event) {
    if (link) {
      event.preventDefault();
    }
    setDialogState(true);
  }
  return (
    <>
      {link ? (
        <a
          href="#"
          className={props.className}
          style={props.style}
          onClick={openConfirm}
        >
          {props.children}
        </a>
      ) : (
        <button
          className={props.className}
          style={props.style}
          onClick={openConfirm}
          type="button"
        >
          {props.children}
        </button>
      )}
      {showDialog && (
        <AlertDialog leastDestructiveRef={closeRef} onDismiss={closeDialog}>
          <div className="modal-header">
            <AlertDialogLabel>
              <h5>{i18n.t("confirmbox.confirm-title")}</h5>
            </AlertDialogLabel>
          </div>
          <div className="modal-body">
            <AlertDialogDescription>
              {i18n.t("confirmbox.confirm-text")}
            </AlertDialogDescription>
          </div>

          <div className="modal-footer">
            <button
              ref={closeRef}
              onClick={closeDialog}
              type="button"
              className="btn btn-link"
            >
              {i18n.t("confirmbox.cancel")}
            </button>
            <button
              onClick={confirm}
              type="button"
              className={`btn btn-${buttonType}`}
            >
              {i18n.t("confirmbox.confirm")}
            </button>
          </div>
        </AlertDialog>
      )}
    </>
  );
}
